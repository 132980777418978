
import React, { Component } from 'react';
import { Col, Row, Container, Form, Button } from 'react-bootstrap'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import './MemberUpdate.scss'
import actions from '../modules/actions';

class MemberUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstNameVal: "",
            phoneVal: "",
            messageVal: "",
            lastNameVal: "",
            passwordVal:"",
            confirmPasswordVal:"",
            id:"",
            error: {},
            invalid: false

        }
    }

    onChangetext = (e) => {
        this.setState({
            [`${e.target.name}`]: e.target.value
        });
    }
    isValid = () => {
        const {
            phoneVal: phone,
            firstNameVal: first_name,
            lastNameVal: last_name,
            passwordVal: password,
            confirmPasswordVal: confpass,

        } = this.state;
        let error = {};
        if (first_name === "" || first_name.length > 35 || !/[A-Za-z]/.test(first_name)) {
            error.first_name = true;
            this.setState({ error });
            return false;
        }
        if (last_name === "" || last_name.length > 35 || !/[A-Za-z]/.test(last_name)) {
            error.last_name = true;
            this.setState({ error });
            return false;
        }
        if (phone === "" || !/[0-9]/.test(phone)) {
            error.phone = true;
            this.setState({ error });
            return false;
        }
        if (password === ""||password.length<6) {
            error.password = true;
            this.setState({ error });
            return false;
        }
        if (confpass === ""||confpass.length<6) {
            error.confpass = true;
            this.setState({ error });
            return false;
        }

        if (confpass !== password) {
            error.confpass = true;
        } this.setState({ error });

        if (error.confpass) {
            return false;
        }


        return true;
    }

    onSignUp = () => {
        const {
            firstNameVal: first_name,
            lastNameVal: last_name,
            phoneVal: phone_number,
            passwordVal:password
            
        } = this.state;
        if (this.isValid()) {
            const {id}= this.state
            const data = {
                phone_number,
                first_name,
                password,
                last_name
            }
            const handleFailure = (data) => {
                alert("SignUP Unsuccess")
            };

            const handleSuccess = (data) => {
                alert("SignUP Success")
                this.props.history.push('/')
            };

            this.props.actions.updateMember(handleSuccess, handleFailure, data, id );
        }
    }
    
    componentDidMount() {
        const { location: { search }  } = this.props;
        if(_.includes(search, 'token')){
            const id = (_.split(search, '='))[1];
    
            if(!_.isEmpty(id)){
                const handleSuccess = (data) => {
                   
                }
                const handleFailure = () => {
    
                }
            this.setState({id : id})
        
    
            }
        }
    }


    render() {
        const { phoneVal, passwordVal, confirmPasswordVal,error} = this.state
        return (
            <div className="contact-container">
                
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 230">
                    <path fill="#e42126" fill-opacity="1" d="M0,160L48,144C96,128,192,96,288,96C384,96,480,128,576,154.7C672,181,768,203,864,181.3C960,160,1056,96,1152,69.3C1248,43,1344,53,1392,58.7L1440,64L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
                </svg>
                <Row className="contents text-center">

                    <Col md="4" lg="4">
                    </Col>
                    <Col md="4" lg="4">
                        <Form.Group>
                            <Form.Control
                                size="lg" 
                                name="firstNameVal" 
                                onChange={this.onChangetext} 
                                type="text" 
                                placeholder="First Name" 
                                />
                                 {error.first_name ? <span style={{ color: "red" }}>First Name Is Required (Upto 35 Characters)</span> : ''}<br />
                            <Form.Control
                                size="lg"
                                name="lastNameVal" 
                                onChange={this.onChangetext}
                                type="text" 
                                placeholder="Last Name"
                                 />
                                 {error.last_name ? <span style={{ color: "red" }}>Last Name Is Required (Upto 35 Characters)</span> : ''}<br />
                            <Form.Control
                                size="lg" 
                                name="phoneVal" 
                                value={phoneVal} 
                                onChange={this.onChangetext} 
                                type="phone" 
                                placeholder="Phone number" 
                                />
                            {error.phone ? <span style={{ color: "red" }}>Please Enter vaild phone number</span> : ''}
                            <br />
                            <Form.Control
                                size="lg" 
                                name="passwordVal" 
                                value={passwordVal} 
                                onChange={this.onChangetext} 
                                type="password" 
                                placeholder="Enter Password" 
                                />
                                {error.password ? <span style={{ color: "red" }}>Password Must Contain At Least 6 Characters</span> : ''} <br />
                            <Form.Control
                                size="lg" 
                                name="confirmPasswordVal"
                                value={confirmPasswordVal}
                                onChange={this.onChangetext} 
                                type="password" 
                                placeholder="Confirm Password" 
                                />
                                {error.confpass ? <span style={{ color: "red" }}>The Password and confirm password do not match</span> : ''} <br />
                            <Button className="button" onClick={this.onSignUp}> Sign Up</Button>
                        </Form.Group>
                        <br />
                      

                    </Col>

                </Row>


            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
       
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberUpdate));


