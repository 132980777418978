
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ResetPasswordMarkup from '../components/ResetPasswordMarkup';
import _ from 'lodash';
import actions from '../modules/actions';
import {Toast} from "../../../shared/toast/Toast"

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: "",
            confirmPassword: "",
            confirmError: '',
            hasPasswordReset: false,
            errors :{}
        }
    }


    isValid = () => {
        const {
        
            newPassword: newPassword,
            confirmPassword: confpass,
        } = this.state;
        let errors = {};

        if (newPassword === ""||newPassword.length<6) {
            errors.newPassword = true;
            this.setState({ errors });
            return false;
        }
        if (confpass === "") {
            errors.confpass = true;
            this.setState({ errors });
            return false;
        }

        if (confpass !== newPassword) {
            errors.confpass = true;
        } this.setState({ errors });

        if (errors.confpass) {
            return false;
        }

        return true;
    }
    onInputChange = (e) => {
        this.setState({
            [`${e.target.name}`]: e.target.value,
            confirmError: ''
        });
    }

    onConfirmBlur = () => {
        const { newPassword, confirmPassword } = this.state;
        if (newPassword !== confirmPassword) {
            this.setState({
                confirmError: 'Passwords do not match.'
            })
        } else {
            this.setState({
                confirmError: ''
            })
        }
    }


    onNewPasswordBlur = () => {
        const { confirmPassword } = this.state;
        if (!!confirmPassword) {
            this.onConfirmBlur();
        }
    }

    onResetPassword = () => {
        const { newPassword, confirmPassword: password, confirmError } = this.state;    
        const { token :{token:{token}}} = this.props
        if (token){
        if (this.isValid()) {
            const data = {
                token,
                password
            };
        
        const handleSuccess = (data) => {
            this.setState({
                hasPasswordReset: true
            })
            Toast.show({
                message: "Password Changed Successfully",
                timeout: 4000,
                intent: 'success'
            })
            this.props.history.push('/login');
        }
        this.props.actions.resetPassword(handleSuccess, () => { }, data);  
    } 
    }

    if (!token){
    Toast.show({
        message: "Invalid link",
        timeout: 4000,
        intent: 'danger'
    })
    this.props.history.push('/login');
    }


}

    onClose = () => {
        this.setState({
            newPassword: "",
            confirmPassword: "",
            hasPasswordReset: false
        }, () => {
            this.props.onCloseModal();
        })
    }


    componentDidMount() {
    const { location: { search }  } = this.props;
    if(_.includes(search, 'token')){
        const token = (_.split(search, '='))[1];

        if(!_.isEmpty(token)){
            const handleSuccess = (data) => {
               
            }
            const handleFailure = () => {

            }
        this.props.actions.tokenVerify(handleSuccess, handleFailure, {token} );

        }
    }
}
    componentWillMount() {
            // const { token: { token } } = this.props;
            // console.log(">>>>>>>>>>>>>>>>>",token)
            // // const urlParts = token.split("/");
            // // this.setState({ mode: urlParts[3], id: urlParts[4] })
            // // if(urlParts[3] && urlParts[4]) {
            // //     const handleFailure = (res) => {
            // //         if (res.status === 401) {
            // //         }
            // //     }
            // //     const handleSuccess = (body) => {
                    
            //     // }
            //     this.props.actions.tokenVerify( );              
            
    }

    render() {
        const { newPassword, confirmPassword, confirmError, hasPasswordReset ,errors} = this.state;
        return (
            <ResetPasswordMarkup
            newPassword={newPassword}
            confirmPassword={confirmPassword}
            confirmError={confirmError}
            hasPasswordReset={hasPasswordReset}
            errors={errors}
            onInputChange={this.onInputChange}
            onNewPasswordBlur={this.onNewPasswordBlur}
            onResetPassword={this.onResetPassword}
            onClose={this.onClose}
            />
        )
       
    }
};

const mapStateToProps = (state) => {
    return {
        token: state.Auth,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword));
