import React from 'react';
import { Container, Button } from 'react-bootstrap';
import './ReportMarkup.scss';
import { Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chart from 'react-apexcharts'
import { ZLoader } from '../../../shared/loader/ZLoader'
import CallLogChart from './CallLogChart'
import CounselorWise from './CounslerWiseChart'
import DirectVsOrganicChart from './DirectVsOrganicChart'
import Assigned from './AssignPieChart';
import APAChart from './APAChart'
import { DateRangeInput } from "@blueprintjs/datetime";
import moment from 'moment';
const ReportMarkup = (props) => {
     const { isLoading, callLogSeries,handleRangeChange,start_date,end_date,getreport, callLogOptions, counselorWiseSeries, counselorWiseOptions, directOrganicOptions, directOrganicSeries, assignOptions,assignSeries, aPAOptions,aPASeries, yopmailVal, onChangeInput } = props


     return (
          <div className="report-container">
               <Container>
                    {isLoading ? <ZLoader /> : null}
                    
                    <Row style={{marginBottom:10}}> 
                    <Col>
                         <DateRangeInput
                              formatDate={date => moment(date).format('DD/MM/YYYY')}
                              onChange={(event)=>handleRangeChange(event)}
                              parseDate={str => new Date(str)}
                              allowSingleDayRange={true}
                              // defaultValue={[moment().add(-1, "weeks").toDate(),moment().add(0, "days").toDate()]}
                              value={[start_date ? start_date:null, end_date ? end_date:null]}
                              // timePickerProps={{precision:'minute'}}

                         />
                    </Col>
                         <Col>
                              <Button onClick={getreport}>
                                   Get Report
                              </Button>
                         </Col>
                         {/* <Col className="check-box">
                         <Form.Group >
                            <Form.Check
                                name={'yopmailVal'}
                                type="checkbox"
                                label="yopmail"
                                value={yopmailVal}
                                checked={yopmailVal ? true: false}
                                onChange={(event) => onChangeInput(event.target.name, event.target.checked)}

                            />
                        </Form.Group>
                        </Col> */}
                    </Row>
                    <Row>
                         <Col>
                         {callLogSeries && callLogOptions ?
                         <div className="chart1">
                              <CallLogChart
                                   callLogSeries={callLogSeries}
                                   callLogOptions={callLogOptions}
                              />
                         </div>
                         :''
                         }
                         </Col>
                         <Col>
                         {counselorWiseSeries && counselorWiseOptions ?
                         <div className="chart2">
                              <CounselorWise
                                   counselorWiseSeries={counselorWiseSeries}
                                   counselorWiseOptions={counselorWiseOptions}
                              />
                              </div>
                              :''
                         }
                         </Col>
                    </Row>
                    <Row>
                         <Col>
                         {aPAOptions && aPASeries ?
                              <div className="chart3">

                              <APAChart
                                   aPAOptions={aPAOptions}
                                   aPASeries = {aPASeries}
                              />
                              </div>
                         :''
                         }
                         </Col>
                         <Col>
                         {assignOptions && assignSeries ?
                         <div className="chart4">

                              <Assigned
                                 assignOptions={assignOptions}
                                 assignSeries={assignSeries}
                              />
                              </div>
                              :''
                         }
                         </Col>
                    </Row>
               </Container>
          </div>
     )
}

export default ReportMarkup;
