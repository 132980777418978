import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormGroup, InputGroup, Button } from "@blueprintjs/core";
import { TimePicker, TimePrecision } from "@blueprintjs/datetime";
import _ from 'lodash';
import moment from 'moment';
import './QuestionMarkup.scss';
import mappingImg from '../../../assets/3.png';
import { ZLoader } from '../../../shared/loader/ZLoader';

const QuestionMarkup = (props) => {
    const {userRoleAccess,character, hideItNow, isLoading,inputquestion,onBack, disabled, edit,wizard, create, update, onEdit, inputs, onChange,question } = props;
    console.log("inputs",inputs,"inputquestion",inputquestion)
    let  issame=JSON.stringify(inputs)===JSON.stringify(inputquestion)
    console.log("issame",issame,"props",props)
    
    return (
        <Container className="questuin-settings">
            {isLoading? <ZLoader/> : null}
            {character ?<div className={'character-container animate '}>
            <img src={mappingImg} />
            <div className="cloud">
                <div><p className="cloud-text">Create questions for the interview</p></div>
                <div><p className="cloud-text">provide question, answering and thinking time</p></div>
                <div><p className="cloud-text"></p>for an interview</div>
                <div className="text-right"><Button variant="light" onClick={hideItNow} >Next</Button></div>
            </div>
        </div>:null}
                <Row className="header-container">
                    <Col md={6}>
            <h1 >{edit ? "Update Question" : "Create Question"}</h1>
            </Col>
            <Col md={6} className="text-right button-container">
                { wizard ? '' :< a href="/questions" ><Button className="create-button back"  text="BACK" /></a>}
               {userRoleAccess[2]==1? <Button className="create-button" onClick={onEdit} hidden={!disabled} intent="warning" text={"EDIT"} />:''}
               {userRoleAccess[1]==1 || userRoleAccess[2]==1? <Button className={issame ? "create-button disabled" : "create-button"} disabled={issame} onClick={edit ? update : create} hidden={disabled} text={edit ? "UPDATE" : "CREATE"}/>:''}
            </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup
                        label="Question"
                        labelFor="text-input"
                        labelInfo="*"
                        //helperText="E.g Why do you wish to study in the UK?"

                    >
                        <InputGroup
                            value={inputs.question}
                            disabled={disabled}
                            name={"question"}
                            placeholder="E.g Why do you wish to study in the UK?"
                            onChange={(event) => {
                                onChange(event.target.value, 'question')
                            }
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        helperText="hh:mm:ss (E.g 0:1:00 ie 0 Hours 1 minutes 00 Seconds)"
                        label="Answer Timing"
                        labelInfo="*"
                    >
                        <TimePicker
                            precision={TimePrecision.SECOND}
                            disabled={disabled}
                            showArrowButtons={true}
                            onChange={(event) => {
                                const value = {
                                    hours: moment(event).format('HH'),
                                    minutes: moment(event).format('mm'),
                                    seconds: moment(event).format('ss'),
                                }
                                onChange(value, 'answer', event, 'answerValue')}
                            }
                            value={inputs.answerValue}
                        />
                    </FormGroup>
                    <FormGroup
                        helperText="hh:mm:ss (E.g 0:1:00 ie 0 Hours 1 minutes 00 Seconds)"
                        label="Thinking Timing."
                        labelInfo="*"
                    >
                        <TimePicker
                            precision={TimePrecision.SECOND}
                            disabled={disabled}
                            showArrowButtons={true}
                            onChange={(event) => {
                                const value = {
                                    hours: moment(event).format('HH'),
                                    minutes: moment(event).format('mm'),
                                    seconds: moment(event).format('ss'),
                                }
                                onChange(value, 'thinking', event, 'thinkingValue')
                            }
                            }
                            value={inputs.thinkingValue}
                        />
                    </FormGroup>
                    {/* <Col md={12} className="text-right">
                    {userRoleAccess[1]==1 || userRoleAccess[2]==1? <Button onClick={edit ? update : create} hidden={disabled} rightIcon="arrow-right" intent="success" text={edit ? "UPDATE":"CREATE"} disabled={inputs.question && inputs.answerValue && inputs.thinkingValue ? false : true}/>:''}
                    </Col>
                    <Col md={12} className="text-right">
                    {userRoleAccess[2]==1? <Button rightIcon="arrow-right" onClick={onEdit} hidden={!disabled} intent="warning" text={"EDIT"} />:''}
                    </Col> */}
                </Col>
            </Row>
        </Container>
    )
}

export default QuestionMarkup;