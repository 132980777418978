import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import CourseMarkup from "../Component/CourseMarkup";
import { bindActionCreators } from 'redux';
import actions from '../modules/action';
import { Toast } from '../../../shared';
import CourseButtonFormatter from "../../../shared/table/CourseButtonFormatter";
import CourseTextFormatter from "../../../shared/table/CourseTextFormatter";
import { Type } from 'react-bootstrap-table2-editor';
import { NonIdealState } from "@blueprintjs/core";
import AccessDenied from '../../../shared/denied/Denied'

class CourseTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageIndex: 1,
			course: {},
			courseNameVal: "", courseTypeVal: "", sscVal: "", hscVal: "", dipVal: "", bacVal: "", masVal: "", greVal: "", satVal: "", actVal: "", gmatVal: "", ieltsVal: "", doubleMasterVal: "", previousBreachVal: "", gapVal: "", experienceVal: "", visaRejectionVal: "",
			error: {},
			viewAccess: true,
			showModal: false,
			showFormModal: false,
			courseFile: null,
			scrollview: true,
			courseCount: null,
			character: false,
			count: 0,
			userRoleAccess: [],
			courses: [],
			editingIndex: null,
			isEditing:false,
			columns: [
				{
					dataField: '',
					text: 'Sr.No',
					headerClasses: 'table-head1 numcont',
					editable: false,
					hidden: false,
				},
				{
					dataField: 'course_name',
					text: 'Course Name',
					hidden: false,
					classes: 'editable',
					headerClasses: 'table-head',
					formatter: CourseTextFormatter,
					formatExtraData: {
						onChange: this.onInputChange,
						onClick: this.onEditRow

					},
					validator: (newValue, row, column) => {
						if (newValue === "") {
							return {
								valid: false,
								message: 'Please Enter Course Name',
							};
						}
					},
					title: (cell, row, rowIndex, colIndex) => `${cell}`
				},
				{
					dataField: 'course_type',
					text: 'Course Type',
					hidden: false,
					classes: 'editable',
					headerClasses: 'table-head',
					formatter: CourseTextFormatter,
					formatExtraData: {
						onChange: this.onInputChange,
						onClick: this.onEditRow
					},
					editor: {
						type: Type.SELECT,
						options:
							[
								{
									value: 'Bachelors',
									label: 'Bachelors'
								},
								{
									value: 'Diploma',
									label: 'Diploma'
								},
								{
									value: 'Masters',
									label: 'Masters'
								},
								
							],
					},

					title: (cell, row, rowIndex, colIndex) => `${cell}`
				},
				{
					dataField: 'ssc_percent',
					text: 'SSC %',
					hidden: false,
					classes: 'editable',
					headerClasses: 'table-head numcont',
					formatter: CourseTextFormatter,
					formatExtraData: {
						onChange: this.onInputChange,
						onClick: this.onEditRow
					},
					validator: (newValue, row, column) => {
						if (newValue === "") {
							return { valid: true };
						}
						if (!/^0|[0-9](\.\d{1,4})? *%?$/.test(newValue)) {
							return {
								valid: false,
								message: 'Please Enter Numeric Value'
							};
						}
					},
					title: (cell, row, rowIndex, colIndex) => `${cell}`
				},
				{
					dataField: 'hsc_percent',
					text: 'HSC %',
					hidden: false,
					classes: 'editable',
					headerClasses: 'table-head numcont',
					formatter: CourseTextFormatter,
					formatExtraData: {
						onChange: this.onInputChange,
						onClick: this.onEditRow
					},
					validator: (newValue, row, column) => {
						if (newValue === "") {
							return { valid: true };
						}
						if (!/^0|[0-9](\.\d{1,4})? *%?$/.test(newValue)) {
							return {
								valid: false,
								message: 'Please Enter Numeric Value'
							};
						}
					},
					title: (cell, row, rowIndex, colIndex) => `${cell}`
				},
				{
					dataField: 'diploma_percent',
					text: 'Diploma %',
					hidden: false,
					classes: 'editable',
					headerClasses: 'table-head numcont',
					formatter: CourseTextFormatter,
					formatExtraData: {
						onChange: this.onInputChange,
						onClick: this.onEditRow
					},
					validator: (newValue, row, column) => {
						if (newValue === "") {
							return { valid: true };
						}
						if (!/^0|[0-9](\.\d{1,4})? *%?$/.test(newValue)) {
							return {
								valid: false,
								message: 'Please Enter Numeric Value'
							};
						}
					},
					title: (cell, row, rowIndex, colIndex) => `${cell}`
				},
				{
					dataField: 'bac_percent',
					text: 'UG %',
					hidden: false,
					classes: 'editable',
					headerClasses: 'table-head numcont',
					formatter: CourseTextFormatter,
					formatExtraData: {
						onChange: this.onInputChange,
						onClick: this.onEditRow
					},
					validator: (newValue, row, column) => {
						if (newValue === "") {
							return { valid: true };
						}
						if (!/^0|[0-9](\.\d{1,4})? *%?$/.test(newValue)) {
							return {
								valid: false,
								message: 'Please Enter Numeric Value'
							};
						}
					},
					title: (cell, row, rowIndex, colIndex) => `${cell}`
				},
				{
					dataField: 'mas_percent',
					text: 'PG %',
					classes: 'editable',
					hidden: false,
					headerClasses: 'table-head numcont',
					formatter: CourseTextFormatter,
					formatExtraData: {
						onChange: this.onInputChange,
						onClick: this.onEditRow
					},
					validator: (newValue, row, column) => {
						if (newValue === "") {
							return { valid: true };
						}
						if (!/^0|[0-9](\.\d{1,4})? *%?$/.test(newValue)) {
							return {
								valid: false,
								message: 'Please Enter Numeric Value'
							};
						}
					},
					title: (cell, row, rowIndex, colIndex) => `${cell}`
				},
				{
					dataField: 'ielts',
					text: 'IELTS',
					classes: 'editable',
					hidden: false,
					headerClasses: 'table-head numcont',
					formatter: CourseTextFormatter,
					formatExtraData: {
						onChange: this.onInputChange,
						onClick: this.onEditRow
					},
					editor: {
						type: Type.SELECT,
						options:
							[{
								value: '1.0',
								label: '1.0'
							},
							{
								value: '1.5',
								label: '1.5'
							},
							{
								value: '2.0',
								label: '2.0'
							},
							{
								value: '2.5',
								label: '2.5'
							},
							{
								value: '3.0',
								label: '3.0'
							},
							{
								value: '3.5',
								label: '3.5'
							},
							{
								value: '4.0',
								label: '4.0'
							},
							{
								value: '4.5',
								label: '4.5'
							},
							{
								value: '5.0',
								label: '5.0'
							},
							{
								value: '5.5',
								label: '5.5'
							},
							{
								value: '6.0',
								label: '6.0'
							},
							{
								value: '6.5',
								label: '6.5'
							},
							{
								value: '7.0',
								label: '7.0'
							},
							{
								value: '7.5',
								label: '7.5'
							},
							{
								value: '8.0',
								label: '8.0'
							},
							{
								value: '8.5',
								label: '8.5'
							},
							{
								value: '9.0',
								label: '9.0'
							}],
					},
					title: (cell, row, rowIndex, colIndex) => `${cell}`
				},
				{
					dataField: 'gre',
					text: 'GRE',
					hidden: false,
					classes: 'editable',
					headerClasses: 'table-head numcont',
					formatter: CourseTextFormatter,
					formatExtraData: {
						onChange: this.onInputChange,
						onClick: this.onEditRow
					},
					validator: (newValue, row, column) => {
						if (newValue === "") {
							return { valid: true };
						}
						if (!/^[0-9]\d{2}$/.test(newValue)) {
							return {
								valid: false,
								message: 'Please Enter Numeric Value Between 130 - 170'
							};
						}
					},
					title: (cell, row, rowIndex, colIndex) => `${cell}`
				},
				{
					dataField: 'sat',
					text: 'SAT',
					hidden: false,
					classes: 'editable',
					headerClasses: 'table-head numcont',
					formatter: CourseTextFormatter,
					formatExtraData: {
						onChange: this.onInputChange,
						onClick: this.onEditRow
					},
					validator: (newValue, row, column) => {
						if (newValue === "") {
							return { valid: true };
						}
						if (!/^[0-9]\d{2,3}$/.test(newValue)) {
							return {
								valid: false,
								message: 'Please Enter Numeric Value Between 400 - 1600'
							};
						}
					},
					title: (cell, row, rowIndex, colIndex) => `${cell}`
				},
				{
					dataField: 'act',
					text: 'ACT',
					hidden: false,
					classes: 'editable',
					headerClasses: 'table-head numcont',
					formatter: CourseTextFormatter,
					formatExtraData: {
						onChange: this.onInputChange,
						onClick: this.onEditRow
					},
					validator: (newValue, row, column) => {
						if (newValue === "") {
							return { valid: true };
						}
						if (!/^[0-9]\d{0,1}$/.test(newValue)) {
							return {
								valid: false,
								message: 'Please Enter Numeric Value Between 1 - 36'
							};
						}
					},
					title: (cell, row, rowIndex, colIndex) => `${cell}`
				},
				{
					dataField: 'gmat',
					text: 'GMAT',
					hidden: false,
					classes: 'editable',
					headerClasses: 'table-head numcont',
					formatter: CourseTextFormatter,
					formatExtraData: {
						onChange: this.onInputChange,
						onClick: this.onEditRow
					},
					validator: (newValue, row, column) => {
						if (newValue === "") {
							return { valid: true };
						}
						if (!/^[0-9]\d{2}$/.test(newValue)) {
							return {
								valid: false,
								message: 'Please Enter Numeric Value Between 200 - 800'
							};
						}
					},
					title: (cell, row, rowIndex, colIndex) => `${cell}`
				},
				{
					dataField: 'double_master',
					text: 'Double Master',
					hidden: false,
					classes: 'editable',
					headerClasses: 'table-head numcont',
					formatter: CourseTextFormatter,
					formatExtraData: {
						onChange: this.onInputChange,
						onClick: this.onEditRow
					},
					editor: {
						type: Type.SELECT,
						options:
							[
								{
									value: 'YES',
									label: 'YES'
								},
								{
									value: 'NO',
									label: 'NO'
								},
							],

					},
					title: (cell, row, rowIndex, colIndex) => `${cell}`
				},
				{
					dataField: 'previous_breach',
					text: 'Previous Breach',
					hidden: false,
					classes: 'editable',
					headerClasses: 'table-head numcont',
					formatter: CourseTextFormatter,
					formatExtraData: {
						onChange: this.onInputChange,
						onClick: this.onEditRow
					},
					editor: {
						type: Type.SELECT,
						options:
							[{
								value: '0',
								label: '0'
							},
							{
								value: '1',
								label: '1'
							},
							{
								value: '2',
								label: '2'
							},
							{
								value: '3',
								label: '3'
							},
							{
								value: '4',
								label: '4'
							},
							{
								value: '5',
								label: '5'
							},
							{
								value: '6',
								label: '6'
							},
							{
								value: '7',
								label: '7'
							},
							{
								value: '8',
								label: '8'
							},
							{
								value: '9',
								label: '9'
							},
							{
								value: '10',
								label: '10'
							}],

					},
					title: (cell, row, rowIndex, colIndex) => `${cell}`
				},
				{
					dataField: 'gap',
					text: 'Gap',
					hidden: false,
					classes: 'editable',
					headerClasses: 'table-head numcont',
					formatter: CourseTextFormatter,
					formatExtraData: {
						onChange: this.onInputChange,
						onClick: this.onEditRow
					},
					editor: {
						type: Type.SELECT,
						options:
							[{
								value: '0',
								label: '0'
							},
							{
								value: '1',
								label: '1'
							},
							{
								value: '2',
								label: '2'
							},
							{
								value: '3',
								label: '3'
							},
							{
								value: '4',
								label: '4'
							},
							{
								value: '5',
								label: '5'
							},
							{
								value: '6',
								label: '6'
							},
							{
								value: '7',
								label: '7'
							},
							{
								value: '8',
								label: '8'
							},
							{
								value: '9',
								label: '9'
							},
							{
								value: '10',
								label: '10'
							}],
					},
					title: (cell, row, rowIndex, colIndex) => `${cell}`
				}, {
					dataField: 'experience',
					text: 'Experience',
					hidden: false,
					classes: 'editable',
					headerClasses: 'table-head numcont',
					formatter: CourseTextFormatter,
					formatExtraData: {
						onChange: this.onInputChange,
						onClick: this.onEditRow
					},
					editor: {
						type: Type.SELECT,
						options:
							[{
								value: '0',
								label: '0'
							},
							{
								value: '1',
								label: '1'
							},
							{
								value: '2',
								label: '2'
							},
							{
								value: '3',
								label: '3'
							},
							{
								value: '4',
								label: '4'
							},
							{
								value: '5',
								label: '5'
							},
							{
								value: '6',
								label: '6'
							},
							{
								value: '7',
								label: '7'
							},
							{
								value: '8',
								label: '8'
							},
							{
								value: '9',
								label: '9'
							},
							{
								value: '10',
								label: '10'
							}],
					},
					title: (cell, row, rowIndex, colIndex) => `${cell}`
				},
				{
					dataField: 'visa_rejection',
					text: 'Visa Rejection',
					classes: 'editable',
					hidden: false,
					headerClasses: 'table-head numcont',
					formatter: CourseTextFormatter,
					formatExtraData: {
						onChange: this.onInputChange,
						onClick: this.onEditRow
					},
					editor: {
						type: Type.SELECT,
						options:
							[
								{
									value: '0',
									label: '0'
								},
								{
									value: '1',
									label: '1'
								},
								{
									value: '2',
									label: '2'
								},
								{
									value: '3',
									label: '3'
								},
								{
									value: '4',
									label: '4'
								},
								{
									value: '5',
									label: '5'
								},
								{
									value: '6',
									label: '6'
								},
								{
									value: '7',
									label: '7'
								},
								{
									value: '8',
									label: '8'
								},
								{
									value: '9',
									label: '9'
								},
								{
									value: '10',
									label: '10'
								}],
					},
					title: (cell, row, rowIndex, colIndex) => `${cell}`
				},
				{
					dataField: 'restricted_states',
					text: 'Restricted States',
					hidden: false,
					classes: 'editable',
					headerClasses: 'table-head',
					formatter: CourseTextFormatter,
					formatExtraData: {
						onChange: this.onInputChange,
						onClick: this.onEditRow
					},
					validator: (newValue, row, column) => {
						if (newValue === "") {
							return { valid: true };
						}
						if (!/^[a-zA-Z]/.test(newValue) || newValue.length > 30) {
							return {
								valid: false,
								message: 'Please Enter Restricted States(Upto 30 characters)',
							};
						}
					},
					title: (cell, row, rowIndex, colIndex) => `${cell}`
				},
				{
					dataField: '',
					text: 'Actions',
					hidden: false,
					headerClasses: 'table-head',
					formatter: CourseButtonFormatter,

					formatExtraData: {
						userRoleAccess: [],
						onClickAdd: this.onClickAdd,
						onClickCopy: this.onClickCopy,
						onClickRemove: this.onClickRemove,
						onClickClear: this.onClickClear
					},
					editable: false
				}],
		}
	}

	static getDerivedStateFromProps(props, state) {
		const { character, user } = props
		const { count } = state
		const access = user.organization.access

		const { courses: { course } } = props;

		let finalData = access ? JSON.parse(access) : [{ "operations": [1, 1, 1, 1], "name": "MAPPING" }, { "operations": [1, 1, 1, 1], "name": "QUESION" }, { "operations": [1, 1, 1, 1], "name": "INTERVIEW" }, { "operations": [1, 1, 1, 1], "name": "COURSE" }, { "operations": [1, 1, 1, 1], "name": "REPORT" }, { "operations": [1, 1, 1, 1], "name": "STUDENTS" }]

		if (finalData && (course && course.length !== state.courseCount)) {

			const { columns } = state;
			let data = columns[19]
			data.formatExtraData.userRoleAccess = finalData
			const col = columns.slice(0, 19)
			col.push(data)
			let viewAccess = false
			if (user.user.is_superuser) {
				viewAccess = true
			}

			else if (finalData[3].operations.includes(1)) {
				viewAccess = true
			}
			else {
				viewAccess = false
			}
			if (count == 0 && character) {
				character = character ? character : null
				count = count + 1
			}
			return {
				userRoleAccess: finalData,
				columns,
				viewAccess: viewAccess,
				courseCount: course.length,
				courses: course,
				character,
				count
			}
		}

	}
	changeState = (data, user) => {

		this.setState({
			data: user.organization.access
		})
	}
	onKeyPress = (e) => {
		console.log("LLLLLLLLLLLLLLLLLLLLLLLLL");

		if (e.which === 13) {
			this.onClickAddcourse();
		}
	}


	componentDidMount() {
		const { pageIndex } = this.state;
		this.props.actions.getOrganizationCourses(pageIndex);
	}
	toggleView = () => {
		const { scrollview } = this.state;
		this.setState({ scrollview: !scrollview });

	}

	onEditRow = (row, index) => {
		const { courses } = this.state

		let course = courses[index]


		this.setState({
			showFormModal: true,
			course: course,
			editingIndex: index,
			isEditing:true

		})


	}


	onClear = () => {
		// const courses = [{ id: "", course_name: "", course_type: "Bachelors", ssc_percent: "", hsc_percent: "", diploma_percent:"", bac_percent: "", mas_percent: "", ielts: "4.5", gre: "", sat: "", act: "", gmat: "", double_master: "NO", previous_breach: "NO", gap: "0", visa_rejection: "0", restricted_states: "", experience: "0" }];
		this.setState({ courses: [] })


	}
	saveCourseInfo = () => {
		const { user: { user, organization } } = this.props;
		const { courses } = this.state;
		let isfalserecord = true;
		if (user) {
			const request = courses.map((course) => {
				if (!course.course_name) {
					isfalserecord = false;
					return;
				}
				else if (course.course_name) {
					return ({
						user_id: user.id,
						organization_id: organization.organization_id,
						id: courses.id,
						course_name: course.course_name,
						course_type: course.course_type,
						ssc_percent: course.ssc_percent ? course.ssc_percent : 0,
						hsc_percent: course.hsc_percent ? course.hsc_percent : 0,
						diploma_percent: course.diploma_percent ? course.diploma_percent : 0,
						bac_percent: course.bac_percent ? course.bac_percent : 0,
						mas_percent: course.mas_percent ? course.mas_percent : 0,
						ielts: course.ielts ? course.ielts : 0,
						gre: course.gre ? course.gre : 0,
						sat: course.sat ? course.sat :0, 
						act: course.act ? course.act : 0,
						gmat: course.gmat ? course.gmat : 0,
						double_master: course.double_master === 'YES' ? '1' : '0',
						previous_breach: course.previous_breach === 'YES' ? '1' : '0',
						gap: course.gap ? course.gap : 0 ,
						experience: course.experience ? course.experience : 0,
						visa_rejection: course.visa_rejection ? course.visa_rejection : 0,
						restricted_states: course.restricted_states,
					})
				}
			});
			const handleFailure = (res, body) => {
				this.setState({
					course_name: '',
					course_type: '',
					ssc_percent: '',
					hsc_percent: '',
					diploma_percent: '',
					bac_percent: '',
					mas_percent: '',
					ielts: '',
					gre: '',
					sat: '',
					act: '',
					gmat: '',
					double_master: '',
					previous_breach: '',
					gap: '',
					experience: '',
					visa_rejection: '',
					restricted_states: '',
					loginErrorMessage: Toast.show({
						message: "Error Missing some fields",
						timeout: 5000,
						intent: 'danger'
					})
				})
			}
			const handleSuccess = () => {
				Toast.show({
					message: "Courses saved successfully !!!!",
					timeout: 10000,
					intent: 'success'
				})
			};
			{
				!isfalserecord ?
				Toast.show({
					message: "Please Enter Course Name",
					timeout: 2000,
					intent: 'danger'
				})
				: this.props.actions.saveCourse(handleSuccess, handleFailure, request)
			}
		}
	}
	addCourseInfo = () => {
		const { user: { user, organization } } = this.props;
		const { courses, course, isEditing, pageIndex } = this.state;
		let isfalserecord = true;
		if (user) {
			const request=()=>{
				if (!course.course_name) {
					isfalserecord = false;
					return;
				}
				else if (course.course_name) {
					return ({
						user_id: user.id,
						organization_id: organization.organization_id,
						course_name: course.course_name,
						course_type: course.course_type,
						ssc_percent: course.ssc_percent ? course.ssc_percent : 0,
						hsc_percent: course.hsc_percent ? course.hsc_percent : 0,
						diploma_percent: course.diploma_percent ? course.diploma_percent : 0,
						bac_percent: course.bac_percent ? course.bac_percent : 0,
						mas_percent: course.mas_percent ? course.mas_percent : 0,
						ielts: course.ielts ? course.ielts : 0,
						gre: course.gre ? course.gre : 0,
						sat: course.sat ? course.sat :0, 
						act: course.act ? course.act : 0,
						gmat: course.gmat ? course.gmat : 0,
						double_master: course.double_master === 'YES' ? '1' : '0',
						previous_breach: course.previous_breach === 'YES' ? '1' : '0',
						gap: course.gap ? course.gap : 0 ,
						experience: course.experience ? course.experience : 0,
						visa_rejection: course.visa_rejection ? course.visa_rejection : 0,
						restricted_states: course.restricted_states,
					})
				}
			};
			const data = request()			
			const handleFailure = (res, body) => {
				this.setState({
					course_name: '',
					course_type: '',
					ssc_percent: '',
					hsc_percent: '',
					diploma_percent: '',
					bac_percent: '',
					mas_percent: '',
					ielts: '',
					gre: '',
					sat: '',
					act: '',
					gmat: '',
					double_master: '',
					previous_breach: '',
					gap: '',
					experience: '',
					visa_rejection: '',
					restricted_states: '',
					loginErrorMessage: Toast.show({
						message: "Error Missing some fields",
						timeout: 5000,
						intent: 'danger'
					})
				})
			}
			const handleSuccess = () => {
				this.props.actions.getOrganizationCourses(pageIndex);
				Toast.show({
					message: "Courses saved successfully !!!!",
					timeout: 10000,
					intent: 'success'
				})
				
			};
			if(isEditing){
				const validUUID = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(course.id);
				console.log("validUUID", validUUID)
				if (validUUID) {
					data["id"] = course.id
					!isfalserecord ?
						Toast.show({
							message: "Please Enter Course Name",
							timeout: 2000,
							intent: 'danger'
						})
						: this.props.actions.updateCourse(handleSuccess, handleFailure, data)
				}
				else {
					!isfalserecord ?
						Toast.show({
							message: "Please Enter Course Name",
							timeout: 2000,
							intent: 'danger'
						})
						: this.props.actions.addCourse(handleSuccess, handleFailure, data)
				}
			}
			else
			{
				!isfalserecord ?
				Toast.show({
					message: "Please Enter Course Name",
					timeout: 2000,
					intent: 'danger'
				})
					
				: this.props.actions.addCourse(handleSuccess, handleFailure, data)
			}
		}
	}

	onClickAdd = (index) => {
		const { courses } = this.state;
		{
			courses[courses.length - 1].course_name ?
				this.setState((prevState) => ({
					courses: [...prevState.courses, { id: prevState.courses.length + 1, course_name: "", course_type: "Bachelors", ssc_percent: "", hsc_percent: "", diploma_percent: "", bac_percent: "", mas_percent: "", ielts: "4.5", gre: "", sat: "", act: "", gmat: "", double_master: "NO", previous_breach: "NO", gap: "0", visa_rejection: "0", restricted_states: "", experience: "0" }],
				}))
				: Toast.show({
					message: "Please Add Course details",
					timeout: 1000,
					intent: 'success'
				});
		}
	}

	onClickAddcourse = () => {
		const { courses, course, editingIndex, isEditing} = this.state;

		if (this.isvalid()) {
			if (isEditing) {
				courses[editingIndex] = { id: editingIndex + 1, course_name: course.course_name, course_type: course.course_type, ssc_percent: course.ssc_percent, hsc_percent: course.hsc_percent, diploma_percent: course.diploma_percent, bac_percent: course.bac_percent, mas_percent: course.mas_percent, ielts: course.ielts, gre: course.gre, sat: course.sat, act: course.act, gmat: course.gmat, double_master: course.double_master, previous_breach: course.previous_breach, gap: course.gap, visa_rejection: course.visa_rejection, restricted_states: course.restricted_states, experience: course.experience }
				this.setState({
					courses
				})
				console.log("coursescoursescoursescourses", course);
				this.addCourseInfo()
				this.onCloseModal();
			}
			else {
				this.setState((prevState) => ({
					courses: [...prevState.courses, { id: prevState.courses.length + 1, course_name: course.course_name, course_type: course.course_type, ssc_percent: course.ssc_percent, hsc_percent: course.hsc_percent, diploma_percent: course.diploma_percent, bac_percent: course.bac_percent, mas_percent: course.mas_percent, ielts: course.ielts, gre: course.gre, sat: course.sat, act: course.act, gmat: course.gmat, double_master: course.double_master, previous_breach: course.previous_breach, gap: course.gap, visa_rejection: course.visa_rejection, restricted_states: course.restricted_states, experience: course.experience }],
				}))
				this.addCourseInfo()
				this.onCloseModal();
			}


		}
	}
	onChange = (value, input) => {
		//console.log("Inout",input,value);

		const { course } = this.state;
		course[input] = value;
		this.setState({ course });
	}
	onClearModal = () => {
		let { course } = this.state;
		course.course_name = ""
		course.course_type = ""
		course.ssc_percent = ""
		course.hsc_percent = ""
		course.diploma_percent = ""
		course.bac_percent = ""
		course.mas_percent = ""
		course.gre = ""
		course.sat = ""
		course.act = ""
		course.gmat = ""
		course.ielts = ""
		course.double_master = ""
		course.previous_breach = ""
		course.gap = ""
		course.experience = ""
		course.visa_rejection = ""
		this.setState({ course })

	}
	isvalid = () => {

		const { course } = this.state;
		const data = {
			courseNameVal: course.course_name,
			courseTypeVal: course.course_type,
			sscVal: course.ssc_percent,
			hscVal: course.hsc_percent,
			dipVal: course.diploma_percent,
			bacVal: course.bac_percent,
			masVal: course.mas_percent,
			greVal: course.gre,
			satVal: course.sat,
			actVal: course.act,
			gmatVal: course.gmat,
			ieltsVal: course.ielts,
			doubleMasterVal: course.double_master,
			previousBreachVal: course.previous_breach,
			gapVal: course.gap,
			experienceVal: course.experience,
			visaRejectionVal: course.visa_rejection
		}
		let error = {};
		if (!data.courseNameVal) {
			error.course_name = true;
			this.setState({ error });
			return false;
		}
		else {
			return true;
		}
	}

	onClickClear = (index) => {

		let { courses } = this.state;
		courses[index] = { course_name: "", course_type: "", ssc_percent: "", hsc_percent: "", diploma_percent: "", bac_percent: "", mas_percent: "", ielts: "", gre: "", sat: "", act: "", gmat: "", double_master: "", previous_breach: "", gap: "", visa_rejection: "", restricted_states: "", experience: "" };
		this.setState(courses)

	}



	onClickCopy = (index) => {
		const { courses } = this.state;
		{
			courses[index].course_name ?
				this.setState((prevState) => ({
					courses: [...prevState.courses, { id: prevState.courses.length + 1, course_name: courses[index].course_name, course_type: courses[index].course_type, ssc_percent: courses[index].ssc_percent, hsc_percent: courses[index].hsc_percent, diploma_percent: courses[index].diploma_percent, bac_percent: courses[index].bac_percent, mas_percent: courses[index].mas_percent, ielts: courses[index].ielts, gre: courses[index].gre, sat: courses[index].sat, act: courses[index].act, gmat: courses[index].gmat, double_master: courses[index].double_master, previous_breach: courses[index].previous_breach, gap: courses[index].gap, visa_rejection: courses[index].visa_rejection, restricted_states: courses[index].restricted_states, experience: courses[index].experience }],
				}))

				: Toast.show({
					message: "Please Add Course details",
					timeout: 1000,
					intent: 'success'
				});
		}

	}

	onClickRemove = (index) => {
		let { courses } = this.state;
		const itemIndex = courses.indexOf(index)
		const data =courses.splice(index, 1);
		this.setState({
			courses
		})
		if(data){

			const handleSuccess = (body) => {
		
				Toast.show({
					message: "Course Deleted Successfully !!!!",
					timeout: 4000,
					intent: 'success'
				})
			}
			const handleFailure = (res, body) => {
					Toast.show({
							message: "Please try again",
							timeout: 5000,
							intent: 'danger'
						})
					}
			const id = data[0].id
			this.props.actions.deleteCourse(id, handleSuccess, handleFailure );
		}
	}
	onInputChange = (e) => {
		this.setState({
			[`${e.target.name}Val`]: e.target.value
		});
	}



	onShowModal = () => {

		this.setState({
			showModal: true

		})

	}
	onshowFormModal = () => {

		this.setState({
			showFormModal: true
		})

	}
	onDropCourseFile = (courseFile) => {
		this.setState({ courseFile });
	}
	onCloseModal = () => {
		let { course } = this.state;
		course.course_name = ""
		course.course_type = ""
		course.ssc_percent = ""
		course.hsc_percent = ""
		course.diploma_percent = ""
		course.bac_percent = ""
		course.mas_percent = ""
		course.gre = ""
		course.sat = ""
		course.act = ""
		course.gmat = ""
		course.ielts = ""
		course.double_master = ""
		course.previous_breach = ""
		course.gap = ""
		course.experience = ""
		course.visa_rejection = ""
		this.setState({ course })
		this.setState({
			showModal: false, 
			showFormModal: false,
			isEditing:false,
		});
	}

	uploadFile = (event) => {
		const { courseFile } = this.state;
		const { user: { user, organization } } = this.props;
		const formData = new FormData()
		formData.append("user_id", user.id);
		formData.append("organization_id", organization.organization_id);
		if (!courseFile) {
			Toast.show({
				message: "Please Upload CSV File",
				timeout: 4000,
				intent: 'danger'
			})
			this.onCloseModal();
		}
		else {
			formData.append("courseFile", courseFile[0], 'course_file.csv');
			const handleSuccess = () => {
				Toast.show({
					message: "File upload Successfully",
					timeout: 4000,
					intent: 'success'
				})
				let pageIndex = 1;
				this.props.actions.getOrganizationCourses(pageIndex);
			};
			const handleFailure = () => {
				Toast.show({
					message: "Could not upload file",
					timeout: 4000,
					intent: 'danger'
				})
			}
			this.setState({
				courseFile: ''
			})
			this.onCloseModal();
			this.props.actions.upload(handleSuccess, handleFailure, formData)
		}
	}
	myColumnToggle = (df) => {
		if (df.text == 'Sr.No' || df.text == 'Actions') { return }

		const { columns } = this.state;
		const Newcolumns = columns.map((val) => {
			if (val.text === df.text) {
				val.hidden = !val.hidden
			}
			return val;
		});
		this.state.columns = Newcolumns;
		this.props.history.push(`/course`);
	}

	itemRender = (current, type, element) => {
		if (type === 'page') {

			return <a>{current}</a>;
		}
		return element;
	};

	onPageChange = (pageIndex) => {
		this.setState({
			pageIndex: pageIndex
		});
		this.props.actions.getOrganizationCourses(pageIndex);
	}
	hideItNow = () => {
		this.setState({
			character: false
		})
	}

	render() {
		const {isEditing, viewAccess, userRoleAccess, course, error, courses, columns, showModal, showFormModal, courseFile, pageIndex, scrollview, character } = this.state;
		const { pagination: { count, pages }, user } = this.props;
		// const jsonObj = JSON.parse(user.organization.access)


		return (
			viewAccess ? <CourseMarkup
				course={course}
				isEditing={isEditing}
				filterOptions={columns}
				courses={courses}
				courseFile={courseFile}
				columns={columns}
				count={count ? count : 0}
				pages={pages}
				showModal={showModal}
				showFormModal={showFormModal}
				error={error}
				courseNameVal={this.courseNameVal}
				courseTypeVal={this.courseTypeVal}
				sscVal={this.sscVal}
				hscVal={this.hscVal}
				bacVal={this.bacVal}
				masVal={this.masVal}
				greVal={this.greVal}
				ieltsVal={this.ieltsVal}
				visaRejectionVal={this.visaRejectionVal}
				onChange={this.onChange}
				onClickAddcourse={this.onClickAddcourse}
				onClickAdd={this.onClickAdd}
				onshowFormModal={this.onshowFormModal}
				saveCourseInfo={this.saveCourseInfo}
				onClear={this.onClear}
				onClearModal={this.onClearModal}
				onDropCourseFile={this.onDropCourseFile}
				onCloseModal={this.onCloseModal}
				uploadFile={this.uploadFile}
				onShowModal={this.onShowModal}
				myColumnToggle={this.myColumnToggle}
				itemRender={this.itemRender}
				onPageChange={this.onPageChange}
				pageIndex={pageIndex}
				scrollview={scrollview}
				toggleView={this.toggleView}
				isLoading={this.props.isLoading}
				character={character}
				hideItNow={this.hideItNow}
				onKeyPress={this.onKeyPress}
				userRoleAccess={userRoleAccess && userRoleAccess.length ? userRoleAccess[3].operations : []}
			/> : <AccessDenied />
		)
	}
}
const mapStateToProps = (state) => {
	return {
		user: state.Auth.user,
		courses: state.courses.courseList,
		pagination: state.courses.pagination,
		isLoading: state.courses.isLoading,
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch),
	};
};
export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(CourseTable));



