export const DASHBOARD_LOADING = 'dashboard_loading';
export const DASHBOARD_SUCCESS = 'dashboard_success';
export const DASHBOARD_ERROR = 'dashboard_error';
export const CHANGE_PASSWORD_SUCCESS = 'change_password_success';
export const CHANGE_PASSWORD_ERROR = 'change_password_error';
export const DEAL_SUCCESS = 'deal_success';
export const DEAL_ERROR = 'deal_error';
export const GET_LEADS_FAIL = 'GET_LEADS_FAIL';
export const GET_LEADS_REQUEST='GET_LEADS_REQUEST';
export const GET_LEADS_SUCCESS = 'GET_LEADS_SUCCESS';
export const GET_AVERAGE_SUCCESS = 'GET_AVERAGE_SUCCESS';
export const GET_AVERAGE_REQUEST='GET_AVERAGE_REQUEST';
export const GET_AVERAGE_FAIL = 'GET_AVERAGE_FAIL';
