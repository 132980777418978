import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FeaturesMarkup from '../components/FeaturesMarkup';
import { bindActionCreators } from 'redux';
import Report from '../../../img/Report.png';
import Interview from '../../../img/Interview.png';
import {Toast} from "../../../shared/toast/Toast"
import actions from '../../Wizards/modules/actions'


class Features extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFeatures:[],
            steps : [{name:"Report" , logo:Report},
                    {name:"Interview" , logo:Interview}]
        }
    }
    handleclick = (name) => {
        const { selectedFeatures } = this.state;
        
        if(!selectedFeatures.some((selectedFeatures) => selectedFeatures.name === name))
        {
        this.setState((prevState) => ({
                selectedFeatures: [...prevState.selectedFeatures, { name:name  }],
            }))
        }
        else{
            let deletefrom =  selectedFeatures.filter(selectedFeatures => selectedFeatures.name !== name);
            this.setState({selectedFeatures:deletefrom})
        }
    }
    onDoneClick = () => {
        const { selectedFeatures } = this.state;
        const { user,userstatus  } = this.props;
        const org_id= user.organization_id
        if(selectedFeatures && selectedFeatures.length)
        {
            const request = {
                feature:JSON.stringify(selectedFeatures),
            }
            const handleSuccess=()  => {  this.props.history.push('/integration?wizard=true') }
            const handleFailure=()  => {}
            this.props.actions.updateStep(handleSuccess,handleFailure,request,org_id);
        }
        else {
            Toast.show({
                message: "Please select Feature",
                timeout: 4000,
                intent: 'success'
            })
        }
    }
    render() {
        const { steps,selectedFeatures } = this.state;         
        return (
            <FeaturesMarkup 
            steps={steps}
            selectedFeatures = { selectedFeatures }
            handleclick ={ this.handleclick }
            onDoneClick = { this.onDoneClick }/>
        )
    }
}
const mapStateToProps = (state) => {
	return {
        user: state.Auth.user.organization,
        userstatus: state.Auth.user.user,
        SelectedfeaturesString : state.wizard.Step.feature
	}
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Features));