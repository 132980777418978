import React from 'react';
import { Container } from 'react-bootstrap';
import './AverageZScore.scss';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chart from 'react-apexcharts'
import { ZLoader } from '../../../shared/loader/ZLoader'

const capitalizeFirstLetter = (string) => {
     if(string){
         var str
         str = string[0].toUpperCase() + string.slice(1);
         return str.replace(/_/g, " ");
     }    
   }
const ReportMarkup = (props) => {
     const { isLoading,source_data, source_name, averageByValue } = props
     return (
          <div className="call-log-container">

               {isLoading ? <ZLoader /> : null}
               {source_name && source_data ?
                    <div id="chart">
                         <Chart options={{
                              chart: {
                                   height: 350,
                                   type: 'bar',
                                   events: {
                                        click: function (chart, w, e) {
                                        }
                                   }
                              },
                              // colors: colors,
                              plotOptions: {
                                   bar: {
                                        columnWidth: '45%',
                                        distributed: true
                                   }
                              },
                              title: {
                                   text: `${capitalizeFirstLetter(averageByValue)} Average Z-Score`,
                                   align: 'left'
                              },
                              dataLabels: {
                                   enabled: false
                              },
                              legend: {
                                   show: false
                              },
                              xaxis: {
                                   categories: source_name,
                                   labels: {
                                        style: {
                                             // colors: colors,
                                             fontSize: '12px'
                                        }
                                   }
                              }
                         }} series={[{
                              data: source_data
                            }]} type="bar" height={350} />
                    </div>
                    : 
                    <>
                    <p style={{fontWeight:'500'}}>Source Average Z-Score</p>
                    <h4>No data available</h4>
                    </>
                    }
          </div>
     )
}

export default ReportMarkup;