import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Image, Accordion, Card } from 'react-bootstrap';
import './Summary.scss';

const Summary = (props) => {
  const { studentqualification, showDocumentModal } = props;
  let qualification = null;
  if (studentqualification && studentqualification) {
    qualification = (studentqualification) => {return  (
      <div className="academic">
        <div><p><strong>Grad score :</strong> {studentqualification.graduation_score}</p></div>
        <div><p><strong>Highest qua. :</strong> {studentqualification.highest_qualification}</p></div>
        <div><p><strong>Lang test :</strong> {studentqualification.language_test}</p></div>
        <div><p><strong>Work exp. :</strong> {studentqualification.work_experience}</p></div>
        <div><p><strong>Pref desti. :</strong>{studentqualification.preferred_destination}</p></div>
        <div><p><strong>Ava budget :</strong> {studentqualification.available_budget}</p></div>
        <div><p><strong>Marital status :</strong> {studentqualification.marital_status}</p></div>
        <div><p><strong>Dob :</strong> {studentqualification.dob}</p></div>
        {/* <div><h6>Interests : {studentqualification.interests ? JSON.stringify(studentqualification.interests) : null}</h6></div>
                            <div><h6>Priorities : {studentqualification.priorities}</h6></div> */}
      </div>
    )}

    return (
      <div className="education-section">
        <Accordion >
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <h3>Summary</h3>
              <FontAwesomeIcon className={"icon"} icon={['fas', 'plus']} />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {qualification(studentqualification)}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    )
  }
}
export default Summary;
