import Constants from './constants';
import { APISAAS_DOMAIN } from '../../../env';

const getInterviews = () => {
   
    return (dispatch, getState) => {
        const { Auth: { user: { token, user, organization } } } = getState();
        dispatch({
            type: Constants.GET_SEARCH_INTERVIEWS_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/interview/organizations/${organization.organization_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_SEARCH_INTERVIEWS_FAIL,
                        payload: body.errors
                    });
                } else {
                    dispatch({
                        type: Constants.GET_SEARCH_INTERVIEWS_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}

const getOrganizationInterviews = (page) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token, user, organization } } } = getState();
        dispatch({
            type: Constants.GET_ALL_INTERVIEWS_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/interview/organization/${organization.organization_id}/${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_ALL_INTERVIEWS_FAIL,
                        payload: body.errors
                    });
                } else {
                    dispatch({
                        type: Constants.GET_ALL_INTERVIEWS_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}

const getInterview = (id) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_ALL_INTERVIEWS_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/interview/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_INTERVIEW_FAIL,
                        payload: body.errors
                    });
                } else {
                    dispatch({
                        type: Constants.GET_INTERVIEW_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}
const createInterview = (handleSuccess, handleFailure, request) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.POST_INTERVIEW_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/interview/create/`, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_INTERVIEW_FAIL,
                        payload: body.errorsupdateInterview
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.POST_INTERVIEW_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}
const updateInterview = (handleSuccess, handleFailure, request) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.UPDATE_INTERVIEW_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/interview/update/${request.id}`, {
            method: 'PUT',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.UPDATE_INTERVIEW_FAIL,
                        payload: body.errors,
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.UPDATE_INTERVIEW_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}
const createQuestion = (handleSuccess, handleFailure, data) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.QUESTION_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/question/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.QUESTION_REGISTER_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.QUESTION_REGISTER_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}
// const getAllQuestions = () => {
//     return (dispatch, getState) => {
//         const { Auth: { user: { token,user } } } = getState();
//         dispatch({
//             type: Constants.GET_ALL_QUESTIONS_REQUEST
//         });
//         return fetch(`${APISAAS_DOMAIN}api/question/organization/${user.id}`, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': 'Bearer ' + token
//             }
//         })

//             .then(response => response.json().then(body => ({ response, body })))
//             .then(({ response, body }) => {
//                 if (!response.ok) {
//                     dispatch({
//                         type: Constants.GET_ALL_QUESTIONS_FAIL,
//                         payload: body.error
//                     });
//                 } else {
//                     dispatch({
//                         type: Constants.GET_ALL_QUESTIONS_SUCCESS,
//                         payload: body
//                     });
//                     console.log(">>>>>>>>>>>>>>>",body)
//                 }
//             });
//     }
// }

const getAllOrganizationQuestions = (page ,search ) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token, user, organization } } } = getState();
        dispatch({
            type: Constants.GET_ALL_QUESTIONS_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/question/organization/${organization.organization_id}/${page}?search=${search}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_ALL_QUESTIONS_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: Constants.GET_ALL_QUESTIONS_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}

const getQuestion = (id) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_QUESTION_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/question/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_QUESTION_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: Constants.GET_QUESTION_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}
const updateQuestion = (handleSuccess,handleFailure,request) => {
    return (dispatch, getState) => {
      const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.UPDATE_QUESTION_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/question/update/${request.id}`, {
            method: 'PUT',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.UPDATE_QUESTION_FAIL,
                        payload: body.errors,
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.UPDATE_QUESTION_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

const uploadInterview = (formData) => {
    return function (dispatch) {
        dispatch({
            type: Constants.SAAS_UPLOAD_INTERVIEW_REQUEST
		});
            return fetch(`${APISAAS_DOMAIN}api/application/interviewupload/`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json()
            .then(body => ({ response, body })))
            .then(({ response, body }) => {
				if (!response.ok) {
                    dispatch({
                        type: Constants.SAAS_UPLOAD_INTERVIEW_REQUEST_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: Constants.SAAS_UPLOAD_INTERVIEW_REQUEST_SUCCESS,
						payload: body
                    });
                }
            });
    }
}

const deleteInterview = (handleSuccess, id) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
       
        fetch(`${APISAAS_DOMAIN}api/interview/deleteinterview/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_INTERVIEW_FAIL,
                        payload: body.errors
                    });
                } else {
                    dispatch({
                        type: Constants.GET_INTERVIEW_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

const deleteQuestion = (handleSuccess, id) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
       console.log("token",token,"id",id)
        fetch(`${APISAAS_DOMAIN}api/question/deletequestion/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {                    
                    dispatch({
                        type: Constants.GET_INTERVIEW_FAIL,
                        payload: body.errors
                    });
                    console.log("body",body)
                } else {
                    dispatch({
                        type: Constants.GET_INTERVIEW_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body)
                }
            });
    }
}

const getStudeudentInterview = (id) => {
    return (dispatch, getState) => {
       
        dispatch({
            type: Constants.GET_STUDENT_INTERVIEW_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/interview/valid/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
      
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_STUDENT_INTERVIEW_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: Constants.GET_STUDENT_INTERVIEW_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}

const contactUs = (handleSuccess,handleFailure, data) => {
    return (dispatch, getState) => {
       
        dispatch({
            type: Constants.QUESTION_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/interviewcontactus/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                   
                    handleFailure(body);
                } else {
                  
                    handleSuccess(body);
                }
            });
    }
}



export default {
    getInterviews,
    getInterview,
    createQuestion,
    createInterview,
    updateInterview,
    // getAllQuestions,
    updateQuestion,
    getQuestion,
    uploadInterview,
    getOrganizationInterviews,
    getAllOrganizationQuestions,
    deleteInterview,
    deleteQuestion,
    getStudeudentInterview,
    contactUs,
};
