import React from 'react';
import { Button, FormGroup, InputGroup } from "@blueprintjs/core";
import { ASSETS_DOMAIN } from '../../../env';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { ZLoader } from '../../../shared/loader/ZLoader';
import './Login.scss';
import "./ForgetPasswordMarkup.scss"

const ForgetPasswordMarkup = (props) => {
    const {onSubmit, forgotPasswordResponse, isSuccess, history} =props
    return (
        <div className="container">
            <div className="inner-container-forgot-password">
                {props.isLoading ?
                    <ZLoader />
                    :
                    <div className="input-container">
                        {/* <div className="image-container">
                            <img
                                src={`${ASSETS_DOMAIN}/assets/images/logo.png`}
                                alt="Zoomabroad logo"
                            />
                        </div> */}
                        <h3>FORGOT PASSWORD</h3>
                        <div className="bp3-card .modifier .bp3-elevation-4 form-container">
                            <FormGroup
                                label="Enter Your Register Email Address "
                                labelFor="text-input"
                                labelInfo="*"
                                style={{
                                    fontWeight: 'bold'
                                }}
                            >
                                <InputGroup
                                    id="text-input"
                                    placeholder="Enter Registered Email Id"
                                    name={"email"}
                                    type="email"
                                    large
                                    onChange={props.onInputChange}
                                />
                                <span className={!isSuccess ? "error" : 'succes'}>{forgotPasswordResponse}</span>
                            </FormGroup>
                            <Button
                                className="bp3-button .bp3-intent-danger button-container" onClick={onSubmit}>
                                Request Reset Link
                            </Button>
                            <div className="signin" onClick={()=>history.push("/login")}><a>Or Sign In</a></div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ForgetPasswordMarkup;