import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DashboardMarkup from '../components/DashboardMarkup';
import { bindActionCreators } from 'redux';
import Denied from '../../../shared/denied/Denied'
import actions from '../modules/actions'
import _ from 'lodash';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewAccess: true,
      yopmailVal:false,
      averageByValue: 'source',
      series: [],
      options: {
        
      },
      piSeries: [44, 55, 13, 43, 22],
      piOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },


    };
  }


  static getDerivedStateFromProps(props, state) {
    const { allLeads , user} = props
    const { series,options  } = state

		const access = user.organization.access


		let finalData = access ? JSON.parse(access) : [{ "operations": [1, 1, 1, 1], "name": "MAPPING" }, { "operations": [1, 1, 1, 1], "name": "QUESION" }, { "operations": [1, 1, 1, 1], "name": "INTERVIEW" }, { "operations": [1, 1, 1, 1], "name": "COURSE" }, { "operations": [1, 1, 1, 1], "name": "REPORT" }, { "operations": [1, 1, 1, 1], "name": "STUDENTS" }, {
      "name":"DASHBOARD",
      "operations":[
         1,
         0,
         0,
         0
      ]
   }, {
      "name":"COUNSELOR-REPORT",
      "operations":[
         1,
         0,
         0,
         0
      ]
   }]
   const dashboard = _.find(finalData, { "name" : "DASHBOARD"})
   
    if (allLeads && allLeads.students && allLeads.students.day_by_count && allLeads.students.day_by_count.length) {
    let sorted =   _.sortBy(allLeads.students.day_by_count, ['date_created']);

      let dateArr = []
      let countArr = []
      let viewAccess = false

      if(dashboard && dashboard){
        if(dashboard.operations[0] == 1){
          viewAccess = true
          }
      }
      if(!user.user.is_counselor){
        viewAccess = true
      }
      for (let i = 0; i < sorted.length; i++) {

        dateArr.push(sorted[i].date_created)
        countArr.push(sorted[i].created_count)

      }
      console.log(dateArr, "dateArr", countArr, "count");
      let series = []
      let options = {}
      options.chart = {
        height: 350,
        type: 'area'
      }
      options.dataLabels= {
        enabled: false
      }
      options.stroke= {
        curve: 'smooth'
      }
      options.xaxis= {
        type: 'date',
        categories:dateArr
      }
      options.tooltip= {
        x: {
          format: 'dd/MM/yy HH:mm'
        }
      }
      options.title = {
        text: 'Number of Students registered',
        align: 'left'
      }
      series.push({
        name: 'Students',
        data: countArr
      })
      
      // let sorted =   _.sortBy(allLeads.students.day_by_count, ['date_created']);
      console.log(sorted,"created_countcreated_count");
      return {
        series,options, viewAccess
      }

    }
  }
  componentDidMount() {
    const { averageByValue } = this.state
    const yopmail = JSON.parse(localStorage.getItem('yopmail'));

    const { user : { organization } } = this.props;
    this.props.actions.getLeads(organization.organization_id, yopmail)
    this.props.actions.getAverage(averageByValue, organization.organization_id, yopmail)
  }
  onChangeInput = (name, value) => {
    const { yopmailVal } = this.state
    const { user : { organization }} = this.props;
        this.setState({
        [name]: value,
        })
        if(name == 'averageByValue'){
          this.props.actions.getAverage(value, organization.organization_id)
        }
        // if(name == 'yopmailVal'){
        //   this.props.actions.getLeads(organization.organization_id, value)
        // }
  }
  render() {
    const { averageScore } = this.props 
    const { averageByValue, yopmailVal, viewAccess } = this.state 

    console.log("viewAccess", viewAccess)
    return (
      viewAccess ? 
      <DashboardMarkup
        options={this.state.options}
        series={this.state.series}
        piSeries={this.state.piSeries}
        piOptions={this.state.piOptions}
        allLeads={this.props.allLeads}
        isLoading={this.props.isLoading}
        averageByValue={averageByValue}
        averageScore={averageScore}
        onChangeInput={this.onChangeInput}
        yopmailVal={yopmailVal}
      />
      : <Denied/>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.Auth.user,
    allLeads: state.Dashboard.leads,
    isLoading:state.Dashboard.isLoading,
    averageScore: state.Dashboard.averageScore
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));