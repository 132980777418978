import React, { Component } from "react";
import { FormGroup, InputGroup } from "@blueprintjs/core";
import { Row, Col, Button } from 'react-bootstrap';
import Paginator from '../../../shared/pagination/pagination'
import Table from '../../../shared/table/Table';
import "./CourseMarkup.scss";
import { ZLoader } from '../../../shared/loader/ZLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropzone from 'react-dropzone';
import { OverlayTrigger, Form,Popover } from "react-bootstrap";
import Modal from '../../../shared/model/model'
import mappingImg from '../../../assets/6.png';

const CourseMarkup =(props) =>{
        
    const userAccess =(userRoleAccess,act)=>{
      
        if((userRoleAccess[1]==1) && act=="create"){
            return true
        }
        if((userRoleAccess[3]==1 ) && act=="delete"){
            return true

        }
        if(userRoleAccess[2]==1 && act=="edit"){
            return true
        }
            
    }

    const {isEditing, userRoleAccess,error, course, onChange,onKeyPress, onClickAddcourse,onClickAdd, index, character, hideItNow,isLoading, courses, columns, count, toggleView, scrollview, pages, saveCourseInfo ,onClearModal, onClear, onShowModal, uploadFile, onCloseModal, onDropCourseFile, showModal, showFormModal, onshowFormModal, courseFile, filterOptions, myColumnToggle, itemRender,onPageChange, pageIndex }=props;
    const toggle = true;
    console.log("course",course);
    return (
        <>
        
        {isLoading?<ZLoader/>:null}
        {character ?<div className={'character-container animate '}>
            <img src={mappingImg} />
            <div className="cloud">
                <div><p className="cloud-text">Provide the eligibility criteria for the courses of your organization</p></div>
                <div><p className="cloud-text">you can edit the table, and can fill data as your requirements.</p></div>
                <div><p className="cloud-text"></p></div>
                <div className="text-right"><Button variant="light" onClick={hideItNow} >Next</Button></div>
            </div>
        </div>:null}
            <div className="course-page">
            <Row>
                <Col md="6" lg="6" sm="6" xs="6" className="title" >
                    Course List                            
                </Col>
                    <Col md="6" lg="6" sm="6" xs="6" className="btn-section text-right">

                    {/* {userAccess(userRoleAccess,"create") || userAccess(userRoleAccess,"edit")?<Button className="btn-prime save" onClick= { saveCourseInfo }>Save</Button>:''} */}

                    {userAccess(userRoleAccess,"create")?<Button variant="success" className="btn-prime save" onClick={onshowFormModal} >Add Course </Button>:""}   
                        {userAccess(userRoleAccess,"delete")?<Button className="btn-prime clear-all" onClick= { onClear }>Delete All</Button>:''}
                        {userAccess(userRoleAccess,"create")?<Button className="btn-prime btn-info" onClick={onShowModal} >Import</Button> :""}                
                        {/* <Button className="btn btn-danger" onClick= { onClear }>Clear All</Button>
                        <Button className="btn btn-success" onClick= { saveCourseInfo }>Save</Button>
                        <Button className="btn btn-info" onClick={onShowModal} >Import</Button>
                        <Button variant="success" className="btn btn-student" onClick={onshowFormModal} >Add Course </Button>   */}
                       
                        <div className="icon-btn-section text-right">
                            <button className="icon-btn-btn">
                                <OverlayTrigger
                                    placement={'top'}
                                    delay={{ hide: 200 }}
                                    overlay={<Popover className="tool-tip" id="tooltip-disabled">Expand</Popover>}>
                                        <FontAwesomeIcon className={ scrollview ? "icon-btn_active": "icon-btn"} onClick={() => toggleView()} icon="arrows-alt-h" />  
                                </OverlayTrigger> 
                            </button>
                        </div>
                        <Modal
                            modalTitle={"Course Data"}
                            isModalOpen={showModal}
                            onCloseModal={onCloseModal}
                        >
                                <Dropzone onDrop={files => onDropCourseFile(files)}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({
                                            className: courseFile && courseFile.length ? 'dropzone active' : 'dropzone',
                                            onDrop: event => event.stopPropagation()
                                        })}>
                                            <input {...getInputProps()} accept='.csv'/>
                                            <p>Click to select file</p>
                                            {courseFile && courseFile.length ? <span className="file-name">Selected File: {courseFile[0].name}</span> : <em>(Only *.csv file type will be accepted)</em>}
                                        </div>
                                    )}
                                </Dropzone>
                                <div className="import">
                                <Row>
                                <Col className="download-csv"  >
                                <a   href="https://zilter.s3.amazonaws.com/courseFile.csv">Download sample CSV file</a>        
                                </Col>
                                <Col>
                                <Button className="upload-button" disabled={courseFile ? false :true} onClick={uploadFile} >Upload</Button>
                                </Col>
                                </Row>
                                </div>   
                        </Modal>
                        <Modal className
                            modalTitle={"Add Course Details"}
                            isModalOpen={showFormModal}
                            onCloseModal={onCloseModal}
                            showLargeModal={true}
                        >
                            <Row md="12" lg="12" sm="12" xs="12" >
                                <Col md="6" lg="6" sm="6" xs="6" classname="form-group">
                                    <FormGroup
                                        label="Enter Course Name"
                                        labelFor="text-input"
                                        labelInfo="*"
                                    >
                                        <InputGroup
                                            value={course.course_name}
                                            type="text" 
                                            id="course_name" 
                                            name="course_name" 
                                            placeholder="Course Name"
                                            onKeyPress={onKeyPress}
                                            onChange={(event) => {
                                                onChange(event.target.value,'course_name')
                                                }
                                            }
                                        />
                                        {error.course_name ? <span className="error">Course Name Is Required </span> :'' }
                                    </FormGroup>
                                </Col>
                                <Col md="6" lg="6" sm="6" xs="6" classname="form-group">
                                    <FormGroup
                                        label="Enter Course Type"
                                        labelFor="text-input"
                                    >
                                        {/* <select>
                                        <option selected value="Bachelors">Bachelors</option>
                                        <option value="Certificate">Certificate</option>
                                        <option value="Diploma">Diploma</option>
                                        <option value="Masters">Masters</option>
                                        <option value="Research">Research</option>
                                        </select> */}
                                        <Form.Control className="option-text" as="select" value={course.course_type} name="course_type"
                                        onKeyPress={onKeyPress}
                                         onChange={(event) => {
                                         onChange(event.target.value, 'course_type')
                                         }
                                         
                                        }>
                                        <option>Select</option>
                                        <option>Bachelors</option>
                                        <option>Masters</option>
                                        <option>Diploma</option>
                                        </Form.Control>
                                        {/* <InputGroup
                                            type="text" 
                                            id="course_type" 
                                            name="course_type" 
                                            placeholder="Course Type"
                                        /> */}
                                        {error.course_type ? <span className="error">Course Type Is Required </span> :'' }
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row md="12" lg="12" sm="12" xs="12"  >
                                    <Col md="2" lg="2" sm="2" xs="2" classname="form-group">
                                        <FormGroup
                                            label="SSC%"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                value={course.ssc_percent}
                                                id="ssc_percent" 
                                                name="ssc_percent" 
                                                placeholder="SSC Score"
                                                onKeyPress={onKeyPress}
                                                onChange={(event) => {
                                                    onChange(event.target.value,'ssc_percent')
                                                }
                                                }
                                            />
                                            {error.ssc_percent ? <span className="error">Enter SSC Score </span> :'' }
                                        </FormGroup></Col>
                                    <Col md="2" lg="2" sm="2" xs="2" classname="form-group">
                                        <FormGroup
                                            label="HSC%"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                value={course.hsc_percent}       
                                                id="hsc_percent" 
                                                name="hsc_percent" 
                                                placeholder="HSC Score"
                                                onKeyPress={onKeyPress}
                                                onChange={(event) => {
                                                    onChange(event.target.value,'hsc_percent')
                                                }
                                                }
                                            />
                                            {error.hsc_percent ? <span className="error">Enter HSC Score </span> :'' }
                                        </FormGroup>
                                    </Col>

                                    <Col md="2" lg="2" sm="2" xs="2" classname="form-group">
                                        <FormGroup
                                            label="Diploma%"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                value={course.diploma_percent}       
                                                id="diploma_percent" 
                                                name="diploma_percent" 
                                                placeholder="Diploma Score"
                                                onKeyPress={onKeyPress}
                                                onChange={(event) => {
                                                    onChange(event.target.value,'diploma_percent')
                                                }
                                                }
                                            />
                                            {error.diploma_percent ? <span className="error">Enter Diploma Score </span> :'' }
                                        </FormGroup>
                                    </Col>



                                    <Col  md="2" lg="2" sm="2" xs="2" classname="form-group">
                                        <FormGroup
                                            label="UG%"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                value={course.bac_percent}    
                                                id="bac_percent" 
                                                name="bac_percent" 
                                                placeholder="Bachlors Score"
                                                onKeyPress={onKeyPress}
                                                onChange={(event) => {
                                                    onChange(event.target.value,'bac_percent')
                                                }
                                                }
                                            />
                                            {error.bac_percent ? <span className="error">Enter Bachelors Score </span> :'' }
                                        </FormGroup></Col>
                                    <Col  md="2" lg="2" sm="2" xs="2" classname="form-group">
                                        <FormGroup
                                            label="PG%"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                value={course.mas_percent}   
                                                id="mas_percent" 
                                                name="mas_percent" 
                                                placeholder="Masters Score"
                                                onKeyPress={onKeyPress}
                                                onChange={(event) => {
                                                    onChange(event.target.value,'mas_percent')
                                                }
                                                }
                                            />
                                            {error.mas_percent ? <span className="error">Enter Masters Score </span> :'' }
                                        </FormGroup></Col>
                            </Row>
                            <Row md="12" lg="12" sm="12" xs="12" >
                                    <Col md="2" lg="2" sm="2" xs="2" classname="form-group">
                                        <FormGroup
                                            label="IELTS"
                                            labelFor="text-input"
                                        >
                                        <Form.Control className="option-text" as="select" value={course.ielts} name="ielts"
                                        onKeyPress={onKeyPress}
                                            onChange={(event) => {
                                            onChange(event.target.value, 'ielts')
                                            }
                                            }>
                                            <option>Select</option>
                                            <option>1.0</option>
                                            <option>1.5</option>
                                            <option>2.0</option>
                                            <option>2.5</option>
                                            <option>3.0</option>
                                            <option>3.5</option>
                                            <option>4.0</option>
                                            <option>4.5</option>
                                            <option>5.0</option>
                                            <option>5.5</option>
                                            <option>6.0</option>
                                            <option>6.5</option>
                                            <option>7.0</option>
                                            <option>7.5</option>
                                            <option>8.0</option>
                                            <option>8.5</option>
                                            <option>9.0</option>
                                        </Form.Control>
                                            {/* <InputGroup
                                                    
                                                id="ielts" 
                                                name="ielts" 
                                                placeholder="IELTS Score"
                                            /> */}
                                            {error.ielts ? <span className="error">IELTS Score Required </span> :'' }
                                        </FormGroup>
                                    </Col>
                                    <Col  md="2" lg="2" sm="2" xs="2" classname="form-group">
                                        <FormGroup
                                            label="GRE"
                                            labelFor="text-input"
                                            
                                        >
                                            <InputGroup
                                                value={course.gre}    
                                                id="gre" 
                                                name="gre" 
                                                placeholder="GRE Score"
                                                onKeyPress={onKeyPress}
                                                onChange={(event) => {
                                                    onChange(event.target.value,'gre')
                                                }
                                                }
                                            />
                                            {error.gre ? <span className="error">Enter Value Between 130 - 170</span> :'' }
                                        </FormGroup>
                                    </Col>
                                    <Col  md="2" lg="2" sm="2" xs="2" classname="form-group">
                                        <FormGroup
                                            label="SAT"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                value={course.sat}    
                                                id="sat" 
                                                name="sat" 
                                                placeholder="SAT Score"
                                                onKeyPress={onKeyPress}
                                                onChange={(event) => {
                                                    onChange(event.target.value,'sat')
                                                }
                                                }
                                            />
                                            {error.sat ? <span className="error">Enter Value Between 400 - 1600</span> :'' }
                                        </FormGroup>
                                    </Col>
                                    <Col  md="2" lg="2" sm="2" xs="2" classname="form-group">
                                        <FormGroup
                                            label="ACT"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                value={course.act}    
                                                id="act" 
                                                name="act" 
                                                placeholder="ACT Score"
                                                onKeyPress={onKeyPress}
                                                onChange={(event) => {
                                                    onChange(event.target.value,'act')
                                                }
                                                }
                                            />
                                            {error.act ? <span className="error">Enter Value Between 1 - 36</span> :'' }
                                        </FormGroup>
                                    </Col>
                            
                                    <Col md="2" lg="2" sm="2" xs="2" classname="form-group">
                                        <FormGroup
                                            label="GMAT"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                value={course.gmat}    
                                                id="gmat" 
                                                name="gmat" 
                                                placeholder="GMAT Score"
                                                onKeyPress={onKeyPress}
                                                onChange={(event) => {
                                                    onChange(event.target.value,'gmat')
                                                }
                                                }
                                            />
                                            {error.gmat ? <span className="error">Enter Value Between 200 - 800</span> :'' }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row md="12" lg="12" sm="12" xs="12" >    
                                    <Col  md="2" lg="2" sm="2" xs="2" classname="form-group">
                                        <FormGroup
                                            label="Double Master"
                                            labelFor="text-input"
                                            
                                        >
                                            <Form.Control className="option-text" as="select" value={course.double_master} name="double_master"
                                                onKeyPress={onKeyPress}
                                                onChange={(event) => {
                                                onChange(event.target.value, 'double_master')
                                                }
                                                }>
                                                <option>Select</option>
                                                <option>YES</option>
                                                <option>NO</option>
                                                
                                            </Form.Control>
                                            {/* <InputGroup
                                                type="text" 
                                                id="double_master" 
                                                name="double_master" 
                                                placeholder="Double Master"
                                            /> */}
                                            {error.double_master ? <span className="error">Required </span> :'' }
                                        </FormGroup>
                                    </Col>
                                    <Col  md="2" lg="2" sm="2" xs="2" classname="form-group">
                                        <FormGroup
                                            label="Previous Breach"
                                            labelFor="text-input"
                                        >
                                            <Form.Control className="option-text" as="select" value={course.previous_breach} name="previous_breach"
                                            onKeyPress={onKeyPress}
                                            onChange={(event) => {
                                            onChange(event.target.value, 'previous_breach')
                                            }
                                            }>
                                            <option>Select</option>
                                            <option>0</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                            <option>7</option>
                                            <option>8</option>
                                            <option>9</option>
                                            <option>10</option>
                                            
                                        </Form.Control>
                                            {/* <InputGroup
                                                     
                                                id="previous_breach" 
                                                name="previous_breach" 
                                                placeholder="Previous Breach"
                                            /> */}
                                            {error.previous_breach ? <span className="error">Required </span> :'' }
                                        </FormGroup>
                                    </Col>
                                    <Col  md="2" lg="2" sm="2" xs="2" classname="form-group">
                                        <FormGroup
                                            label="GAP"
                                            labelFor="text-input"
                                        >
                                            <Form.Control className="option-text" as="select" value={course.gap} name="gap"
                                            onKeyPress={onKeyPress}
                                            onChange={(event) => {
                                            onChange(event.target.value, 'gap')
                                            }
                                            }>
                                            <option>Select</option>
                                            <option>0</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                            <option>7</option>
                                            <option>8</option>
                                            <option>9</option>
                                            <option>10</option>
                                            
                                        </Form.Control>
                                            {/* <InputGroup
                                                     
                                                id="gap" 
                                                name="gap" 
                                                placeholder="GAP"
                                            /> */}
                                            {error.gap ? <span className="error">Required </span> :'' }
                                        </FormGroup>
                                    </Col>
                            
                                    <Col  md="2" lg="2" sm="2" xs="2" classname="form-group">
                                        <FormGroup
                                            label="Experience"
                                            labelFor="text-input"
                                        >
                                            <Form.Control className="option-text" as="select" value={course.experience} name="experience"
                                            onKeyPress={onKeyPress}
                                            onChange={(event) => {
                                            onChange(event.target.value, 'experience')
                                            }
                                            }>
                                            <option>Select</option>
                                            <option>0</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                            <option>7</option>
                                            <option>8</option>
                                            <option>9</option>
                                            <option>10</option>
                                            
                                        </Form.Control>
                                            {/* <InputGroup
                                                     
                                                id="experience" 
                                                name="experience" 
                                                placeholder="Experience"
                                            /> */}
                                            {error.experience ? <span className="error">Experience Is Required </span> :'' }
                                        </FormGroup>
                                    </Col>
                                    <Col  md="2" lg="2" sm="2" xs="2" classname="form-group">
                                        <FormGroup
                                            label="Visa Rjection"
                                            labelFor="text-input"
                                            
                                        >
                                            <Form.Control className="option-text" as="select" value={course.visa_rejection} name="visa_rejection"
                                            onKeyPress={onKeyPress}
                                            onChange={(event) => {
                                            onChange(event.target.value, 'visa_rejection')
                                            }
                                            }>
                                            <option>Select</option>
                                            <option>0</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                            <option>7</option>
                                            <option>8</option>
                                            <option>9</option>
                                            <option>10</option>
                                            
                                        </Form.Control>
                                            {/* <InputGroup
                                                    
                                                id="visa_rejection" 
                                                name="visa_rejection" 
                                                placeholder="Visa Rjection"
                                            /> */}
                                            {error.visa_rejection ? <span className="error">Visa Rejection Is Required </span> :'' }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row md="12" lg="12" sm="12" xs="12" >    
                                    <Col  md="3" lg="3" sm="3" xs="3" classname="form-group">
                                        <FormGroup
                                            label="Restricted States"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                value={course.restricted_states}   
                                                id="restricted_states" 
                                                name="restricted_states" 
                                                placeholder="Restricted States"
                                                onKeyPress={onKeyPress}
                                                onChange={(event) => {
                                                    onChange(event.target.value,'restricted_states')
                                                }
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                            </Row>
                           
                            <Row md="12" lg="12" sm="12" xs="12" >
                                <Col className="btn-section text-right">
                                    {userAccess(userRoleAccess,"delete") ? <Button className="modal-button btn " onClick={onClearModal}>Clear</Button>:''}
                                    {userAccess(userRoleAccess,"create")? <Button className="modal-button btn " onClick={() => onClickAddcourse()} >{isEditing?"Update":"Add"}</Button>:''}
                                </Col>
                            </Row>
                        </Modal>
                    </Col> 
                    </Row>
                    <div style={{position:'absolute'}}>*You can edit the table by using options below</div>
                <Row md="12" lg="12" sm="12" xs="12" className="table-div">
                    <section md="12" lg="12" sm="12" xs="12" className={ scrollview ? "horizontal-scroll" : ""}>
                        <Col md="12" lg="12" sm="12" xs="12" >
                            <Table striped
                                keyField='id' 
                                data={ courses } 
                                columns={ columns }
                                filterOptions={filterOptions}
                                toggle={toggle}
                                scrollview = { scrollview }
                                myColumnToggle={myColumnToggle}
                            />
                        </Col>  
                    </section>     
                </Row>                       
            </div>
        <Paginator
        count={count}
        pages={pages}
        itemRender={itemRender}
        onPageChange={onPageChange}
        pageIndex={pageIndex}/>
        </>
    )
}
export default CourseMarkup
