import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DesignMarkup from '../components/DesignMarkup'
import _ from 'lodash'

class Design extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activate: false,
            showSubmenu: false,
            count: 0,
            showReportSubmenu: false,
            reportCount: 0,
            reportAccess: false,
            dashboardAccess: false

        }
    }

    static getDerivedStateFromProps(props, state) {
        const { allLeads, user } = props
        const access = user.organization.access

        let finalData = access ? JSON.parse(access) : [{ "operations": [1, 1, 1, 1], "name": "MAPPING" }, { "operations": [1, 1, 1, 1], "name": "QUESION" }, { "operations": [1, 1, 1, 1], "name": "INTERVIEW" }, { "operations": [1, 1, 1, 1], "name": "COURSE" }, { "operations": [1, 1, 1, 1], "name": "REPORT" }, { "operations": [1, 1, 1, 1], "name": "STUDENTS" }, {
            "name": "DASHBOARD",
            "operations": [
                1,
                0,
                0,
                0
            ]
        }, {
            "name": "COUNSELOR-REPORT",
            "operations": [
                1,
                0,
                0,
                0
            ]
        }]
        const report = _.find(finalData, { "name": "COUNSELOR-REPORT" })
        const dashboard = _.find(finalData, { "name": "DASHBOARD" })

        let reportAccess = false
        let dashboardAccess = false
        if (report && report) {
            if (report.operations[0] == 1) {
                reportAccess = true
            }
        }
        if (dashboard && dashboard) {
            if (dashboard.operations[0] == 1) {
                dashboardAccess = true
            }
        }
        if (!user.user.is_counselor) {
            reportAccess = true
            dashboardAccess=true
        }
        console.log("reportAccess", reportAccess)
        return { reportAccess, dashboardAccess }


    }
    onActive = () => {
        this.setState({
            activate: true
        })
    }

    onShow = () => {
        const { count } = this.state
        if (count % 2 == 0) {
            this.setState({
                showSubmenu: true,
                count: count + 1
            })
        }
        else {

            this.setState({
                showSubmenu: false,
                count: count + 1
            })
        }
    }

    onReportShow = () => {
        const { reportCount } = this.state
        if (reportCount % 2 == 0) {
            this.setState({
                showReportSubmenu: true,
                reportCount: reportCount + 1
            })
        }
        else {

            this.setState({
                showReportSubmenu: false,
                reportCount: reportCount + 1
            })
        }
    }
    render() {

        const { user: { user }, wizardMode } = this.props
        const { showSubmenu, count, showReportSubmenu, reportCount, reportAccess, dashboardAccess } = this.state
        console.log(count, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
        return (
            <DesignMarkup
                wizardMode={wizardMode}
                userAccessData={user.is_superuser}
                push={this.props.history}
                location={this.props.location.pathname}
                onShow={this.onShow}
                showSubmenu={showSubmenu}
                showReportSubmenu={showReportSubmenu}
                onReportShow={this.onReportShow}
                reportCount={reportCount}
                reportAccess={reportAccess}
                dashboardAccess={dashboardAccess}

            />
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.userRole.isLoading,
        user: state.Auth.user,
        userData: state.userRole.userData
    }
}
export default withRouter(connect(mapStateToProps, null)(Design));