import {
  
    GET_CUSTOMER_REPORT_FAIL,
    GET_CUSTOMER_REPORT_REQUEST,
    GET_CUSTOMER_REPORT_SUCCESS,
    GET_CALLING_REPORT_FAIL,
    GET_CALLING_REPORT_REQUEST,
    GET_CALLING_REPORT_SUCCESS,

} from './type';

const INITIAL_STATE = {
    isLoading: false,
    error: '',
    customerReport:[],
    callingReport:[]
    
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      
        case GET_CUSTOMER_REPORT_FAIL:
            return { ...state, isLoading: false, error: action.payload };
        case GET_CUSTOMER_REPORT_REQUEST:
            return { ...state, isLoading: true };
        case GET_CUSTOMER_REPORT_SUCCESS:
            return { ...state, isLoading: false, error: '',customerReport:action.payload };
            case GET_CALLING_REPORT_FAIL:
                return { ...state, isLoading: false, error: action.payload };
            case GET_CALLING_REPORT_REQUEST:
                return { ...state, isLoading: true };
            case GET_CALLING_REPORT_SUCCESS:
                return { ...state, isLoading: false, error: '',callingReport:action.payload };
        default:
            return state;
    }
};