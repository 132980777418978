import React from 'react';
import Members from './containers/Members';

export default [
    {
        path: '/members',
        exact: true,
        component: () => <Members />
    },
    
];