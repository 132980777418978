import React from 'react';
import { Container } from 'react-bootstrap';
import './callLog.scss';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chart from 'react-apexcharts'
import { ZLoader } from '../../../shared/loader/ZLoader'
const CounslerWiseChart = (props) => {
     const { isLoading, callLogSeries, callLogOptions, counselorWiseSeries, counselorWiseOptions, aPASeries, aPAOptions } = props
     return (
          <div className="call-log-container">

               {isLoading ? <ZLoader /> : null}
               {aPASeries.length && aPAOptions ?
                    <div id="chart">
                         <Chart options={{
                              chart: {
                                   type: 'bar',
                                   height: 350
                              },
                              title: {
                                   text: "APA's students status",
                                   align: 'left'
                              },
                              plotOptions: {
                                   bar: {
                                        horizontal: true,
                                   }
                              },
                              dataLabels: {
                                   enabled: false
                              },
                              xaxis: {
                                   categories: aPAOptions,
                              }
                         }} series={
                              [{
                                   data: aPASeries
                                 }]
                         } type="bar" height={350} />
                    </div> : 
                         <>
                         <p style={{fontWeight:'500'}}>APA's students status</p>
                           <h4>No data available</h4>
                           </>
                    }

          </div>
     )
}

export default CounslerWiseChart;