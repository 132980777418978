import React from 'react';
import { Row, Col,Form, Button } from 'react-bootstrap';
import './RegisterMakupNew.scss';
import background from '../../../assets/background.png'
import zilter from '../../../assets/zilterWhite1.png'
import automation from '../../../assets/auto (1).png'
import { CLOUDFROND_ASSET_URL,BRAND_IMAGE_WHITE_LINK } from '../../../env';
const RegisterMakupNew = (props) => {
    const { test, errors, history,onKeyPress } = props;

    return (
        <div className="new-signup-markup" >
           <Row className="outer-row">
               <Col md="7" lg="7" xs="12" sm="12" className="left-side-login slide-right">
                <div ><img src={`${CLOUDFROND_ASSET_URL}${BRAND_IMAGE_WHITE_LINK}`} height={50}/></div>
                <div><img src={automation} height={370} width={550}/></div>
                <div className="text-container-login">
                    <h1>World' first student quality scoring software</h1>
                    <ul>
                        <li>Convert enquiries to deposit in less than 20 min</li>
                        <li>Reduce visa rejections</li>
                        <li>Identify risks and quality of students before its too late</li>
                        <li>Ai and automation to predict student's performance</li>
                        <li>UK govt approved and listed</li>
                    </ul>
                </div>
               </Col>
              
               <Col>
               <div className="login-box slide-left">
                    <h3 >Sign Up</h3>
                    <Form.Group>
                    <Form.Control type="text"
                     name={"firstName"}
                     placeholder="First Name" 
                     onChange={props.onInputChange}
                     onKeyPress = {onKeyPress}
                     />
                    {errors.first_name ? <span className="error">First Name Is Required (Upto 35 Characters)</span> :'' }

                    <Form.Control 
                    type="text" 
                    placeholder="Last Name" 
                    name={"lastName"}
                    onChange={props.onInputChange}
                    onKeyPress = {onKeyPress}
                    />
                    {errors.last_name ? <span className="error">Last Name Is Required (Upto 35 Characters)</span> :'' }

                    <Form.Control type="email"
                     placeholder="Email" 
                     name={"email"}
                     onChange={props.onInputChange}
                     onKeyPress = {onKeyPress}
                     />
                     {errors.email ? <span className="error">Please Enter Valid Email ID (name@example.com)</span> :'' }

                    <Form.Control type="number"
                     placeholder="Phone"
                     name={"phone"}
                     onChange={props.onInputChange}
                     onKeyPress = {onKeyPress}
                     />
                    {errors.phone ? <span className="error">Phone Number Is Required</span> :'' }

                    <Form.Control 
                    type="text" 
                    placeholder="Organization Name" 
                    name={"organizationName"}
                    onChange={props.onInputChange}
                    onKeyPress = {onKeyPress}  
                    />
                    {errors.organizationName ? <span className="error">Organization Name Is Required</span> :'' }

                    <Form.Control 
                    type="password" 
                    placeholder="Password" 
                    name={"password"}
                    onChange={props.onInputChange}
                    onKeyPress = {onKeyPress}
                    />
                    {errors.password ? <span className="error">Password Must Contain At Least 6 Characters.</span> :'' }

                    <Form.Control 
                    type="password" 
                    placeholder="Confirm password" 
                    name={"confirmPassword"}
                    onChange={props.onInputChange}
                    onKeyPress = {onKeyPress}
                    />
                    <div className="terms">By signin up, you agree to our <a href="https://www.zilter.io/termsandcondition" target = "_blank">Terms and Conditions</a> & <a href="https://www.zilter.io/privacypolicy" target = "_blank">Privacy Policy</a></div>
                    <Button className="login-button" onClick={() => props.onSignUp()}>Register</Button>
                    <Button className="login-button signup" onClick={()=>history.push("/")}>Signin</Button>

                    </Form.Group>
                    
                    </div>
               </Col>
           </Row>
            </div>
       
    )
}

export default RegisterMakupNew;