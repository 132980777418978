import React from 'react';
import { Button, FormGroup, InputGroup } from "@blueprintjs/core";
import { ASSETS_DOMAIN } from '../../../env';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { ZLoader } from '../../../shared/loader/ZLoader';
import ResetPassword from '../containers/ResetPassword'
import Modal from '../../../shared/model/model'
import './Login.scss';
import "./ResetPasswordMarkup.scss"
const ResetPasswordMarkup = (props) => {

    const { newPassword, confirmPassword, confirmError, hasPasswordReset, onInputChange, onConfirmBlur, onNewPasswordBlur, onResetPassword, onClose, errors } = props;

    return (
        <div className="container">
            <div className="inner-container">
                {props.isLoading ?
                    <ZLoader />
                    :
                    <div className="input-container">
                        <div className="image-container">
                            <img
                                src={`${ASSETS_DOMAIN}/assets/images/logo.png`}
                                alt="Zoomabroad logo"
                            />
                        </div>
                        <h3>RESET PASSWORD</h3>
                        <div className="bp3-card .modifier .bp3-elevation-4 form-container">
                            <FormGroup
                                label="New Password"
                                labelFor="text-input"
                                labelInfo="*"
                                style={{
                                    fontWeight: 'bold'
                                }}
                            >
                                <InputGroup
                                    id="text-input"
                                    placeholder={"Enter New Password"}
                                    type={"password"}
                                    name={"newPassword"}
                                    large
                                    value={newPassword}
                                    onChange={onInputChange}
                                    onBlur={onNewPasswordBlur}
                                    appendPasswordEye
                                />
                                {errors.password ? <span className="reset-password">Password Must Contain At Least 6 Characters.</span> :'' }
                                </FormGroup>
                                <FormGroup
                                 label="Confirm New Password"
                                 labelFor="text-input"
                                 labelInfo="*"
                                 style={{
                                     fontWeight: 'bold'
                                 }}
                                 >
                                <InputGroup
                                    id="text-input"
                                    placeholder={"Enter Confirm New Password"}
                                    type={"password"}
                                    name={"confirmPassword"}
                                    large
                                    value={confirmPassword}
                                    onChange={onInputChange}
                                    onBlur={onConfirmBlur}
                                    appendPasswordEye
                                />
                                 {errors.confpass ? <span className="reset-password">The Password and confirm password do not match</span> :'' }
                            </FormGroup>
                            <Button
                                className="bp3-button .bp3-intent-danger button-container" onClick={onResetPassword}>
                                Reset Password
                                    </Button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ResetPasswordMarkup;
