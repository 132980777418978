import React, { Component } from "react";
import _ from 'lodash';
import { ItemRenderer, MultiSelect } from "@blueprintjs/select";
import { MenuItem, Intent } from "@blueprintjs/core";

class MultiSelectOptions extends React.PureComponent<IMultiSelectExampleState> {
        constructor(props) {
            super(props);
            this.state ={
                 createdItems:[],
                 fill:true,                 
                 fields:props.filterOptions,
                 items:[],
            } 
        }
        
   renderfield: ItemRenderer<filterOptions> = (field, { modifiers, handleClick }) => {
       if(field.text=='Sr.No' || field.text=='Actions') { return }
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <div className="menu">
                <MenuItem
                    active={modifiers.active}
                    icon={this.isFieldSelected(field) ? "tick" : "blank"}
                    key={field.dataField}
                    label={field.dataField}
                    onClick={handleClick}
                    text={`${field.dataField}`}
                    shouldDismissPopover={true}
                />
            </div>
        );
    };

    isFieldSelected = (field: filterOptions) => {
        return this.getSelectedFieldIndex(field) !== -1;
    }

    getSelectedFieldIndex = (field: filterOptions) => {
        return this.state.fields.indexOf(field);
    }

    renderInputValue = fields => fields.dataField;

    handleFieldSelect = (field: filterOptions) => {
        if (!this.isFieldSelected(field)) {
            this.selectField(field);
        } else {
            this.deselectField(this.getSelectedFieldIndex(field));
        }
        this.props.myColumnToggle(field)
    }

    deselectField = (index: number) => {
        const { fields } = this.state;

        const field = fields[index];
        const { createdItems: nextCreatedItems, items: nextItems } = this.maybeDeleteCreatedFieldFromArrays(
            this.state.items,
            this.state.createdItems,
            field,
        );

        // Delete the item if the user manually created it.
        this.setState({
            createdItems: nextCreatedItems,
            fields: fields.filter((_field, i) => i !== index),
            items: nextItems,
        });
    }
    maybeDeleteCreatedFieldFromArrays = (
        items: filterOptions[],
        createdItems: filterOptions[],
        field: filterOptions,
        ) => {                          
        const wasItemCreatedByUser = this.arrayContainsField(createdItems, field);
    
        // Delete the item if the user manually created it.
        return {
            createdItems: wasItemCreatedByUser ? this.deleteFieldFromArray(createdItems, field) : createdItems,
            items: wasItemCreatedByUser ? this.deleteFieldFromArray(items, field) : items,
        };
    }

    deleteFieldFromArray = (fields: filterOptions[], fieldToDelete: filterOptions) => {
        return fields.filter(field => field !== fieldToDelete);
    }

    selectField = (field: filterOptions) => {
        this.selectFields([field]);
    }

    selectFields(fieldToSelect: filterOptions[]) {
        const { createdItems, fields, items } = this.state;
        let nextCreatedItems = createdItems.slice();
        let nextFields = fields.slice();
        let nextItems = items.slice();
       
        fieldToSelect.forEach(field => {
            const results = this.maybeAddCreatedFieldToArrays(nextItems, nextCreatedItems, field);
            nextItems = results.items;
            nextCreatedItems = results.createdItems;
             nextFields = !this.arrayContainsField(nextFields, field) ? [...nextFields, field] : nextFields;
        });

        this.setState({
            createdItems: nextCreatedItems,
            fields: nextFields,
            items: nextItems,
        });
    }

    maybeAddCreatedFieldToArrays=(
        items: filterOptions[],
        createdItems: filterOptions[],
        field: filterOptions,
        )=>{ 
        const isNewlyCreatedItem = !this.arrayContainsField(items, field);
        return {
            createdItems: isNewlyCreatedItem ? this.addFieldToArray(createdItems, field) : createdItems,
             items: isNewlyCreatedItem ? this.addFieldToArray(items, field) : items,
        };
    }

    arrayContainsField = (fields: filterOptions[], fieldToFind: filterOptions)=> {
        return fields.some((field: filterOptions) => fields.dataField === fieldToFind.dataField);
    }

    addFieldToArray = (fields: filterOptions[], fieldToAdd: filterOptions) => {
        return [...fields, fieldToAdd];
    }

    arrayContainsField = (fields: filterOptions[], fieldToFind: filterOptions) => {
        return fields.some((field: filterOptions) => field.dataField === fieldToFind.dataField);
    }
    
    render() {
        const {filterOptions} =this.props
        const { fields,fill } = this.state

        return (           
            <MultiSelect
                itemRenderer={this.renderfield}
                items={filterOptions}
                fill ={ fill }
                noResults={<MenuItem disabled={true} text="No results."/>}
                onItemSelect={this.handleFieldSelect}
                popoverProps={{ minimal: true }}
                tagRenderer={this.renderInputValue}
                selectedItems={fields}
            />
        )
    } 
}
export default MultiSelectOptions


