import React, { Component } from 'react';
import { RadioGroup, Radio } from "@blueprintjs/core";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import action from '../../../modules/actions'
import { Toast } from "../../../../../shared/toast/Toast";
import './UniversityAssessment.scss';
import moment from "moment";
import { DateInput } from "@blueprintjs/datetime";
import {
    Label
} from "@blueprintjs/core";
class UniversityAssessment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            commentValue: '',
            pass: null,
            fail: null,
            passYesChecked: false,
            passNoChecked: false,
            id: '',
            errors:{},
            assessmentDate:""
        }
    }
    componentDidMount() {
        const { applicationStatusId } = this.props;
        // if(applicationStatusId && applicationStatusId){
        // this.props.action.getAppUniAssessment(applicationStatusId)
        // }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.assessment && props.assessment.length) {
            if (props.assessment[0].id != state.id) {
                return {
                    commentValue: props.assessment[0].ua_comment_description,
                    passYesChecked: props.assessment[0].pass_val,
                    passNoChecked: props.assessment[0].fail_val,
                    id: props.assessment[0].id,
                    assessmentDate:props.assessment[0].ua_date
                };
            }
        }
        // else {
        //     return {
        //         commentValue: '',
        //     pass: null,
        //     fail: null,
        //     passYesChecked: false,
        //     passNoChecked: false,
        //     id: '',
        //     errors:{}
        //     }
        // }
    }
    clearForm = () => {
        this.setState({
            commentValue: '',
            pass: null,
            fail: null,
            passYesChecked: false,
            passNoChecked: false,
            id: '',
            errors:{}
        })
        this.props.setClearForm()
    }
    isvalid = () =>{
        const{ passNoChecked, commentValue } = this.state;
        let errors = {};
        if(!passNoChecked== '' && commentValue ==''){
            errors.commentValue = true;
            this.setState ({ errors});
            return false;
        }
        return true;
    };
    
    onSubmit = () => {
        const { commentValue, passYesChecked, passNoChecked,assessmentDate } = this.state
        const { match: { params }, userProfile, onCloseModal, applicationStatusId } = this.props;
        if(this.isvalid()){
        const data = {
            'student_id': params.id,
            'user_id': userProfile.organization.user_id,
            'organization_id': userProfile.organization.organization_id,
            'ua_comment_description': commentValue,
            'pass_val': passYesChecked,
            'fail_val': passNoChecked,
            'application_id': applicationStatusId,
            'ua_date' :assessmentDate
        }
        const handlesuccessfull = () => {
            Toast.show({
                message: 'Save successfully',
                timeout: 4000,
                intent: 'success'
            });
            onCloseModal()
            this.setState({
                commentValue: '',
                pass: null,
                fail: null,
                passYesChecked: false,
                passNoChecked: false,
                id: ''
            })
        };
        const handleFailure = () => {
            Toast.show({
                message: 'Save unsuccessfully',
                timeout: 4000,
                intent: 'danger'
            });
            onCloseModal()
            this.setState({
                commentValue: '',
                pass: null,
                fail: null,
                passYesChecked: false,
                passNoChecked: false,
                id: ''
            })
        }
        this.props.action.createApplicationStatus(data, handlesuccessfull, handleFailure, 'assessment')
    }
    }
    onCheckYes = (e) => {
        const { target: { name } } = e;
        this.setState({
            [name]: '1',
            [`${name}YesChecked`]: true,
            [`${name}NoChecked`]: false
        })

    }

    onCheckNo = (e) => {
        const { target: { name } } = e;
        this.setState({
            [name]: '0',
            [`${name}YesChecked`]: false,
            [`${name}NoChecked`]: true
        })
    }

    onChangeInput = (name, value) => {
        this.setState({
            [name]: value, errors:{}
        })
    }
    render() {
        const { commentValue, pass, fail, passYesChecked, passNoChecked, errors ,assessmentDate} = this.state;
        const { clearModel } = this.props
        if (!clearModel) {
            this.clearForm()
        } return (
            <div className="university-assessment">
                <Col>
                    <Form.Label className="title">Result *</Form.Label>
                    <Form.Group as={Row}>
                        <Col>
                            <Form.Check
                                checked={passYesChecked}
                                inline
                                name={'pass'}
                                onChange={(e) => {
                                    this.onCheckYes(
                                        {
                                            "target": { id: "radio1", value: true, name: "pass" }
                                        })
                                }}
                                label="Pass"
                                type={'radio'}
                            />
                            <Form.Check
                                checked={passNoChecked}
                                inline
                                name={'pass'}
                                onChange={(e) => {
                                    this.onCheckNo({
                                        "target": { id: "radio1", value: false, name: "pass" }
                                    })
                                }}
                                label="Fail"
                                type={'radio'} />
                        </Col>
                    </Form.Group>

                </Col>
                <Col>
                <Label className="applied-date" htmlFor="input-b">Date
                <DateInput
                            formatDate={date => moment(date).format("DD/MM/YYYY")}
                            onChange={(date) => this.onChangeInput("assessmentDate", date)}
                            parseDate={str => new Date(str)}
                            placeholder={"DD/MM/YYYY"}
                            defaultValue={new Date()}
                            maxDate={new Date('1/1/2030')}
                            value={assessmentDate?new Date(assessmentDate):new Date()}
                        />
                    </Label>
                </Col>
                <Col>
                    <Form.Label className="title">Comment / Reason *</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Click here to enter text"
                        className="form-control text-box"
                        value={commentValue}
                        onChange={(event) => this.onChangeInput('commentValue', event.target.value)}
                    />
                    {errors.commentValue ? <span className="errors">Please Add comment / Reason Here</span> : ''}
                </Col>
                <Col className="button-save">
                    <Button className="save-offer" onClick={this.onSubmit}>Save</Button>
                </Col>
            </div>
        )

    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        assessment: state.students.assessment
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators(action, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UniversityAssessment));
