import React, { Component } from "react";
import { Container, Row, Col, Form } from 'react-bootstrap';
import { FormGroup, InputGroup, Button, MenuItem, Intent, TagProps, Switch } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import { Editor } from 'react-draft-wysiwyg';
import { GoogleLogin } from 'react-google-login';
import MultiSelectTag from "../../../shared/table/TagInputs"
import counsellorInterest from '../module/counsellorInterest.json'
import "./ProfileDetailsMarkup.scss"


const ProfileDetailsMarkup = (props) => {
    const { inputs, disabled, edit, onEdit, update, onChange, onUnselectInterest, handleClear, onChangeInput, socialResponse, response, onFailure,
        onCheckChange, change_password, error, onEditorStateChange, editorEmailstate } = props;
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>", inputs)
    return (
        <Container>
            {inputs ?
                <Row className="Mapping-page">
                    <Col md="12" lg="12" sm="12" xs="12" className="title" >
                        WELCOME {inputs.first_name}
                    </Col>
                    <Col className="btn-section text-right">
                        <Col md={12} className="text-right">
                            <Button onClick={edit ? update : ''} rightIcon="arrow-right" hidden={disabled} intent="success" text={edit ? "UPDATE" : "CREATE"} />
                        </Col>
                        <Col md={12} className="text-right">
                            {<Button rightIcon="arrow-right" onClick={() => onEdit(inputs.id)} hidden={!disabled} intent="success" text={"EDIT"} />}
                        </Col>
                    </Col>
                    <Col className="table-section" md="12" lg="12" sm="12" xs="12" >
                        <Container>
                            <Col className="table-class">
                                <Row>
                                    <Col className="table-head">
                                        Your Profile
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup
                                            label="First Name"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                value={inputs.first_name}
                                                disabled={disabled}
                                                name={"first_name"}
                                                onChange={(event) => {
                                                    onChange(event.target.value, 'first_name')
                                                }
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup
                                            label="Last Name"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                value={inputs.last_name}
                                                disabled={disabled}
                                                name={"last_name"}
                                                onChange={(event) => {
                                                    onChange(event.target.value, 'last_name')
                                                }
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup
                                            label="Phone Number"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                value={inputs.phone_number}
                                                disabled={disabled}
                                                name={"phone_number"}
                                                onChange={(event) => {
                                                    onChange(event.target.value, 'phone_number')
                                                }
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup
                                            label="Email"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                value={inputs.email}
                                                disabled={true}
                                                name={"email"}
                                                onChange={(event) => {
                                                    onChange(event.target.value, 'email')
                                                }
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup
                                            label="Organization Name"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                value={inputs.organization_name}
                                                disabled={true}
                                                name={"organization_name"}
                                                onChange={(event) => {
                                                    onChange(event.target.value, 'organization_name')
                                                }
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup
                                            label="Country name"
                                            labelFor="text-input"
                                        >
                                            <InputGroup
                                                value={inputs.countryName}
                                                disabled={disabled}
                                                name={"countryName"}
                                                onChange={(event) => {
                                                    onChange(event.target.value, 'countryName')
                                                }
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label>Change Password</Form.Label>

                                        <Form.Group>
                                            <Form.Check
                                                name="change_password"
                                                type="checkbox"
                                                onChange={onCheckChange}
                                                checked={change_password ? true : false}
                                                value={change_password}
                                                disabled={disabled}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {change_password ?
                                    <>
                                        <Row>
                                            <Col>
                                                <FormGroup
                                                    label="New Password"
                                                    labelFor="text-input"
                                                >
                                                    <InputGroup
                                                        value={inputs.new_password}
                                                        disabled={!change_password && disabled}
                                                        name={"new_password"}
                                                        onChange={(event) => {
                                                            onChange(event.target.value, 'new_password')
                                                        }
                                                        }
                                                    />
                                                    {error.newPasswordErr ? <span className="error">Password length must be atleast 6 characters</span> : ''}

                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup
                                                    label="Confirm New Password"
                                                    labelFor="text-input"
                                                >
                                                    <InputGroup
                                                        value={inputs.confirm_new_password}
                                                        disabled={!change_password && disabled}
                                                        name={"confirm_new_password"}
                                                        onChange={(event) => {
                                                            onChange(event.target.value, 'confirm_new_password')
                                                        }
                                                        }
                                                    />
                                                    {/* {error.password ? <span className="error">Password length must be atleast 6 characters</span> : ''} */}
                                                    {error.newPassword ? <span className="error">Passwords did not match</span> : ''}

                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </>
                                    : ''}
                                <Row>
                                    <Col>
                                        <Form.Label>Student Limit</Form.Label>
                                        <Switch
                                            disabled={disabled}
                                            checked={inputs.studentLimitStatus}
                                            onChange={(event) => onChange(event.target.checked, 'studentLimitStatus')}
                                        />
                                    </Col>
                                </Row>
                                {inputs.studentLimitStatus ?
                                    <Row>
                                        <Col>
                                            <Form.Label>Daily Student Limit</Form.Label>

                                            <Form.Control
                                                as="select"
                                                placeholder="Daily Student Limit"
                                                className="form-control modal-email"
                                                disabled={disabled}
                                                value={inputs.studentLimit}
                                                onChange={(event) => onChange(event.target.value, 'studentLimit')}
                                            >
                                                <option value="">Select limt</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    :
                                    ''}
                                <Row>
                                    <Col md="6" lg="6" sm="6" xs="6">
                                        <Form.Label>Department</Form.Label>
                                        <MultiSelectTag
                                            filterOptions={counsellorInterest}
                                            selected={false}
                                            disabled={disabled}
                                            myColumnToggle={onChangeInput}
                                            selectedInterest={inputs.selectedInterest}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6" lg="6" sm="6" xs="6">
                                        <Form.Label>Email Signature</Form.Label>
                                        <Editor
                                            readOnly={disabled}
                                            editorState={editorEmailstate}
                                            wrapperClassName="email-wrapper"
                                            editorClassName="email-editor"
                                            onEditorStateChange={onEditorStateChange}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    {inputs.googleId && disabled ?
                                        <Col className='google-sync'>
                                            <Button disabled={disabled} intent="success"
                                            >G-calender connected</Button>
                                        </Col>
                                        : <Col className='google-sync'>
                                            <GoogleLogin
                                                clientId={`922610075607-oj5o0crom5jt63fkj7cth3r20ogqn5ni.apps.googleusercontent.com`}
                                                buttonText="Google Sync"
                                                onSuccess={(response) => socialResponse(response, 'google')}
                                                onFailure={onFailure}
                                            />
                                        </Col>
                                    }
                                </Row>
                            </Col>
                        </Container>
                    </Col>
                    <Col className="btn-section text-right">
                        <Col md={12} className="text-right">
                            <Button onClick={edit ? update : ''} rightIcon="arrow-right" hidden={disabled} intent="success" text={edit ? "UPDATE" : "CREATE"} />
                        </Col>
                        <Col md={12} className="text-right">
                            {<Button rightIcon="arrow-right" onClick={() => onEdit(inputs.id)} hidden={!disabled} intent="success" text={"EDIT"} />}
                        </Col>
                    </Col>
                </Row> : ''}
        </Container>
    )
}
export default ProfileDetailsMarkup;