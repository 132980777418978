import mirrorkey from 'mirrorkey';

export default mirrorkey([

    'USER_ACCESS_REQUEST',
    'USER_ACCESS_SUCCESS',
    'USER_ACCESS_FAIL',
    'GET_USER_REQUEST',
    'GET_USER_SUCCESS',
    'GET_USER_FAIL',
    'GET_USER_DATA_RESET'

])