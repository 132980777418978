import React, { Component } from 'react';
import 'rc-pagination/assets/index.css';
import { Row, Col, Button } from 'react-bootstrap';
import Pagination from 'rc-pagination';
import './pagination.scss'

class Paginator extends Component {
    constructor(props) {
        super(props);
        this.state = {
			pageSize: 10,

        }
    }
    
    render() {
		const {count, itemRender, onPageChange, pageIndex} = this.props;
		
        return (
            <Row md="12" lg="12" sm="12" xs="12" >
            <Col className="paginationButton"  md="7" lg="7" sm="7" xs="7">
                <div className="pull-right float-right">
                    <Pagination pageSize={10} total={count} itemRender={itemRender} onChange={onPageChange} current={pageIndex} locale={"en_US"}></Pagination>
                </div>
            </Col>
           </Row>
        )
    }
}


export default Paginator;













 

