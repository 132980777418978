import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import AuthenticateMarkup from '../components/AuthenticateMarkup';
import actions from '../modules/actions';
import { Toast } from '../../../shared';

class Authenticate extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        const { location: { search }  } = this.props;
        if (search) {
            const query = new URLSearchParams(search);
            const app = query.get('app');
            const token = query.get('token');
            const id = query.get('id');
            if(app && token && id) {
                const handleSuccess = (response) => {
                    this.props.history.push(`student/result/${id}`);
                }
                const handleFailure = (error) => {
                    Toast.show({
                        message: "Invalid auth details",
                        timeout: 100000,
                        intent: 'danger'
                    })
                }
                this.props.actions.ssologin(handleSuccess, handleFailure, token);
            }
        }
    }
    render() {
        return (
            <AuthenticateMarkup
                isLoading={this.props.isLoading}
            />
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.Auth.isLoading,
    }

}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Authenticate));