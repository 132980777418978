import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import ForgetPasswordMarkup from '../components/ForgetPasswordMarkup';
import actions from '../modules/actions';
import { Toast } from '../../../shared';

class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailVal: "",
            forgotPasswordResponse: "",
            isSuccess:false
        }
    }

    onInputChange = (e) => {
        this.setState({
            [`${e.target.name}Val`]: e.target.value
        });
    }
    onOpenResetModal = () => {
		this.setState({
			isResetModalOpen: true,
		})
	}

    componentDidMount() {
        const { token: { token } } = this.props;
        // const urlParts = pathname.split("/");
        // this.setState({ mode: urlParts[3], id: urlParts[4] })
        // if(urlParts[3] && urlParts[4]) {
        //     this.props.actions.tokenVerify(urlParts[4]);
        // }
    }

    onCloseModal = () => {
		this.setState({	
			isResetModalOpen: false,
		});
	}
    onSubmit = () => {
        const { emailVal: email } = this.state;
        if (email === "" || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email )) {
            this.setState({
                forgotPasswordResponse: "Please Enter Registered Email ID",
            })
            return;
        }

        const handleFailure = (error) => {
            if (error.status === 401) {
                this.setState({
                    forgotPasswordResponse: Toast.show({
                        message: "Please Enter Registered Email ID",
                        timeout: 4000,
                        intent: 'danger'
                    })
                })
            }
        }
        const handleSuccess = (body) => {
            this.setState({
                isSuccess: true,
                forgotPasswordResponse: 'Reset Link has been sent to the given email id',
                emailVal: ''
            });      
        };
        
        this.props.actions.forgetPassword(handleSuccess, handleFailure, { email });
    }
    render() {
        const { emailVal,forgotPasswordResponse, isSuccess} = this.state;
        return (
            <ForgetPasswordMarkup
                isLoading={this.props.isLoading}
                error={this.props.error}
                emailVal={emailVal}
                onInputChange={this.onInputChange}
                onSubmit={this.onSubmit}
                isSuccess={isSuccess}
                forgotPasswordResponse = {forgotPasswordResponse}
                history={this.props.history}
            />
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.Auth.isLoading,
        token: state.Auth.token
    }

}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgetPassword));