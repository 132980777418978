import React, { Component } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import actions from '../../modules/actions'
import { ORG_ID } from '../../../../env'
import { Toast } from '../../../../shared'
import moment from 'moment';
import {
    Label
} from "@blueprintjs/core";
import './ConfirmingInterests.scss';

class ConfirmingInterests extends Component {
    constructor(props) {
        super(props)
        this.state = {
            universityName: '',
            courseName: '',
            year: '',
            month: '',
            degree: '',
            id: '',
            errors: {},
            multiple: [1],
            studentIndex: 0,
            selected: [],
            disableError: false
        }
    }
    componentDidMount() {
        const { match: { params }, userProfile, student } = this.props;
        this.props.actions.getStudent(params.id);
        this.props.actions.getStudentAppliedCourses(params.id)
    }

    static getDerivedStateFromProps(props, state) {

    }

    isvalid = () => {
        const { selected, courseName } = this.state;
        let errors = {};

        if (selected == '') {
            errors.universityName = true;
            this.setState({ errors });
            return false;
        }
        if (courseName == '') {
            errors.courseName = true;
            this.setState({ errors });
            return false;
        }


        return true;
    };
    onAdd = () => {
        const { multiple } = this.state
        multiple.push(1)
        this.setState({
            multiple
        })
    }
    onSubmit = () => {
        const { match: { params }, userProfile, onCloseModal, student, studentCourses } = this.props;
        const { universityName, courseName, multiple, studentIndex, selected } = this.state;

        let data = []
        let note = []
        for (let i = 0; i <= selected.length - 1; i++) {

            let opportunityData = {}
            opportunityData = {
                "option_course_name": `${selected[i].applied_courses}, ${selected[i].course_level}, ${selected[i].application_intake_month}-${selected[i].application_intake_year}`,
                "option_course_description": selected[i].country,
                "option_university_name": selected[i].university_name,
                "selected_course": null,
                "type": 'final_decision',
                "selected_offer": 0,
                "student_id": params.id,
                "user_id": userProfile.organization.user_id,
                'is_advisor': 1
            }
            data.push(opportunityData)
            let noteT = {
                "Select course offer" : `course name : ${selected[i].applied_courses}
                                         course degree : ${selected[i].course_level}
                                         intake month: ${selected[i].application_intake_month}
                                         intake year: ${selected[i].application_intake_year}
                                        university name : ${selected[i].university_name}
                                        country name : ${selected[i].country}`
            }
            noteT = JSON.stringify(noteT)
            noteT = noteT.replace('{', '')
            noteT = noteT.replace('}', ',')

            note.push(noteT)

        }
        const taskList = {
            'student_id': params.id,
            'user_id': userProfile.organization.user_id,
            'organization_id': userProfile.organization.organization_id,
            'meeting_schedule': false,
            'attending_meeting': false,
            'required_documents': false,
            'visa_appointment': false,
            'opportunity': false,
            'select_offer': true,
            'qualifying': false,
        }
        
        const log = {
            "std": params.id,           ////student_id
            "u_id": userProfile ? userProfile.organization.user_id : null,
            "student_courses_id": courseName ? courseName : null,
            "subject": 'Task assigned course offer select',
            "type": 'note',
            "note": note.toString(),
            "note_status": userProfile.organization.organization_id == ORG_ID ? 'private' : 'private',
            "student_name": student.first_name,
            "student_email": student.email,
            "organization_id": userProfile ? userProfile.organization.organization_id : null,

        }
        const handlesuccessfull = () => {
            this.props.actions.taskAllocation(taskList, () => { 
      this.props.actions.getLogs(params.id, 1);

            }, () => { })
            this.props.actions.createNoteLog(log, ()=>{}, () => { });
            Toast.show({
                message: "Making final decision task allocation send successfully!",
                timeout: 4000,
                intent: 'success'
            })
            this.setState({
                universityName: '',
                courseName: '',
                year: '',
                month: '',
                degree: '',
                id: '',
                errors: {},
                multiple: [1],
                studentIndex: 0,
                selected: []

            })
            onCloseModal()
        }
        const handleFailure = () => {
            Toast.show({
                message: "Making final decision task allocation send unsuccessfull!",
                timeout: 4000,
                intent: 'danger'
            })
            this.setState({
                universityName: '',
                courseName: '',
                year: '',
                month: '',
                degree: '',
                id: '',
                errors: {},
                multiple: [1],
                studentIndex: 0,
                selected: []

            })
            onCloseModal()

        }
        if(data && data.length){
            this.props.actions.counsellorOpportunity(data, handlesuccessfull, handleFailure)
        }else{
            this.setState({disableError: true})
        }

    }
    onChangeInput = (name, event) => {
        const { selected } = this.state
        if (event.target.checked) {
            const found = selected.some(el => el.id === name.id);
            if (!found){
                selected.push(name)
            } 
            this.setState({
                selected
            })
            this.setState({disableError: false})
        }else{
            const newArr = selected.filter(object => {
                return object.id !== name.id;
              });
              this.setState({
                selected : newArr
            })
        }
    }
    render() {
        const { universityName, errors, courseName, multiple, disableError } = this.state;
        const { studentCourses } = this.props
        return (
            <div className="balance-fee">
                { studentCourses && studentCourses.length > 0 ?
                    <>
                        { studentCourses.map((input, index) => {
                            return (<div>
                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check
                                        id={`${index}`}
                                        type="checkbox"
                                        onChange={(event) => {
                                            this.onChangeInput(input, event)
                                        }}
                                        label={`${input.applied_courses}, ${input.university_name}, ${input.country} - ${input.course_level} ${input.application_intake_month} ${input.application_intake_year}`}
                                    />
                                </Form.Group>
                            </div>)
                        })
                        }
                        <Col className="button-save">
                            <Button className="save-offer" onClick={() => this.onSubmit()}>Allocate</Button>
                        </Col>
                        {disableError ? <div className="errors">Please select the course</div> : ''}

                    </>
                    : <div>
                        <h5>Please apply for course</h5>
                    </div>}

            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        student: state.students.student,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfirmingInterests));
