import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Toast } from "../../../shared/toast/Toast";
import _ from "lodash";
import moment from "moment";
import actions from "../modules/actions";
import EventMarkup from "../components/EventMarkup";

class Event extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Sunday: false,
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      from: "",
      to: "",
      Saturday: false,
      duration: "",
      schedule: [],
      start_date: "",
      end_date: "",
      stateSchedule: [],
      stateId: "",
    };
  }
  static getDerivedStateFromProps(props, state) {
    const {
      getTimeSlots,
      user: { user },
    } = props;

    if (!state.stateId) {
     

      // if(getTimeSlots && getTimeSlots.duration){
      //     duration = getTimeSlots.duration
      // }
      if (getTimeSlots && getTimeSlots.slots) {
        let duration = "";
        let Friday = false;
        let Sunday = false;
        let Monday = false;
        let Tuesday = false;
        let Wednesday = false;
        let Thursday = false;
        let Saturday = false;
        let stateSchedule = [];
        let slots = [];
        slots = JSON.parse(getTimeSlots.slots);
        duration = getTimeSlots.duration;

        let fridayObj = _.find(slots, { day: "Friday" });
        if (fridayObj) {
          Friday = true;
        }
        let saturdayObj = _.find(slots, { day: "Saturday" });
        if (saturdayObj) {
          Saturday = true;
        }
        let sundayObj = _.find(slots, { day: "Sunday" });
        if (sundayObj) {
          Sunday = true;
        }
        let mondayObj = _.find(slots, { day: "Monday" });
        if (mondayObj) {
          Monday = true;
        }
        let tuesdayObj = _.find(slots, { day: "Tuesday" });
        if (tuesdayObj) {
          Tuesday = true;
        }
        let wednesdayObj = _.find(slots, { day: "Wednesday" });
        if (wednesdayObj) {
          Wednesday = true;
        }
        let thursdayObj = _.find(slots, { day: "Thursday" });
        if (thursdayObj) {
          Thursday = true;
        }
      
      return {
        duration,
        Friday,
        Sunday,
        Monday,
        Tuesday,
        Wednesday,
        Thursday,
        Saturday,
        stateSchedule: slots,
        schedule: slots,
        stateId: getTimeSlots && getTimeSlots.id ? getTimeSlots.id : "",
      };
    }
  }
  }
  componentDidMount() {
    const {
      location: { pathname },
      user: { user },
    } = this.props;
    this.props.actions.getScheduleDetails(user.id);
  }
  onChangeSelect = (name, value, day) => {
    const { schedule } = this.state;
    console.log(name, value, day, ">>>>>>>>>name,value,day");
    // console.log(name,value,day);
    let select = { day: day };
    let index = schedule.findIndex((x) => x.day == day);
    console.log(index, "index");
    index === -1
      ? schedule.splice(0, 0, select)
      : console.log("object already exists");

    for (let i = 0; i < schedule.length; i++) {
      if (schedule[i].day == day && name == "from") {
        schedule[i].from = value;
      }
      if (schedule[i].day == day && name == "to") {
        schedule[i].to = value;
      }
    }

    this.setState({
      schedule,
    });
  };

  onChange = (name, value) => {
    const { schedule } = this.state;
    console.log(name, value, ">>>name");
    if (value == false) {
      let index = schedule.findIndex((x) => x.day === name);

      schedule.splice(index, 1);
    }
    this.setState({
      [name]: value,
      schedule,
    });
  };

  onSave = () => {
    const {
      Sunday,
      Monday,
      Tuesday,
      Wednesday,
      Thursday,
      Friday,
      Saturday,
      from,
      to,
      duration,
      schedule,
      start_date,
      end_date,
    } = this.state;
    console.log(
      Sunday,
      Monday,
      Tuesday,
      Wednesday,
      Thursday,
      Friday,
      Saturday,
      from,
      to,
      duration,
      schedule
    );

    const data = {
      slots: JSON.stringify(schedule),
      duration: duration ? duration : 15,
      datarange: JSON.stringify({
        startDate: start_date,
        endDate: end_date,
      }),
    };
    const handleFailure = (res) => {
      Toast.show({
        message: "Save Unsuccessfully",
        timeout: 4000,
        intent: "danger",
      });
    };
    const handleSuccess = () => {
      Toast.show({
        message: "Save Successfully",
        timeout: 4000,
        intent: "success",
      });
    };
    this.props.actions.counsellorTimeSlot(
      () => {},
      () => {},
      data
    );
    this.props.actions.ZACounsellorTimeSlot(handleSuccess, handleFailure, data);

    console.log(data);
  };
  handleRangeChange = (event) => {
    this.setState({ start_date: event[0], end_date: event[1] });
  };
  render() {
    console.log(this.state.schedule, ">>>>>>>>jhjkhkhk>>>.");
    const { start_date, end_date, duration, stateSchedule, schedule } =
      this.state;
    const { isLoading, getTimeSlots } = this.props;
    return (
      <EventMarkup
        onSave={this.onSave}
        onChange={this.onChange}
        onChangeSelect={this.onChangeSelect}
        myState={this.state}
        handleRangeChange={this.handleRangeChange}
        start_date={start_date}
        end_date={end_date}
        duration={duration}
        isLoading={isLoading}
        stateSchedule={schedule}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.Auth.user,
    isLoading: state.activity.isLoading,
    getTimeSlots: state.activity.getTimeSlots,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Event));
