import React, { Component } from "react";
import { Container, Row, Col, Form , Button, OverlayTrigger, Popover} from 'react-bootstrap';
import { FormGroup, InputGroup  } from "@blueprintjs/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { ZLoader } from '../../../shared/loader/ZLoader'
import Modal from '../../../shared/model/model'
import "./ChannelMarkup.scss"

const ChannelMarkup = (props) => {
    const { channelList, openChannelEdit, showCreate, onCloseModal, createShow, onChangeInput, channelName, emailName, stateName, cityName, channelCreate, openChannelDelete, isLoading, shortName, errors } = props
    return (
        <div className="channel-container">
        {isLoading ? <ZLoader /> : null}
            <Modal
                modalTitle={"Channel"}
                isModalOpen={createShow}
                onCloseModal={onCloseModal}

            >
                <div>
                    <Form>
                        <Form.Group>
                        <Form.Label>Channel Name *</Form.Label>
                            <Form.Control
                                name="channelName"
                                value={channelName}
                                className="form-input-field"
                                placeholder="Enter channel name"
                            onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                            />
                            {errors.channelName ? <span className="errors">Please Enter valid Channel Name</span> : ''}
                        </Form.Group>
                        <Form.Group>
                        <Form.Label>Channel Short Name *</Form.Label>
                            <Form.Control
                                name="shortName"
                                value={shortName}
                                className="form-input-field"
                                placeholder="Enter channel short name"
                            onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                            />
                            {errors.shortName ? <span className="errors">Please Enter valid Channel Short Name</span> : ''}
                        </Form.Group>
                        <Form.Group>
                        <Form.Label>Email *</Form.Label>
                            <Form.Control
                                name="emailName"
                                value={emailName}
                                className="form-input-field"
                                placeholder="Enter Email"
                            onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                            />
                            {errors.emailName ? <span className="errors">Please Enter valid Email</span> : ''}
                        </Form.Group>
                        <Form.Group>
                        <Form.Label>State *</Form.Label>
                            <Form.Control
                                name="stateName"
                                value={stateName}
                                className="form-input-field"
                                placeholder="Enter State"
                            onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                            />
                            {errors.stateName ? <span className="errors">Please Enter valid State</span> : ''}
                        </Form.Group>
                        <Form.Group>
                        <Form.Label>City *</Form.Label>
                            <Form.Control
                                name="cityName"
                                value={cityName}
                                className="form-input-field"
                                placeholder="Enter City"
                            onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                            />
                            {errors.cityName ? <span className="errors">Please Enter valid City Name</span> : ''}
                        </Form.Group>
                        <Button disabled={!(channelName && shortName && emailName && stateName && cityName)}
                        className="pull-right channel-button"
                        onClick={channelCreate}
                    >Submit
                            </Button>
                    </Form>
                </div>
            </Modal>
            <Row className="channel-top-row">
                <Col md="5" className="channel-create">
                    Channel-channel
                </Col>
                <Col className="channel-create-button">
                    <Button
                        className="pull-right channel-button"
                        onClick={showCreate}
                    >Create
                            </Button>
                </Col>
            </Row>
            <div>
                <Row>
                    <Col md='4'>
                        <p className="text-center">channel Name</p>
                    </Col>
                    <Col  md='3'>
                        <p className="text-center">Email</p>
                    </Col>
                    <Col md='2'>
                        <p className="text-left">State</p>
                    </Col>
                    <Col>
                        <p className="text-left">City</p>
                    </Col>
                    <Col>
                        <p className="text-left">Short</p>
                    </Col>
                    <Col>
                        <p className="text-left">Actions</p>
                    </Col>
                </Row>
            </div>
            {channelList.map(channel => {
                return (
                    <Accordion>
                        <Card className="channel">
                            <Row className="channel-row">
                                <Col md='4'>
                                    {channel.channel_name}
                                </Col>
                                <Col md='3'>
                                    {channel.email}
                                </Col>
                                <Col md='2'>
                                    {channel.state}
                                </Col>
                                <Col className='city-name' md='1'>
                                    {channel.city}
                                </Col>
                                <Col md='1'>
                                    {channel.channel_short_name}
                                </Col>
                                <Col md='1' className="channel-action">
                                <OverlayTrigger
                                        placement={'top'}
                                        delay={{ hide: 200 }}
                                        overlay={<Popover className="tool-tip" id="tooltip-disabled">Edit</Popover>}>
                                        <FontAwesomeIcon onClick={() => openChannelEdit(channel.id, channel)} className="cross-icon edit" icon={['fas', 'edit']} />
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        placement={'top'}
                                        delay={{ hide: 200 }}
                                        overlay={<Popover className="tool-tip" id="tooltip-disabled">Delete</Popover>}>
                                        <FontAwesomeIcon onClick={() => openChannelDelete(channel.id)} className="cross-icon trash" icon={['fas', 'trash']} />
                                    </OverlayTrigger>
                                    
                                </Col>
                            </Row>
                        </Card>

                    </Accordion>
                )
            })}
        </div>
    )
}
export default ChannelMarkup;