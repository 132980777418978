import React, { Component } from 'react'
import Card from './Card'
import update from 'immutability-helper'
const style = {
  
}

class Sortable extends Component {
    constructor(props){
        super(props)
        this.state = {
            cards: props.selected
        }
    }
    static getDerivedStateFromProps(props, state) {
        if(state.cards.length != props.selected.length) {
            return {
                cards: props.selected
            }
        }
    }
    moveCard = (dragIndex, hoverIndex) => {
      
        const { cards } = this.state;
        const dragCard = cards[dragIndex]
        const newCards = update(cards, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
            ],
        })
        this.setState({cards: newCards});
    }
    render() {
        const { cards } = this.state;
        const {onClickRemove} = this.props
        return ( 
            <div style={style}>
                {cards.map((card, i) => (
                    <Card
                        key={card.id}
                        index={i}
                        id={card.id}
                        text={card.question}
                        moveCard={this.moveCard }
                        onClickRemove={onClickRemove}
                        cards={card}
                    />
                ))}
            </div>
        )
    }
}
export default Sortable;
