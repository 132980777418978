import React from 'react';
import Integration from './container/Integration'
import UCAMForm from './container/UCAMForm'
import ZilterForm from './container/ZilterForm'
import Connect from './container/Connect'
import Mapping from './container/Mapping'
import MappedConnect from './container/MappedConnect'
import Thanks from './components/Thanks'
import Cutomwizard from '../Wizards/components/Wizard'

export default [
    {
        path: '/integration',
        exact: true,
        component: () => <Integration />
    },
    {
        path: '/application/:org_id',
        exact: true,
        component: () => <UCAMForm />
    },
    {
        path: '/form/:org_id',
        exact: true,
        component: () => <ZilterForm />
    },
    {
        path: '/integration/connect',
        exact: true,
        component: () => <Connect />
    },
    {
        path: '/integration/mapping',
        exact: true,
        component: () => <Mapping />
    },
    {
        path: '/integration/mappedconnect',
        exact: true,
        component: () => <MappedConnect />
    },
    {
        path: '/ziltersteps',
        exact: true,
        component: () => <Cutomwizard />
    },
    {
        path: '/studentsuccess',
        exact: true,
        component: () => <Thanks />
    },
];