import mirrorkey from 'mirrorkey';
export default mirrorkey([
    'DASHBOARD_LOADING',
    'DASHBOARD_SUCCESS',
    'DASHBOARD_ERROR',
    'CHANGE_PASSWORD_SUCCESS',
    'CHANGE_PASSWORD_ERROR',
    'DEAL_ERROR',
    'DEAL_SUCCESS',
    'GET_ALL_INTERVIEWS_REQUEST',
    'GET_ALL_INTERVIEWS_SUCCESS',
    'GET_ALL_INTERVIEWS_FAIL',
    'GET_INTERVIEW_SUCCESS',
    'GET_INTERVIEW_FAIL',
    'QUESTION_REQUEST',
    'QUESTION_REGISTER_ERROR',
    'QUESTION_REGISTER_SUCCESS',
    'POST_INTERVIEW_REQUEST',
    'POST_INTERVIEW_FAIL',
    'POST_INTERVIEW_SUCCESS',
    'UPDATE_INTERVIEW_REQUEST',
    'UPDATE_INTERVIEW_FAIL',
    'UPDATE_INTERVIEW_SUCCESS',
    'GET_ALL_QUESTIONS_REQUEST',
    'GET_ALL_QUESTIONS_SUCCESS',
    'GET_ALL_QUESTIONS_FAIL',
    'UPDATE_QUESTION_REQUEST',
    'UPDATE_QUESTION_SUCCESS',
    'UPDATE_QUESTION_FAIL',
    'GET_QUESTION_REQUEST',
    'GET_QUESTION_SUCCESS',
    'GET_QUESTION_FAIL',
    'SAAS_UPLOAD_INTERVIEW_REQUEST',
    'SAAS_UPLOAD_INTERVIEW_REQUEST_FAIL',
    'SAAS_UPLOAD_INTERVIEW_REQUEST_SUCCESS',
    'GET_SEARCH_INTERVIEWS_REQUEST',
    'GET_SEARCH_INTERVIEWS_SUCCESS',
    'GET_SEARCH_INTERVIEWS_FAIL',
    'GET_STUDENT_INTERVIEW_REQUEST',
    'GET_STUDENT_INTERVIEW_FAIL',
    'GET_STUDENT_INTERVIEW_SUCCESS'
])