import React, { Component } from 'react';
import Integration from '../../Integration/container/Integration'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import Mapping from '../../Integration/container/Mapping'
import Question from '../../Interview/containers/Question'
import Questions from '../../Interview/containers/Questions'
import Interview from '../../Interview/containers/Settings'
import Interviews from '../../Interview/containers/Interviews'
import Student from '../../Students/container/Student'
import Course from '../../Course/Container/CourseTable'
import { Button } from "@blueprintjs/core";
import actions from '../modules/actions'
//import Selectedfeatures from '../modules/Selectedfeatures'
import './ZilterSteps.scss'
class ZilterSteps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            compState : 0,
            compName:undefined,
            show:true,            
              showNextBtn:true,
              showPreviousBtn:false,
              tempset:'',
              styles : [], 
              steps:{},          
              stepobj : [ 
                     [ 
                        {name: 'Mapping', component: <Mapping wizard={true} wizardMode={props.wizardMode}/>},
                        {name: 'Question', component:  <Question gotoStep = { this.gotoStep } setstep ={this.setstep} gotoStep={this.gotoStep} wizard={true} wizardMode={props.wizardMode}/> },
                        {name: 'Interview', component: <Interview gotoStep = { this.gotoStep } setstep ={this.setstep} wizard={true} wizardMode={props.wizardMode}/>},
                        {name: 'Course', component: <Course wizard={true} wizardMode={props.wizardMode}/>},
                        {name: 'Questions List', component:  <Questions gotoStep = { this.gotoStep } setstep ={this.setstep} wizard={true} wizardMode={props.wizardMode}/> },
                        {name: 'Interviews List', component: <Interviews gotoStep = { this.gotoStep } setstep ={this.setstep} wizard={true} wizardMode={props.wizardMode}/>},
                     ],
                     [ 
                        {name: 'Mapping', component: <Mapping wizard={true} wizardMode={props.wizardMode}/>},
                        {name: 'Question', component:  <Question gotoStep = { this.gotoStep } setstep ={this.setstep} wizard={true} wizardMode={props.wizardMode}/> },
                        {name: 'Interview', component: <Interview gotoStep = { this.gotoStep } setstep ={this.setstep} wizard={true} wizardMode={props.wizardMode}/>},
                        {name: 'Questions List', component:  <Questions gotoStep = { this.gotoStep } setstep ={this.setstep} wizard={true} wizardMode={props.wizardMode}/> },
                        {name: 'Interviews List', component: <Interviews gotoStep = { this.gotoStep } setstep ={this.setstep} wizard={true} wizardMode={props.wizardMode}/>},
                    ],
                    [ 
                        {name: 'Mapping', component: <Mapping wizard={true} wizardMode={props.wizardMode}/>},
                        {name: 'Question', component:  <Question setstep ={this.setstep} gotoStep = { this.gotoStep } wizard={true} wizardMode={props.wizardMode}/> },
                        {name: 'Course', component: <Course wizard={true} wizardMode={props.wizardMode}/>},
                        {name: 'Questions List', component:  <Questions setstep ={this.setstep} gotoStep = { this.gotoStep } wizard={true} wizardMode={props.wizardMode}/> },
                        {name: 'Interviews List', component: <Interviews setstep ={this.setstep} gotoStep = { this.gotoStep } wizard={true} wizardMode={props.wizardMode}/>},
                   ]
                ]
        }
    }
    static getDerivedStateFromProps(props, state) {
        const { Zilterstep , SelectedfeaturesString } = props;
        const { steps,compState,compName,stepobj} =state;
        let Compindex = undefined;
        let tempstep = {};
        if(SelectedfeaturesString && SelectedfeaturesString.length)
        {
            const Selectedfeatures  = JSON.parse(SelectedfeaturesString)
            if(Selectedfeatures.length==2) {
                tempstep=stepobj[0];
            }
            else if(!Selectedfeatures.some((Selectedfeatures) => Selectedfeatures.name === "Interview"))
            {
                tempstep = stepobj[2]
            }
            else
            {
                tempstep = stepobj[1]
            }
        } 
        else 
        {
            tempstep = stepobj[0]
        }
        if(Zilterstep) {
            console.log("ZilterstepZilterstepZilterstep",Zilterstep)
             if(compName   !=  Zilterstep.step)
            {
                {
                    const stepname = Zilterstep.step;
                    const StepsFilter=tempstep;
                    StepsFilter.map((item,i) => {
                        if(item.name==stepname)
                        {
                            Compindex=i;
                        }
                        if(stepname==1)
                        {
                            Compindex=0;
                        }
                    })
                    return({
                        steps:tempstep,
                        compState:Compindex,
                        compName:tempstep[Compindex].name
                        })
                }
            }
        }     
        
    }
    componentDidMount() {
        const { user } = this.props;
        this.props.actions.getStep(user.organization_id);
        this.props.actions.wizardstart();
    }
    setstep = (step) => {
        const { steps } = this.state;
        this.setState({ compState:step })
        this.getButtonsState(step);
        if(steps[step].name == "Questions List" || steps[step].name == "Interviews List") return
        else {
            this.update(step) 
        }
    }
    gotoStep = (stepname) => {
        const { steps } = this.state;
        let stepno = undefined;
        steps.map((s,i) => (
            s.name==stepname ? stepno= i : {}
        ))
        this.setstep(stepno)
    }
    update = (fixstep) => {
        const { steps , compState} = this.state;
        const { user } = this.props;
        const org_id= user.organization_id
        const request = {
            step:steps[fixstep].name,
        }
        const handleSuccess=()  => {}
        const handleFailure=()  => {}
        this.props.actions.updateStep(handleSuccess,handleFailure,request,org_id); 
    }
    previous = () => { 
        const { compState ,steps } = this.state;
        let check =  compState>steps.length-3? `${compState == steps.length-2 ? 0 : 1}`:compState - 1; 
        let step =  compState > 0 ? check : compState  
        this.setState((prevState) => ({ compState:step }))
        this.getButtonsState(step); 
        this.update(step) 
        this.setstep(step)     
    }
    next = () => {  
        const { compState,steps,compName } = this.state;
        if(compState > steps.length-3) {
            let fixstep= compState == steps.length-2  ? 2 : steps.length - 3;
            this.getButtonsState(fixstep); 
            this.update(fixstep)
            this.setstep(fixstep) 
            this.setState((prevState) => ({ compState: fixstep}))  
       }
        else {            
            let step = compState+1;
            let fixstep =/^[0-9]{1,2}[:.,-]?$/.test(step) ? step%10 : step
            this.getButtonsState(fixstep);
            this.update(fixstep)
            this.setstep(fixstep) 
            this.setState((prevState) => ({ compState: fixstep}))  
           
        }      
    }
    finish = () => {
        const handleSuccess=()  => {}
        const handleFailure=()  => {}
        const { user } = this.props;
        const org_id= user.organization_id
        let request = {
            step:"Finished",
        }
        this.props.actions.updateStep(handleSuccess,handleFailure,request,org_id);
        this.props.history.push('students') 
        this.props.actions.wizardend();
    }
    getButtonsState = (compState) => {
        const {  steps } =this.state;
        if (compState > 0 && compState < steps.length - 1) {
           return this.setState({ showPreviousBtn:true,showNextBtn:true }) 
        } else if (compState === 0) {
          return this.setState({ showPreviousBtn:false,showNextBtn:true })
        } else {
          return this.setState({ showPreviousBtn:true,showNextBtn:false })
        }
    }
    render() {
        const { compState,show,steps}= this.state; 
        const { propscompState,wizardMode } = this.props;            
        const step  =  propscompState ? propscompState : compState
        return (
            <>
            
            { steps.length ? <div className="customwizard-container">                                  
                <ol>
                    {  steps.map((s, i) => ( 
                      i < steps.length-2 ?
                      <>
                        <li 
                        className ={ step > steps.length-3  ? `${compState == steps.length-2 ? `${i < 1 ? `${steps[i].name} done` : `${i == 1 ? `${steps[i].name} doing` : `${steps[i].name} todo`}`}`:`${i < 2 ? `${steps[i].name} done` : `${i == 2 ? `${steps[i].name} doing` : `${steps[i].name} todo`}`}`}`:`${i < step ? `${steps[i].name} done` : `${i == step ? `${steps[i].name} doing` : `${steps[i].name} todo`}`}`}
                            key={i}
                            value={i}
                        >
                        <span>{steps[i].name}</span>
                        </li> </>:''
                        ))  
                    }
                </ol>
                {show && ( <Row className="navdiv">
                    <Col>
                <Button className={`steps-button-top ${step==0 ?"hide-it":""}`} onClick={this.previous} >Previous</Button>
                </Col>
                <Col>
                <Button className="steps-button-top" text={step==steps.length-3 ? "Finish" : "Next"} onClick={step==steps.length-3? this.finish : this.next} style={{right:20,} } />
                </Col>
                </Row> )}
                <div>{ steps[step].component } </div>
                {/* {show && (
                    <div className="navdiv">   
                        <div>
                            <button className="prevbtn"
                                style={step==0 ? {  display: 'none' } : { }}
                                onClick={this.previous} >
                                Prev
                            </button>
                        </div>
                        <div>
                            <Button className="nextbtn"
                                onClick={step==steps.length-3? this.finish : this.next}
                                text={step==steps.length-3 ? "Finish" : "Next"} />
                        </div>                     
                    </div> */}
            </div> :''}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    console.log("state",state)
	return {
		wizardmode: state.wizard.wizardmode,
        user: state.Auth.user.organization,
        Zilterstep:state.wizard.Step,
        SelectedfeaturesString : state.wizard.Step.feature
	}
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ZilterSteps));

