const json_to_params = (params) => Object.keys(params).map(key => key + '=' + params[key]).join('&');

const app_url_generator = (config) => `${config.url}?${json_to_params(config.params)}`;

const jsonToFormData = (item) => {
    let formData = new FormData();
    for (let key in item) {
        formData.append(key, item[key]);
    }
    return formData;
}

const toQueryString = object =>
    '?' +
    Object.keys(object)
        .map(key => `${key}=${object[key].toString()}`)
        .join('&');

const generateProperties = object => object.map(key => `property=${key.toString()}`)
    .join('&');

const validation = (data,newData) => {
    console.log("data==========",data);
    console.log("newdat========",newData);
    let valid = true;
    data.map((input) => {
        console.log(input,":::::::::::::::::");
        input.error = false;
        if (input.mandatory && input.value === '') {
            input.error = true;
            input.errorText = `${input.label} is required.`;
            valid = false;
        }
        if(input.pattern ){
        if (input.value  && input.value != '' && !input.pattern.test(input.value)) {
            
            input.error = true;
            input.errorText = `Please enter valid ${input.label}`;
            valid = false;
        }
    }
        if(input.value && input.compare){
            if (newData[input.compare].value>input.value ) {
            input.error = true;
            input.errorText = `Start date can not come after end date`;
            valid = false;
            }
        }
        return input;
    });
    data.valid = valid;
    return data;
};

export {
    json_to_params,
    app_url_generator,
    jsonToFormData,
    toQueryString,
    generateProperties,
    validation
}