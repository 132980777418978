import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import actions from '../modules/actions';
import { Toast } from '../../../shared';
import InterviewListMarkup from "../components/InterviewMarkup";
import InterviewButtonFormatter from "../../../shared/table/InterviewButtonFormatter";
import CourseTextFormatter from "../../../shared/table/CourseTextFormatter";
import Denied from '../../../shared/denied/Denied'
class Interviews extends Component {
    constructor(props) {
        super(props);

        this.state = {
            disabled:false,
            pageIndex:1,
            viewAccess:false,
            userRoleAccess:[],
            columns: [
                {
                    //dataField: 'id',
                    text: 'S.NO.',
                    headerClasses: 'table-head',
                    editable:false
                },
                {
                    dataField: 'set_name',
                    text: 'INTERVIEW SET',
                    headerClasses: 'table-head',
                    editable:false,
                    formatter: CourseTextFormatter,
                    formatExtraData: {
                        onChange: this.onInputChange
                    },
                },
                {
                    dataField: 'id',
                    text: 'ACTIONS',
                    headerClasses: 'table-head',
                    formatter: InterviewButtonFormatter,
                    editable: false,
                    formatExtraData: {
                        userRoleAccess:[],
                        onView: this.onView,
                        onEdit: this.onEdit,
                        onClickDelete:this.onClickDelete,
                       
                    },
                }
            ],
        }
    }
    static getDerivedStateFromProps(props, state) {
		const{user}=props 
		const access = user.organization.access
		let finalData = access?JSON.parse(access):[{"operations": [1, 1, 1, 1], "name": "MAPPING"}, {"operations": [1, 1, 1, 1], "name": "QUESION"}, {"operations": [1, 1, 1, 1], "name": "INTERVIEW"}, {"operations": [1, 1, 1, 1], "name": "COURSE"}, {"operations": [1, 1, 1, 1], "name": "REPORT"}, {"operations": [1, 1, 1, 1], "name": "STUDENTS"}]

        console.log(">>fdsfsdfsdfsdfsdfsdf>>>>>>>",finalData);
        if(finalData){
            const {columns} =state;
            let data = columns[2]
			data.formatExtraData.userRoleAccess = finalData
            const col = columns.slice(0,2)
            col.push(data)
            let viewAccess = false
            if(finalData[2].operations.includes(1)){
                viewAccess=true
            }
			return{
                userRoleAccess:finalData,
                columns,
                viewAccess:viewAccess
			}
		}
    }

    onInputChange = (e) => {
        this.setState({
            [`${e.target.name}Val`]: e.target.value
        });
    }
    componentDidMount() {
        const {pageIndex} = this.state
        this.props.actions.getOrganizationInterviews(pageIndex);
    }

    onCreate = (id) => {
       // this.props.wizard ? this.props.setstep(2): this.props.history.push(`/interview/settings`);
        this.props.wizard ? this.props.gotoStep("Interview"): this.props.history.push(`/interview/settings`);

    }

    onView = (id) => {
        this.props.history.push(`/interview/settings/view/${id}`);
    }
    onEdit = (id) => {
        this.props.history.push(`/interview/settings/edit/${id}`);
    }
    onClickDelete = (id, index) =>{
        let { interviews :{interview}} = this.props;
		interview.splice(index, 1);
		this.setState({
			interview
        })
        const handleSuccess = () => {
            Toast.show({
                message: "Interview Delete Successfully",
                timeout: 4000,
                intent: 'success'
            })
            let pageIndex =1;
            this.props.actions.getOrganizationInterviews(pageIndex);
        };
        this.props.actions.deleteInterview(handleSuccess,id);
    }

    itemRender = (current, type, element) => {
        if (type === 'page') {

            return <a>{current}</a>;
        }
        return element;
    };

    onPageChange = (pageIndex) => {
        this.setState({
            pageIndex: pageIndex
        });
		this.props.actions.getOrganizationInterviews(pageIndex);
    }


    render() {
        const { interviews,wizard, interviewPagination : {count, pages}} = this.props
  
        const {viewAccess, columns, pageIndex, disabled,userRoleAccess } = this.state;
        console.log(userRoleAccess)
        return (
            viewAccess? <InterviewListMarkup
            userRoleAccess={userRoleAccess[2].operations}
            interviews={interviews}
            columns={columns}
            onCreate={this.onCreate}
            pageIndex={pageIndex}
            count={count}
            pages={pages}
            onPageChange={this.onPageChange}
            itemRender={this.itemRender}
            isLoading={this.props.isLoading}
            disabled={disabled}
            wizard ={ wizard }
        />:<Denied/>);
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.Auth.user,
        interviews: state.interview.interviewsAll,
        interviewPagination: state.interview.interviewPagination,
        isLoading: state.interview.isLoading

    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Interviews));