import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import actions from '../modules/actions';
import { Toast } from "../../../shared/toast/Toast";
import SettingsMarkup from '../components/SettingsMarkup';
import { bindActionCreators } from 'redux';
import Denied from '../../../shared/denied/Denied';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            mode: null,
            character:false ,
            count:0,
            pageIndex:1,
            search:'',
            viewAccess:false,
            itemAdd:'',
            userRoleAccess:[],
            itemRemove:'',
            inputs: {
                user_id: '',
                set_name: '',
                start_text: '',
                end_text: '',
                question_set: []
            },
            interview: {},
            questions: [
                {
                    id: 10,
                    question: 'Write a cool JS library',
                },
                {
                    id: 20,
                    question: 'Make it generic enough',
                },
                {
                    id: 30,
                    question: 'Write README',
                },
                {
                    id: 40,
                    question: 'Create some examples',
                },
                {
                    id: 50,
                    question:
                        'Spam in Twitter and IRC to promote it (note that this element is taller than the others)',
                },
                {
                    id: 60,
                    question: '???',
                },
                {
                    id: 7,
                    question: 'PROFIT',
                },
            ]
        }
    }
    
    componentDidMount() {
        const { location: { pathname } , interview: {questionList}} = this.props;
        const { pageIndex, search }= this.state
        const urlParts = pathname.split("/");
        this.setState({ mode: urlParts[3], id: urlParts[4] })
        if (urlParts[3] && urlParts[4]) {
            this.props.actions.getInterview(urlParts[4]);
        }
        this.props.actions.getAllOrganizationQuestions(pageIndex, search);

    }
    static getDerivedStateFromProps(props, state) {
        const{character,user}=props 
        const { interview: { interview } } = props;
        let questionListTest
        const { count } =state
        let viewAccess ;
        const access = user.organization.access
		let finalData = access?JSON.parse(access):[{"operations": [1, 1, 1, 1], "name": "MAPPING"}, {"operations": [1, 1, 1, 1], "name": "QUESION"}, {"operations": [1, 1, 1, 1], "name": "INTERVIEW"}, {"operations": [1, 1, 1, 1], "name": "COURSE"}, {"operations": [1, 1, 1, 1], "name": "REPORT"}, {"operations": [1, 1, 1, 1], "name": "STUDENTS"}]

            
            if(finalData){
                viewAccess =false
                if(finalData[2].operations.includes(1)){
                    viewAccess=true
                }
                // return{
                //     userRoleAccess:finalData,
                //     viewAccess
                // }
            }
        // if(count==0 && character){
        //     return{
        //         character,
        //         count:count+1
        //     }
        // }
        if(count==0 && character){
            character = character?character:null
            count = count+1
        }
        



        if (state.interview.id !== props.interview.interview.id) {
            const { mode, id } = state;
            if (mode && id) {
                const { interview: { interview } } = props;
                if (interview.id) {
                    const inputs = { ...interview };
                    inputs.question_set = JSON.parse(interview.question_set);
                    return {
                        inputs: inputs,
                        interview: { ...interview }
                    }
                }
            }
        }
      
        if (state.questions !== props.interview.questionList) { 
            questionListTest = props.interview.questionList.question
        }   
            return {
                questions: questionListTest,
                character,
                count,
                userRoleAccess:finalData,
                viewAccess,
        
            }
        
    }   
    onChange = (value, input) => {
        const { inputs } = this.state;
        inputs[input] = value;
        this.setState({ inputs });
    }
    onDrop = (selected, item) => {
        const { questions, inputs } = this.state;
        let index = questions.indexOf(item)
        questions.splice(index, 1);
        inputs.question_set = [...selected];
        this.setState({ inputs, questions});
    }

    onClickAdd = (item) => {
        const { questions, inputs } = this.state;
        let index = questions.indexOf(item)
        questions.splice(index, 1);
        inputs.question_set.push(item)
        this.setState({ inputs, questions, itemAdd:item});
    }
    onClickRemove = (item) =>{
        const { questions, inputs } = this.state;
        let index = inputs.question_set.indexOf(item)
        inputs.question_set.splice(index, 1);
        questions.push(item)
        this.setState({ inputs, questions,  itemRemove:item});
    }
    itemRender = (current, type, element) => {
        if (type === 'page') {
            return <a>{current}</a>;
        }
        return element;
    };

    onPageChange = (pageIndex) => {
        this.setState({
            pageIndex: pageIndex
        });
        this.props.actions.getAllOrganizationQuestions(pageIndex);
    }
    onSearch =() => {
        const { pageIndex, search } = this.state;
        this.props.actions.getAllOrganizationQuestions(pageIndex, search);
    }
   
    handleClick = () => {
        this.setState({
            isOpen: true,
        })

    };

    onChangeSearch = e => {
        this.setState({ search: e.target.value });
    };
    create = () => {
        const { user: { user, organization } } = this.props;
        if (user) {
            const { inputs } = this.state;
            if(!inputs.set_name || ! /^[a-zA-Z0-9]/.test(inputs.set_name)){
                Toast.show({
                    message: "Please Enter Interview Set Name. No special character allowed",
                    timeout: 3000,
                    intent: 'danger'
                })
                return;
            }
            else if(!inputs.start_text || ! /^[a-zA-Z0-9]/.test(inputs.start_text)){
                Toast.show({
                    message: "Please Enter Interview Start Guid Text. No special character allowed",
                    timeout: 3000,
                    intent: 'danger'
                })
                return;
            }
            else if(!inputs.end_text || ! /^[a-zA-Z0-9]/.test(inputs.end_text)){
                Toast.show({
                    message: "Please Enter Interview End Guid Text. No special character allowed",
                    timeout: 3000,
                    intent: 'danger'
                })
                return;
            }
            else if(!inputs.question_set[0]){
                Toast.show({
                    message: "Please Enter Question Set to Create Interview",
                    timeout: 3000,
                    intent: 'danger'
                })
                return;
            }
            const request = {
                user_id: user.id,
                organization_id: organization.organization_id,
                set_name: inputs.set_name,
                start_text: inputs.start_text,
                end_text: inputs.end_text,
                question_set: JSON.stringify(inputs.question_set)
            }
            const handleFailure = (res, body) => {
                this.setState({
                    set_name: '',
                    start_text: '',
                    end_text: '',
                    loginErrorMessage: Toast.show({
                        message: "Interview Creation Failed Please Try Again",
                        timeout: 4000,
                        intent: 'danger'
                    })
                })
            }
            const handleSuccess = () => {
                Toast.show({
                    message: "Interview Created Successfully !!!!",
                    timeout: 3000,
                    intent: 'success'
                })
               // {this.props.wizard ? this.props.setstep(5) :this.props.history.push('/interviews')}
                {this.props.wizard ? this.props.gotoStep("Interviews List") :this.props.history.push('/interviews')}
           
            };
            this.props.actions.createInterview(handleSuccess, handleFailure, request);
        }
    }
    update = () => {
        const { user: { user, organization } } = this.props;
        if (user) {
            const { inputs, id } = this.state;
            const request = {
                user_id: user.id,
                organization_id: organization.organization_id,
                id: inputs.id,
                set_name: inputs.set_name,
                start_text: inputs.start_text,
                end_text: inputs.end_text,
                question_set: JSON.stringify(inputs.question_set)
            }
            const handleFailure = (res, body) => {
                this.setState({
                    set_name: '',
                    start_text: '',
                    end_text: '',
                    loginErrorMessage: Toast.show({
                        message: "Update Interview Failed Please Try Again",
                        timeout: 4000,
                        intent: 'danger'
                    })
                })
            }
            const handleSuccess = () => {
                Toast.show({
                    message: "Interview Updated Successfully !!!!",
                    timeout: 3000,
                    intent: 'success'
                })

                this.props.history.push(`/interviews`);
            };
            this.props.actions.updateInterview(handleSuccess, handleFailure, request);
            this.props.actions.getInterview(id); 
        }
    }
    onEdit = (id) => {
        this.props.history.push(`/interview/settings/edit/${id}`);
    }
    hideItNow=()=>{
		this.setState({
			character:false
		})
    }  

    render() {
        const {viewAccess, questions, inputs, mode, character,itemAdd, pageIndex,itemRemove,userRoleAccess} = this.state;

        const { wizard ,pagination:{ questions_count , questions_pages }} =this.props    
        return (
            viewAccess?  <SettingsMarkup
                userRoleAccess={userRoleAccess[2].operations}
                questionAccess = {userRoleAccess[1].operations}
                questions={questions}
                selected={inputs.question_set}
                itemAdd={itemAdd}
                itemRemove={itemRemove}
                count={questions_count}
                pages={questions_pages}
                pageIndex={pageIndex}
                onChangeSearch={this.onChangeSearch}
                onPageChange={this.onPageChange}
                itemRender={this.itemRender}
                onClickRemove={this.onClickRemove}
                onSearch={this.onSearch}
                onDrop={this.onDrop}
                onClickAdd={this.onClickAdd}
                create={this.create}
                update={this.update}
                inputs={inputs}
                onChange={this.onChange}
                disabled={mode === 'view'}
                edit={mode === 'edit'}
                onEdit={this.onEdit}
                isLoading={this.props.isLoading}
                character={character}
                hideItNow={this.hideItNow}
                wizard ={ wizard }
                gotoStep ={this.props.gotoStep}
            />: <Denied/>
            
        )
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.Auth.user,
        interview: state.interview,
        pagination: state.interview.paginationQuestion,
        isLoading: state.interview.isLoading

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));
