import Constants from './Constants';

const INITIAL_STATE = {
    studentsList: [],
    student: {},
    studentAppicationIntake: [],
    report: {},
    isUploadFetching: false,
    isEvaluateFetching: false,
    error: false,
    saasData: null,
    saasResult: null,
    saasOffer: null,
    updateReport: {},
    isLoading: false,
    pagination: {},
    updateStudentReport: {},
    applicationData: [],
    applicationList: [],
    logsDetails: [],
    studentCourses: [],
    applicationStatus: [],
    documents: [],
    passport: [],
    lor: [],
    other: [],
    visa: [],
    studentZA: {},
    experienceData: [],
    experience: {},
    proficiency: {},
    educationData: {},
    pageNumber: 1,
    searchName: '',
    studentLead: '',
    searchAssigned: '',
    searchSupportStaff: '',
    searchMonth: '',
    searchYear: '',
    searchSource: '',
    exportStudent: {},
    zoomabroadCourses: [],
    zoomabroadUniversity: {},
    isAcademicLoading: false,
    interviewNew: {},
    deleteInterview: {},
    isAcademicLoading: false,
    balanceFee: [],
    lost: [],
    enrolled: [],
    assessment: [],
    unconditionalOffer: [],
    conditionalOffer: [],
    visaApplied: [],
    visaAppointment: [],
    visaGranted: [],
    refusalData: [],
    feeDeposit: [],
    invoice: [],
    casReceived: [],
    casApplied: [],
    searchStartDate: '',
    searchEndDate: '',
    searchUniversity: '',
    searchCourse: '',
    searchAdvancedFilter: false,
    searchApplicationStatus: '',
    courseData: '',
    appRejected: [],
    attendMeeting: '',
    checkList: {},
    isStudentLoading: false,
    getFeedbackForm: {},
    taskVisaAppointment: [],
    qualification: {},
    allDocuments: [],
    selectedCourses: [],
    taskAlocation: [],
    whatsAppTemplates: {}
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Constants.GET_ALL_STUDENTS_REQUEST:
            return {
                ...state,
                isStudentLoading: true
            };
        case Constants.GET_ALL_STUDENTS_SUCCESS:
            return {
                ...state,
                isStudentLoading: false,
                studentsList: action.payload,
                pagination: action.payload.pagination,
                applicationList: action.payload.application
            };
        case Constants.GET_ALL_STUDENTS_FAIL:
            return {
                ...state,
                isStudentLoading: false,
                error: action.error
            };
        case Constants.GET_ALL_APPLICATION_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_ALL_APPLICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                applicationData: action.payload,
                pagination: action.payload.pagination
            };
        case Constants.GET_ALL_APPLICATION_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.GET_STUDENT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_STUDENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                student: action.payload,

            };
        case Constants.GET_STUDENT_APPLICATION_INTAKE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_STUDENT_APPLICATION_INTAKE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                studentAppicationIntake: action.payload,
            };
        case Constants.GET_STUDENT_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.GET_COURSE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_COURSE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                courseData: action.payload
            };
        case Constants.GET_COURSE_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.GET_STUDENT_REPORT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_STUDENT_REPORT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                report: action.payload
            };
        case Constants.GET_STUDENT_REPORT_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case Constants.SAAS_UPLOAD_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.SAAS_UPLOAD_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                saasData: action.payload
            };
        case Constants.SAAS_UPLOAD_REQUEST_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.UPDATE_REPORT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.UPDATE_REPORT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                updateReport: action.payload
            };
        case Constants.UPDATE_REPORT_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.SAAS_INTERVIEW_LINK_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.SAAS_INTERVIEW_LINK_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                saasData: action.payload
            };
        case Constants.SAAS_INTERVIEW_LINK_REQUEST_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case Constants.SAAS_OFFER_LETTER_REQUEST:
            return {
                ...state,
                isSaasOfferLoading: true
            };
        case Constants.SAAS_OFFER_LETTER_REQUEST_SUCCESS:
            return {
                ...state,
                isSaasOfferLoading: false,
                saasOffer: action.payload
            };
        case Constants.SAAS_OFFER_LETTER_REQUEST_FAIL:
            return {
                ...state,
                isSaasOfferLoading: false,
                error: action.error
            }

        case Constants.UPDATE_STUDENT_REPORT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.UPDATE_STUDENT_REPORT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                updateStudentReport: action.payload
            };
        case Constants.GET_LOG_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: action.error
            };
        case Constants.UPDATE_STUDENT_REPORT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_LOG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                logsDetails: action.payload
            };
        case Constants.GET_LOG_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.UPDATE_LOGS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.UPDATE_LOGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.UPDATE_LOGS_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case Constants.GET_APPLIED_COURSES_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_APPLIED_COURSES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                studentCourses: action.payload
            };
        case Constants.GET_APPLIED_COURSES_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.GET_APPLICATION_STATUS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_APPLICATION_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                applicationStatus: action.payload
            };
        case Constants.GET_APPLICATION_STATUS_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case Constants.UPDATE_ASSIGNED_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.UPDATE_ASSIGNED_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.UPDATE_ASSIGNED_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case Constants.POST_ACADEMIC_REQUEST:
            return {
                ...state,
                isAcademicLoading: true
            };
        case Constants.POST_ACADEMIC_SUCCESS:
            return {
                ...state,
                isAcademicLoading: false,
            };
        case Constants.POST_ACADEMIC_FAIL:
            return {
                ...state,
                isAcademicLoading: false,
                error: action.error
            };
        case Constants.POST_USER_DOCUMENTS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.POST_USER_DOCUMENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.POST_USER_DOCUMENTS_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.GET_USER_DOCUMENTS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_USER_DOCUMENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                documents: action.payload,
            };
        case Constants.GET_USER_DOCUMENTS_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case Constants.GET_SOP_DOCUMENTS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_SOP_DOCUMENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                sop: action.payload,
            };
        case Constants.GET_SOP_DOCUMENTS_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case Constants.GET_PASSPORT_DOCUMENTS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_PASSPORT_DOCUMENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                passport: action.payload,
            };
        case Constants.GET_PASSPORT_DOCUMENTS_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };

        case Constants.GET_VISA_DOCUMENTS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_VISA_DOCUMENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                visa: action.payload,
            };
        case Constants.GET_VISA_DOCUMENTS_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case Constants.GET_OTHER_DOCUMENTS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_OTHER_DOCUMENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                other: action.payload,
            };
        case Constants.GET_OTHER_DOCUMENTS_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case Constants.GET_LOR_DOCUMENTS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_LOR_DOCUMENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                lor: action.payload,
            };
        case Constants.GET_LOR_DOCUMENTS_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case Constants.GET_ZA_STUDENT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_ZA_STUDENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                studentZA: action.payload,
            };
        case Constants.GET_ZA_STUDENT_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };

        case Constants.GET_FEEDBACK_FORM_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_FEEDBACK_FORM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                getFeedbackForm: action.payload,
            };
        case Constants.GET_FEEDBACK_FORM_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case Constants.POST_EXPERIENCE_DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.POST_EXPERIENCE_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                experienceData: action.error,
            };
        case Constants.POST_EXPERIENCE_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                experienceData: action.payload,
            };
        case Constants.GET_EDUCATIONAL_DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_EDUCATIONAL_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                educationData: {},
            };
        case Constants.GET_EDUCATIONAL_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                educationData: action.payload,
            };
        case Constants.GET_PROFICIENCY_DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_PROFICIENCY_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                proficiency: action.payload,
            };
        case Constants.GET_PROFICIENCY_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                proficiency: {},
            };
        case Constants.GET_EXPERIENCE_DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_EXPERIENCE_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                experience: action.payload,
            };
        case Constants.GET_EXPERIENCE_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                experience: {},
            };
        case Constants.POST_EDUCATIONAL_DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.POST_EDUCATIONAL_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                education: action.payload,
            };
        case Constants.POST_EDUCATIONAL_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case Constants.POST_PROFICIENCY_DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.POST_PROFICIENCY_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                proficiency: action.payload,
            };
        case Constants.POST_PROFICIENCY_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case Constants.DELETE_STUDENT_LOGS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.DELETE_STUDENT_LOGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.DELETE_STUDENT_LOGS_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case Constants.POST_LOG_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.POST_LOG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                proficiency: action.payload,
            };
        case Constants.POST_LOG_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case Constants.STUDENT_VIEW_BACK_BUTTON:
            return {
                ...state,
                pageNumber: action.payload,
            }
        case Constants.STUDENT_LEAD_BACK_BUTTON:
            return {
                ...state,
                studentLead: action.payload,
            }
        case Constants.STUDENT_SEARCH_BACK_BUTTON:
            return {
                ...state,
                searchName: action.payload,
            }
        case Constants.STUDENT_ASSIGNED_BACK_BUTTON:
            return {
                ...state,
                searchAssigned: action.payload,
            }

        case Constants.STUDENT_SUPPORT_STAFF_BACK_BUTTON:
            return {
                ...state,
                searchSupportStaff: action.payload,
            }
        case Constants.STUDENT_MONTH_BACK_BUTTON:
            return {
                ...state,
                searchMonth: action.payload,
            }
        case Constants.STUDENT_YEAR_BACK_BUTTON:
            return {
                ...state,
                searchYear: action.payload,
            }
        case Constants.STUDENT_SOURCE_BACK_BUTTON:
            return {
                ...state,
                searchSource: action.payload,
            }
        case Constants.STUDENT_START_DATE_BACK_BUTTON:
            return {
                ...state,
                searchStartDate: action.payload,
            }
        case Constants.STUDENT_END_DATE_BACK_BUTTON:
            return {
                ...state,
                searchEndDate: action.payload,
            }
        case Constants.STUDENT_UNIVERSITY_BACK_BUTTON:
            return {
                ...state,
                searchUniversity: action.payload,
            }
        case Constants.STUDENT_COURSE_BACK_BUTTON:
            return {
                ...state,
                searchCourse: action.payload,
            }
        case Constants.GET_EXPORT_STUDENTS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_EXPORT_STUDENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                exportStudent: action.payload,
            };
        case Constants.GET_EXPORT_STUDENTS_FAIL:
            return {
                ...state,
                isLoading: false,
                exportStudent: {},
            };
        case Constants.GET_ZOOMABROAD_COURSES_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_ZOOMABROAD_COURSES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                zoomabroadCourses: action.payload
            };
        case Constants.GET_ZOOMABROAD_COURSES_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.GET_ZOOMABROAD_UNIVERSITIES_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_ZOOMABROAD_UNIVERSITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                zoomabroadUniversity: action.payload
            };
        case Constants.GET_ZOOMABROAD_UNIVERSITIES_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.POST_INTERVIEW_NEW_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.POST_INTERVIEW_NEW_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                interviewNew: action.payload
            };
        case Constants.POST_INTERVIEW_NEW_REQUEST_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.GET_ALL_NEW_INTERVIEWS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_ALL_NEW_INTERVIEWS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                getAllNewInterviews: action.payload
            };
        case Constants.GET_ALL_NEW_INTERVIEWS_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case Constants.DELETE_INTERVIEW_REQ:
            return {
                ...state,
                isLoading: true
            };
        case Constants.DETELE_INTERVIEW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                deleteInterview: action.payload
            };
        case Constants.DELETE_INTERVIEW_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.GET_BALANCE_FEE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_BALANCE_FEE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                balanceFee: action.payload,
            };
        case Constants.GET_BALANCE_FEE_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };

        case Constants.GET_CONDITIONAL_OFFER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_CONDITIONAL_OFFER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                conditionalOffer: action.payload,
            };
        case Constants.GET_CONDITIONAL_OFFER_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };

        case Constants.GET_INVOICE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_INVOICE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                invoice: action.payload,
            };
        case Constants.GET_INVOICE_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };

        case Constants.GET_LOST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_LOST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                lost: action.payload,
            };
        case Constants.GET_LOST_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };

        case Constants.GET_APPLIED_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_APPLIED_SUCCESS:
            return {
                ...state,
                isLoading: false,
                visaApplied: action.payload,
            };
        case Constants.GET_APPLIED_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };

        case Constants.GET_APPOINTMENT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_APPOINTMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                visaAppointment: action.payload,
            };
        case Constants.GET_APPOINTMENT_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };

        case Constants.GET_GRANTED_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_GRANTED_SUCCESS:
            return {
                ...state,
                isLoading: false,
                visaGranted: action.payload,
            };
        case Constants.GET_GRANTED_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };

        case Constants.GET_UNCONDITIONAL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_UNCONDITIONAL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                unconditionalOffer: action.payload,
            };
        case Constants.GET_UNCONDITIONAL_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };

        case Constants.GET_REFUSAL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_REFUSAL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                refusalData: action.payload,
            };
        case Constants.GET_REFUSAL_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };

        case Constants.GET_ASSESSMENT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_ASSESSMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                assessment: action.payload,
            };
        case Constants.GET_ASSESSMENT_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case Constants.GET_ENROLLED_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_ENROLLED_SUCCESS:
            return {
                ...state,
                isLoading: false,
                enrolled: action.payload,
            };
        case Constants.GET_ENROLLED_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case Constants.GET_FEE_DEPOSIT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_FEE_DEPOSIT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                feeDeposit: action.payload,
            };
        case Constants.GET_FEE_DEPOSIT_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case Constants.GET_CAS_RECEIVED_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_CAS_RECEIVED_SUCCESS:
            return {
                ...state,
                isLoading: false,
                casReceived: action.payload,
            };
        case Constants.GET_CAS_RECEIVED_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case Constants.GET_CAS_APPLIED_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_CAS_APPLIED_SUCCESS:
            return {
                ...state,
                isLoading: false,
                casApplied: action.payload,
            };
        case Constants.GET_CAS_APPLIED_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case Constants.POST_BALANCE_FEE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.POST_BALANCE_FEE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.POST_BALANCE_FEE_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.STUDENT_ADVANCED_FILTER_BACK_BUTTON:
            return {
                ...state,
                searchAdvancedFilter: action.payload,
            }
        case Constants.STUDENT_APPLICATION_MODEL_OPEN:
            return {
                ...state,
                balanceFee: [],
                lost: [],
                enrolled: [],
                assessment: [],
                unconditionalOffer: [],
                conditionalOffer: [],
                visaApplied: [],
                visaAppointment: [],
                visaGranted: [],
                refusalData: [],
                feeDeposit: [],
                invoice: [],
                casReceived: [],
                casApplied: [],
                appRejected: []
            }
        case Constants.STUDENT_APPLICATION_STATUS_BACK_BUTTON:
            return {
                ...state,
                searchApplicationStatus: action.payload,
            }
        case Constants.GET_APP_REJECTED_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_APP_REJECTED_SUCCESS:
            return {
                ...state,
                isLoading: false,
                appRejected: action.payload,
            };
        case Constants.GET_APP_REJECTED_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case Constants.GET_MEETING_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_MEETING_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                attendMeeting: action.payload
            };
        case Constants.GET_MEETING_DETAILS_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.GET_CHECKLIST_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_CHECKLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                checkList: action.payload
            };
        case Constants.GET_CHECKLIST_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.GET_TASK_VISA_APPOINTMENT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_TASK_VISA_APPOINTMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                taskVisaAppointment: action.payload
            };
        case Constants.GET_TASK_VISA_APPOINTMENT_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.GET_STUDENT_QUALIFICATION_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_STUDENT_QUALIFICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                qualification: action.payload
            };
        case Constants.GET_STUDENT_QUALIFICATION_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.GET_STUDENT_ALL_DOCUMENTS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_STUDENT_ALL_DOCUMENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                allDocuments: action.payload
            };
        case Constants.GET_STUDENT_ALL_DOCUMENTS_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.GET_SELECTED_COURSES_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_SELECTED_COURSES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                selectedCourses: action.payload
            };
        case Constants.GET_SELECTED_COURSES_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.POST_TASK_ALLOCATION_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.POST_TASK_ALLOCATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                taskAlocation: action.payload
            };
        case Constants.POST_TASK_ALLOCATION_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        //
        case Constants.POST_ZA_TASK_ALLOCATION_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.POST__ZA_TASK_ALLOCATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.POST__ZA_TASK_ALLOCATION_FAIL:
            return {
                ...state,
                isLoading: false,
            };


        case Constants.POST_COUNSELLOR_REQUIRED_DOC_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.POST_COUNSELLOR_REQUIRED_DOC_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.POST_COUNSELLOR_REQUIRED_DOC_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.GET_WHATSAPP_TEMPLATES_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_WHATSAPP_TEMPLATES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                whatsAppTemplates: action.payload
            };
        case Constants.GET_WHATSAPP_TEMPLATES_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.POST_SENDEMAIL_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.POST_SENDEMAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.POST_SENDEMAIL_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        

        default:
            return state;


    }
};

