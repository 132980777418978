import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { FormGroup, InputGroup, Button } from "@blueprintjs/core";
import "./ActivityMarkup.scss"
const ActivityMarkup = (props) => {
    const { inputs, disabled, edit, onEdit,update,onChange,onRoute } = props;
    return (    
        <div className="activity-container"> 
        <Container>
            <div className="top-container">
            <Row>
                <Col>
                    Username
                </Col>
                <Col style={{textAlign:'right'}}>
                    <Button onClick={()=> onRoute.push('/activity/event')}>New event</Button>
                </Col>
            </Row>
            </div>
            <Row className="card-container">
                <Col md="4">
                    <div className="event-cards"  onClick={()=> onRoute.push('/activity/event')}>
                    <h6>Event name</h6>
                    <p>30 min , one on one</p>
                    <span>view booking page</span>
                    <hr/>
                    <div>
                        <span>Copy link</span>
                        <Button className="card-button">Turn on</Button>
                    </div>
                    </div>
                </Col>
              
            </Row>
        </Container>
        </div>
    )
}
export default ActivityMarkup;