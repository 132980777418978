import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import StudentInterviewMarkup from '../components/StudentInterview'
import actions from '../../Interview/modules/actions'
class StudentInterview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            questions:[]
        }
       
    }

    render(){
        
        return(
            <StudentInterviewMarkup
            
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.Auth.user,
        studentInterview: state.interview.studentInterview,
        isLoading: state.interview.isLoading
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentInterview));