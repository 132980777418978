import React, { Component } from "react";
import { Callout, Intent, Code, Tag } from "@blueprintjs/core";
import { Row, Col, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileViewer from 'react-file-viewer';
import { Button, MenuItem, Label } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import moment from 'moment';
import { Select } from "@blueprintjs/select";
import './Activity.scss'
const Activity = (props) => {
    const { logsDetail, onChangetext, status, index, myState, openStudentEdit, onNote, onTask, onDeleteStudentLogs, onWhatsappEdit } = props
    if (logsDetail.status) {
        if (myState[`status${logsDetail.id}`] == undefined) {
            myState[`status${logsDetail.id}`] = logsDetail.status
        }
    }
    const capitalizeFirstLetter = (string) => {
        if (logsDetail.note_status && string) {
            var str
            str = string[0].toUpperCase() + string.slice(1);
            return str.replace(/_/g, " ");
        }
    }
    const iconDisplay = () => {
        return <FontAwesomeIcon className='whatsapp' icon={["fab", "whatsapp"]} />
    }
    const isValidHttpUrl = (string) => {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }
    const onError = () => { console.log("error") }

    return (
        <Row className="activity-view">
            <Col>
                <Callout title={logsDetail.subject} className={logsDetail.type == 'task' ? `tab-activity-${logsDetail.status}` : `tab-activity-${logsDetail.type}`} icon={logsDetail.type == 'email' ? 'envelope' : logsDetail.type == 'note' ? 'annotation' : logsDetail.type == 'whats_app' ? iconDisplay() : 'timeline-events'}>
                    <Row className="edit-delete-row">
                        {logsDetail.type == 'task' || logsDetail.type == 'note' || logsDetail.type == 'whats_app' ? <>
                            {logsDetail.type == 'note' || logsDetail.type == 'whats_app' ? <>
                                <Col md="6" className={logsDetail.type == 'note' ? "note-status" : "whatsapp-status"}>
                                    <p>{capitalizeFirstLetter(logsDetail.note_status) ? capitalizeFirstLetter(logsDetail.note_status) : 'NA'}</p>
                                </Col>
                            </> : ""}
                            <Col md="1" className="edit-col">
                                <FontAwesomeIcon onClick={() => logsDetail.type == 'note' ? onNote(logsDetail, 'edit') : logsDetail.type == 'task' ? onTask(logsDetail, 'edit') : onWhatsappEdit(logsDetail, 'edit')} className="cross-icon edit-update" icon={['fas', 'edit']} />
                            </Col>
                            <Col md="1" className="delete-col">
                                <FontAwesomeIcon onClick={() => onDeleteStudentLogs(logsDetail.id)} className="cross-icon trash-delete" icon={['fas', 'trash']} />
                            </Col>
                        </> : ""}
                    </Row>
                    {/* // <script crossorigin src="...">      </script> */}

                    {isValidHttpUrl(logsDetail.note) ?
                        <>
                        Download :
                        <a href={`${logsDetail.note}`}>
                            <span>
                                <OverlayTrigger
                                    placement={'top'}
                                    delay={{ hide: 200 }}
                                    overlay={<Popover className="tool-tip" id="tooltip-disabled">Download</Popover>}>
                                    <i class="fa fa-cloud-download" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </span>
                        </a>
                        </>
                        : <div className="activity-info" dangerouslySetInnerHTML={{ __html: logsDetail.note }} />
                    }

                    <div className="field-wrapper">
                        {logsDetail.type == 'task' ?
                            <Row>
                                <Col>
                                    <Form.Group >
                                        {/* <Form.Label>Status</Form.Label> */}
                                        <Form.Control
                                            className="select-options"
                                            name={`status${logsDetail.id}`}
                                            as="select"
                                            placeholder="Status"
                                            value={myState && myState[`status${logsDetail.id}`] ? myState[`status${logsDetail.id}`] : null}
                                            onChange={(event) => onChangetext(event.target.name, event, logsDetail.id, logsDetail.id)} >
                                            {/* <option value="">Select...</option> */}
                                            <option value="inprogress">Inprogress</option>
                                            <option value="pending">Pending</option>
                                            <option value="completed">Completed</option>

                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col>
                                </Col>
                                <Col>
                                </Col>
                            </Row>

                            : ''}
                    </div>
                    <Row>
                        <Col>
                            {logsDetail.course_name ? <Code className="activity-course-name">{logsDetail.course_name} / {logsDetail.university_name} / {logsDetail.application_intake_month}-{logsDetail.application_intake_year}</Code> : <Code className="activity-course-name">General</Code>}
                        </Col>
                    </Row>

                    <Row>
                        <Col className="activity-creator">
                            {logsDetail.type == 'notereply' ? <Code className="activity-create-name">Student Reply:{logsDetail.first_name} {logsDetail.last_name}</Code> : <Code className="activity-create-name">{logsDetail.first_name} {logsDetail.last_name}</Code>}
                        </Col>
                        {logsDetail.type == 'task' ?
                            <Col className="date-task">
                                {logsDetail.due_date ? <Tag className="date-task-tag">Due At : {moment(logsDetail.due_date).format('lll')}</Tag> : ''}
                            </Col>
                            : ''}
                        <Col className="date-task">
                            {logsDetail.created_at ? <Tag className="date-task-tag">Created At : {moment(logsDetail.created_at).format('lll')}</Tag> : ''}
                        </Col>


                        {/* { logsDetail.type == 'task' || logsDetail.type == 'note' ?<>
                        <Col md="1">
                            <FontAwesomeIcon onClick={() =>  logsDetail.type == 'note' ? onNote(logsDetail ,'edit') : onTask(logsDetail ,'edit')  } className="cross-icon edit-update" icon={['fas', 'edit']} />
                        </Col>
                        <Col md="1">
                        <FontAwesomeIcon onClick={() =>  onDeleteStudentLogs(logsDetail.id)  } className="cross-icon trash-delete" icon={['fas', 'trash']} />
                        </Col>
                            </>:""} */}
                    </Row>

                </Callout>
            </Col>
        </Row>
    )

}
export default Activity;
