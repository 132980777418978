import {
  
    GET_CALL_LOGS_FAIL,
    GET_CALL_LOGS_REQUEST,
    GET_CALL_LOGS_SUCCESS,
    GET_COUNSELOR_LOGS_FAIL,
    GET_COUNSELOR_LOGS_REQUEST,
    GET_COUNSELOR_LOGS_SUCCESS,
    GET_ASSINGED_LOGS_FAIL,
    GET_ASSINGED_LOGS_REQUEST,
    GET_ASSINGED_LOGS_SUCCESS,
    GET_APA_LOGS_FAIL,
    GET_APA_LOGS_REQUEST,
    GET_APA_LOGS_SUCCESS
} from './type';

const INITIAL_STATE = {
    isLoading: false,
    error: '',
    contactList: [],
    deals: [],
    leads:{},
    callLogs:{},
    counselorLogs:{},
    assignedLog:{},
    APALogs:{}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      
        case GET_CALL_LOGS_FAIL:
            return { ...state, isLoading: false, error: action.payload, leads: {} };
        case GET_CALL_LOGS_REQUEST:
            return { ...state, isLoading: true };
        case GET_CALL_LOGS_SUCCESS:
            return { ...state, isLoading: false, error: '',callLogs:action.payload };
        case GET_COUNSELOR_LOGS_FAIL:
            return { ...state, isLoading: false, error: action.payload, leads: {} };
        case GET_COUNSELOR_LOGS_REQUEST:
            return { ...state, isLoading: true };
        case GET_COUNSELOR_LOGS_SUCCESS:
            return { ...state, isLoading: false, error: '',counselorLogs:action.payload };

        case GET_ASSINGED_LOGS_FAIL:
            return { ...state, isLoading: false, error: action.payload, leads: {} };
        case GET_ASSINGED_LOGS_REQUEST:
            return { ...state, isLoading: true };
        case GET_ASSINGED_LOGS_SUCCESS:
            return { ...state, isLoading: false, error: '',assignedLog:action.payload };
        case GET_APA_LOGS_FAIL:
            return { ...state, isLoading: false, error: action.payload, leads: {} };
        case GET_APA_LOGS_REQUEST:
            return { ...state, isLoading: true };
        case GET_APA_LOGS_SUCCESS:
            return { ...state, isLoading: false, error: '',APALogs:action.payload };
        default:
            return state;
    }
};