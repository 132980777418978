import React, { Component } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import Table from '../../../shared/table/Table';
import Paginator from '../../../shared/pagination/pagination'
import './InterviewMarkup.scss';
import { ZLoader } from "../../../shared/loader/ZLoader";

const InterviewMarkup = (props) => {
   
    

    const userAccess =(userRoleAccess,act)=>{
        if((userRoleAccess[1]==1) && act=="create"){
            return true
        }
        if((userRoleAccess[3]==1 ) && act=="delete"){
            return true

        }
        if(userRoleAccess[2]==1 && act=="edit"){
            return true
        }
            
    }
    const { userRoleAccess,isLoading, interviews, columns, onCreate,wizard, count, pages, onPageChange, itemRender, pageIndex, disabled } = props;
    return (
        <div class="container-fluid">
            {isLoading? <ZLoader/>:null}
            <Row className="interviews-page" md="12" lg="12" sm="12" xs="12">
                <Col className="title" md="5" lg="5" sm="5" xs="5">
                    <span>Interviews List</span>
                    </Col>
                    <Col className="count-title" md="6" lg="6" sm="6" xs="6">
                    <p>Showing 0 out of 0 entries</p>
                    </Col>
                    {/* <Col className="title" md="1" lg="1" sm="1" xs="1"></Col> */}
                    <Col className="title interviewtext" md="1" lg="1" sm="1" xs="1">    
                   {userAccess(userRoleAccess,"create")? <Button
                      
                        className="pull-right"
                        onClick={onCreate}
                    >Create
                    </Button>:''}
                    
                </Col>
                <Col md="12" lg="12" sm="12" xs="12" >
                    
                    <Table striped
                        keyField='id'
                        data={interviews.interview}
                        columns={columns}
                    />
                    </Col>
            </Row>  
            <Paginator
             count={count}
             pages={pages}
             onPageChange={onPageChange}
             itemRender={itemRender}
             pageIndex={pageIndex}
            />
        </div>
    )
}
export default InterviewMarkup;