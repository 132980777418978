import sopPdf from "../../../assets/SOP.pdf";
import interview from "../../../assets/INTERVIEW.pdf";
import letter from "../../../assets/LETTER.pdf";
import HS from "../../../assets/HS.pdf";
import BE from "../../../assets/BACHELOR.pdf";
import experience from "../../../assets/experience.pdf";



const data = [{
    "user": "Jayati Ghosh",
    "email": "jayatighosh@gmail.com",
    "contact": "987654321",
    "address": "some random address, City, State, Country",
    "Course": "Civil Engineering",
    "University": "Oxford University",

    "overall_score": {
        "totalScore": "1000",
        "marksObtain": "952"
    },
    "overallComparison": {
        "totalScorePercentage": "100",
        "marksObtainPercentage": "62"
    },
    "overall_academic_score": "500",
    "overall_academic_score_obtain": "499",
    "academic": [{
        "qualification": "Higher Secondry",
        "candidateScore": "88",
        "requiredScore": "65",
        "document": HS,
        "varified": "yes"

    },
    {
        "qualification": "Bachelors Degree",
        "candidateScore": "78",
        "requiredScore": "60",
        "document": BE,
        "varified": "no"

    },
    ],
    "overall_experience": "100",
    "overall_experience_marks": "99",
    "experience": [{
        "qualification": "ABCD",
        "candidateScore": "1 year",
        "requiredScore": "3 year",
        "document": experience,
        "varified": "no"


    }],
    "keyHighlight": [
        "someHighlight-1",
        "someHighlight-2"
    ],
    "overall_sop": "150",
    "overall_sop_marks": "149",
    "SOP": {
        "document": sopPdf,
        "varified": "no"
    },
    "interview_overall": "150",
    "interview_overall_marks": "149",
    "interview": {
        "document": interview,
        "varified": "no"
    },
    "recommendation_overall": "150",
    "recommendation_overall_marks": "149",
    "recommendationLetter": {
        "document": letter,
        "varified": "yes"
    },
    "financialCapacity": "yes",
    "gap": "yes",
    "regionalCap": "yes"
},
{
    "user": "SomeName1",
    "email": "someName1@gmail.com",
    "contact": "987654321",
    "address": "some random address, City, State, Country",
    "Course": "Electrical Engineering",
    "University": "ARU University",

    "overall_score": {
        "totalScore": "1000",
        "marksObtain": "855"
    },
    "overallComparison": {
        "totalScorePercentage": "100",
        "marksObtainPercentage": "62"
    },
    "overall_academic_score": "500",
    "overall_academic_score_obtain": "499",
    "academic": [{
        "qualification": "Higher Secondry",
        "candidateScore": "88",
        "requiredScore": "65",
        "document": HS,
        "varified": "yes"

    },
    {
        "qualification": "Bachelors Degree",
        "candidateScore": "78",
        "requiredScore": "60",
        "document": BE,
        "varified": "no"

    },


    ],
    "overall_experience": "100",
    "overall_experience_marks": "99",
    "experience": [{
        "qualification": "ABCD",
        "candidateScore": "1 year",
        "requiredScore": "3 year",
        "document": experience,
        "varified": "no"


    }],
    "keyHighlight": [
        "someHighlight-1",
        "someHighlight-2"
    ],
    "overall_sop": "150",
    "overall_sop_marks": "149",
    "SOP": {
        "document": sopPdf,
        "varified": "no"
    },
    "interview_overall": "150",
    "interview_overall_marks": "149",
    "interview": {
        "document": interview,
        "varified": "no"
    },
    "recommendation_overall": "150",
    "recommendation_overall_marks": "149",
    "recommendationLetter": {
        "document": letter,
        "varified": "yes"
    },
    "financialCapacity": "yes",
    "gap": "yes",
    "regionalCap": "yes"




},
{
    "user": "SomeName-2",
    "email": "SomeName2@gmail.com",
    "contact": "966654321",
    "address": "some random address, City, State, Country",
    "Course": "Computer Engineering",
    "University": "Oxford University",

    "overall_score": {
        "totalScore": "1000",
        "marksObtain": "795"
    },
    "overallComparison": {
        "totalScorePercentage": "100",
        "marksObtainPercentage": "62"
    },
    "overall_academic_score": "500",
    "overall_academic_score_obtain": "499",
    "academic": [{
        "qualification": "Higher Secondry",
        "candidateScore": "88",
        "requiredScore": "65",
        "document": HS,
        "varified": "yes"

    },
    {
        "qualification": "Bachelors Degree",
        "candidateScore": "78",
        "requiredScore": "60",
        "document": BE,
        "varified": "no"

    },


    ],
    "overall_experience": "100",
    "overall_experience_marks": "99",
    "experience": [{
        "qualification": "ABCD",
        "candidateScore": "1 year",
        "requiredScore": "3 year",
        "document": experience,
        "varified": "no"


    }],
    "keyHighlight": [
        "someHighlight-1",
        "someHighlight-2"
    ],
    "overall_sop": "150",
    "overall_sop_marks": "149",
    "SOP": {
        "document": sopPdf,
        "varified": "no"
    },
    "interview_overall": "150",
    "interview_overall_marks": "149",
    "interview": {
        "document": interview,
        "varified": "no"
    },
    "recommendation_overall": "150",
    "recommendation_overall_marks": "149",
    "recommendationLetter": {
        "document": letter,
        "varified": "yes"
    },
    "financialCapacity": "yes",
    "gap": "yes",
    "regionalCap": "yes"




}]
export default data