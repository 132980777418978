import React, { Component } from "react";
import Input from "../../shared/input/Input";
import "./CourseTextFormatter.scss"

const CourseTextFormatter = (cell, row, index, { onChange,onClick }) =>{
    return ( 
        <div className="text-formatter">
                <Input className="register-input"
                    id="text-input"
                    onChange={onChange}
                    value={cell}
                    onClick={()=> onClick(row,index)}
                />
        </div>
    );
}

export default CourseTextFormatter