import React, { Component } from 'react';
import { DateInput } from "@blueprintjs/datetime";
import { Row, Col, Button, Form } from 'react-bootstrap';
import {
    InputGroup,
    Label,
    MenuItem,
    TextArea,
    Position,
    Checkbox,
} from "@blueprintjs/core";
import moment from 'moment';
import ReactExport from "react-data-export";
import "./StudentExport.scss"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Download extends Component {
    constructor(props) {
        super(props);
        this.state = {
            create: true,
        }
    }
    render() {
        const { exportStartDate, exportEndDate, onChangeInput, exportExel, downloadStudentData, exportStudent,onCloseModal } = this.props
        let student = exportStudent.students
        return (
            <div className='export-page'>
                {downloadStudentData ?
                    <div className='export-view'>
                        <Label className="due-date" htmlFor="input-b">Start Date
                        <DateInput
                                formatDate={date => moment(date).format("DD/MM/YYYY")}
                                onChange={(date) => onChangeInput("exportStartDate", date)}
                                parseDate={str => new Date(str)}
                                placeholder={"DD/MM/YYYY"}
                                defaultValue={new Date()}
                                maxDate={new Date('1/1/2030')}
                                value={exportStartDate}
                            />
                        </Label>  
                        <Label className="due-date" htmlFor="input-b">End Date 
                        <DateInput
                                formatDate={date => moment(date).format("DD/MM/YYYY")}
                                onChange={(date) => onChangeInput("exportEndDate", date)}
                                parseDate={str => new Date(str)}
                                placeholder={"DD/MM/YYYY"}
                                defaultValue={new Date()}
                                maxDate={new Date('1/1/2030')}
                                value={exportEndDate}
                            /> 
                        </Label> 
                        <Row className='export-create'> 
                            <Button onClick={ ()=>exportExel()}>create</Button>
                        </Row>
                        <p> You can only download to a maximum 30 students from start date to end date.</p>
                 </div>
                    :
                    <Row className='download-file'>
                    <ExcelFile element={<Button onClick={() =>onCloseModal()}>Download file</Button>}>
                        <ExcelSheet data={student} name="students">
                            <ExcelColumn label="First Name" value='first_name' />
                            <ExcelColumn label="Last Name" value='last_name' />
                            <ExcelColumn label="Email" value='email' />
                            <ExcelColumn label="Phone Number" value='phone_number' />
                            <ExcelColumn label="Intake Month" value='intake_month'/>
                            <ExcelColumn label="Intake Year" value='intake_year'/>
                            {/* <ExcelColumn label="Marital Status"
                                value={(col) => col.is_married ? "Married" : "Single"} /> */}
                        </ExcelSheet>
                        {/* <ExcelSheet data={dataSet2} name="Leaves">
                            <ExcelColumn label="Name" value="name" />
                            <ExcelColumn label="Total Leaves" value="total" />
                            <ExcelColumn label="Remaining Leaves" value="remaining" />
                            "tempa-xlsx": "^0.8.20",
                        </ExcelSheet> */}
                    </ExcelFile>
                    </Row>
                }
            </div>
        );
    }
}

export default Download