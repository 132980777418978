import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Image, Accordion,Card } from 'react-bootstrap';
import './Education.scss';

const Experience = (props) => {
    const { student, showDocumentModal } = props;
    let language_efficiency = null;
    let type = 'IELTS';
    if(student.language_efficiency && student.language_efficiency.length) {
        language_efficiency = student.language_efficiency.map(acad => {
            return (<div className="academic">
                <div className="degree-type">{type}</div>
                <div className="degree-name">{acad.ielts_score}</div>
                <Row>
                {acad.lang_doc_url? <Col md="4" className="inside-container" onClick={()=> showDocumentModal(acad.lang_doc_url)}>
                 <span>Document</span>
          <FontAwesomeIcon className={"icon-inside"} icon={['fas', 'file-contract']} />

                 </Col>:null}
             </Row>
            </div>)
        })
    }
    return (
        // <Row className="education-section">
        //     <Col>
        //         <h3>Language</h3>
        //         {language_efficiency}
        //         <div></div>
        //     </Col>
        // </Row>
           <div className="education-section">
           <Accordion >
           <Card>
             <Accordion.Toggle as={Card.Header} eventKey="0">
             <h3>Language</h3>
          <FontAwesomeIcon className={"icon"} icon={['fas', 'plus']} />

             </Accordion.Toggle>
             <Accordion.Collapse eventKey="0">
               <Card.Body>
                {language_efficiency}
   
               </Card.Body>
             </Accordion.Collapse>
           </Card>
         </Accordion>
         </div>
    )
}
export default Experience;
