import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./CourseButtonFormatter.scss";
import { OverlayTrigger, Popover } from "react-bootstrap";

const CourseButtonFormatter = (cell, row, index, {userRoleAccess, onClickAdd, onClickCopy, onClickRemove, onClickClear }) => {
    const isFirstItem = index === 0;
    console.log(userRoleAccess,">>>>>>>>>>>>>>>>>>.%%%%%%%%%%%%%")
    const access = userRoleAccess[3].operations
    return (
        <div className="button-formatter">
           {access[1] ==1? <OverlayTrigger
                placement={'top'}
                delay={{ hide: 200 }}
                overlay={<Popover className="tool-tip" id="tooltip-disabled">Copy</Popover>}>
                <FontAwesomeIcon className="icon-btn" onClick={() => onClickCopy(index)} icon="clone" />
            </OverlayTrigger>:''}
            
                {isFirstItem && access[1] ==1?<OverlayTrigger
                    placement={'top'}
                    delay={{ hide: 200 }}
                    overlay={<Popover className="tool-tip" id="tooltip-disabled">Add</Popover>}>
                    <FontAwesomeIcon className="icon-btn add" onClick={() => onClickAdd(index)} icon="plus-square" />
                </OverlayTrigger>:''}
                
                
            
            {access[1]==1 && access[2]==1 ?<OverlayTrigger
                placement={'top'}
                delay={{ hide: 200 }}
                overlay={<Popover className="tool-tip" id="tooltip-disabled">Clear</Popover>}>
                <FontAwesomeIcon className="icon-btn edit" onClick={() => onClickClear(index)} icon="backspace" />
            </OverlayTrigger>:''}
            {isFirstItem==false && access[3]==1 ?<OverlayTrigger
                    placement={'top'}
                    delay={{ hide: 200 }}
                    overlay={<Popover className="tool-tip" id="tooltip-disabled">Delete</Popover>}>
                     <FontAwesomeIcon className="icon-btn delete" onClick={() => onClickRemove(index)} icon="trash-alt" />
                </OverlayTrigger>:''}  
        </div>
    );
}

export default CourseButtonFormatter
