
import Constants from './constants';

const INITIAL_STATE = {
    isLoading: false,
    Members: {},
    error: undefined,
    listError: undefined,
    allMembers: []
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Constants.GET_MEMBERS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_MEMBERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                Members: action.payload
            };
        case Constants.GET_MEMBERS_FAIL:
            return {
                ...state,
                isLoading: false,
                listError: action.error
            };
        case Constants.GET_ALL_MEMBERS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_ALL_MEMBERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                allMembers: action.payload
            };
        case Constants.GET_ALL_MEMBERS_FAIL:
            return {
                ...state,
                isLoading: false,
                listError: action.error
            };

        default:
            return state;
    }
};
