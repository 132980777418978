import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import QuestionMarkup from '../components/QuestionMarkup';
import { Toast } from "../../../shared/toast/Toast"
import actions from '../modules/actions';
import { bindActionCreators } from 'redux';
import _ from   'lodash';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userRoleAccess:[],
            inputs: {
                user_id: '',
                question: '',
                answer: null,
                answerValue:null,
                thinkingValue:null,
                thinking: null
            },
            inputquestion :{},
            question: {},
            selected: [],
            character:false ,
            count:0
        }
    }
    componentDidMount() {
        const { location: { pathname } } = this.props;
        const urlParts = pathname.split("/");
        this.setState({ mode: urlParts[3], id: urlParts[4] })
        if(urlParts[3] && urlParts[4]) {
            this.props.actions.getQuestion(urlParts[4]);
        }
    }
    static getDerivedStateFromProps(props, state) { 
        const{character,user}=props 
        const {count} =state
        let userRoleAccess = [];
        let charactervar = false;
             const userDataAccess =  user.organization.access
               const somedata = userDataAccess.replace(/'/g,'"')
               let finalData = userDataAccess?JSON.parse(somedata):[{"operations": [1, 1, 1, 1], "name": "MAPPING"}, {"operations": [1, 1, 1, 1], "name": "QUESION"}, {"operations": [1, 1, 1, 1], "name": "INTERVIEW"}, {"operations": [1, 1, 1, 1], "name": "COURSE"}, {"operations": [1, 1, 1, 1], "name": "REPORT"}, {"operations": [1, 1, 1, 1], "name": "STUDENTS"}]
                   if(finalData){
                        userRoleAccess=finalData
                }   
        if(count==0 && character){
                charactervar=character;
                count=count+1;
               
        }      
        if(state.question.id != props.question.id) {  
            const { mode, id} = state;          
            if (mode && id) {
                const { question} = props;
                if(question.id) {
                    const inputs = {...question,
                        answerValue: moment(question.answer,'HH:mm:ss').toDate(),
                        thinkingValue:moment(question.thinking,'HH:mm:ss').toDate()
                    }; 
                    const inputquestion  = {...question,
                        answerValue: moment(question.answer,'HH:mm:ss').toDate(),
                        thinkingValue:moment(question.thinking,'HH:mm:ss').toDate()
                    }; 
                                   
                    return {
                        inputs: inputs,
                        question,
                        inputquestion,
                        character:charactervar,
                        count,
                        userRoleAccess,
                    }
                }
                
            }
            
        }
        return {userRoleAccess}
    }
    
    onChange = (value, input, event, inputValue) => {
        const { inputs } = this.state;
        inputs[input] = value;
        if(event){
            inputs[inputValue] = event;
        }
        this.setState({ inputs });
    }
    create = () => {
        const { user: { user, organization } } = this.props;
        if (user) {
            const { inputs, user_id } = this.state;
            if(!inputs.question || ! /^[a-zA-Z0-9]/.test(inputs.question)){
                Toast.show({
                    message:"Please Enter Your Question (No special character's are allowed)",
                    timeout:3000,
                    intent:'danger'
                })
                return;
            }
            else if(!inputs.answer){
                Toast.show({
                    message:"Please Set the Answer Time",
                    timeout:3000,
                    intent:'danger'
                })
                return;
            }
            else if(!inputs.thinking){
                Toast.show({
                    message:"Please Set the Thinking Time",
                    timeout:3000,
                    intent:'danger'
                })
                return;
            }
            const request = {
                user_id: user.id,
                organization_id: organization.organization_id,
                question: inputs.question,
                answer: JSON.stringify(inputs.answer),
                thinking: JSON.stringify(inputs.thinking)
            }
            if (request.question) {
                const handleFailure = (res, body) => {
                    if (res.status === 401) {
                        this.setState({
                            question: '',
                            answer: '',
                            thinking: '',
                            loginErrorMessage: Toast.show({
                                message: "Please try again",
                                timeout: 5000,
                                intent: 'danger'
                            })
                        })
                    }
                }
                const handleSuccess = () => {
                    this.setState({
                        question: '',
                        answer: '',
                        thinking: ''})
                    Toast.show({
                        message: "Question Created Successfully !!!!",
                        timeout: 4000,
                        intent: 'success'
                    })
                    this.props.wizard ? this.props.gotoStep("Questions List") :  this.props.history.push('/questions')
               
                   //this.props.wizard ? this.props.setstep(4) :  this.props.history.push('/questions')
                };
                this.props.actions.createQuestion(handleSuccess, handleFailure, request)
            }
            else {
                Toast.show({
                    message: "Question Creation Failed Please Try Again",
                    timeout: 4000,
                    intent: 'danger'
                })
            }
        }
    }
    update = () => {
        const { user: { user, organization } } = this.props;
        if (user) {
            const { inputs,id } = this.state;
            const request = {
                user_id: user.id,
                organization_id: organization.organization_id,
                id:inputs.id,
                question: inputs.question,
                answer: JSON.stringify(inputs.answer),
                thinking: JSON.stringify(inputs.thinking)
            }
            const handleFailure = (res, body) => {
                this.setState({
                    question: '',
                    answer: null,
                    thinking: null,
                    loginErrorMessage: Toast.show({
                        message: "Error",
                        timeout: 5000,
                        intent: 'danger'
                    })
                })
            }
            const handleSuccess = () => {
                Toast.show({
                    message: "Question Updated Successfully !!!!",
                    timeout: 4000,
                    intent: 'success'
                })
                this.props.history.push('/questions');
                this.props.actions.getQuestion(request.id);
            };
            this.props.actions.updateQuestion(handleSuccess,handleFailure,request)
       }
    }
    onEdit=()=>{
        const { inputs } = this.state;
        this.props.history.push(`/questions/settings/edit/${inputs.id}`);
    }
    hideItNow=()=>{
		this.setState({
			character:false
		})
    }
    onBack =() => {
        window.history.back();
    }

    render() {
        const { inputs, mode,character,count,userRoleAccess ,question,inputquestion} = this.state;
        const { wizard } =this.props;
        console.log("this.props",this.props)
        return (
            <QuestionMarkup
                userRoleAccess={userRoleAccess.length ? userRoleAccess[1].operations : {} }
                create={this.create}
                inputs={inputs}
                question={question}
                onChange={this.onChange}
                disabled={mode === 'view'}
                edit={mode === 'edit'}
                update={this.update}
                onBack={this.onBack}
                onEdit={this.onEdit}
                isLoading={this.props.isLoading}
                character={character}
                hideItNow={this.hideItNow}
                wizard = { wizard }
                inputquestion ={ inputquestion }
                />
        )
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.Auth.user,
        questions: state.interview.questionList,
        question: state.interview.getQuestion,
        isLoading: state.interview.isLoading
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    actions: bindActionCreators(actions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Settings));
