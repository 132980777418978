import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Image, Accordion, Card } from 'react-bootstrap';
import { CLOUDFROND_ASSETS3_URL } from '../../../../env'
import './Documents.scss';

const Documents = (props) => {
  const { allDocuments, showDocumentModal } = props;
  let docData = null;
  if (allDocuments && allDocuments.length) {
    docData = allDocuments.map(acad => {
      return (<div className="academic">
        <Row>
          {acad.document_link && acad.document_link ?
           <>
            {
            acad.document_link && acad.document_link ? <Col md="6" className="inside-container" onClick={() => showDocumentModal(`${CLOUDFROND_ASSETS3_URL}/${acad.document_link}`)}>
              <span>{acad.document_name}</span>
              <FontAwesomeIcon className={"icon-inside"} icon={['fas', 'file-contract']} />
            </Col> : null}
            </> : null
            }
        </Row>
      </div>)
    })
  }
  return (
    <div className="education-section">
      <Accordion >
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            <h3>Documents</h3>
            <FontAwesomeIcon className={"icon"} icon={['fas', 'plus']} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {docData}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  )
}
export default Documents;
