import React from 'react';
import { Container } from 'react-bootstrap';
import './callLog.scss';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chart from 'react-apexcharts'
import { ZLoader } from '../../../shared/loader/ZLoader'
const CounslerWiseChart = (props) => {
     const {  isLoading, callLogSeries, callLogOptions,counselorWiseSeries, counselorWiseOptions } = props
     return (
          <div className="call-log-container">
                    {isLoading ? <ZLoader /> : null}
                    {counselorWiseSeries.length && counselorWiseSeries[0].data && counselorWiseSeries[0].data.length ?  <div id="chart">
                <Chart options={{
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        title: {
          text: 'Counselor lead status',
          align: 'left'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          categories: counselorWiseOptions,
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        fill: {
          opacity: 1
        }
      }} series={counselorWiseSeries} type="bar" height={350} />
                </div>
                :
                <>
                <p style={{fontWeight:'500'}}>Counselor lead status</p>
                <h4>No data available</h4>
                </>
              }                 
          </div>
     )
}

export default CounslerWiseChart;