import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { H5, Intent, Label, Slider, Spinner, Switch } from "@blueprintjs/core";
import "./ConnectMarkup.scss"

const ConnectMarkup = (props) => {
    return (
        <Container>
            <Row className="spinner">
            <Spinner
                    labelStepSize={50}
                    min={0}
                    showTrackFill={false}
                    stepSize={5} 
                    size={Spinner.SIZE_STANDARD}    
                />
            </Row>
        </Container>
    );
}

export default ConnectMarkup;

