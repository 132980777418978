import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Row, Col, Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { CLOUDFROND_ASSETS3_URL } from '../../../../../env'
import integrationAction from '../../../../Integration/modules/actions'
import action from '../../../modules/actions'
import { Toast } from "../../../../../shared/toast/Toast";
import './UnconditionalOffer.scss';


class UnconditionalOffer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visaLetterNumber: '',
      id: '',
      docFile: '',
      docUrl: '',
      errors: {}

    }
  }
  componentDidMount() {
    const { applicationStatusId } = this.props;
    if (applicationStatusId && applicationStatusId) {
      this.props.action.getAppUnConditionalOffer(applicationStatusId)
    }
  }
  static getDerivedStateFromProps(props, state) {
    console.log("props>>>>", props)
    if (props.unconditionalOffer && props.unconditionalOffer.length) {
      if (props.unconditionalOffer[0].id != state.id) {
        return {
          visaLetterNumber: props.unconditionalOffer[0].visa_letter_number,
          docUrl: props.unconditionalOffer[0].unconditional_ol_doc,
          id: props.unconditionalOffer[0].id
        };
      }
    }
    // else {
    //   return {
    //     visaLetterNumber: '',
    //     id: '',
    //     docFile: '',
    //     docUrl: '',
    //     errors: {}
    //   }
    // }
  }
  onDropFile = (event) => {
    this.setState({
      docFile: event.target.files[0]
    });
  }
  clearForm = () => {
    this.setState({
      visaLetterNumber: '',
      id: '',
      docFile: '',
      docUrl: '',
      errors: {}
    })
    this.props.setClearForm()
  }

  isvalid = () => {
    let errors = {};
    const { visaLetterNumber, docFile } = this.state;
    if (visaLetterNumber == '') {
      errors.visaLetterNumber = true;
      this.setState({ errors });
      return false;
    }
    if (docFile == '') {
      errors.docFile = true;
      this.setState({ errors });
      return false;
    }
    return true;
  };
  onSubmit = () => {
    const { visaLetterNumber, docFile, docUrl } = this.state;
    const { match: { params }, userProfile, onCloseModal, applicationStatusId } = this.props;
    if (this.isvalid()) {
      if (docFile) {

        const formData = new FormData()
        formData.append('doc_file', docFile);

        const handleSuccess = (response) => {
          const data = {
            'student_id': params.id,
            'user_id': userProfile.organization.user_id,
            'organization_id': userProfile.organization.organization_id,
            'visa_letter_number': visaLetterNumber,
            'unconditional_ol_doc': `${CLOUDFROND_ASSETS3_URL}/${response.data}`,
            'application_id': applicationStatusId
          }

          const handlesuccessfull = () => {
            Toast.show({
              message: 'Save successfully',
              timeout: 4000,
              intent: 'success'
            });
            onCloseModal()
            this.setState({
              visaLetterNumber: '',
              id: '',
              docFile: '',
              docUrl: ''
            })
          };
          const handleFailure = () => {
            Toast.show({
              message: 'Save unsuccessfully',
              timeout: 4000,
              intent: 'danger'
            });
            onCloseModal()
            this.setState({
              visaLetterNumber: '',
              id: '',
              docFile: '',
              docUrl: ''
            })
          }
          this.props.action.createApplicationStatus(data, handlesuccessfull, handleFailure, 'unconditional')

        }
        const handleDocFailure = () => { }

        this.props.integrationAction.documentUpload(handleSuccess, handleDocFailure, formData)

      } else {
        const data = {
          'student_id': params.id,
          'user_id': userProfile.organization.user_id,
          'organization_id': userProfile.organization.organization_id,
          'visa_letter_number': visaLetterNumber,
          'unconditional_ol_doc': docUrl,
          'application_id': applicationStatusId
        }
        const handlesuccessfull = () => {
          Toast.show({
            message: 'Save successfully',
            timeout: 4000,
            intent: 'success'
          });
          onCloseModal()
          this.setState({
            visaLetterNumber: '',
            id: '',
            docFile: '',
            docUrl: ''
          })
        };
        const handleFailure = () => {
          Toast.show({
            message: 'Save unsuccessfully',
            timeout: 4000,
            intent: 'danger'
          });
          onCloseModal()
          this.setState({
            visaLetterNumber: '',
            id: '',
            docFile: '',
            docUrl: ''
          })
        }
        this.props.action.createApplicationStatus(data, handlesuccessfull, handleFailure, 'unconditional')

      }
    }

  }
  onChangeInput = (name, value) => {
    this.setState({
      [name]: value, errors: {}
    })
  }
  userDocument = (doc_url) => {
    if (doc_url) {
      return (
        <a href={`${doc_url}`}>
          <span>
            <OverlayTrigger
              placement={'top'}
              delay={{ hide: 200 }}
              overlay={<Popover className="tool-tip" id="tooltip-disabled">Download</Popover>}>
              <i class="fa fa-cloud-download" aria-hidden="true"></i>
            </OverlayTrigger>
          </span>
        </a>

      );
    }
  }
  render() {
    const { visaLetterNumber, docUrl, errors } = this.state;
    const { clearModel } = this.props
    if (!clearModel) {
      this.clearForm()
    }
    return (
      <div className="unconditional-offer">
        <Col>
          <Form.Label className="title">Comment *</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            placeholder="unconditional offer reference number"
            className="form-control text-box"
            value={visaLetterNumber}
            onChange={(event) => this.onChangeInput('visaLetterNumber', event.target.value)}
          />
          {errors.visaLetterNumber ? <span className="errors">Please Add unconditional offer Number Here</span> : ''}
        </Col>
        <Col>
          {
            docUrl && docUrl ? <Col>
              {this.userDocument(docUrl)}
            </Col> : ''}
          <Col>
            <Form.File
              id="custom-file"
              label="Upload Document *"
              val
              accept=".png, .jpg, .jpeg, .pdf"
              name="docFile"
              onChange={this.onDropFile}
            />
          </Col>
          {errors.docFile ? <span className="errors">Please Upload Document File (".png, .jpg, .jpeg, .pdf")</span> : ''}
        </Col>
        <Col className="button-save">
          <Button className="save-offer" onClick={this.onSubmit}>Save</Button>
        </Col>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    isLoading: state.students.isLoading,
    userProfile: state.Auth.user,
    unconditionalOffer: state.students.unconditionalOffer
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(action, dispatch),
    integrationAction: bindActionCreators(integrationAction, dispatch),
  };
};
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconditionalOffer));
