
import React from 'react';
import { Container } from 'react-bootstrap';
import './Denied.scss';

const Denied = (props) => {
    return (
        <Container className="access-denied">
         <span className="text-admin">Please contact Admin to give access to this content</span>
            <div className="hover">
    <div className="background">
      <div className="door">Access denied</div>
      <div className="rug"></div>
    </div>
    <div className="foreground">
      <div className="bouncer">
        <div className="head">
          <div className="neck"></div>
          <div className="eye left"></div>
          <div className="eye right"></div>
          <div className="ear"></div>
        </div>
        <div className="body"></div>
        <div className="arm"></div>
      </div>
      <div className="poles">
        <div className="pole left"></div>
        <div className="pole right"></div>
        <div className="rope"></div>
      </div>
    </div>
  </div>
  
        </Container>
    )
}

export default Denied;
