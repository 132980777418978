import React from 'react';
import { Container } from 'react-bootstrap';
import './callLog.scss';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chart from 'react-apexcharts'
import { ZLoader } from '../../../shared/loader/ZLoader'
const Assigned = (props) => {
     const { isLoading, callLogSeries, callLogOptions, counselorWiseSeries, counselorWiseOptions, assignOptions, assignSeries } = props
     return (
          <div className="call-log-container">

               {isLoading ? <ZLoader /> : null}
               {assignSeries.length && assignOptions ?
                    <div id="chart">
                         <Chart options={{
                              chart: {
                                   width: 380,
                                   type: 'pie',
                              },
                              title: {
                                   text: '% of student assigned',
                                   align: 'left'
                              },
                              labels: assignOptions,
                              responsive: [{
                                   breakpoint: 480,
                                   options: {
                                        chart: {
                                             width: 200
                                        },
                                        legend: {
                                             position: 'bottom'
                                        }
                                   }
                              }]
                         }} series={assignSeries
                         } type="pie" height={300} />
                    </div>
                    :
                    <>
                    <p style={{fontWeight:'500'}}>% of student assigned</p>
                    <h4>No data available</h4>
                    </>
               }
          </div>
     )
}

export default Assigned;