import React from 'react';
import Login from './containers/Login';
import Register from './containers/Register';
import ForgetPassword from './containers/ForgetPassword';
import Authenticate from './containers/Authenticate';
import ResetPassword from './containers/ResetPassword';
import Member from './containers/MemberUpdate';
import NewLogin from './components/LoginMarkupNew'
import NewRegister from './components/RegisterMarkupNew'
import Guide from '../guide/Guide'
import Chat from '../chat/container/Guide'
export default [
    {
        path: '/',
        exact: true,
        component: () => <Login />
    },
    {
        path: '/login',
        exact: true,
        component: () => <Login />
    },
    {
        path: '/register',
        exact: true,
        component: () => <Register />
    },
    {
        path: '/forgetpassword',
        exact: true,
        component: () => <ForgetPassword />
    },
    {
        path: '/resetpassword',
        exact: true,
        component: () => <ResetPassword />
    }
    ,
    {
        path: '/user',
        exact: true,
        component: () => <Member />
    },
    {
        path: '/sso',
        exact: true,
        component: () => <Authenticate />
    },
    {
        path: '/chat',
        exact: true,
        component: () => <Chat />
    },
    {
        path: '/guide',
        exact: true,
        component: () => <Guide />
    }
];