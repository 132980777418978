import React from 'react';
import { Container, Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge'
import { Row, Col, Form, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ZLoader } from '../../../shared/loader/ZLoader'
import { DateRangeInput } from "@blueprintjs/datetime";
import moment from 'moment';
import './CustomerReportMarkup.scss';

const ReportMarkup = (props) => {
    const { isLoading, handleRangeChange, start_date, end_date, intakeYearSearch, intakeMonthSearch, selectedapplicationStatus, applicationStatus, applicationstatusvalue, getreport, customerReport, onChangeInput, onDeselect, SelectedLeadStatus, leadStatusValue, multipleYesChecked,
        multipleNoChecked, onCheckNo, onCheckYes } = props
    return (
        <div className="report-container">
            <Container>
                {isLoading ? <ZLoader /> : null}
                <Row>
                    {/* Lead status filter commented               */}
                    {<Col lg='3' sm='3' md='3' xs='3'>
                        <Form.Group >
                            <Form.Control
                                className="select-options"
                                name={"leadStatusValue"}
                                as="select"
                                placeholder="Status"
                                value={leadStatusValue ? leadStatusValue : null}
                                onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                            >
                                <option value="">Select lead status</option>
                                <option value="international">International</option>
                                <option value="subscriber">Subscriber</option>
                                <option value="ringing">Ringing</option>
                                <option value="others">Others</option>
                                <option value="lead">Lead</option>
                                <option value="hot_lead">Hot Lead</option>
                                <option value="customer">Customer</option>
                                <option value="beyond_intake">Beyond Intake</option>

                            </Form.Control>
                        </Form.Group>
                        {/* Lead Status: Customer */}
                    </Col>

                    }
                    <Col lg='3' sm='3' md='3' xs='3'>
                        <Form.Control
                            className="select-options"
                            name={"applicationstatusvalue"}
                            as="select"
                            placeholder="Status"
                            value={applicationstatusvalue ? applicationstatusvalue : null}
                            onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                        >
                            <option value="">Select Application status</option>
                            {
                                applicationStatus && applicationStatus.length ? applicationStatus.map((applications) => {
                                    return (
                                        <option value={applications.key}>{applications.key}</option>
                                    )
                                })
                                    : ''}
                        </Form.Control>
                    </Col>

                  
                    <Col  lg='2' sm='2' md='2' xs='2'>
                        <Form.Control
                            className="righ-side-filter"
                            name={"intakeMonthSearch"}
                            as="select"
                            placeholder="Status"
                            value={intakeMonthSearch ? intakeMonthSearch : ''}
                            onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                        >
                            <option value="">Intake Month</option>
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                        </Form.Control>
                    </Col>
                    <Col  lg='2' sm='2' md='2' xs='2'>
                        <Form.Control
                            className="righ-side-filter"
                            name={"intakeYearSearch"}
                            as="select"
                            placeholder="Status"
                            value={intakeYearSearch ? intakeYearSearch : ''}
                            onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                        >
                            <option value="">Intake Year</option>
                            <option value="2016">2016</option>
                            <option value="2017">2017</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                        </Form.Control>
                    </Col>
                    <Col>
                        <Row>
                        <Form.Check
                            checked={multipleYesChecked}
                            inline
                            name={'multiple'}
                            onChange={(e) => {
                                onCheckYes(
                                    {
                                        "target": { id: "radio1", value: true, name: "multiple" }
                                    })
                            }}
                            label="non-Unique"
                            type={'radio'}
                        />
                        </Row>
                        <Row>
                        <Form.Check
                            checked={multipleNoChecked}
                            inline
                            name={'multiple'}
                            onChange={(e) => {
                                onCheckNo({
                                    "target": { id: "radio1", value: false, name: "multiple" }
                                })
                            }}
                            label="Unique"
                            type={'radio'} />
                        </Row>
                    </Col>
                    <Col>
                        <Button onClick={getreport}>
                            Report
                        </Button>
                    </Col>
                </Row>
                <Row>
                
                    <Col>
                        <DateRangeInput
                            formatDate={date => moment(date).format('DD/MM/YYYY')}
                            onChange={(event) => handleRangeChange(event)}
                            parseDate={str => new Date(str)}
                            allowSingleDayRange={true}
                            className={'date-range-selector'}
                            // defaultValue={[moment().add(-1, "weeks").toDate(),moment().add(0, "days").toDate()]}
                            value={[start_date ? start_date : null, end_date ? end_date : null]}
                        // timePickerProps={{precision:'minute'}}

                        />
                    </Col>

                   
                </Row>
                <Row style={{ textAlign: 'center' }}>
                    <Col>
                        Total Count : {customerReport && customerReport.length ? customerReport.length : 0}
                    </Col>
                </Row>
                {/* Applied Lead status filter commented */}
                <Row className="customer-selected-status">
                    {SelectedLeadStatus && SelectedLeadStatus.length ? <div className='selected-status'><strong>Selected Lead Status :</strong></div> : null}
                    {SelectedLeadStatus && SelectedLeadStatus.length ? 
                        SelectedLeadStatus.map((item) => {
                        return <div className='selected-status'><span>{`${item.replaceAll("'", '')}`}&nbsp;&nbsp;<i onClick={() => onDeselect(item,'leadstatus')} className="fa fa-times-circle delete-icon" aria-hidden="true"></i></span></div>;
                        }) : null}
                        
                </Row>
                <Row className="customer-selected-status">
                    {selectedapplicationStatus && selectedapplicationStatus.length ? <div className='selected-status'><strong>Selected Status :</strong></div> : null}
                    {selectedapplicationStatus && selectedapplicationStatus.length ?
                        selectedapplicationStatus.map((item) => {
                            return <div className='selected-status'><span>{`${item.replaceAll("'", '')}`}&nbsp;&nbsp;<i onClick={() => onDeselect(item, 'applicationstatus')} className="fa fa-times-circle delete-icon" aria-hidden="true"></i></span></div>;
                        }) : null}   

                </Row>
                <Row lg='8' sm='8' md='8' className='customer-report'>
                    {customerReport && customerReport ?
                        <>
                            <Table striped bordered hover responsive="sm">
                                <tbody>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Phone Number</th>
                                        <th>Email</th>
                                        <th>Source Name</th>
                                        <th>Reference By</th>
                                        <th>Lead Status</th>
                                        <th>University Name</th>
                                        <th>Course Name</th>
                                        <th>Course Type</th>
                                        <th>Appliaction Status</th>
                                        <th>Intake Year</th>
                                        <th>Intake Month</th>
                                        <th>Channel Name</th>
                                        <th>Advisor Fname</th>
                                        <th>Advisor Lname</th>
                                        <th>Notes</th>
                                        <th>Application Date</th>
                                        <th>Student ID</th>
                                        <th>Unique/Non Unique</th>
                                    </tr>
                                    {
                                        customerReport.map((numList, i) => (
                                            <tr key={i}>
                                                {
                                                    numList.map((num, j) => {
                                                        if (j == 17) {
                                                            return <td key={j}>{num ? moment(num).format('MMM DD, YYYY') : ''}</td>
                                                        }
                                                        if (j == 19) {
                                                            return <td key={j}>{num == '1' ? "Unique" : "Non-unique"}</td>
                                                        }
                                                        else return <td key={j}>{num}</td>
                                                    }

                                                    )
                                                }
                                            </tr>

                                        ))
                                    }
                                </tbody>
                            </Table>
                        </>
                        : ''}
                </Row>
            </Container>
        </div>
    )
}

export default ReportMarkup;
