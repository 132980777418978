import { HUBURL, HUBSPOT_OAUTH_REQUEST } from '../../../env';
import { toQueryString, generateProperties } from '../../../utils';
import Constants from './constants';
import { APISAAS_DOMAIN } from '../../../env';

const getOauthToken = (app, code, handleSuccess, handleFailure) => {
    HUBSPOT_OAUTH_REQUEST.code = code;
    const url = `${HUBURL}oauth/v1/token${toQueryString(HUBSPOT_OAUTH_REQUEST)}`;
    return (dispatch) => {
        fetch(`${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                dispatch({
                    type: Constants.GET_HUBSPOT_TOKEN_SUCCESS,
                    payload: body
                });
                handleSuccess(body, app)
            })
            .catch((error) => {
                dispatch({
                    type: Constants.GET_HUBSPOT_TOKEN_ERROR,
                    payload: error
                });
                handleFailure(error);
            });
    }
}

export const getHubSpotContacts = (handleContactSuccess, handleContactFailure, data, properties) => {
    
    const property = generateProperties(properties);
    const url = `${HUBURL}contacts/v1/lists/all/contacts/all?count=100&${property}`;
    return (dispatch) => {
        fetch(`${url}`, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    handleContactFailure(response, body);
                } else {
                    handleContactSuccess(body);
                }
            })
            .catch((error) => {
                handleContactFailure(error, error.message);
            });
    }
}

export const getHubSpotContactsProperties = (handleSuccess, handleFailure, data) => {
    const url = `${HUBURL}properties/v1/contacts/properties`;
    return (dispatch) => {
        fetch(`${url}`, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_HUBSPOT_PROPERTIES_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.GET_HUBSPOT_PROPERTIES_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            })
            .catch((error) => {
                dispatch({
                    type: Constants.GET_HUBSPOT_PROPERTIES_ERROR,
                    payload: error
                });
                handleFailure(error, error.message);
            });
    }
}

export const createHubSpotContactsProperties = (handleSuccess, handleFailure, tokenData, data) => {
    const url = `${HUBURL}properties/v1/contacts/properties`;
    return (dispatch) => {
        fetch(`${url}`, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + tokenData.access_token
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.CREATE_HUBSPOT_PROPERTIES_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.CREATE_HUBSPOT_PROPERTIES_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            })
            .catch((error) => {
                dispatch({
                    type: Constants.CREATE_HUBSPOT_PROPERTIES_ERROR,
                    payload: error
                });
                handleFailure(error, error.message);
            });
    }
}

export const updateHubSpotContactsProperties = (handleSuccess, handleFailure, data, vid) => {
    const url = `${HUBURL}contacts/v1/contact/vid/${vid}/profile`;
    return (dispatch, getState) => {
        const { Auth: { user: { token, user, organization} } } = getState();
        console.log("organization>>>", organization);
        fetch(`${url}`, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + organization.app_access_token
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.UPDATE_HUBSPOT_CONTACT_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.UPDATE_HUBSPOT_CONTACT_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            })
            .catch((error) => {
                dispatch({
                    type: Constants.UPDATE_HUBSPOT_CONTACT_ERROR,
                    payload: error
                });
                handleFailure(error, error.message);
            });
    }
}


const createStudent = (data, handleCreateSuccess, handleFailure) => {
    
    return (dispatch) => {
        dispatch({
            type: Constants.AUTH_LOADING
        });
        fetch(`${APISAAS_DOMAIN}api/student/create/bulk/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.REGISTER_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.REGISTER_SUCCESS,
                        payload: body
                    });
                    handleCreateSuccess(body);
                }
            });
    }
}
const updateMapping = (handleSuccess,handleFailure,request, id) => {

    return (dispatch, getState) => {
      const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.UPDATE_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/organization/update/${id}`, {
            method: 'PUT',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.UPDATE_FAIL,
                        payload: body.errors,
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.UPDATE_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}


const getIntegrationMapping = (handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token, user, organization} } } = getState();
        dispatch({
            type: Constants.GET_MAPPING_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/organization/${organization.organization_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_MAPPING_FAIL,
                        payload: body.error
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.GET_MAPPING_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

export const createHubSpotContactNote = (handleSuccess, handleFailure, data) => {
    const url = `${HUBURL}engagements/v1/engagements`;
    return (dispatch, getState) => {
        const { Auth: { user: { organization} } } = getState();
        fetch(`${url}`, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + organization.app_access_token
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.CREATE_HUBSPOT_NOTE_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.CREATE_HUBSPOT_NOTE_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            })
            .catch((error) => {
                dispatch({
                    type: Constants.CREATE_HUBSPOT_NOTE_ERROR,
                    payload: error
                });
                handleFailure(error, error.message);
            });
    }
}


const documentUpload = (handleSuccess,handleFailure, formData) => {
    return function (dispatch) {
        dispatch({
            type: Constants.SAAS_DOCUMENT_UPLOAD_REQUEST
		});
            return fetch(`${APISAAS_DOMAIN}api/student/documentupload/`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json()
            .then(body => ({ response, body })))
            .then(({ response, body }) => {
				if (!response.ok) {
                    dispatch({
                        type: Constants.SAAS_DOCUMENT_UPLOAD_REQUEST_FAIL,
                        payload: body.error
                    });
                    handleFailure()
                } else {
                    dispatch({
                        type: Constants.SAAS_DOCUMENT_UPLOAD_REQUEST_SUCCESS,
						payload: body
                    });
                    handleSuccess(body)
                }
            });
    }
}
const createZilterFormStudent = (data, handleCreateSuccess, handleFailure) => {
    
    return (dispatch) => {
        dispatch({
            type: Constants.AUTH_LOADING
        });
        fetch(`${APISAAS_DOMAIN}api/student/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.REGISTER_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.REGISTER_SUCCESS,
                        payload: body
                    });
                    handleCreateSuccess(body);
                }
            });
    }
}
const createFormStudentNotification = (data, handleCreateSuccess, handleFailure) => {
    
    return (dispatch) => {
        dispatch({
            type: Constants.AUTH_LOADING
        });
        fetch(`${APISAAS_DOMAIN}api/student/apa/notification/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.REGISTER_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.REGISTER_SUCCESS,
                        payload: body
                    });
                    handleCreateSuccess(body);
                }
            });
    }
}


export default {
    getOauthToken,
    getHubSpotContacts,
    getHubSpotContactsProperties,
    createStudent,
    updateMapping,
    getIntegrationMapping,
    createHubSpotContactsProperties,
    updateHubSpotContactsProperties,
    createHubSpotContactNote,
    documentUpload,
    createZilterFormStudent,
    createFormStudentNotification
};