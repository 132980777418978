import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, OverlayTrigger,  } from 'react-bootstrap';
import './Header.scss'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import authActions from '../../screens/Auth/modules/actions';

import {
    Position,
    Popover,
} from "@blueprintjs/core";
import Notification from '../notofication/Notification'
class HeaderNew extends Component {

    onLogout=()=>{
        this.props.authActions.logOut();
        this.props.history.push('/')
    }
     UserProfile = (user) =>{
        return(
            <div className="profile-user">
                
                <div className={"notification-show"}>
                <h6 className="title-notification">{user?user.email:''}</h6>
                <p onClick={()=>this.props.history.push('/profile/view')}>Profile</p>
                <p onClick={this.onLogout}>Log Out</p>

            </div>
            </div>
        )
    }
render(){
    const {user:{user}} =this.props
    return (
        <Row className="header-new-container">
            <Col>
            </Col>
            <Col className="right-side">
            <Popover  content={this.UserProfile(user)} position={Position.BOTTOM}>

            <FontAwesomeIcon  className="user-icon" icon={['fas', 'user-circle']} ></FontAwesomeIcon>
</Popover>
            </Col>
        </Row>
    )
}
}

const mapStateToProps = (state) => {
    
    return {
        user: state.Auth.user,
    }
}
const mapDispatchToProps = (dispatch) => {
	return {
	    authActions: bindActionCreators(authActions, dispatch),
	};
};



export default withRouter(connect(mapStateToProps,mapDispatchToProps)(HeaderNew));