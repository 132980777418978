import React from "react";
import ZilterSteps from '../container/ZilterSteps'
import './Wizard.scss'
const Wizards = (props) => {   
 return (
    <div className="NewWizard-container">
         <div className="quote">
            <h3> You are few steps away from Zilter's Experience.
                </h3>
         </div>         
        <ZilterSteps className="wizard"/>
    </div>
    )
}
export default Wizards