import React, { Component } from "react";
import { Container } from 'react-bootstrap';
import './List.scss';
import Activity from './Activity';
import { ZLoader } from "../../../../shared/loader/ZLoader";
import Paginator from '../../../../shared/pagination/pagination'
const List = (props) => {
    const { myState, logsDetails, isLoading, count, pages, onPageChange, itemRender, pageIndex, status, 
          onChangetext, onNote, onTask, onDeleteStudentLogs, onWhatsappEdit} = props
    return (
        <Container className="list-view">
            {
              logsDetails.logs && logsDetails.logs.length ? logsDetails.logs.map((logsDetail, index) => {
                return (
            <Activity 
            logsDetail={logsDetail}
            onChangetext={onChangetext}
            status={status}
            index={index}
            myState={myState}
            onNote={onNote}
            onTask={onTask}
            onDeleteStudentLogs={onDeleteStudentLogs}
            onWhatsappEdit={onWhatsappEdit}

            />
            )
        })
      : ''}
        <Paginator
             count={count}
             pages={pages}
             onPageChange={onPageChange}
             itemRender={itemRender}
             pageIndex={pageIndex}
             />
        </Container> 
    )
}
export default List;
