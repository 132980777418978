import React, { Component } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import Table from '../../../shared/table/Table';
import Paginator from '../../../shared/pagination/pagination'
import './QuestionListMarkup.scss';
import { ZLoader } from "../../../shared/loader/ZLoader";

const QuestionListMarkup = (props) => {
    const {userRoleAccess,isLoading, questions, questions_count,columns, onCreate, question_pages,setstep,gotoStep ,wizard, pageIndex, itemRender, onPageChange, history} = props;
    return (
        <div class="container-fluid">
            {isLoading? <ZLoader/>:null}
            <div className="questions-page">
                <Row>
                    <Col md="4" lg="4" sm="4" xs="4" className="title question" >
                        <span>Questions List</span>
                    </Col>
                        <Col md="8" lg="8" sm="8" xs="8" className="title" >
                            {userRoleAccess[1]==1?<Button
                                    variant="danger"
                                    className="pull-right"
                                    onClick={onCreate}
                                >Create
                            </Button>:''}
                           {/*  <Button
                                className="pull-right back"
                                //onClick = {wizard ? ()=>setstep(1):props.history.push("/interview/settings")}
                                onClick = {wizard ? ()=>gotoStep("Question"):()=>props.history.push("/questions")}
                              >Back 
                            </Button> */}
                            </Col>

                            </Row>
                            <Col md="12" lg="12" sm="12" xs="12" >
                                
                                    {
                                    questions_count != 0 ?
                                    <Table striped
                                    keyField='id'
                                    data={questions ? questions.question : {}}
                                    columns={columns}
                                    />
                                    :
                                    <div style={{textAlign:'center'}}>
                                        No Questions to show
                                    </div>

                                    }
                            </Col>
                            
            </div>
            {
                questions_count > 10 ?
                <Paginator
                count={questions_count}
                pages={question_pages}
                onPageChange={onPageChange}
                itemRender={itemRender}
                pageIndex={pageIndex}
                />
                :null
            }
           
               
        </div>
    )
}
export default QuestionListMarkup;