import React from 'react';
import { Form } from 'react-bootstrap';
import { FormGroup, InputGroup, TextArea, Button, MenuItem, RadioGroup, Radio, Checkbox, Popover, Menu, Position } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { ZLoader } from '../../../shared/loader/ZLoader';

import './CreateUser.scss';

const CreateUserMarkup = (props) => {
    const { inputStyle, textAreaStyle, fileInputStyle } = style;
    return (
        <div style={{ background: '#f4f8fa' }}>
            <nav className="bp3-navbar bp3-dark">
                <div style={{ margin: '0 auto' }}>
                    <div className="bp3-navbar-group bp3-align-left">
                        <div className="bp3-navbar-heading">
                            <img
                                src={`http://admin.zoomabroad.com/zoomabroad.png`}
                                alt="Zoomabroad logo"
                                style={{
                                    width: '70%'
                                }}
                            />
                        </div>
                    </div>
                    <div className="bp3-navbar-group bp3-align-right">
                    </div>

                </div>
            </nav>
            {props.error ?
                < div >
                    <h5
                        style={{
                            color: 'red',
                            textAlign: 'center',
                            margin: 10
                        }}
                    >
                        {props.error}
                    </h5>
                </div>
                :
                null
            }
            {
                props.isLoading ?
                    <ZLoader />
                    :
                    <div
                        style={{
                            backgroundColor: '#fff',
                            margin: '0 auto',
                            marginTop: 30,
                            maxWidth: 800,
                            boxShadow: '0 4px 8px 0 rgba(53,105,128,0.3), 0 6px 20px 0 rgba(165,200,213,0.41)'
                        }}
                    >
                        <div
                            style={{
                                padding: 40
                            }}
                        >
                            <FormGroup
                                helperText={props.errors.firstName ? 'Firstname is required' : ''}
                                label="First name"
                                labelFor="text-input"
                                labelInfo="*"
                                className="warring"
                            >
                                <InputGroup
                                    id="text-input"
                                    intent={props.errors.firstName ? "Danger" : "None"}
                                    name={"firstName"}
                                    value={props.firstNameVal}
                                    onChange={props.onInputChange}
                                    style={inputStyle}
                                />
                            </FormGroup>
                            <FormGroup
                                helperText={props.errors.lastName ? "Lastname  is required" : ''}
                                label="Last name"
                                labelFor="text-input"
                                labelInfo="*"
                                className="warring"
                            >
                                <InputGroup
                                    id="text-input"
                                    intent={props.errors.lastName ? "Danger" : "None"}
                                    name={"lastName"}
                                    value={props.lastNameVal}
                                    onChange={props.onInputChange}
                                    style={inputStyle}
                                />
                            </FormGroup>
                            <div className='row'>
                                <div className="col-sm">
                                    <FormGroup
                                        helperText={props.errors.phone ? 'Phone number is required' : ''}
                                        label="Phone"
                                        labelFor="text-input"
                                        labelInfo="*"
                                        className="warring"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            intent={props.errors.phone ? "Danger" : "None"}
                                            name={"phone"}
                                            value={props.phoneVal}
                                            onChange={props.onInputChange}
                                            type="numeric"
                                            style={inputStyle}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-sm'>
                                    <FormGroup
                                        helperText={props.errors.email ? 'name@example.com' : ''}
                                        label="Email"
                                        labelFor="text-input"
                                        labelInfo="*"
                                        className="warring"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            intent={props.errors.email ? "Danger" : "None"}
                                            name={"email"}
                                            value={props.emailVal}
                                            onChange={props.onInputChange}
                                            style={inputStyle}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm">
                                    <FormGroup
                                        label="12th"
                                        labelFor="text-input"
                                        labelInfo="Overall marks in Percentages"
                                        className="lineBreak"
                                    >
                                        <TextArea
                                            id="text-input"
                                            placeholder="eg. 70%"
                                            name={"overallMarks"}
                                            value={props.overallMarksVal}
                                            onChange={props.onInputChange}
                                            growVertically={true}
                                            large={true}
                                            style={textAreaStyle}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-sm'>
                                    <FormGroup
                                        label="12th- HSC mark sheet"
                                        labelFor="text-input"
                                    >
                                        {props.vid ?
                                            <a href={props.hscMarkSheetVal} >
                                                {props.hscMarkSheetVal}
                                            </a>
                                            :
                                            <InputGroup
                                                id="text-input"
                                                name={"hscMarkSheet"}
                                                type="file"
                                                onChange={props.onInputChange}
                                                style={fileInputStyle}
                                            />
                                        }
                                    </FormGroup>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm">
                                    <FormGroup
                                        label="Bachelors"
                                        labelFor="text-input"
                                        labelInfo="Consolidated marks in Percentage"
                                        className="lineBreak"
                                    >
                                        <TextArea
                                            id="text-input"
                                            placeholder="eg. 70%"
                                            name={"bachelors"}
                                            value={props.bachelorsVal}
                                            onChange={props.onInputChange}
                                            growVertically={true}
                                            large={true}
                                            style={textAreaStyle}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-sm'>
                                    <FormGroup
                                        label="Masters"
                                        labelFor="text-input"
                                        labelInfo="Consolidated marks in Percentage"
                                        className="lineBreak"
                                    >
                                        <TextArea
                                            id="text-input"
                                            placeholder="eg. 70%"
                                            name={"masters"}
                                            value={props.mastersVal}
                                            onChange={props.onInputChange}
                                            growVertically={true}
                                            large={true}
                                            style={textAreaStyle}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-sm'>
                                    <FormGroup
                                        label="Any Other Qualification"
                                        labelFor="text-input"
                                        labelInfo="Any Other Degree, Diploma Certificate"
                                        className="lineBreak"
                                    >
                                        <TextArea
                                            id="text-input"
                                            name={"anyOtherQul"}
                                            value={props.anyOtherQulVal}
                                            onChange={props.onInputChange}
                                            growVertically={true}
                                            large={true}
                                            style={textAreaStyle}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm">
                                    <FormGroup
                                        label="Bachelors Marksheet and degree"
                                        labelFor="text-input"
                                    >
                                        {props.vid ?
                                            <a href={props.bachelorsMarksheetVal} >
                                                {props.bachelorsMarksheetVal}
                                            </a>
                                            :
                                            <InputGroup
                                                id="text-input"
                                                name={"bachelorsMarksheet"}
                                                onChange={props.onInputChange}
                                                type="file"
                                                style={fileInputStyle}
                                            />
                                        }
                                    </FormGroup>
                                </div>
                                <div className='col-sm'>
                                    <FormGroup
                                        label="Masters marksheet and degree"
                                        labelFor="text-input"
                                    >
                                        {props.vid ?
                                            <a href={props.mastersMarksheetVal} >
                                                {props.mastersMarksheetVal}
                                            </a>
                                            :
                                            <InputGroup
                                                id="text-input"
                                                name={"mastersMarksheet"}
                                                onChange={props.onInputChange}
                                                type="file"
                                                style={fileInputStyle}
                                            />
                                        }
                                    </FormGroup>
                                </div>
                                <div className='col-sm'>
                                    <FormGroup
                                        label="Other qualification marksheet"
                                        labelFor="text-input"
                                    >
                                        {props.vid ?
                                            <a href={props.otherMarksheetVal} >
                                                {props.otherMarksheetVal}
                                            </a>
                                            :
                                            <InputGroup
                                                id="text-input"
                                                name={"otherMarksheet"}
                                                onChange={props.onInputChange}
                                                type="file"
                                                style={fileInputStyle}
                                            />
                                        }
                                    </FormGroup>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm">
                                    <FormGroup
                                        label="Country"
                                        labelFor="text-input"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            name={"country"}
                                            value={props.countryVal}
                                            onChange={props.onInputChange}
                                            style={inputStyle}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-sm'>
                                    <FormGroup
                                        helperText={props.errors.city ? 'City is required' : ''}
                                        label="City"
                                        labelFor="text-input"
                                        labelInfo="*"
                                        className="warring"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            intent={props.errors.city ? "Danger" : "None"}
                                            name={"city"}
                                            value={props.cityVal}
                                            onChange={props.onInputChange}
                                            style={inputStyle}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm">
                                    <FormGroup
                                        label="Gap in Education (Years)"
                                        labelFor="text-input"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            name={"gapInEducation"}
                                            value={props.gapInEducationVal}
                                            onChange={props.onInputChange}
                                            style={inputStyle}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-sm'>
                                    <FormGroup
                                        label="Reason for Gap (by Year)"
                                        labelFor="text-input"
                                        labelInfo="Please list the reason with years"
                                        className="lineBreak"
                                    >
                                        <TextArea
                                            id="text-input"
                                            placeholder="eg. 2015-16 - Medical reasons"
                                            name={"reasonForGap"}
                                            value={props.reasonForGapVal}
                                            onChange={props.onInputChange}
                                            style={textAreaStyle}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <FormGroup
                                label="Marks in English"
                                labelFor="text-input"
                                labelInfo="English score in % in Year 12 (if applicable)"
                                className="lineBreak"
                            >
                                <InputGroup
                                    id="text-input"
                                    placeholder="eg. 75%"
                                    name={"marksInEnglish"}
                                    value={props.marksInEnglishVal}
                                    onChange={props.onInputChange}
                                    style={inputStyle}
                                />
                            </FormGroup>
                            <div className='row'>
                                <div className="col-sm">
                                    <FormGroup
                                        label="Language test"
                                        labelFor="text-input"
                                        labelInfo="IELTS/ TOEFL/ PTE Scores"
                                        className="lineBreak"
                                    >
                                        <TextArea
                                            id="text-input"
                                            placeholder="O: 7.0, L: 6.0, R: 5.5, W: 7.5, S: 7.0"
                                            name={"languageTest"}
                                            value={props.languageTestVal}
                                            onChange={props.onInputChange}
                                            style={textAreaStyle}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-sm'>
                                    <FormGroup
                                        label="English language (IELTS scorecard)"
                                        labelFor="text-input"
                                    >
                                        {props.vid ?
                                            <a href={props.englishLanguageVal} >
                                                {props.englishLanguageVal}
                                            </a>
                                            :
                                            <InputGroup
                                                id="text-input"
                                                type="file"
                                                name={"englishLanguage"}
                                                onChange={props.onInputChange}
                                                style={fileInputStyle}
                                            />
                                        }
                                    </FormGroup>
                                </div>
                                <div className='col-sm'>
                                    <FormGroup
                                        label="Budget"
                                        labelFor="text-input"
                                        labelInfo="Overall Budget"
                                        className="lineBreak"
                                    >
                                        <Form.Control
                                            as="select"
                                            style={inputStyle}
                                            name={"budget"}
                                            value={props.budgetVal}
                                            onChange={props.onInputChange}
                                        >
                                            <option value="" disabled>Please Select</option>
                                            <option value="0-10,00,000">0-10,00,000</option>
                                            <option value="10,00,001-20,00,000">10,00,001-20,00,000</option>
                                            <option value="20,00,001-30,00,000">20,00,001-30,00,000</option>
                                            <option value="30,00,001-40,00,000">30,00,001-40,00,000</option>
                                            <option value="40,00,001-50,00,000">40,00,001-50,00,000</option>
                                            <option value="50,00,001-60,00,000">50,00,001-60,00,000</option>
                                            <option value="60,00,001 and above">60,00,001 and above</option>
                                        </Form.Control>
                                    </FormGroup>
                                </div>
                            </div>
                            <FormGroup
                                label="Experience and other Credentials"
                                labelFor="text-input"
                                labelInfo="Any work experience, Awards, Volunteering work etc"
                                className="lineBreak"
                            >
                                <TextArea
                                    id="text-input"
                                    placeholder=""
                                    name={"experience"}
                                    value={props.experienceVal}
                                    onChange={props.onInputChange}
                                    style={textAreaStyle}
                                />
                            </FormGroup>
                            <div className='row'>
                                <div className="col-sm">
                                    <FormGroup
                                        label="Interested Courses"
                                        labelFor="text-input"
                                        labelInfo="Name of the Course"
                                        className="lineBreak"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            placeholder="eg. Business Management"
                                            name={"interestedCourses"}
                                            value={props.interestedCoursesVal}
                                            onChange={props.onInputChange}
                                            type="numeric"
                                            style={inputStyle}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-sm'>
                                    <FormGroup
                                        label="Desired Course Level"
                                        labelFor="text-input"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            placeholder="eg. Masters"
                                            name={"desiredCourseLevel"}
                                            value={props.desiredCourseLevelVal}
                                            onChange={props.onInputChange}
                                            style={inputStyle}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm">
                                    <FormGroup
                                        label="Intake"
                                        labelFor="text-input"
                                    >
                                        <Form.Control
                                            as="select"
                                            style={inputStyle}
                                            name={"intake"}
                                            value={props.intakeVal}
                                            onChange={props.onInputChange}
                                            placeholder="eg. Sep-Dec 19"
                                        >
                                            <option value="" disabled>eg. Sep-Dec 19</option>
                                            <option value="May-Aug 19">May-Aug 19</option>
                                            <option value="Sept-Dec 19">Sept-Dec 19</option>
                                            <option value="Jan-Apr 20">Jan-Apr 20</option>
                                            <option value="May-Aug 20">May-Aug 20</option>
                                            <option value="Sept-Dec 20">Sept-Dec 20</option>
                                            <option value="Jan-Apr 21">Jan-Apr 21</option>
                                            <option value="May-Aug 21">May-Aug 21</option>
                                            <option value="Sept-Dec 21">Sept-Dec 21</option>
                                            <option value="Jan-Apr 22">Jan-Apr 22</option>
                                            <option value="May-Aug 22">May-Aug 22</option>
                                            <option value="Sept-Dec 22">Sept-Dec 22</option>
                                        </Form.Control>
                                    </FormGroup>
                                </div>
                                <div className='col-sm'>
                                    <FormGroup
                                        label="Interested University (if any)"
                                        labelFor="text-input"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            name={"interestedUniversity"}
                                            value={props.interestedUniversityVal}
                                            onChange={props.onInputChange}
                                            style={inputStyle}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm">
                                    <FormGroup
                                        label="Interested Destination Country"
                                        labelFor="text-input"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            placeholder="eg. UK"
                                            name={"destinationCountry"}
                                            value={props.destinationCountryVal}
                                            onChange={props.onInputChange}
                                            style={inputStyle}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-sm'>
                                    <FormGroup
                                        label="Interested City"
                                        labelFor="text-input"
                                    >
                                        <InputGroup
                                            id="text-input"
                                            placeholder="eg. London"
                                            name={"interestedCity"}
                                            value={props.interestedCityVal}
                                            onChange={props.onInputChange}
                                            style={inputStyle}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <RadioGroup
                                label="Have you traveled abroad ?"
                                onChange={props.onInputChange}
                                name={"Traveledabroad"}
                                selectedValue={props.TraveledabroadVal}
                            >
                                <Radio label="Yes" value="Yes" />
                                <Radio label="No" value="No" />
                            </RadioGroup>
                            <FormGroup
                                label="Any visa rejections ?"
                                labelFor="text-input"
                                labelInfo="Please provide date and reason for visa rejection if any."
                                className="lineBreak"
                            >
                                <TextArea
                                    id="text-input"
                                    placeholder="eg. 2015- Canada- Student visa- due to insufficient funds"
                                    name={"anyVisaRejections"}
                                    value={props.anyVisaRejectionsVal}
                                    onChange={props.onInputChange}
                                    style={textAreaStyle}
                                />
                            </FormGroup>
                            <FormGroup
                                label="Source"
                                labelFor="text-input"
                            >
                                <InputGroup
                                    id="text-input"
                                    placeholder="Organisation Name/ Agent Name"
                                    name={"source"}
                                    value={props.sourceVal}
                                    onChange={props.onInputChange}
                                    style={inputStyle}
                                    disabled
                                />
                            </FormGroup>
                            <FormGroup
                                label="SOP"
                                labelFor="text-input"
                            >
                                {props.vid ?
                                    <a href={props.sopVal} >
                                        {props.sopVal}
                                    </a>
                                    :
                                    <InputGroup
                                        id="text-input"
                                        placeholder="eg. 2015- Canada- Student visa- due to insufficient funds"
                                        name={"sop"}
                                        type="file"
                                        onChange={props.onInputChange}
                                        style={fileInputStyle}
                                    />
                                }
                            </FormGroup>
                            <FormGroup
                                label="LOR"
                                labelFor="text-input"
                            >
                                {props.vid ?
                                    <a href={props.lorVal} >
                                        {props.lorVal}
                                    </a>
                                    :
                                    <InputGroup
                                        id="text-input"
                                        placeholder="eg. 2015- Canada- Student visa- due to insufficient funds"
                                        name={"lor"}
                                        type="file"
                                        onChange={props.onInputChange}
                                        style={fileInputStyle}
                                    />
                                }
                            </FormGroup>
                            <FormGroup
                                label="Passport"
                                labelFor="text-input"
                            >
                                {props.vid ?
                                    <a href={props.passportVal} >
                                        {props.passportVal}
                                    </a>
                                    :
                                    <InputGroup
                                        id="text-input"
                                        placeholder="eg. 2015- Canada- Student visa- due to insufficient funds"
                                        name={"passport"}
                                        type="file"
                                        onChange={props.onInputChange}
                                        style={fileInputStyle}
                                    />
                                }
                            </FormGroup>
                            <div>
                                <div
                                    style={{
                                        lineHeight: '24px',
                                        fontSize: 14,
                                        color: '#33475b',
                                        marginBottom: 3,
                                        wordBreak: 'break-word'
                                    }}
                                >
                                    Zoom Abroad is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you:
                                </div>
                                <div>
                                    <Checkbox
                                        name={"agree"}
                                        checked={props.agreeVal}
                                        onChange={props.onInputChange}
                                    >
                                        I agree to receive other communications from Zoom Abroad.
                                    </Checkbox>
                                </div>
                                <div
                                    style={{
                                        lineHeight: '24px',
                                        fontSize: 14,
                                        color: '#33475b',
                                        marginBottom: 3,
                                        wordBreak: 'break-word'
                                    }}
                                >
                                    You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy.
                                </div>
                                <div
                                    style={{
                                        lineHeight: '24px',
                                        fontSize: 14,
                                        color: '#33475b',
                                        marginBottom: 3,
                                        wordBreak: 'break-word'
                                    }}
                                >
                                    By clicking submit below, you consent to allow Zoom Abroad to store and process the personal information submitted above to provide you the content requested.
                                </div>
                            </div>
                            <div
                                style={{
                                    marginTop: 18,
                                    marginBottom: 18
                                }}
                            >
                                <Button
                                    style={{
                                        backgroundColor: '#ff7a59',
                                        boxShadow: 'none',
                                        backgroundImage: 'none',
                                        color: '#fff',
                                        fontWeight: 700,
                                        fontSize: 14,
                                        padding: '12px 24px',
                                        borderRadius: 15
                                    }}
                                    onClick={() => props.createNew()}
                                >
                                    {props.vid ? 'Update' : 'Submit'}
                                </Button>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

const style = {
    inputStyle: {
        padding: '0 15px',
        minHeight: 27,
        borderRadius: 15,
        border: '1px solid #cbd6e2',
        backgroundColor: '#f5f8fa',
        color: '#33475b',
        height: 40
    },
    textAreaStyle: {
        padding: '10px 15px',
        maxWidth: '100%',
        borderRadius: 15,
        display: 'inline-block',
        width: '100 %',
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans - serif',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: '22px',
        color: '#33475b',
        backgroundColor: '#f5f8fa',
        border: '1px solid #cbd6e2',
        boxSizing: 'border - box',
        width: '100%'
    },
    fileInputStyle: {
        boxShadow: 'none',
        lineHeight: 'inherit'
    }
}

export default CreateUserMarkup;