import Constants from './constants';

const INITITAL_STATE = {

    wizardMode: undefined,
    isLoading: false,
    Step:[],
    details:{},
    FeatureStep:[],
    error: undefined,
}

export default (state = INITITAL_STATE, action) => {
    switch (action.type) {
        case Constants.WIZARDSTART_REQUEST:
            return { ...state,wizardMode: true }; 
        case Constants.WIZARDEND_REQUEST:
            return { ...state,wizardMode: false }; 

        case Constants.GET_STEP_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_STEP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                Step: action.payload
            };
        case Constants.GET_STEP_FAIL:
            return {
                ...state,
                isLoading: false,
                Step: action.error
            };
        case Constants.UPDATE_STEP_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.UPDATE_STEP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                details: action.payload 
            };
        case Constants.UPDATE_STEP_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
            case Constants.GET_FEATURESTEP_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_FEATURESTEP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                FeatureStep: action.payload
            };
        case Constants.GET_FEATURESTEP_FAIL:
            return {
                ...state,
                isLoading: false,
                Step: action.error
            };
        default:
            return state;
    }
};