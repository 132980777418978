import React, { Component } from 'react';
import './Notification.scss'
class Notification extends Component {
    constructor(props) {
        super(props);
    }


    render(){
        return(
            <div className={"notification-show"}>
                <h5 className="title-notification">Notification (4)</h5>
                <p>content1</p>
            </div>
        )
    }
}

export default Notification;