import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IntegrationMarkup from "../components/IntegrationMarkup"
import { bindActionCreators } from 'redux';
import hubsImg from "../../../img/hubs-pot.png"
import sugerImg from "../../../img/sugar-crm.png"
import sapImg from "../../../img/sap.jpeg"
import fullFabricImg from "../../../img/full-fabric.png"
import maestroSisImg from "../../../img/maestro-sis.png"
import maximizerImg from "../../../img/maximizer.png"
import radiusImg from "../../../img/radius.png"
import salesforceImg from "../../../img/salesforce.png"
import zohoImg from "../../../img/zoho.svg"
import config from '../../../app_config'
import CsvImg from '../../../img/csv.png'
import formImg from '../../../img/form.png'
import { Toast } from '../../../shared';
import actions from '../../Students/modules/actions'
import wizardactions from '../../Wizards/modules/actions'
import { app_url_generator } from '../../../utils'
import { QueryList } from '@blueprintjs/select';

class Integration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            showModal : false,
            showFormModal: false,
            studentFile : null,
            step: 0,
            integrations: [
                {
                    app: "import",
                    name: "Import Manually",
                    details: "You can skip the process of connecting to app for data,instead just import your csv files.",
                    logo: CsvImg,
                    buttonText: "Import" 
                },
                {
                    app: "hubspot",
                    name: "HubSpot",
                    details: "HubSpot is a developer and marketer of software products for marketing.",
                    logo: hubsImg,
                    buttonText: "Connect" 
                },
                {
                    app: "sap",
                    name: "SAP",
                    details: "SAP makes enterprise software to manage business operations and customer relations.",
                    logo: sapImg,
                    buttonText: "Connect" 
                },
                {
                    app: "sugar",
                    name: "Sugar CRM",
                    details: "SugarCRM produces customer relationship management system includes Social CRM.",
                    logo: sugerImg,
                    buttonText: "Connect" 
                },
                {
                    app: "zoho",
                    name: "Zoho CRM",
                    details: "Zoho CRM helps you engage with leads and customers, get insights about your business.",
                    logo: zohoImg,
                    buttonText: "Connect" 
                },
                {
                    app: "maestro",
                    name: "Maestro SIS",
                    details: "Maestro SIS has over 14 years experience of CRM in the education sector.",
                    logo: maestroSisImg,
                    buttonText: "Connect" 
                },
                {
                    app: "salesforce",
                    name: "Salesforce",
                    details: "Salesforce is a giant of the CRM world. The company’s cloud-based CRM for higher education.",
                    logo: salesforceImg,
                    buttonText: "Connect" 
                },
                {
                    app: "fullFabric",
                    name: "FullFabric CRM",
                    details: "FullFabric is a dedicated higher education solutions and technology company.",
                    logo: fullFabricImg,
                    buttonText: "Connect" 
                },
                {
                    app: "maximizer",
                    name: "Maximizer CRM",
                    details: "Easy-to-use and affordable, Maximizer software is an all-in-one CRM solution available.",
                    logo: maximizerImg,
                    buttonText: "Connect" 
                },
                {
                    app: "Radius",
                    name: "Radius",
                    details: "Campus Management’s sole aim as a company is to produce the best CRM for higher education.",
                    logo: radiusImg,
                    buttonText: "Connect" 
                },
                {
                    app: "form",
                    name: "Application Form",
                    details: "You can skip the process of connecting to app for data,instead just share the form link.",
                    logo: formImg,
                    buttonText: "Get Link"
                },
                {
                    app: "zilter_form",
                    name: "Zilter Form",
                    details: "You can skip the process of connecting to app for data,instead just share the form link.",
                    logo: formImg,
                    buttonText: "Get Link"
                },
            ]
        };
    }
    onChange = e => {
        this.setState({ search: e.target.value });
    };
    connect = (app) => {
        switch(app) {
            case "import":
                this.setState({ showModal: true })
                break;
            case "form":
                this.setState({ showFormModal: true })
                break;
            case "zilter_form":
                this.setState({ showZilterFormModal: true })
                break;
            default:
                window.open(app_url_generator(config[app]), "_self")
                break;
        }
    };
    nextStep = (step) => {
        this.setState({step});
    };
    onShowModal = () => {
        this.setState({
            showModal: true
        })
    }
    onCloseModal = () => {
        this.setState({
            showModal: false,
            showFormModal: false,
            showZilterFormModal: false
        })
    }
    uploadFile = (event) => {
        const { user: { user, organization }, } = this.props;
        const { studentFile } = this.state;
        const formData = new FormData()
        formData.append("user_id", user.id);
        formData.append("organization_id",organization.organization_id);
        if (!studentFile) {
            Toast.show({
                message: "Please Upload CSV File",
                timeout: 4000,
                intent: 'danger'

            })
            this.onCloseModal();
        }
        else {
            formData.append("studentFile", studentFile[0], 'student_file.csv');
            const handleSuccess = () => {

                Toast.show({
                    message: "File upload Successfully",
                    timeout: 4000,
                    intent: 'success'

                })
                if(studentFile)
                {  
                const { user } = this.props;
                const org_id= user.organization.organization_id
                
                const request = {
                    step:"Question",
                }
                const handleStep=()  => {
                        const handleSuccess=()  => {}
                        const handleFailure=()  => {}
                        this.props.wizardactions.updateStep(handleSuccess,handleFailure,request,org_id)
                        this.props.history.push('/ziltersteps')
                } 
                organization.step=="Finished" ? this.props.history.push('/questions/settings'): handleStep();
                }

            };

            const handleFailure = (data) => {
                Toast.show({
                    message: "Please Check CSV File ",
                    timeout: 4000,
                    intent: 'Danger'

                })
            };
            this.setState({
                studentFile: ''
            })
            this.onCloseModal();
            this.props.actions.upload(handleSuccess,handleFailure, formData)
        }
    }
    onDropStudentFile = (studentFile) => {
		this.setState({studentFile});
    }
    continuebtn = () => {
        const { user: { user, organization }, } = this.props;
        console.log("organizationorganizationorganization",organization);
        console.log("useruser",user);
        const org_id= organization.organization_id;                
        const request = {
            step:"Question",
        }
        const handleStep=()  => {
                const handleSuccess=()  => {}
                const handleFailure=()  => {}
                this.props.wizardactions.updateStep(handleSuccess,handleFailure,request,org_id)
                this.props.history.push('/ziltersteps')
        } 
        organization.step=="Finished" ? this.props.history.push('/integration'): handleStep();
        this.onCloseModal();
    }
    render() {
        const { integrations, search, step , showModal, studentFile, showFormModal, showZilterFormModal } = this.state;
        const { user }  = this.props;
        return (
            <IntegrationMarkup
                isLoading={this.props.isLoading}
                isCSV={this.props.isCSV}
                itemResult={integrations}
                search={search}
                onChange={this.onChange}
                connect={this.connect}
                step={step}
                nextStep={this.nextStep}
                onShowModal = { this.onShowModal }
                onCloseModal = { this.onCloseModal}
                showModal = { showModal }
                uploadFile = { this.uploadFile}
                studentFile = { studentFile }
                onDropStudentFile = { this.onDropStudentFile }
                pathname={this.props.location.search}
                showFormModal={showFormModal}
                continuebtn = { this.continuebtn }
                user={user}
                showZilterFormModal={showZilterFormModal}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.Auth.isLoading,
        isCSV:state.students.isLoading,
        error: state.Auth.error,
        user:state.Auth.user,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
        wizardactions : bindActionCreators(wizardactions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Integration));