import {
    CREATE_CONTACT_LOADING,
    CREATE_CONTACT_SUCCESS,
    CREATE_CONTACT_ERROR,
    UPDATE_CONTACT_SUCCESS,
    UPDATE_CONTACT_ERROR,
    GET_CONTACT_SUCCESS,
    GET_CONTACT_ERROR
} from './type';

import { HUBURL } from '../../../env';

export const createContact = (handleSuccess, handleFailure, data, listId, formData) => {
    const url = `${HUBURL}contacts/v1/contact?hapikey=bc42d833-f807-468e-8d0a-3dd61567de4c`;
    const imageUrl = `${HUBURL}filemanager/api/v2/files?hapikey=bc42d833-f807-468e-8d0a-3dd61567de4c`;
    const imageEngageUrl = `${HUBURL}engagements/v1/engagements?hapikey=bc42d833-f807-468e-8d0a-3dd61567de4c`;
    return (dispatch) => {
        dispatch({
            type: CREATE_CONTACT_LOADING
        });
        fetch(`${imageUrl}`, {
            mode: 'cors',
            method: 'POST',
            body: formData
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: CREATE_CONTACT_ERROR,
                        payload: body.error
                    });
                    handleFailure(response, body);
                } else {
                    let imageId = [];
                    let temData = data;
                    for (let i = 0; i < body.objects.length; i++) {
                        imageId.push({
                            "id": body.objects[i].id
                        });
                        if (body.objects[i].name.indexOf("hscMarkSheet") !== -1) {
                            temData.properties.push({
                                "property": "n12th_mark_sheet",
                                "value": body.objects[i].s3_url
                            })
                        }
                        if (body.objects[i].name.indexOf("bachelorsMarksheet") !== -1) {
                            temData.properties.push({
                                "property": "bachelors_marksheet_and_degree",
                                "value": body.objects[i].s3_url
                            })
                        }
                        if (body.objects[i].name.indexOf("mastersMarksheet") !== -1) {
                            temData.properties.push({
                                "property": "masters_marksheet_and_degree",
                                "value": body.objects[i].s3_url
                            })
                        }
                        if (body.objects[i].name.indexOf("otherMarksheet") !== -1) {
                            temData.properties.push({
                                "property": "other_qualification_marksheet",
                                "value": body.objects[i].s3_url
                            })
                        }
                        if (body.objects[i].name.indexOf("englishLanguage") !== -1) {
                            temData.properties.push({
                                "property": "english_language_ielts_scorecard_",
                                "value": body.objects[i].s3_url
                            })
                        }
                        if (body.objects[i].name.indexOf("sop") !== -1) {
                            temData.properties.push({
                                "property": "sop",
                                "value": body.objects[i].s3_url
                            })
                        }
                        if (body.objects[i].name.indexOf("lor1") !== -1) {
                            temData.properties.push({
                                "property": "lor",
                                "value": body.objects[i].s3_url
                            })
                        }
                        if (body.objects[i].name.indexOf("passport") !== -1) {
                            temData.properties.push({
                                "property": "passport",
                                "value": body.objects[i].s3_url
                            })
                        }
                    }
                    
                    fetch(`${url}`, {
                        mode: 'cors',
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(temData)
                    })
                        .then(response => response.json().then(body => ({ response, body })))
                        .then(({ response, body }) => {
                            if (!response.ok) {
                                dispatch({
                                    type: CREATE_CONTACT_ERROR,
                                    payload: body.error
                                });
                                handleFailure(response, body);
                            } else {
                                fetch(`${imageEngageUrl}`, {
                                    mode: 'cors',
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        "engagement": {
                                            "active": true,
                                            "ownerId": 1,
                                            "type": "NOTE",
                                            "timestamp": 1409172644778
                                        },
                                        "associations": {
                                            "contactIds": [body.vid]
                                        },
                                        "attachments": imageId,
                                        "metadata": {
                                            "body": "note body"
                                        }
                                    })
                                })
                                    .then(response => response.json().then(body1 => ({ response, body1 })))
                                    .then(({ response, body1 }) => {
                                        if (!response.ok) {
                                            dispatch({
                                                type: CREATE_CONTACT_ERROR,
                                                payload: body1.error
                                            });
                                            handleFailure(response, body1);
                                        } else {
                                            dispatch(addContactToContactList(handleSuccess, handleFailure, body, listId))
                                        }
                                    })
                                    .catch((error) => {
                                        dispatch({
                                            type: CREATE_CONTACT_ERROR,
                                            payload: error.message
                                        });

                                        handleFailure(error, error.message);
                                    });
                            }
                        })
                        .catch((error) => {
                            dispatch({
                                type: CREATE_CONTACT_ERROR,
                                payload: error.message
                            });

                            handleFailure(error, error.message);
                        });
                }
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_CONTACT_ERROR,
                    payload: error.message
                });

                handleFailure(error, error.message);
            });
        }
}

export const updateContact = (handleSuccess, handleFailure, data, vid) => {
    const url = `${HUBURL}contacts/v1/contact/vid/${vid}/profile?hapikey=bc42d833-f807-468e-8d0a-3dd61567de4c`;
    return (dispatch) => {
        dispatch({
            type: CREATE_CONTACT_LOADING
        });
        fetch(`${url}`, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    dispatch({
                        type: UPDATE_CONTACT_SUCCESS,
                        payload: ''
                    });
                    handleSuccess();
                } else {
                    dispatch({
                        type: UPDATE_CONTACT_ERROR,
                        payload: response.statusText
                    });
                    handleFailure(response.statusText);
                }
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_CONTACT_ERROR,
                    payload: error.message
                });

                handleFailure();
            });
    }
}


export const addContactToContactList = (handleSuccess, handleFailure, data, listId) => {
    const url = `${HUBURL}contacts/v1/lists/${listId}/add?hapikey=bc42d833-f807-468e-8d0a-3dd61567de4c`;
    return (dispatch) => {
        dispatch({
            type: CREATE_CONTACT_LOADING
        });
        fetch(`${url}`, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "vids": [
                    data.vid
                ]
            })
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: CREATE_CONTACT_ERROR,
                        payload: body.error
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: CREATE_CONTACT_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_CONTACT_ERROR,
                    payload: error.message
                });
                handleFailure(error, error.message);
            });
    }
}

export const getContactById = (handleSuccess, handleFailure, vid) => {
    const url = `${HUBURL}contacts/v1/contact/vid/${vid}/profile?hapikey=bc42d833-f807-468e-8d0a-3dd61567de4c`;
    return (dispatch) => {
        dispatch({
            type: CREATE_CONTACT_LOADING
        });
        fetch(`${url}`, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: GET_CONTACT_ERROR,
                        payload: body.error
                    });

                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: GET_CONTACT_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_CONTACT_ERROR,
                    payload: error.message
                });
                handleFailure(error, error.message);
            });
    }
}
