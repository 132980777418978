import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { components } from 'react-select';
import { bindActionCreators } from 'redux';
import CustomerReportMarkup from '../components/CustomerReportMarkup'
import Denied from '../../../shared/denied/Denied'
import moment from 'moment';
import _ from 'lodash'
import actions from '../modules/actions'
import studentactions from '../../Students/modules/actions';


class CustomerReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start_date: moment().add(-1, "weeks").toDate(),
            end_date: moment().add(0, "days").toDate(),
            yopmailVal: false,
            leadStatusValue: [],
            SelectedLeadStatus:[],
            viewAccess: true,
            multiple:null,
            multipleYesChecked: null,
            multipleNoChecked: null,            
            intakeMonthSearch: '',
            intakeYearSearch: '',
            applicationstatusvalue:[],
            selectedapplicationStatus:[]
        }
    }
    static getDerivedStateFromProps(props, state) {
        const { allLeads, user,applicationStatus } = props
        const access = user.organization.access

        let finalData = access ? JSON.parse(access) : [{ "operations": [1, 1, 1, 1], "name": "MAPPING" }, { "operations": [1, 1, 1, 1], "name": "QUESION" }, { "operations": [1, 1, 1, 1], "name": "INTERVIEW" }, { "operations": [1, 1, 1, 1], "name": "COURSE" }, { "operations": [1, 1, 1, 1], "name": "REPORT" }, { "operations": [1, 1, 1, 1], "name": "STUDENTS" }, {
            "name": "DASHBOARD",
            "operations": [
                1,
                0,
                0,
                0
            ]
        }, {
            "name": "COUNSELOR-REPORT",
            "operations": [
                1,
                0,
                0,
                0
            ]
        }]
        const dashboard = _.find(finalData, { "name": "COUNSELOR-REPORT" })

        let viewAccess = false

        if (dashboard && dashboard) {
            if (dashboard.operations[0] == 1) {
                viewAccess = true
            }
        }
        if(!user.user.is_counselor){
            viewAccess = true
          }
        return { viewAccess }


    }
    componentDidMount() {
        const { user: { organization, user } } = this.props;
        const { start_date, end_date, yopmailVal } = this.state;
        const yopmail = JSON.parse(localStorage.getItem('yopmail'));
        this.props.studentactions.getApplicationStatus(organization.organization_id)
        let startdate = start_date ? moment(start_date).format("YYYY-MM-DD HH:mm:ss") : '';
        let enddate = end_date ? moment(end_date).format("YYYY-MM-DD HH:mm:ss") : '';
        // this.props.actions.getCustomerReport(organization.organization_id, startdate, enddate, user.email);

    }
    handleRangeChange = (event) => {
        this.setState({ start_date: event[0], end_date: event[1] })
    }

    getreport = (value) => {
        const { user: { organization, user } } = this.props;
        const { start_date, end_date, leadStatusValue, SelectedLeadStatus,multipleNoChecked, multipleYesChecked, intakeMonthSearch, intakeYearSearch ,applicationstatusvalue} = this.state;
        
        const yopmail = JSON.parse(localStorage.getItem('yopmail'));
        let startdate = start_date ? moment(start_date).format("YYYY-MM-DD") : '';
        let enddate = end_date ? moment(end_date).format("YYYY-MM-DD 23:59:00") : '';
        let month = intakeMonthSearch ? intakeMonthSearch :'';
        let year = intakeYearSearch ? intakeYearSearch :'';

        if(multipleYesChecked == null && multipleNoChecked == null)
        {
            let unique = 'noStatus'
            this.props.actions.getCustomerReport(organization.organization_id, startdate, enddate, user.email, leadStatusValue,applicationstatusvalue,month,year,unique);
        }
        else
        {
            let unique = multipleYesChecked && multipleYesChecked ? 0 : 1;
            this.props.actions.getCustomerReport(organization.organization_id, startdate, enddate, user.email, leadStatusValue,applicationstatusvalue,month,year,unique);
        }
    }
    onChangeInput = (name, value) => {
        console.log("name, value",name, value)
        const {leadStatusValue,SelectedLeadStatus,applicationstatusvalue,selectedapplicationStatus} = this.state;
        console.log("this.state",this.state)
        const { applicationStatus } =this.props;

        if(name == 'leadStatusValue')
        {
            if(leadStatusValue.indexOf(`'${value}'`) >= 0)
            { 
                return
            }
            else {
                    leadStatusValue.push(`'${value}'`);
                    this.setState({leadStatusValue});
                    const spacesReplaced = value.replaceAll('_', ' ');
                    let showvalue = spacesReplaced.charAt(0).toUpperCase() + spacesReplaced.slice(1);
                    SelectedLeadStatus.push(`'${showvalue}'`);
                    this.setState({leadStatusValue,SelectedLeadStatus});
                    return
            }
        }
        else if(name == 'applicationstatusvalue')
        {
            if(applicationstatusvalue.indexOf(`'${value}'`) >= 0)
            { 
                return
            }
            else {
                applicationstatusvalue.push(`'${value}'`);
                let showvalue =""
                let x = applicationStatus && applicationStatus && applicationStatus.map((item) => {
                    if(item.key == value) showvalue = item.key
                })
                selectedapplicationStatus.push(`'${showvalue}'`);
                this.setState({applicationstatusvalue,selectedapplicationStatus});
                return
            }
        }
        else { 
            this.setState({[name]: value})
        }
        // this.getreport(value)
    }
    onDeselect = (value,status) => {   
        const {leadStatusValue,SelectedLeadStatus,applicationstatusvalue,selectedapplicationStatus} = this.state;
        const { applicationStatus } = this.props;
        if(status == 'leadstatus')
        {
            let SelectedLeadStatusarr = SelectedLeadStatus.filter(e => e !== value);      
            const spacesReplaced = value.replaceAll(' ', '_');
            let showvalue = spacesReplaced.toLowerCase();     
            let leadStatusValuearr = leadStatusValue.filter(e => e !== showvalue);
            this.setState({leadStatusValue:leadStatusValuearr,SelectedLeadStatus:SelectedLeadStatusarr});
        }
        else if(status == 'applicationstatus')
        {
            let selectedapplicationStatusarr = selectedapplicationStatus.filter(e => e !== value); 
            let showvalue = applicationStatus && applicationStatus && applicationStatus.map((item) => {
                if(item.key == value) return item.key
            })   
            let applicationstatusvaluearr = applicationstatusvalue.filter((e) => e !== value);

            this.setState({applicationstatusvalue:applicationstatusvaluearr,selectedapplicationStatus:selectedapplicationStatusarr});
        }
    }
    onCheckYes = (e) => {
        const { target: { name } } = e;
        this.setState({
            [name]: true,
            [`${name}YesChecked`]: true,
            [`${name}NoChecked`]: false
        })

    }

    onCheckNo = (e) => {
        const { target: { name } } = e;
        this.setState({
            [name]: false,
            [`${name}YesChecked`]: false,
            [`${name}NoChecked`]: true
        })
    }

    render() {
        const { start_date, end_date, leadStatusValue,SelectedLeadStatus,selectedapplicationStatus, viewAccess,applicationstatusvalue,multipleYesChecked, multipleNoChecked,  intakeMonthSearch, intakeYearSearch } = this.state
        const { customerReport, isLoading,applicationStatus} = this.props
        return (
            viewAccess ?
                <CustomerReportMarkup
                    start_date={start_date}
                    end_date={end_date}
                    onChangeInput={this.onChangeInput}
                    getreport={this.getreport}
                    handleRangeChange={this.handleRangeChange}
                    customerReport={customerReport && customerReport ? customerReport : []}
                    isLoading={isLoading}
                    leadStatusValue={leadStatusValue}
                    SelectedLeadStatus={SelectedLeadStatus}
                    selectedapplicationStatus={selectedapplicationStatus}
                    multipleYesChecked={multipleYesChecked}
                    multipleNoChecked={multipleNoChecked}
                    intakeMonthSearch={intakeMonthSearch}
                    intakeYearSearch={intakeYearSearch}
                    applicationStatus={applicationStatus}
                    applicationstatusvalue={applicationstatusvalue}
                    onCheckYes={this.onCheckYes}
                    onCheckNo={this.onCheckNo}
                    onDeselect={this.onDeselect}
                />
                : <Denied/>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.customerReport.isLoading,
        user: state.Auth.user,
        customerReport: state.customerReport.customerReport,
        applicationStatus: state.students.applicationStatus,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
        studentactions: bindActionCreators(studentactions, dispatch),

    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerReport))