import React from 'react';
// import { Toast } from 'react-bootstrap';
import { Position, Toaster } from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

const Toast = Toaster.create({
    className: "recipe-toaster",
    position: Position.TOP
});

export { Toast };
