import constants from './constants';

const INITITAL_STATE = {
    isLoading: false,
    error: '',
    timeSlot: [],
    getTimeSlots:{}
}

export default (state = INITITAL_STATE, action) => {
    switch (action.type) {
        case constants.CREATE_TIME_SLOT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case constants.CREATE_TIME_SLOT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                timeSlot: action.payload,
            };
        case constants.CREATE_TIME_SLOT_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
            case constants.GET_SCHEDULE_DETAILS_REQUEST:
                return {
                    ...state,
                    isLoading: true,
                };
            case constants.GET_SCHEDULE_DETAILS_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    getTimeSlots: action.payload,
                };
            case constants.GET_SCHEDULE_DETAILS_FAIL:
                return {
                    ...state,
                    isLoading: false,
                    error: action.error
                };
        default:
            return state;
    }
};