import React, { Component } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { H5, MenuItem, Switch } from "@blueprintjs/core";
import AppliactionCreate from "../Profile/ApplicationCreate";
import { Suggest } from "@blueprintjs/select";
import { Toast } from "../../../../shared";
import actions from "../../modules/actions";
import { ORG_ID } from "../../../../env";
import moment from "moment";
import { Label } from "@blueprintjs/core";
import "./ConfirmingInterests.scss";
import countryList from '../../../Integration/modules/countryList'

const courseTypeOptions = [
  { label: "Bachelor", value: "Bachelors" },
  { label: "Master", value: "Masters" },
  { label: "Foundation", value: "Foundation" },
  { label: "Phd", value: "phd_doctoral" },
  { label: "Diploma", value: "Diploma" },
  { label: "English Language", value: "english_language" },

];
export const renderItem = (item, { handleClick, modifiers, query }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  // if (item.label == "" || item.label == "demo" || item.label == "xyz" || item.label == "test" || item.label == "test22" || item.label == "Qualifi" || item.label == "qualifi") {
  //     return null;
  // }
  return (
    <MenuItem
      // className="form-generator-menu-item"
      active={modifiers.active}
      disabled={modifiers.disabled}
      text={item.label ? item.label.toString() : null}
      key={item.label}
      onClick={handleClick}
    />
  );
};
export function createFilm(value, options) {
  return {
    value: value,
    label: value,
  };
}
const filterItems = (query, options) => {
  return options.label
    ? options.label.toLowerCase().indexOf(query.toLowerCase()) !== -1
    : [];
};
export const renderCreateFilmOption = (query, active, handleClick) => (
  <MenuItem
    icon="add"
    text={`Create "${query}"`}
    active={active}
    onClick={handleClick}
    shouldDismissPopover={false}
  />
);
class ConfirmingInterests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      universityName: "",
      courseName: "",
      year: "",
      month: "",
      degree: "",
      country: "",
      id: "",
      errors: {},
      multiple: [1],
      isDisabled: true,
    };
  }
  componentDidMount() {
    const {
      match: { params },
      userProfile,
      student,
    } = this.props;
    this.props.actions.getStudent(params.id);
  }

  static getDerivedStateFromProps(props, state) {}

  isvalid = () => {
    const { universityName, courseName, year, month, degree, multiple, country } =
      this.state;
    let errors = {};

    if (degree == "") {
      errors.degree = true;
      this.setState({ errors });
      return false;
    }
    if (courseName == "") {
      errors.courseName = true;
      this.setState({ errors });
      return false;
    }
    if (universityName == "") {
      errors.universityName = true;
      this.setState({ errors });
      return false;
    }
    if (month == "") {
      errors.month = true;
      this.setState({ errors });
      return false;
    }
    if (year == "") {
      errors.year = true;
      this.setState({ errors });
      return false;
    }
    if (country == ""){
      errors.country = true;
      this.setState({errors})
      return false;
    }
    return true;
  };
  onAdd = () => {
    const { multiple } = this.state;
    multiple.push(1);
    this.setState({
      multiple,
      isDisabled: true,
    });
  };
  onSubmit = () => {
    const {
      match: { params },
      userProfile,
      student,
      onCloseModal,
    } = this.props;
    const { universityName, courseName, multiple, errors } = this.state;
    let data = [];
    let zadata = [];
    let note = [];
    for (let i = 0; i < multiple.length; i++) {
      let opportunityData = {};
      // if(`courseName${i}` == '' || `universityName${i}` || `degree${i}`){
      //     errors.courseName = true;
      //     errors.universityName = true;
      //     errors.degree = true;
      //     this.setState({ errors });
      // }
      opportunityData = {
        option_course_name: `${this.state[`courseName${i}`].label}, ${
          this.state[`degree${i}`].label}, ${this.state[`month${i}`]}-${this.state[`year${i}`]}`,
        option_course_description: this.state[`country${i}`].label,
        option_university_name: this.state[`universityName${i}`].label,
        selected_course: 0,
        type: "confirm_courses",
        selected_offer: null,
        student_id: params.id,
        user_id: userProfile.organization.user_id,
        is_advisor: 1,
      };
      data.push(opportunityData);
      let noteT = {
        "Course Option": `course name : ${this.state[`courseName${i}`].label}
                          course degree : ${this.state[`degree${i}`].label}
                          university name : ${this.state[`universityName${i}`].label}
                          country name : ${this.state[`country${i}`].label}
                          intake : ${this.state[`month${i}`]} ${this.state[`year${i}`]}`,
      };
      noteT = JSON.stringify(noteT);
      noteT = noteT.replace("{", "");
      noteT = noteT.replace("}", ",");
      note.push(noteT);
    }
    const taskList = {
      student_id: params.id,
      user_id: userProfile.organization.user_id,
      organization_id: userProfile.organization.organization_id,
      meeting_schedule: false,
      attending_meeting: false,
      required_documents: false,
      visa_appointment: false,
      opportunity: true,
      select_offer: false,
      qualifying: false,
    };

    const log = {
      std: params.id, ////student_id
      u_id: userProfile ? userProfile.organization.user_id : null,
      student_courses_id: courseName ? courseName : null,
      subject: "Task assigned course option select",
      type: "note",
      note: note.toString(),
      note_status:
        userProfile.organization.organization_id == ORG_ID
          ? "private"
          : "private",
      student_name: student.first_name,
      student_email: student.email,
      organization_id: userProfile
        ? userProfile.organization.organization_id
        : null,
    };
    const handlesNoteuccessfull = () => {
      const page = 1;
      this.props.actions.getLogs(params.id, page);
    };
    const handlesuccessfull = () => {
      this.props.actions.taskAllocation(
        taskList,
        () => {
      this.props.actions.getLogs(params.id, 1);

        },
        () => {}
      );
      this.props.actions.createNoteLog(
        log,
        () => {},
        () => {}
      );
      Toast.show({
        message: "Confirming interest task allocation send successfully!",
        timeout: 4000,
        intent: "success",
      });
      onCloseModal();
    };
    const handleFailure = () => {
      onCloseModal();
      Toast.show({
        message: "Confirming interest task allocation send unsuccessfully!",
        timeout: 4000,
        intent: "danger",
      });
      onCloseModal();
    };

    this.props.actions.counsellorOpportunity(
      data,
      handlesuccessfull,
      handleFailure
    );
  };
  onChangeInput = (name, value) => {
    console.log(name, value);
    if (name.includes('universityName')) {
        let uId = value.value
        const validUUID = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(uId);
        if (validUUID) {
            this.props.actions.getZAUniversityCourses(uId)
        }
    }
    this.setState(
      {
        [name]: value,
        errors: {},
      },
      () => {
        for (let i = 0; i < this.state.multiple.length; i++) {
          if (
            !this.state[`courseName${i}`] == "" &&
            !this.state[`universityName${i}`] == "" &&
            !this.state[`degree${i}`] == "" &&
            !this.state[`country${i}`] == "" &&
            !this.state[`month${i}`] == "" &&
            !this.state[`year${i}`] == ""
          ) {
            this.setState({
              isDisabled: false,
            });
          } else {
            this.setState({
              isDisabled: true,
            });
          }
        }
      }
    );
  };

  render() {
    const { universityName, errors, courseName, multiple } = this.state;
    const {
      applicationCourseType,
      applicationCourseUniversity,
      zaUniversityData,
      zaCoursesData
    } = this.props;
    console.log("fddsfsdfsdfdss", this.state.universityName0);
    const maybeCreateNewItemFromQuery = true ? createFilm : undefined;
    const maybeCreateNewItemRenderer = true ? renderCreateFilmOption : null;
    const renderInputValue = (options) => options.label;

    return (
      <div className="confirming-interests">
        {multiple.map((input, index) => {
          return (
            <div>
              <p className="options">{index ? `Option: ${index + 1} ` : ""}</p>
              <Row>
                <Col md="12" lg="12" sm="12" xs="12">
                  <Form.Label className="title">Degree Type *</Form.Label>
                  {/* <Form.Control
                                    placeholder="Enter degree type"
                                    type="text"
                                    className="form-control text-box"
                                    value={this.state[`degree${index}`]}
                                    onChange={(event) => this.onChangeInput(`degree${index}`, event.target.value)}
                                /> */}
                </Col>
                <Col md="12" lg="12" sm="12" xs="12">
                  <Suggest
                    fill
                    className="select-class"
                    createNewItemFromQuery={maybeCreateNewItemFromQuery}
                    createNewItemRenderer={maybeCreateNewItemRenderer}
                    items={courseTypeOptions}
                    itemRenderer={renderItem}
                    noResults={<MenuItem disabled={true} text="No results." />}
                    filterable={true}
                    minimal={true}
                    itemPredicate={filterItems}
                    selectedItem={
                      this.state[`degree${index}`]
                        ? this.state[`degree${index}`]
                        : "select"
                    }
                    inputValueRenderer={renderInputValue}
                    inputProps={{ placeholder: "Search" }}
                    onItemSelect={(e) => {
                      const event = {
                        target: {
                          value: e,
                        },
                      };
                      this.onChangeInput(`degree${index}`, event.target.value);
                    }}
                  >
                    <Button
                      text={
                        courseTypeOptions.value
                          ? courseTypeOptions.value.label
                          : "select"
                      }
                      rightIcon="double-caret-vertical"
                    />
                  </Suggest>
                </Col>
                {this.state.errors.degree ? (
                  <span className="errors">Please Enter Degree Name</span>
                ) : (
                  ""
                )}
              </Row>
        
              <Row>
                <Col md="12" lg="12" sm="12" xs="12">
                  <Form.Label className="title">University Name *</Form.Label>
                  {/* <Form.Control
                                    placeholder="Enter university name"
                                    type="text"
                                    className="form-control text-box"
                                    value={this.state[`universityName${index}`]}
                                    onChange={(event) => this.onChangeInput(`universityName${index}`, event.target.value)}
                                /> */}
                </Col>
                <Col md="12" lg="12" sm="12" xs="12">
                  <Suggest
                    fill
                    className="select-class"
                    createNewItemFromQuery={maybeCreateNewItemFromQuery}
                    createNewItemRenderer={maybeCreateNewItemRenderer}
                    items={zaUniversityData ? zaUniversityData : []}
                    itemRenderer={renderItem}
                    noResults={<MenuItem disabled={true} text="No results." />}
                    filterable={true}
                    minimal={true}
                    disabled={this.state[`degree${index}`] ? false : true}
                    itemPredicate={filterItems}
                    selectedItem={
                      this.state[`universityName${index}`] ? this.state[`universityName${index}`] : "select"
                    }
                    inputValueRenderer={renderInputValue}
                    inputProps={{ placeholder: "Search" }}
                    onItemSelect={(e) => {
                      const event = {
                        target: {
                          value: e,
                        },
                      };
                      this.onChangeInput(
                        `universityName${index}`,
                        event.target.value
                      );
                    }}
                  >
                    {zaUniversityData ? (
                      <Button
                        text={
                          zaUniversityData.value
                            ? zaUniversityData.value.label
                            : "select"
                        }
                        rightIcon="double-caret-vertical"
                      />
                    ) : (
                      ""
                    )}
                  </Suggest>
                </Col>
                {this.state.errors.universityName ? (
                  <span className="errors">Please Enter University Name</span>
                ) : (
                  ""
                )}
              </Row>
              <Row>
                <Col>
                  <Form.Label className="title">Course Name *</Form.Label>
                </Col>
                {/* <Form.Control
                                    placeholder="Enter course name"
                                    type="text"
                                    className="form-control text-box"
                                    value={this.state[`courseName${index}`]}
                                    onChange={(event) => this.onChangeInput(`courseName${index}`, event.target.value)}
                                /> */}
                {this.state.errors.courseName ? (
                  <span className="errors">Please Enter Course Name</span>
                ) : (
                  ""
                )}
                {/* </Col> */}
                <Col md="12" lg="12" sm="12" xs="12">
                  <Suggest
                    fill
                    className="select-class"
                    createNewItemFromQuery={maybeCreateNewItemFromQuery}
                    createNewItemRenderer={maybeCreateNewItemRenderer}
                    items={zaCoursesData ? zaCoursesData : []}
                    itemRenderer={renderItem}
                    noResults={<MenuItem disabled={true} text="No results." />}
                    filterable={true}
                    minimal={true}
                    disabled={this.state[`universityName${index}`] ? false : true}
                    itemPredicate={filterItems}
                    selectedItem={
                      this.state[`courseName${index}`] ? this.state[`courseName${index}`] : "select"
                    }
                    inputValueRenderer={renderInputValue}
                    inputProps={{ placeholder: "Search" }}
                    onItemSelect={(e) => {
                      const event = {
                        target: {
                          value: e,
                        },
                      };
                      this.onChangeInput(
                        `courseName${index}`,
                        event.target.value
                      );
                    }}
                  >
                    {zaUniversityData ? (
                      <Button
                        text={
                          zaUniversityData.value
                            ? zaUniversityData.value.label
                            : "select"
                        }
                        rightIcon="double-caret-vertical"
                      />
                    ) : (
                      ""
                    )}
                  </Suggest>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label className="title">Intake Month *</Form.Label>
                  <Form.Control
                    placeholder="Enter intake month"
                    // type="text"
                    as="select"
                    className="form-control text-box"
                    value={this.state[`month${index}`]}
                    onChange={(event) =>
                      this.onChangeInput(`month${index}`, event.target.value)
                    }
                  >
                    <option value="">Select</option>
                    <option value="january">January</option>
                    <option value="february">February</option>
                    <option value="march">March</option>
                    <option value="april">April</option>
                    <option value="may">May</option>
                    <option value="june">June</option>
                    <option value="july">July</option>
                    <option value="august">August</option>
                    <option value="september">September</option>
                    <option value="october">October</option>
                    <option value="november">November</option>
                    <option value="december">December</option>
                  </Form.Control>

                  {this.state.errors.month ? (
                    <span className="errors">Please Enter Intake Month</span>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label className="title">Intake Year *</Form.Label>
                  <Form.Control
                    placeholder="Enter intake year"
                    // type="text"
                    as="select"
                    className="form-control text-box"
                    value={this.state[`year${index}`]}
                    onChange={(event) =>
                      this.onChangeInput(`year${index}`, event.target.value)
                    }
                  >
                    <option value="">Select</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                  </Form.Control>
                  {this.state.errors.year ? (
                    <span className="errors">Please Enter Intake Year</span>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row>
                <Col md="12" lg="12" sm="12" xs="12">
                  <Form.Label className="title">Country *</Form.Label>
                </Col>
                <Col md="12" lg="12" sm="12" xs="12">
                  <Suggest
                    fill
                    className="select-class"
                    createNewItemFromQuery={maybeCreateNewItemFromQuery}
                    createNewItemRenderer={maybeCreateNewItemRenderer}
                    items={countryList ? countryList : []}
                    itemRenderer={renderItem}
                    noResults={<MenuItem disabled={true} text="No results." />}
                    filterable={true}
                    minimal={true}
                    itemPredicate={filterItems}
                    selectedItem={
                      this.state[`country${index}`]
                        ? this.state[`country${index}`]
                        : "select"
                    }
                    inputValueRenderer={renderInputValue}
                    inputProps={{ placeholder: "Search" }}
                    onItemSelect={(e) => {
                      const event = {
                        target: {
                          value: e,
                        },
                      };
                      this.onChangeInput(`country${index}`, event.target.value);
                    }}
                  >
                    <Button
                      text={
                        countryList.value
                          ? countryList.value.label
                          : "select"
                      }
                      rightIcon="double-caret-vertical"
                    />
                  </Suggest>
                </Col>
                {this.state.errors.country ? (
                  <span className="errors">Please Enter Country Name</span>
                ) : (
                  ""
                )}
              </Row>
            </div>
          );
        })}

        <Col>
          <Button onClick={() => this.onAdd()}>Add</Button>
        </Col>
        <Col className="button-save">
          <Button
            disabled={this.state.isDisabled}
            className="save-offer"
            onClick={() => this.onSubmit()}
          >
            Allocate
          </Button>
        </Col>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoading: state.students.isLoading,
    userProfile: state.Auth.user,
    student: state.students.student,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmingInterests)
);
