import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Card from 'react-bootstrap/Card'
import { Row, Col, Button } from 'react-bootstrap';
import { EditableText } from '@blueprintjs/core';
import { DateInput, IDateFormatProps } from "@blueprintjs/datetime";
import moment from 'moment';
import MembersMarkup from '../components/MembersMarkup'
import { ZLoader } from '../../../shared/loader/ZLoader'
import "./Members.scss"
import { checkAuth } from '../../../utils';
import actions from '../modules/actions'
import editActions from '../../UserRole/modules/actions'


class Members extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            statemembers: [],
            loading: true,
            dateValue: moment().toDate(),
            validEmail: true

        }
    }
    static getDerivedStateFromProps(props, state) {
        const { members: { Users } } = props;
        let tempmembers = {};
        if (Users) {
            tempmembers = Users;
        }
        return ({ statemembers: tempmembers })
    }
    componentDidMount() {
        const { user: { organization } } = this.props;
        this.props.actions.getMembers(organization.organization_id);
    }
    onEdit = (id) => {
        console.log("id", id)
        // this.props.editActions.getUser(id);
        this.props.history.push(`./userrole/edit/${id}`)
    }
    invite = (id) => {
        this.props.history.push(`./userrole`)
    }

    render() {
        const { statemembers } = this.state;
        const { isLoading } = this.props
        return (
            <>
                {isLoading ? <ZLoader /> : null}
                <div className="members-container container">
                    <Row>
                        <Col className="top-button text-right">
                            <Button onClick={() => this.invite()}>Invite</Button>
                        </Col>
                    </Row>
                    <Card className="header-card">
                        <Row className="row-class">
                            <Col md="8" xs="8" className="text-left">
                                <p className="title">Name</p>
                            </Col>
                            <Col md="2" xs="2" className="text-center">
                                <p className="title">EDIT</p>
                            </Col>
                        </Row>
                    </Card>

                    {statemembers.length ? statemembers.map(member => {
                        if (member.user_id == null) return
                        return (
                            <MembersMarkup
                                item={member}
                                isLoading={this.props.isLoading}
                                loading={this.state.loading}
                                onEdit={this.onEdit}
                            />
                        )
                    }) : ''
                    }
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.Auth.user,
        members: state.members.Members,
        isLoading: state.members.isLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
        editActions: bindActionCreators(editActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Members));