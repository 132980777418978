import React from 'react';
import Profile from './containers/ProfileDetails'

export default [
    {
        path: '/profile/view',
        exact: true,
        component: () => <Profile />
    },
    {
        path: '/profile/view/:id',
        exact: true,
        component: () => <Profile />
    },
    {
        path: '/profile/edit/:id',
        exact: true,
        component: () => <Profile />
    },
];