import React from 'react';
import { Container } from 'react-bootstrap';
import './DashboardMarkup.scss';
import { Row, Col, Button, Image, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chart from 'react-apexcharts'
import { ZLoader } from '../../../shared/loader/ZLoader'
import AverageZScore from './AverageZScore'
const DashboardMarkup = (props) => {
     const { options, series, piOptions, piSeries, allLeads, isLoading, averageScore, averageByValue, onChangeInput, yopmailVal } = props
     const sorting = () => {

     }
     console.log("yopmailValyopmailVal", yopmailVal)
     return (
          <div className="dashboard-container">
               <Container>
                    {isLoading ? <ZLoader /> : null}
                    <div className="card-container">
                         {/* <Row>
                              <Col className="check-box">
                         <Form.Group >
                            <Form.Check
                                name={'yopmailVal'}
                                type="checkbox"
                                label="yopmail"
                                value={yopmailVal}
                                checked={yopmailVal && yopmailVal ? true: false}
                                onChange={(event) => onChangeInput(event.target.name, event.target.checked)}

                            />
                        </Form.Group>
                        </Col>
                         </Row> */}
                         <Row>
                              <Col>

                                   <div className="cards">
                                        <Row>
                                             <Col md="4">
                                                  <FontAwesomeIcon className={"icon"} icon={['fas', 'user-graduate']} />

                                             </Col>
                                             <Col>
                                                  <h3>{allLeads && allLeads.students ? allLeads.students.total_count : 1}</h3>
                                                  <p>Total Students</p>
                                             </Col>
                                        </Row>
                                   </div>

                              </Col>
                              <Col>
                                   {/* <div className="cards">
                                        <FontAwesomeIcon className={"icon"} icon={['fas', 'calendar-week']} />
                                        <h3>{allLeads && allLeads.students ? allLeads.students.week_count : 1}</h3>
                                        <p>This Week</p>
                                   </div> */}
                                   <div className="cards week">
                                        <Row>
                                             <Col md="4">
                                                  <FontAwesomeIcon className={"icon"} icon={['fas', 'calendar-week']} />

                                             </Col>
                                             <Col>

                                                  <h3>{allLeads && allLeads.students ? allLeads.students.week_count : 1}</h3>
                                                  <p>This Week</p>
                                             </Col>
                                        </Row>
                                   </div>
                              </Col>
                              <Col>
                                   {/* <div className="cards">
                                        <FontAwesomeIcon className={"icon"} icon={['fas', 'external-link-alt']} />
                                        <h3>{allLeads && allLeads.students ? allLeads.students.hot_lead_count : 1}</h3>
                                        <p>Hot Leads</p>
                                   </div> */}
                                   <div className="cards hot">
                                        <Row>
                                             <Col md="4">
                                                  <FontAwesomeIcon className={"icon"} icon={['fas', 'external-link-alt']} />

                                             </Col>
                                             <Col>
                                                  <h3>{allLeads && allLeads.students ? allLeads.students.avg_z_score : 1}</h3>
                                                  <p>Avg Z-score</p>
                                             </Col>
                                        </Row>
                                   </div>
                              </Col>
                              <Col>
                                   {/* <div className="cards">
                                        <FontAwesomeIcon className={"icon"} icon={['fas', 'calendar-day']} />
                                        <h3>{allLeads && allLeads.students ? allLeads.students.today_count : 1}</h3>
                                        <p>Today's Students</p>
                                   </div> */}
                                   <div className="cards today">
                                        <Row>
                                             <Col md="4">
                                                  <FontAwesomeIcon className={"icon"} icon={['fas', 'calendar-day']} />

                                             </Col>
                                             <Col>
                                                  <h3>{allLeads && allLeads.students ? allLeads.students.today_count : 1}</h3>
                                                  <p>Today's Students</p>
                                             </Col>
                                        </Row>
                                   </div>
                              </Col>
                         </Row>
                    </div>
                    <div className="charts-containers">
                         <Row>
                              <Col md="12">
                                   <div className="chart-one" >
                                        <div id="chart" >
                                             <Chart options={options} series={series} type="area" height={350} />
                                        </div>
                                        <p style={{ textAlign: 'center' }}>Dates  <FontAwesomeIcon className={"icon"} icon={['fas', 'long-arrow-alt-right']} /></p>
                                   </div>
                              </Col>
                              {/* <Col>
                                   <div className="chart-two">

                                        <div id="chart">
                                             <Chart options={piOptions} series={piSeries} type="pie" width={300} />
                                        </div>
                                   </div>
                                   <div className="chart-two" style={{marginTop:9}}>

                                        <div id="chart">
                                             <Chart options={piOptions} series={piSeries} type="donut" width={300} />
                                        </div>
                                   </div>
                              </Col> */}
                         </Row>
                    </div>
                    <div className="charts-containers">
                         <Row>
                              <Col md="4">
                                   <Form.Group >
                                        {/* <Form.Label>Average Z-Score by: </Form.Label> */}
                                        <Form.Control
                                             className="select-options"
                                             name={"averageByValue"}
                                             as="select"
                                             placeholder="Status"
                                          value={averageByValue ? averageByValue : null}
                                          onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                                        >
                                             <option value="source">Source</option>
                                             <option value="course">Course</option>
                                             <option value="level">Level</option>
                                             <option value="region">Region</option>

                                        </Form.Control>
                                   </Form.Group>
                              </Col>
                         </Row>
                         <Row>
                              <Col md="12">
                                   <div className="chart-avg">
                                        <AverageZScore
                                             isLoading={isLoading}
                                             source_name={averageScore && averageScore ? averageScore.options : []}
                                             source_data={averageScore && averageScore ? averageScore.data : []}
                                             averageByValue={averageByValue}
                                        />
                                   </div>
                              </Col>
                         </Row>
                    </div>

               </Container>
          </div>
     )
}

export default DashboardMarkup;