import React, { Component } from "react";
import { Container, Row, Col, Form, Accordion, Card } from "react-bootstrap";
import { FormGroup, InputGroup, Button } from "@blueprintjs/core";
import { DateRangeInput } from "@blueprintjs/datetime";
import { ZLoader } from "../../../shared/loader/ZLoader";
import Times from "./time";
import moment from "moment";
import _ from "lodash";
import "./EventMarkup.scss";
const EventMarkup = (props) => {
  const {
    onChange,
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    onSave,
    onChangeSelect,
    myState,
    handleRangeChange,
    start_date,
    end_date,
    isLoading,
    duration,
    stateSchedule,
  } = props;

  const DayData = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const handleData = (data, type) => {
    // console.log("inside",stateSchedule);

    if (stateSchedule && stateSchedule.length) {
      // console.log("insihghgde");

      let currentDay = _.find(stateSchedule, { day: data });
      if (currentDay && currentDay.from && currentDay.to) {
        if (type == "to") {
          return currentDay.to;
        }
        if (type == "from") {
          return currentDay.from;
        }
      }
    }
  };
  return (
    <Container>
      {isLoading ? <ZLoader /> : null}

      <hr />
      <Row>
        <Col>
          <Accordion defaultActiveKey="1">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                When can people book this event?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  {/* <Row>
                                        <Col>
                                            <Form.Label style={{ width: '99%' }}>Date Range</Form.Label>

                                            <DateRangeInput
                                                  formatDate={date => moment(date).format('DD/MM/YYYY')}
                                                  onChange={(event)=>handleRangeChange(event)}
                                                  parseDate={str => new Date(str)}
                                                  defaultValue={[moment().add(-1, "weeks").toDate(),moment().add(0, "days").toDate()]}
                                                  value={[start_date , end_date]}

                                            />
                                        </Col>
                                    </Row> */}
                  <Row>
                    <Col>
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label style={{ marginTop: "1rem" }}>
                          Duration
                        </Form.Label>
                        <Form.Control
                          value={duration ? duration : 15}
                          as="select"
                          onChange={(e) => onChange("duration", e.target.value)}
                        >
                          <option value={15}>15 min</option>
                          <option value={30}>30 min</option>
                          <option value={45}>45 min</option>
                          <option value={60}>60 min</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>
                          How do you want to offer your availability for this
                          event type?
                        </Form.Label>
                        {DayData.map((data) => {
                          return (
                            <>
                              <Row style={{ marginTop: 8 }}>
                                <Col style={{ alignSelf: "center" }}>
                                  <Form.Check
                                    value={myState[data]}
                                    checked={myState[data]}
                                    type="checkbox"
                                    label={data}
                                    onChange={(e) =>
                                      onChange(data, e.target.checked)
                                    }
                                  />
                                </Col>
                                {myState[data] ? (
                                  <>
                                    {" "}
                                    <Col style={{ alignSelf: "center" }}>
                                      <Form.Control
                                        value={handleData(data, "from")}
                                        as="select"
                                        onChange={(e) =>
                                          onChangeSelect(
                                            "from",
                                            e.target.value,
                                            data
                                          )
                                        }
                                      >
                                        <Times />
                                      </Form.Control>
                                    </Col>
                                    <Col style={{ alignSelf: "center" }}>
                                      <Form.Control
                                        value={handleData(data, "to")}
                                        as="select"
                                        onChange={(e) =>
                                          onChangeSelect(
                                            "to",
                                            e.target.value,
                                            data
                                          )
                                        }
                                      >
                                        <Times />
                                      </Form.Control>
                                    </Col>
                                  </>
                                ) : (
                                  ""
                                )}
                                <Col md="2"></Col>
                              </Row>
                              <hr />
                            </>
                          );
                        })}
                      </Form.Group>
                    </Col>
                    <Col></Col>
                  </Row>
                  <Button intent="success" onClick={onSave}>Save</Button>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};
export default EventMarkup;
