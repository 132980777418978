import React, { Component } from 'react'

import Card from './Card'
import './QuestionsContainer.scss'

class QuestionsContainer extends Component {
    constructor(props){
        super(props)
    }
    render() {
        const { props } = this;
        return(
            <ul className="question-container-list">
             { props.selected.map((question) => {
                                    return <li className="view-container">
                                                 {question.question}
                                            </li>
                                }) }
            </ul>
     )
    }
}
export default QuestionsContainer;