import React from 'react';
import Interview from './containers/Interview';
import Settings from './containers/Settings';
import Question from './containers/Question';
import Interviews from './containers/Interviews';
import Questions from './containers/Questions';
import InterviewSuccess from './containers/InterviewFinish'

export default [
    {
        path: '/interviews',
        exact: true,
        component: () => <Interviews />
    },
    {
        path: '/interview/settings',
        exact: true,
        component: () => <Settings />
    },
    {
        path: '/interview/settings/view/:id',
        exact: true,
        component: () => <Settings />
    },
    {
        path: '/interview/settings/edit/:id',
        exact: true,
        component: () => <Settings />
    },
    {
        path: '/questions',
        exact: true,
        component: () => <Questions />   
    },
    {
        path: '/questions/settings',
        exact: true,
        component: () => <Question />
    },
    {
        path: '/questions/settings/view/:id',
        exact: true,
        component: () => <Question />
    },
    {
        path: '/questions/settings/edit/:id',
        exact: true,
        component: () => <Question />
    },
    {
        path: '/interview/check/:id',
        exact: true,
        component: () => <Interview />
    },
    {
        path: '/complete',
        exact: true,
        component: () => <InterviewSuccess />
    },
];