import React from 'react';
import CreateUser from './containers/CreateUser';

export default [
    {
        path: '/createuser',
        exact: true,
        component: () => <CreateUser />
    },
    {
        path: '/edituser/:vid',
        exact: true,
        component: () => <CreateUser />
    }
];