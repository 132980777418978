import React from 'react';
import CustomerReport from './containers/CustomerReport';
import CallingReport from './containers/CallingReport';

export default [
    {
        path: '/customerreports',
        exact: true,
        component: () => <CustomerReport />
    },
    {
        path: '/callingreports',
        exact: true,
        component: () => <CallingReport />
    },
];