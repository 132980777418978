import React, { Component } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { DateInput } from "@blueprintjs/datetime";
import { RadioGroup, Radio } from "@blueprintjs/core";
import moment from 'moment';
import {
    Label
} from "@blueprintjs/core";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import action from '../../../modules/actions'
import { Toast } from "../../../../../shared/toast/Toast";
import './VisaAppointment.scss';
class VisaAppointment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visaAppoinmentDate: new Date(),
            priority: '',
            id: '',
            errors:{}
        }
    }
    componentDidMount() {
        const { applicationStatusId } = this.props;
        // if (applicationStatusId && applicationStatusId) {
        //     this.props.action.getAppVisaAppointment(applicationStatusId)
        // }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.visaAppointment && props.visaAppointment.length) {
            if (props.visaAppointment[0].id != state.id) {
                return {
                    visaAppoinmentDate: props.visaAppointment[0].visa_appoinment_date ? new Date(props.visaAppointment[0].visa_appoinment_date) : new Date(),
                    priority: props.visaAppointment[0].priority,
                    id: props.visaAppointment[0].id
                };
            }
        }
        // else {
        //     console.log("getDerivedStateFromProps")
        //     return {
        //         visaAppoinmentDate: new Date(),
        //         priority: '',
        //         id: '',
        //         errors:{}
        //     }
        // }
    }
    clearForm = () => {
        this.setState({
            visaAppoinmentDate: new Date(),
            priority: '',
            id: '',
            errors:{}
        })
        this.props.setClearForm()
    }
    isvalid = () => {
        const { visaAppoinmentDate, priority } = this.state;
        let errors = {};
        if(visaAppoinmentDate == ''){
            errors.visaAppoinmentDate = true;
            this.setState ({ errors });
            return false;
          }
          if(priority == ''){
            errors.priority = true;
            this.setState ({ errors });
            return false;
          }
          return true;
    };
    onSubmit = () => {
        const { visaAppoinmentDate, priority } = this.state
        const { match: { params }, userProfile, onCloseModal, applicationStatusId } = this.props;
        if(this.isvalid()){

        const data = {
            'student_id': params.id,
            'user_id': userProfile.organization.user_id,
            'organization_id': userProfile.organization.organization_id,
            'visa_appoinment_date': visaAppoinmentDate,
            'priority': priority,
            'application_id': applicationStatusId
        }
        const handlesuccessfull = () => {
            Toast.show({
                message: 'Save successfully',
                timeout: 4000,
                intent: 'success'
            });
            onCloseModal()
            this.setState({
                visaAppoinmentDate: new Date(),
                priority: '',
                id: ''
            })
        };
        const handleFailure = () => {
            Toast.show({
                message: 'Save unsuccessfully',
                timeout: 4000,
                intent: 'danger'
            });
            onCloseModal()
            this.setState({
                visaAppoinmentDate: new Date(),
                priority: '',
                id: ''
            })
        }
        this.props.action.createApplicationStatus(data, handlesuccessfull, handleFailure, 'visaappointment')
    }
    }

    onChangeInput = (name, value) => {
        console.log("name>>>>", name)
        console.log("value>>>>", value)
        this.setState({
            [name]: value, errors:{}
        })
    }
    render() {
        const { visaAppoinmentDate, priority ,errors} = this.state;
        const { clearModel } = this.props
        if (!clearModel) {
            this.clearForm()
        }
        console.log("visaAppoinmentDate>>>>", visaAppoinmentDate)
        console.log("priority>>>>", priority)
        return (
            <div className="visa-appointment">

                <Col>
                    <Label className="appointment-date" htmlFor="input-b">Appointment date *
                <DateInput
                            formatDate={date => moment(date).format("DD/MM/YYYY")}
                            onChange={(date) => this.onChangeInput("visaAppoinmentDate", date)}
                            parseDate={str => new Date(str)}
                            placeholder={"DD/MM/YYYY"}
                            // defaultValue={new Date()}
                            // maxDate={new Date('1/1/2030')}
                            value={visaAppoinmentDate}
                        />
                    </Label>
                    {errors.visaAppoinmentDate ? <span className="errors">Please Enter Appointment Date </span> : ''}
                </Col>
                <Col>
                <Label className="appointment-date" htmlFor="input-b">Visa Priority *</Label>
                    <Form.Check type="radio" checked={priority === 'normal' ? true : false} label="Normal" id="radio1" name="priority" value={priority} onChange={(e) => this.onChangeInput("priority", "normal")} />
                    <Form.Check type="radio" checked={priority === 'priority' ? true : false} label="Priority" id="radio1" name="priority" value={priority} onChange={(e) => this.onChangeInput("priority", "priority")} />
                    <Form.Check type="radio" checked={priority === 'super' ? true : false} label="Super priority" id="radio1" name="priority" value={priority} onChange={(e) => this.onChangeInput("priority", "super")} />

                </Col>
                {errors.priority ? <span className="errors">Please Set the priority  </span> : ''}

                <Col className="button-save">
                    <Button className="save-offer" onClick={this.onSubmit}>Save</Button>
                </Col>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        visaAppointment: state.students.visaAppointment
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators(action, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VisaAppointment));
