import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import actions from '../modules/actions';
import authActions from '../../Auth/modules/actions'
import wizardactions from '../../Wizards/modules/actions'
import ConnectMarkup from "../components/ConnectMarkup"

class Connect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: "",
            request: "",
            iswizardComplete: false
        };
    }
    static getDerivedStateFromProps(props, state) {
        const { iswizardComplete } = state;
        const { userStep } = props;
        if(userStep)
        {
            if(userStep.step == "Finished")
            {
                return({iswizardComplete:true})
            }
        }
    }

    handleUpdateSuccess = (data) => {
        const { request , iswizardComplete} = this.state;
        iswizardComplete?this.props.history.push('/integration/mapping'):this.props.history.push('/ziltersteps')
    }

    handleUpdateFailure = (data) => {

    }

    handleSuccess = (data, app_name) => {
        const request = {
            app_name,
            app_access_token: data.access_token,
            app_refresh_token: data.refresh_token

        }
        this.setState({ request }, this.props.authActions.update(this.handleUpdateSuccess, this.handleUpdateFailure, request));
    }

    handleFailure = (data) => {

    }


    componentDidMount() {
        const { location: { search },user  } = this.props;
        if (search) {
            const query = new URLSearchParams(search);
            const app = query.get('app');
            const code = query.get('code');
            this.props.actions.getOauthToken(app, code, this.handleSuccess, this.handleFailure);
        }
          if(user)
         {
            this.props.wizardactions.getStep(user.organization.organization_id)
         }
       
    }

    render() {
        return (
            <ConnectMarkup />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.Auth.user,
        interview: state.interview,
        userStep : state.wizard.Step
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
        authActions: bindActionCreators(authActions, dispatch),
        wizardactions:bindActionCreators(wizardactions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Connect));