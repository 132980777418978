import {
    CREATE_CONTACT_LOADING,
    CREATE_CONTACT_SUCCESS,
    CREATE_CONTACT_ERROR,
    UPDATE_CONTACT_SUCCESS,
    UPDATE_CONTACT_ERROR,
    GET_CONTACT_SUCCESS,
    GET_CONTACT_ERROR
} from './type';

const INITIAL_STATE = {
    isLoading: false,
    error: '',
    contactDetails: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_CONTACT_LOADING:
            return { ...state, isLoading: true };
        case CREATE_CONTACT_SUCCESS:
            return { ...state, isLoading: false, error: '' };
        case CREATE_CONTACT_ERROR:
            return { ...state, isLoading: false, error: action.payload };
        case UPDATE_CONTACT_SUCCESS:
            return { ...state, isLoading: false, error: '' };
        case UPDATE_CONTACT_ERROR:
            return { ...state, isLoading: false, error: action.payload };
        case GET_CONTACT_SUCCESS:
            return { ...state, isLoading: false, error: '', contactDetails: action.payload };
        case GET_CONTACT_ERROR:
            return { ...state, isLoading: false, error: action.payload, contactDetails: {} };
        default:
            return state;
    }
};