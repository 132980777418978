import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import actions from '../modules/actions';
import _ from 'lodash';
import { Toast } from '../../../shared';
import QuestionListMarkup from "../components/QuestionListMarkup";
import InterviewButtonFormatter from "../../../shared/table/InterviewButtonFormatter";
import CourseTextFormatter from "../../../shared/table/CourseTextFormatter";
import './Questions.scss' ;
import Denied from '../../../shared/denied/Denied';

class Questions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageIndex:1,
            search:'',
            viewAccess:false,
            userRoleAccess:[],
            columns: [
                {
                    dataField: '',
                    text: 'Question No ',
                    headerClasses: 'table-head',
                    editable:false,
                    className:"alignment"
                },
                {
                    dataField: 'question',
                    text: 'Question name',
                    headerClasses: 'table-head',
                    formatter: CourseTextFormatter,
                    formatExtraData: {
                        onChange: this.onInputChange
                    },
                    editable:false
                },
                {
                    dataField: 'id',
                    text: 'Actions',
                    headerClasses: 'table-head',
                    formatter: InterviewButtonFormatter,
                    editable: false,
                    formatExtraData: {
                        userRoleAccess:[],
                        onView: this.onView,
                        onEdit: this.onEdit,
                        onClickDelete:this.onClickDelete
                        
                    },
                }],
        }
    }
    componentDidMount() {
       const { pageIndex, search } = this.state
        this.props.actions.getAllOrganizationQuestions(pageIndex, search);
    }

    static getDerivedStateFromProps(props, state) {
		const{user}=props 
        const access = user.organization.access
		let finalData = access?JSON.parse(access):[{"operations": [1, 1, 1, 1], "name": "MAPPING"}, {"operations": [1, 1, 1, 1], "name": "QUESION"}, {"operations": [1, 1, 1, 1], "name": "INTERVIEW"}, {"operations": [1, 1, 1, 1], "name": "COURSE"}, {"operations": [1, 1, 1, 1], "name": "REPORT"}, {"operations": [1, 1, 1, 1], "name": "STUDENTS"}]
        if(finalData){
            const {columns} =state;
            let data = columns[2]
			data.formatExtraData.userRoleAccess = finalData
            const col = columns.slice(0,2)
            col.push(data)
            let viewAccess = false
            if(finalData[1].operations.includes(1)){
                viewAccess=true
            }
			return{
                userRoleAccess:finalData,
                columns,
                viewAccess:viewAccess
			}
		}
    }
    onInputChange = (e) => {
        this.setState({
            [`${e.target.name}Val`]: e.target.value
        });
    }

    onCreate = (id) => {
        this.props.wizard ? this.props.gotoStep("Question"):this.props.history.push(`/questions/settings`);
   
       // this.props.wizard ? this.props.setstep(1):this.props.history.push(`/questions/settings`);
    }

    onView = (id) => {
        this.props.actions.getQuestion(id);
        this.props.history.push(`/questions/settings/view/${id}`);
    }
    onEdit = (id) => {
        this.props.history.push(`/questions/settings/edit/${id}`);
    }
    
    onClickDelete = (id, index) =>{
        let { questions:{ question } } = this.props;
		question.splice(index, 1);
		this.setState({
			question
        })
        const handleSuccess = (body) => {
            console.log("body",body)
            Toast.show({
                message: "Question Delete Successfully",
                timeout: 4000,
                intent: 'success'
            })
            let pageIndex =1;
            this.props.actions.getAllOrganizationQuestions(pageIndex);
        };

        this.props.actions.deleteQuestion(handleSuccess,id);    
    }

    itemRender = (current, type, element) => {
        if (type === 'page') {

            return <a>{current}</a>;
        }
        return element;
    };

    onPageChange = (pageIndex) => {
        this.setState({
            pageIndex: pageIndex
        });
		this.props.actions.getAllOrganizationQuestions(pageIndex);
    }
    

    render() {
        const {viewAccess, columns, pageIndex,userRoleAccess } = this.state;
        const {pagination : {questions_count, question_pages},wizard} = this.props
        return (
        
        viewAccess?<QuestionListMarkup
            questions={this.props.questions}
            userRoleAccess={userRoleAccess[1].operations}
            columns={columns}
            onCreate={this.onCreate}
            questions_count={questions_count}
            question_pages={question_pages}
            pageIndex={pageIndex}
            itemRender={this.itemRender}
            onPageChange={this.onPageChange}
            isLoading={this.props.isLoading}
            history={this.props.history}
            setstep={this.props.setstep}
            gotoStep ={ this.props.gotoStep}
            wizard = {wizard}

        />:<Denied/>);
    }
}

const mapStateToProps = (state) => {
    return {
        userq: state.Auth.user.token,
        user: state.Auth.user,
        questions: state.interview.questionList,
        question: state.interview.getQuestion,
        pagination: state.interview.paginationQuestion,
        isLoading: state.interview.isLoading
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Questions));