import React from 'react';
import { Button } from "react-bootstrap";
import './model.scss';
const Modal = ({ modalTitle, isModalOpen, onCloseModal, children, showLargeModal,id, width,showDocumentModalClass }) => {
	const style = {};
	if(width) {
		style.width = width;
		style.maxWidth = width;
	}
    return (
        <div className={`${isModalOpen ? "show " : ""}modal fade`} id={id} tabIndex={isModalOpen ? "0" : "-1"} role="dialog" aria-hidden={!isModalOpen}>
            <div style={style} className={`modal-dialog modal-dialog-centered ${showLargeModal ? 'modal-lg' : ''}`} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="modal-title" id="exampleModalLongTitle">{modalTitle}</h6>
                        <Button className="close" onClick={onCloseModal} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&#x2716;</span>
                        </Button>
                    </div>
                    <div className={showDocumentModalClass?"modal-body-document":"modal-body"}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Modal;