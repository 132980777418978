import React from 'react';
import { Row, Col,Button } from 'react-bootstrap';
import InterviewDemo from '../../Interview/containers/Interview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./StudentInterview.scss"

const StudentInterview = (props) => {

 
    
    return (
        <div className="container result-container">
                <Row>
                <Col md="6"  lg="6" sm="12" xs="12">
                    <div className="video">
                    <InterviewDemo
                     
                    />

                    </div>
                </Col>
                <Col md="6"  lg="6" sm="12" xs="12" >
                       <h5>Question </h5>
                           <p>dfjkdfjknkjnksdjnsjknfksdnfkndfksdnkfnsdkfnsdjk</p>
                </Col>
                </Row>
                <Row className="abc">
                    <div className="col-md-6">
                    <Row className="icons-container">
                        <Col md="3" lg="3" sm="3" xs="3"><span><FontAwesomeIcon className="icons" icon={['fas', 'microphone']} />  <audio/> </span></Col>
                        <Col md="6" lg="6" sm="6" xs="6"><span><FontAwesomeIcon className="icons" icon={['fas', 'camera']} /> web camera</span></Col>
                        <Col md="3" lg="3" sm="3" xs="3"> <span><FontAwesomeIcon className="icons" icon={['fas', 'question-circle']} /> Help</span></Col>
                    </Row>
                    </div>
                    <div className="col-md-6">
                        <Row classname="time-container">
                            <Col md="1"></Col>
                            <Col md="5" lg="5" sm="12" xs="12">
                    <h3>07:00</h3></Col>
                    <Col md="5" lg="5" sm="12" xs="12"  className="btn-section text-right" ><Button className="next">Next Question</Button></Col></Row>
                    </div>
                </Row>
                </div>
    )
}

export default StudentInterview;