
import Constants from './Constants';
import { APISAAS_DOMAIN, API_DOMAIN, ORG_ID } from '../../../env';
import _ from 'lodash';

const createQueryLink = (filterObject) => {
    let query = '';
    _.map(filterObject, (queryParamVal, queryParamName) => {
        if (Array.isArray(queryParamVal)) {
            const x = queryParamVal.map((item) => (query += `${queryParamName}=${item}&`));
        } else {
            query += `${queryParamName}=${queryParamVal}&`;
        }
    });
    return query;
};

const getAllStudents = (page, filterObject, search, status, assigned) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token, user: { is_counselor }, organization } } } = getState();
        dispatch({
            type: Constants.GET_ALL_STUDENTS_REQUEST
        });
        filterObject["is_counselor"] = is_counselor;
        const searchQuery = createQueryLink(filterObject);

        return fetch(`${APISAAS_DOMAIN}api/student/userstudent/${is_counselor ? organization.user_id : organization.organization_id}/${page}?${searchQuery}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_ALL_STUDENTS_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: Constants.GET_ALL_STUDENTS_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}

const getAllApplications = () => {
    return (dispatch, getState) => {
        const { Auth: { user: { token, user, organization } } } = getState();
        const user_id = user.id
        dispatch({
            type: Constants.GET_ALL_APPLICATION_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/application/userstudentinterview/${organization.organization_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_ALL_APPLICATION_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: Constants.GET_ALL_APPLICATION_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}

const getStudentApplicationIntake = (id) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_STUDENT_APPLICATION_INTAKE_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/applicationintake/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_STUDENT_APPLICATION_INTAKE_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: Constants.GET_STUDENT_APPLICATION_INTAKE_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}

const getStudent = (id) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_STUDENT_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/student/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_STUDENT_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: Constants.GET_STUDENT_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}


const getCourse = (id) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_COURSE_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/course/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_COURSE_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: Constants.GET_COURSE_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}
const getReport = (id, handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_STUDENT_REPORT_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/student/report/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_STUDENT_REPORT_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body.error);
                } else {
                    dispatch({
                        type: Constants.GET_STUDENT_REPORT_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const updateReport = (handleSuccess, id, data) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.UPDATE_REPORT_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/application/update/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.UPDATE_REPORT_FAIL,
                        payload: body.errors,
                    });
                } else {
                    dispatch({
                        type: Constants.UPDATE_REPORT_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body)
                }
            });
    }
}

const postInterview = (handleSuccess, handleFailure, data) => {
    return (dispatch, getState) => {
        const { Auth: { user: { user, organization } } } = getState();
        data.user_id = user.id
        data.organization_id = organization.organization_id
        dispatch({
            type: Constants.POST_INTERVIEW_NEW_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/interviewdetails/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_INTERVIEW_NEW_REQUEST_FAIL,
                        payload: body.error
                    });
                    handleFailure();
                } else {
                    dispatch({
                        type: Constants.POST_INTERVIEW_NEW_REQUEST_SUCCESS,
                        payload: body
                    });
                    handleSuccess();
                }
            });
    }
}


const upload = (handleSuccess, handleFailure, formData) => {
    return function (dispatch) {
        dispatch({
            type: Constants.SAAS_UPLOAD_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/student/creates/csv/`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json()
                .then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.SAAS_UPLOAD_REQUEST_FAIL,
                        payload: body.error
                    });
                    handleFailure()
                } else {
                    dispatch({
                        type: Constants.SAAS_UPLOAD_REQUEST_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body)
                }
            });
    }
}

const sendInterviewLink = (data, handleSuccess, handleFailure) => {

    return function (dispatch, getState) {
        const { Auth: { user: { organization } } } = getState();
        const { Auth: { user: { token } } } = getState();

        const organization_name = organization.organization_name
        data.organization = organization_name
        dispatch({
            type: Constants.SAAS_INTERVIEW_LINK_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/interviewdetails/sendinterview/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json()
                .then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.SAAS_INTERVIEW_LINK_REQUEST_FAIL,
                        payload: body.error
                    });
                    handleFailure(body)
                } else {
                    dispatch({
                        type: Constants.SAAS_INTERVIEW_LINK_REQUEST_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body)
                }
            });
    }
}
const sendOfferLetter = (data, handleSuccess, handleFailure) => {

    return function (dispatch, getState) {
        const { Auth: { user: { organization } } } = getState();
        const { Auth: { user: { token } } } = getState();

        const organization_name = organization.organization_name
        data.organization = organization_name
        dispatch({
            type: Constants.SAAS_OFFER_LETTER_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/application/sendoffer/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json()
                .then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.SAAS_OFFER_LETTER_REQUEST_FAIL,
                        payload: body.error
                    });
                    handleFailure(body)
                } else {
                    dispatch({
                        type: Constants.SAAS_OFFER_LETTER_REQUEST_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body)
                }
            });
    }
}
const updateStudentReport = (handleSuccess, id, data) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        const { Auth: { user: { user } } } = getState();
        const { Auth: { user: { organization } } } = getState();
        data.organization_id = organization.organization_id

        data.user_id = user.id
        dispatch({
            type: Constants.UPDATE_STUDENT_REPORT_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/student/update/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.UPDATE_STUDENT_REPORT_FAIL,
                        payload: body.errors,
                    });
                } else {
                    dispatch({
                        type: Constants.UPDATE_STUDENT_REPORT_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body)
                }
            });
    }
}

const sendEmail = (data, handleSuccess, handleError) => {
    return function (dispatch) {
        dispatch({
            type: Constants.POST_SENDEMAIL_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/logs/emailcreate/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_SENDEMAIL_FAIL,
                        payload: body.error
                    });
                    handleError();
                } else {
                    dispatch({
                        type: Constants.POST_SENDEMAIL_SUCCESS,
                        payload: body
                    });
                    handleSuccess();
                }
            });
    }
}


const createNoteLog = (data, handleSuccess, handleError) => {
    return function (dispatch) {
        dispatch({
            type: Constants.POST_LOG_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/logs/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_LOG_FAIL,
                        payload: body.error
                    });
                    handleError();
                } else {
                    dispatch({
                        type: Constants.POST_LOG_SUCCESS,
                        payload: body
                    });
                    handleSuccess();
                }
            });
    }
}

const createTaskLog = (data, handleSuccess, handleError) => {
    return function (dispatch) {
        dispatch({
            type: Constants.POST_LOG_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/logs/taskcreate/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_LOG_FAIL,
                        payload: body.error
                    });
                    handleError();
                } else {
                    dispatch({
                        type: Constants.POST_LOG_SUCCESS,
                        payload: body
                    });
                    handleSuccess();
                }
            });
    }
}

const getLogs = (id, pageIndex, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_LOG_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/logs/studentlogs/${id}/${pageIndex}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_LOG_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_LOG_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}

const getStudentAppliedCourses = (id, pageIndex, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_APPLIED_COURSES_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/studentcourses/appliedcourses/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_APPLIED_COURSES_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_APPLIED_COURSES_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}

const updateTaskLog = (id, data, handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        const { Auth: { user: { user } } } = getState();
        const { Auth: { user: { organization } } } = getState();
        data.organization_id = organization.organization_id
        data.user_id = user.id
        dispatch({
            type: Constants.UPDATE_LOGS_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/logs/update/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.UPDATE_LOGS_FAIL,
                        payload: body.errors,
                    });
                    handleFailure(body)
                } else {
                    dispatch({
                        type: Constants.UPDATE_LOGS_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body)
                }
            });
    }
}

const updateAppliction = (id, data, handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        const { Auth: { user: { user } } } = getState();
        const { Auth: { user: { organization } } } = getState();
        data.organization_id = organization.organization_id

        // data.user_id = user.id     
        dispatch({
            type: Constants.UPDATE_LOGS_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/studentcourses/update/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.UPDATE_LOGS_FAIL,
                        payload: body.errors,
                    });
                    handleFailure(body)
                } else {
                    dispatch({
                        type: Constants.UPDATE_LOGS_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body)
                }
            });
    }
}

const createStatus = (data, handleSuccess, handleError) => {
    return function (dispatch) {
        dispatch({
            type: Constants.POST_SENDEMAIL_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/applicationstatus/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_SENDEMAIL_FAIL,
                        payload: body.error
                    });
                    handleError();
                } else {
                    dispatch({
                        type: Constants.POST_SENDEMAIL_SUCCESS,
                        payload: body
                    });
                    handleSuccess();
                }
            });
    }
}

const getApplicationStatus = (id, pageIndex, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_APPLICATION_STATUS_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/applicationstatus/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_APPLICATION_STATUS_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_APPLICATION_STATUS_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}

// updateAssigned
const updateAssigned = (id, data, handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();

        dispatch({
            type: Constants.UPDATE_ASSIGNED_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/student/studentassigned/${id}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.UPDATE_ASSIGNED_FAIL,
                        payload: body.errors,
                    });
                    handleFailure(body)
                } else {
                    dispatch({
                        type: Constants.UPDATE_ASSIGNED_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body)
                }
            });
    }
}
const updateStudentStaff = (id, data, handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();

        dispatch({
            type: Constants.UPDATE_SUPPORT_STAFF_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/student/supportstaffassigned/${id}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.UPDATE_SUPPORT_STAFF_FAIL,
                        payload: body.errors,
                    });
                    handleFailure(body)
                } else {
                    dispatch({
                        type: Constants.UPDATE_SUPPORT_STAFF_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body)
                }
            });
    }
}
const updateStudent = (data, handleSuccess, handleFailure) => {
    let id = data.id
    return function (dispatch, getState) {
        const { Auth: { user: { organization } } } = getState();
        const { Auth: { user: { token } } } = getState();

        dispatch({
            type: Constants.SAAS_OFFER_LETTER_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/student/profileupdate/${id}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json()
                .then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.SAAS_OFFER_LETTER_REQUEST_FAIL,
                        payload: body.error
                    });
                    handleFailure(body)
                } else {
                    dispatch({
                        type: Constants.SAAS_OFFER_LETTER_REQUEST_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body)
                }
            });
    }
}

const createStudentAcademics = (data, handleSuccess, handleError) => function (dispatch) {
    dispatch({
        type: Constants.POST_ACADEMIC_REQUEST,
    });
    return fetch(`${APISAAS_DOMAIN}api/academic/create/`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.POST_ACADEMIC_FAIL,
                    payload: body.error,
                });
                handleError();
            } else {
                dispatch({
                    type: Constants.POST_ACADEMIC_SUCCESS,
                    payload: body,
                });
                handleSuccess(body);
            }
        });
};

const createStudentExperience = (data, handleSuccess, handleError) => function (dispatch) {
    dispatch({
        type: Constants.POST_ACADEMIC_REQUEST,
    });
    return fetch(`${APISAAS_DOMAIN}api/experience/create/`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.POST_ACADEMIC_FAIL,
                    payload: body.error,
                });
                handleError();
            } else {
                dispatch({
                    type: Constants.POST_ACADEMIC_SUCCESS,
                    payload: body,
                });
                handleSuccess(body);
            }
        });
};
const createStudentLanguageEfficiency = (data, handleSuccess, handleError) => function (dispatch) {
    dispatch({
        type: Constants.POST_LOG_REQUEST,
    });
    return fetch(`${APISAAS_DOMAIN}api/languageefficiency/create/`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.POST_LOG_FAIL,
                    payload: body.error,
                });
                handleError();
            } else {
                dispatch({
                    type: Constants.POST_LOG_SUCCESS,
                    payload: body,
                });
                handleSuccess(body);
            }
        });
};

const uploadZAOtherDocuments = (formData, handleSuccess, handleFailure, name, userId) => function (dispatch, getState) {
    // const userId = getState().auth.details.id;
    dispatch({
        type: Constants.POST_USER_DOCUMENTS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/user/${name}/upload/${userId}`, {
        method: 'POST',
        body: formData,
    })
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.POST_USER_DOCUMENTS_FAIL,
                    payload: body.error,
                });
                handleFailure(body);
            } else {
                dispatch({
                    type: Constants.POST_USER_DOCUMENTS_SUCCESS,
                    payload: body,
                });
                handleSuccess(body);
            }
        });
};

const getZADocuments = (userId, name) => function (dispatch) {
    dispatch({
        type: Constants.GET_USER_DOCUMENTS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/user/${name}/${userId}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_USER_DOCUMENTS_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_USER_DOCUMENTS_SUCCESS,
                    payload: body,
                });
            }
        });
};

const getSopDocuments = (userId) => function (dispatch) {
    dispatch({
        type: Constants.GET_SOP_DOCUMENTS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/user/sop/${userId}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_SOP_DOCUMENTS_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_SOP_DOCUMENTS_SUCCESS,
                    payload: body,
                });
            }
        });
};

const getPassportDocuments = (userId) => function (dispatch) {
    dispatch({
        type: Constants.GET_PASSPORT_DOCUMENTS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/user/passport/${userId}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_PASSPORT_DOCUMENTS_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_PASSPORT_DOCUMENTS_SUCCESS,
                    payload: body,
                });
            }
        });
};

const getLorDocuments = (userId) => function (dispatch) {
    dispatch({
        type: Constants.GET_LOR_DOCUMENTS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/user/lor/${userId}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_LOR_DOCUMENTS_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_LOR_DOCUMENTS_SUCCESS,
                    payload: body,
                });
            }
        });
};

const getVisaRefusalDocuments = (userId) => function (dispatch) {
    dispatch({
        type: Constants.GET_VISA_DOCUMENTS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/user/visarefusal/${userId}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_VISA_DOCUMENTS_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_VISA_DOCUMENTS_SUCCESS,
                    payload: body,
                });
            }
        });
};
const getOtherDocuments = (userId) => function (dispatch) {
    dispatch({
        type: Constants.GET_OTHER_DOCUMENTS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/user/otherdocuments/${userId}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_OTHER_DOCUMENTS_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_OTHER_DOCUMENTS_SUCCESS,
                    payload: body,
                });
            }
        });
};

const uploadZADocument = (formData, handleFailure, handleSuccess, userId) => function (dispatch, getState) {
    // const userId = getState().auth.details.id;

    dispatch({
        type: Constants.POST_USER_DOCUMENTS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/user/document/upload/${userId}`, {
        method: 'POST',
        body: formData,
    })
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.POST_USER_DOCUMENTS_FAIL,
                    payload: body.error,
                });
                handleFailure(body);
            } else {
                dispatch({
                    type: Constants.POST_USER_DOCUMENTS_SUCCESS,
                    payload: body,
                });
                handleSuccess(body);
            }
        });
};

const updateZAUser = (data, handleSuccess, handleFailure) => function (dispatch, getState) {
    dispatch({
        type: Constants.UPDATE_USERID_REQUEST,
    });
    return fetch(`${API_DOMAIN}/user/update/${data.user_id}`, {
        method: 'PUT',
        body: JSON.stringify(data.data),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.UPDATE_USERID_FAIL,
                    payload: body.error,
                });
                handleFailure(body);
            } else {
                dispatch({
                    type: Constants.UPDATE_USERID_SUCCESS,
                    payload: body,
                });
                handleSuccess(body);
            }
        });
};

const getFeedbackForm = () => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_FEEDBACK_FORM_REQUEST
        });
        return fetch(`http://zilter-dj-api-env.eba-4wfs43gx.us-west-2.elasticbeanstalk.com/api/feedbackform/${ORG_ID}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_FEEDBACK_FORM_FAIL,
                        payload: body.error
                    });
                    // handleUserFailure()
                } else {
                    dispatch({
                        type: Constants.GET_FEEDBACK_FORM_SUCCESS,
                        payload: body
                    });
                    // handleUserSuccess(body)
                }
            });
    }
}

const getUserZADetailId = (id, handleSuccess, handleFailure) => function (dispatch, getState) {
    dispatch({
        type: Constants.GET_USERID_REQUEST,
    });
    return fetch(`${API_DOMAIN}/user/${id ? id : id}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_USERID_FAIL,
                    payload: body.error,
                });
                if (handleFailure) handleFailure(body);
            } else {
                dispatch({
                    type: Constants.GET_USERID_SUCCESS,
                    payload: body,
                });
                if (handleSuccess) handleSuccess(body);
            }
        });
};

const addEducationalData = (handleSuccess, handleFailure, data, userId) => function (dispatch, getState) {
    dispatch({
        type: Constants.POST_EDUCATIONAL_DATA_REQUEST,
    });
    return fetch(`${API_DOMAIN}/user/education/${userId}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.POST_EDUCATIONAL_DATA_FAIL,
                    payload: body.error,
                });
                handleFailure(body.error);
            } else {
                dispatch({
                    type: Constants.POST_EDUCATIONAL_DATA_SUCCESS,
                    payload: body.education,
                });
                handleSuccess(body);
            }
        });
};

const addProficiencyData = (data, handleSuccess, handleFailure, userId) => function (dispatch, getState) {
    // const userId = getState().auth.details.id;

    dispatch({
        type: Constants.POST_PROFICIENCY_DATA_REQUEST,
    });
    return fetch(`${APISAAS_DOMAIN}api/languageefficiency/create/`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.POST_PROFICIENCY_DATA_FAIL,
                    payload: body.error,
                });
                handleFailure(body);
            } else {
                dispatch({
                    type: Constants.POST_PROFICIENCY_DATA_SUCCESS,
                    payload: body.proficiency,
                });
                handleSuccess(data);
            }
        });
};
const getProficiencyData = (userId) => function (dispatch, getState) {
    dispatch({
        type: Constants.GET_PROFICIENCY_DATA_REQUEST,
    });
    return fetch(`${API_DOMAIN}/user/proficiency/${userId}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_PROFICIENCY_DATA_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_PROFICIENCY_DATA_SUCCESS,
                    payload: body,
                });
            }
        });
};

const getExperienceData = (userId) => function (dispatch, getState) {
    dispatch({
        type: Constants.GET_EXPERIENCE_DATA_REQUEST,
    });
    return fetch(`${API_DOMAIN}/user/experience/${userId}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_EXPERIENCE_DATA_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_EXPERIENCE_DATA_SUCCESS,
                    payload: body,
                });
            }
        });
};

const addExperienceData = (data, handleSuccess, handleFailure, userId) => function (dispatch, getState) {
    // const userId = getState().auth.details.id;

    dispatch({
        type: Constants.POST_EXPERIENCE_DATA_REQUEST,
    });
    return fetch(`${API_DOMAIN}/user/experience/${userId}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.POST_EXPERIENCE_DATA_FAIL,
                    payload: body.error,
                });
                handleFailure(body);
            } else {
                dispatch({
                    type: Constants.POST_EXPERIENCE_DATA_SUCCESS,
                    payload: body.proficiency,
                });
                handleSuccess(data);
            }
        });
};
const getEducationDetails = (id) => function (dispatch, getState) {
    dispatch({
        type: Constants.GET_EDUCATIONAL_DATA_REQUEST,
    });
    return fetch(`${API_DOMAIN}/user/education/${id}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_EDUCATIONAL_DATA_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_EDUCATIONAL_DATA_SUCCESS,
                    payload: body,
                });
            }
        });
};
const getUserZADocuments = (userId) => function (dispatch) {
    dispatch({
        type: Constants.GET_USER_DOCUMENTS_REQUEST,
    });
    return fetch(`${API_DOMAIN}/user/documents/${userId}`)
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.GET_USER_DOCUMENTS_FAIL,
                    payload: body.error,
                });
            } else {
                dispatch({
                    type: Constants.GET_USER_DOCUMENTS_SUCCESS,
                    payload: body,
                });
            }
        });
};

const deleteAcademics = (id) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_COURSE_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/academic/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_COURSE_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: Constants.GET_COURSE_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}
const deleteInterview = (id, handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.DELETE_INTERVIEW_REQ
        });
        fetch(`${APISAAS_DOMAIN}api/interviewdetails/delete/${id}`, {
            method: 'DELETE',
            // body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(({ response, body }) => {
                if (!response) {
                    dispatch({
                        type: Constants.DELETE_INTERVIEW_FAILED,
                        // payload: body.errorsDELETEchannel
                    });
                    handleSuccess(response);
                } else {
                    dispatch({
                        type: Constants.DETELE_INTERVIEW_SUCCESS,
                        // payload: body
                    });
                    handleFailure();
                }
            });
    }
}


const deleteExperience = (id) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_COURSE_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/experience/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_COURSE_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: Constants.GET_COURSE_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}
const deleteLanguageEfficiency = (id) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_COURSE_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/languageefficiency/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_COURSE_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: Constants.GET_COURSE_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}
const uploadZilterDoc = (data, docname, handleSuccess, handleError) => function (dispatch) {
    dispatch({
        type: Constants.POST_LOG_REQUEST,
    });
    return fetch(`${APISAAS_DOMAIN}api/${docname}/create/`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.POST_LOG_FAIL,
                    payload: body.error,
                });
                handleError();
            } else {
                dispatch({
                    type: Constants.POST_LOG_SUCCESS,
                    payload: body,

                });

                handleSuccess(data);
            }
        });
};

const upadteZilterStudent = (data, id, handleSuccess, handleFailure) => (dispatch, getState) => {
    const { Auth: { user: { token } } } = getState();
    dispatch({
        type: Constants.UPDATE_STUDENT_REQUEST,
    });
    return fetch(`${APISAAS_DOMAIN}api/student/profileupdate/${id}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.UPDATE_STUDENT_FAIL,
                    payload: body.error,
                });
                handleFailure(body.error);
            } else {
                dispatch({
                    type: Constants.UPDATE_STUDENT_SUCCESS,
                    payload: body,
                });
                handleSuccess(body);
            }
        });
};
const updateStudentExperiance = (data, handleSuccess, handleFailure) => {
    let id = data.id
    return function (dispatch, getState) {
        const { Auth: { user: { organization } } } = getState();
        const { Auth: { user: { token } } } = getState();

        dispatch({
            type: Constants.SAAS_OFFER_LETTER_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/experience/update/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json()
                .then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.SAAS_OFFER_LETTER_REQUEST_FAIL,
                        payload: body.error
                    });
                    handleFailure(body)
                } else {
                    dispatch({
                        type: Constants.SAAS_OFFER_LETTER_REQUEST_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body)
                }
            });
    }
}
const updateStudentAcademics = (data, handleSuccess, handleFailure) => {
    let id = data.id
    return function (dispatch, getState) {
        const { Auth: { user: { organization } } } = getState();
        const { Auth: { user: { token } } } = getState();

        dispatch({
            type: Constants.SAAS_OFFER_LETTER_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/academic/update/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json()
                .then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.SAAS_OFFER_LETTER_REQUEST_FAIL,
                        payload: body.error
                    });
                    handleFailure(body)
                } else {
                    dispatch({
                        type: Constants.SAAS_OFFER_LETTER_REQUEST_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body)
                }
            });
    }
}

const deleteStudentLogs = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.DELETE_STUDENT_LOGS_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/logs/delete/${id}`, {
            method: 'DELETE',
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.DELETE_STUDENT_LOGS_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.DELETE_STUDENT_LOGS_SUCCESS,
                        // payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}

const paginationBack = (payload) => function (dispatch) {
    dispatch({
        type: Constants.STUDENT_VIEW_BACK_BUTTON,
        payload,
    });
};
const paginationLead = (payload) => function (dispatch) {
    dispatch({
        type: Constants.STUDENT_LEAD_BACK_BUTTON,
        payload,
    });
};
const paginationAssigned = (payload) => function (dispatch) {
    dispatch({
        type: Constants.STUDENT_ASSIGNED_BACK_BUTTON,
        payload,
    });
};
const paginationSupportStaff = (payload) => function (dispatch) {
    dispatch({
        type: Constants.STUDENT_SUPPORT_STAFF_BACK_BUTTON,
        payload,
    });
};
const paginationSource = (payload) => function (dispatch) {
    dispatch({
        type: Constants.STUDENT_SOURCE_BACK_BUTTON,
        payload,
    });
};
const paginationSearch = (payload) => function (dispatch) {
    dispatch({
        type: Constants.STUDENT_SEARCH_BACK_BUTTON,
        payload,
    });
};
const paginationMonth = (payload) => function (dispatch) {
    dispatch({
        type: Constants.STUDENT_MONTH_BACK_BUTTON,
        payload,
    });
};


const paginationYear = (payload) => function (dispatch) {
    dispatch({
        type: Constants.STUDENT_YEAR_BACK_BUTTON,
        payload,
    });
};

const paginationStartDate = (payload) => function (dispatch) {
    dispatch({
        type: Constants.STUDENT_START_DATE_BACK_BUTTON,
        payload,
    });
};
const paginationEndDate = (payload) => function (dispatch) {
    dispatch({
        type: Constants.STUDENT_END_DATE_BACK_BUTTON,
        payload,
    });
};
const paginationUniversity = (payload) => function (dispatch) {
    dispatch({
        type: Constants.STUDENT_UNIVERSITY_BACK_BUTTON,
        payload,
    });
};
const paginationCourse = (payload) => function (dispatch) {
    dispatch({
        type: Constants.STUDENT_COURSE_BACK_BUTTON,
        payload,
    });
};
const advancedFilterApply = (payload) => function (dispatch) {
    dispatch({
        type: Constants.STUDENT_ADVANCED_FILTER_BACK_BUTTON,
        payload,
    });
};
const paginationApplicationStatus = (payload) => function (dispatch) {
    dispatch({
        type: Constants.STUDENT_APPLICATION_STATUS_BACK_BUTTON,
        payload,
    });
};
const getExportStudents = (page, filterObject, handleSuccess) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token, user, organization } } } = getState();
        dispatch({
            type: Constants.GET_EXPORT_STUDENTS_REQUEST
        });
        const searchQuery = createQueryLink(filterObject);

        return fetch(`${APISAAS_DOMAIN}api/student/user/export/${organization.organization_id}/${page}?${searchQuery}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_EXPORT_STUDENTS_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: Constants.GET_EXPORT_STUDENTS_SUCCESS,
                        payload: body
                    });
                    handleSuccess()
                }
            });
    }
}
const studentCourseApply = (handleSuccess, handleFailure, data) => function (dispatch) {
    dispatch({
        type: Constants.POST_APPLY_COURSE_REQUEST,
    });
    return fetch(`${APISAAS_DOMAIN}api/studentcourses/create/`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.POST_APPLY_COURSE_FAIL,
                    payload: body.error,
                });
                handleFailure();
            } else {
                dispatch({
                    type: Constants.POST_APPLY_COURSE_SUCCESS,
                    payload: body,

                });

                handleSuccess(data);
            }
        });
};
const getZAUniversityCourses = (university_id, handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        dispatch({
            type: Constants.GET_ZOOMABROAD_COURSES_REQUEST
        });
        return fetch(`${API_DOMAIN}/api/university/courses/list/${university_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_ZOOMABROAD_COURSES_FAIL,
                        payload: body.error
                    });
                    // handleFailure()

                } else {
                    dispatch({
                        type: Constants.GET_ZOOMABROAD_COURSES_SUCCESS,
                        payload: body
                    });
                    // handleSuccess()
                }
            });
    }
}
const getZoomabroadUniversities = (handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        dispatch({
            type: Constants.GET_ZOOMABROAD_UNIVERSITIES_REQUEST
        });
        return fetch(`${API_DOMAIN}/api/university/name/list/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_ZOOMABROAD_UNIVERSITIES_FAIL,
                        payload: body.error
                    });
                    // handleFailure()

                } else {
                    dispatch({
                        type: Constants.GET_ZOOMABROAD_UNIVERSITIES_SUCCESS,
                        payload: body
                    });
                    // handleSuccess()
                }
            });
    }
}
const createWhatsAppLog = (data, handleSuccess, handleError) => {
    return function (dispatch) {
        dispatch({
            type: Constants.POST_LOG_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/logs/whatsapp/note/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_LOG_FAIL,
                        payload: body.error
                    });
                    handleError();
                } else {
                    dispatch({
                        type: Constants.POST_LOG_SUCCESS,
                        payload: body
                    });
                    handleSuccess();
                }
            });
    }
}

const createApplicationStatus = (data, handleSuccess, handleError, status) => function (dispatch) {
    dispatch({
        type: Constants.POST_BALANCE_FEE_REQUEST,
    });
    return fetch(`${APISAAS_DOMAIN}api/${status}/create/`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
            if (!response.ok) {
                dispatch({
                    type: Constants.POST_BALANCE_FEE_FAIL,
                    payload: body.error,
                });
                handleError();
            } else {
                dispatch({
                    type: Constants.POST_BALANCE_FEE_SUCCESS,
                    payload: body,
                });
                handleSuccess(body);
            }
        });
};
const getBalanceFeePayment = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_BALANCE_FEE_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/balancefee/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_BALANCE_FEE_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_BALANCE_FEE_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const getAppConditionalOffer = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_CONDITIONAL_OFFER_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/conditional/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_CONDITIONAL_OFFER_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_CONDITIONAL_OFFER_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const getAppEnrolled = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_ENROLLED_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/enrolled/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_ENROLLED_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_ENROLLED_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const getAppFeedeposit = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_FEE_DEPOSIT_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/feedeposit/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_FEE_DEPOSIT_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_FEE_DEPOSIT_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const getAppInvoice = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_INVOICE_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/invoice/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_INVOICE_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_INVOICE_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const getAppLost = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_LOST_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/lost/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_LOST_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_LOST_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const getAppUnConditionalOffer = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_UNCONDITIONAL_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/unconditional/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_UNCONDITIONAL_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_UNCONDITIONAL_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const getAppUniAssessment = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_ASSESSMENT_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/assessment/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_ASSESSMENT_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_ASSESSMENT_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const getAppVisaApplied = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_APPLIED_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/applied/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_APPLIED_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_APPLIED_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const getAppVisaAppointment = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_APPOINTMENT_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/visaappointment/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_APPOINTMENT_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_APPOINTMENT_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const getAppVisaGranted = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_GRANTED_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/granted/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_GRANTED_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_GRANTED_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const getAppVisaRefused = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_REFUSAL_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/refusal/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_REFUSAL_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_REFUSAL_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const getAppCasReceived = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_CAS_RECEIVED_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/casreceive/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_CAS_RECEIVED_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_CAS_RECEIVED_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const getAppCasApplied = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_CAS_APPLIED_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/casapplied/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_CAS_APPLIED_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_CAS_APPLIED_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const getAppRejected = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_APP_REJECTED_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/apprejected/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_APP_REJECTED_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_APP_REJECTED_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const applicationModelOpen = (payload) => function (dispatch) {
    dispatch({
        type: Constants.STUDENT_APPLICATION_MODEL_OPEN,
        payload,
    });
};
const consellorMeeting = (data, handleSuccess, handleError) => {
    return function (dispatch) {
        dispatch({
            type: Constants.POST_COUNSELLOR_MEETING_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/meeting/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_COUNSELLOR_MEETING_FAIL,
                        payload: body.error
                    });
                    handleError();
                } else {
                    dispatch({
                        type: Constants.POST_COUNSELLOR_MEETING_SUCCESS,
                        payload: body
                    });
                    handleSuccess();
                }
            });
    }
}
const ZAconsellorMeeting = (data, handleSuccess, handleError) => {
    return function (dispatch) {
        dispatch({
            type: Constants.POST_ZA_COUNSELLOR_MEETING_REQUEST
        });
        return fetch(`${API_DOMAIN}/api/meeting/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_ZA_COUNSELLOR_MEETING_FAIL,
                        payload: body.error
                    });
                    handleError();
                } else {
                    dispatch({
                        type: Constants.POST_ZA_COUNSELLOR_MEETING_SUCCESS,
                        payload: body
                    });
                    handleSuccess();
                }
            });
    }
}
const taskAllocation = (data, handleSuccess, handleError) => {
    return function (dispatch) {
        dispatch({
            type: Constants.POST_TASK_ALLOCATION_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/taskallocation/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_TASK_ALLOCATION_FAIL,
                        payload: body.error
                    });
                    handleError();
                } else {
                    dispatch({
                        type: Constants.POST_TASK_ALLOCATION_SUCCESS,
                        payload: body
                    });

                    handleSuccess();
                }
            });
    }
}
const ZAtaskAllocation = (data, handleSuccess, handleError) => {
    return function (dispatch) {
        dispatch({
            type: Constants.POST_ZA_TASK_ALLOCATION_REQUEST
        });
        return fetch(`${API_DOMAIN}/api/taskallocation/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST__ZA_TASK_ALLOCATION_FAIL,
                        payload: body.error
                    });
                    handleError();
                } else {
                    dispatch({
                        type: Constants.POST__ZA_TASK_ALLOCATION_SUCCESS,
                        payload: body
                    });
                    handleSuccess();
                }
            });
    }
}
const counsellorRequiredDoc = (data, handleSuccess, handleError) => {
    return function (dispatch) {
        dispatch({
            type: Constants.POST_COUNSELLOR_REQUIRED_DOC_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/requiredocuments/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_COUNSELLOR_REQUIRED_DOC_FAIL,
                        payload: body.error
                    });
                    handleError();
                } else {
                    dispatch({
                        type: Constants.POST_COUNSELLOR_REQUIRED_DOC_SUCCESS,
                        payload: body
                    });
                    handleSuccess();
                }
            });
    }
}
const ZAcounsellorRequiredDoc = (data, handleSuccess, handleError) => {
    return function (dispatch) {
        dispatch({
            type: Constants.POST_COUNSELLOR_REQUIRED_DOC_REQUEST
        });
        return fetch(`${API_DOMAIN}/api/requiredocuments/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_COUNSELLOR_REQUIRED_DOC_FAIL,
                        payload: body.error
                    });
                    handleError();
                } else {
                    dispatch({
                        type: Constants.POST_COUNSELLOR_REQUIRED_DOC_SUCCESS,
                        payload: body
                    });
                    handleSuccess();
                }
            });
    }
}
const attendingMeeting = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_MEETING_DETAILS_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/meeting/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_MEETING_DETAILS_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_MEETING_DETAILS_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const counsellorOpportunity = (data, handleSuccess, handleError) => {
    return function (dispatch) {
        dispatch({
            type: Constants.POST_COUNSELLOR_REQUIRED_DOC_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/opportunity/bulkcreate/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_COUNSELLOR_REQUIRED_DOC_FAIL,
                        payload: body.error
                    });
                    handleError();
                } else {
                    dispatch({
                        type: Constants.POST_COUNSELLOR_REQUIRED_DOC_SUCCESS,
                        payload: body
                    });
                    handleSuccess();
                }
            });
    }
}
//   const ZAcounsellorOpportunity = (data, handleSuccess, handleError) => {
//     return function (dispatch) {
//         dispatch({
//             type: Constants.POST_COUNSELLOR_REQUIRED_DOC_REQUEST
//         });
//         return fetch(`${API_DOMAIN}/api/opportunity/bulkcreate/`, {
//             method: 'POST',
//             body: JSON.stringify(data),
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         })
//             .then(response => response.json().then(body => ({ response, body })))
//             .then(({ response, body }) => {
//                 if (!response.ok) {
//                     dispatch({
//                         type: Constants.POST_COUNSELLOR_REQUIRED_DOC_FAIL,
//                         payload: body.error
//                     });
//                     handleError();
//                 } else {
//                     dispatch({
//                         type: Constants.POST_COUNSELLOR_REQUIRED_DOC_SUCCESS,
//                         payload: body
//                     });
//                     handleSuccess();
//                 }
//             });
//     }
//   }
const counsellorVisaAppointment = (data, handleSuccess, handleError) => {
    return function (dispatch) {
        dispatch({
            type: Constants.POST_COUNSELLOR_REQUIRED_DOC_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/counsellorvisa/bulkcreate/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_COUNSELLOR_REQUIRED_DOC_FAIL,
                        payload: body.error
                    });
                    handleError();
                } else {
                    dispatch({
                        type: Constants.POST_COUNSELLOR_REQUIRED_DOC_SUCCESS,
                        payload: body
                    });
                    handleSuccess();
                }
            });
    }
}
const ZAcounsellorVisaAppointment = (data, handleSuccess, handleError) => {
    return function (dispatch) {
        dispatch({
            type: Constants.POST_COUNSELLOR_REQUIRED_DOC_REQUEST
        });
        return fetch(`${API_DOMAIN}/api/visaappointment/bulkcreate/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_COUNSELLOR_REQUIRED_DOC_FAIL,
                        payload: body.error
                    });
                    handleError();
                } else {
                    dispatch({
                        type: Constants.POST_COUNSELLOR_REQUIRED_DOC_SUCCESS,
                        payload: body
                    });
                    handleSuccess();
                }
            });
    }
}
const ZAupdateAssigned = (id, data, handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();

        dispatch({
            type: Constants.UPDATE_ZA_ASSIGNED_REQUEST
        });
        fetch(`${API_DOMAIN}/api/user/profile/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.UPDATE_ZA_ASSIGNED_FAIL,
                        payload: body.errors,
                    });
                    handleFailure(body)
                } else {
                    dispatch({
                        type: Constants.UPDATE_ZA_ASSIGNED_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body)
                }
            });
    }
}
const getProgressCheckList = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_CHECKLIST_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/checklist/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_CHECKLIST_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_CHECKLIST_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const createActivity = (data, handleSuccess, handleError) => {
    return function (dispatch) {
        dispatch({
            type: Constants.POST_SENDEMAIL_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/activity/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_SENDEMAIL_FAIL,
                        payload: body.error
                    });
                    handleError();
                } else {
                    dispatch({
                        type: Constants.POST_SENDEMAIL_SUCCESS,
                        payload: body
                    });
                    handleSuccess();
                }
            });
    }
}
const za_appliaction_activity = (data, handleSuccess, handleError) => {
    return function (dispatch) {
        dispatch({
            type: Constants.POST_SENDEMAIL_REQUEST
        });
        return fetch(`${API_DOMAIN}/api/applicationactivity/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_SENDEMAIL_FAIL,
                        payload: body.error
                    });
                    handleError();
                } else {
                    dispatch({
                        type: Constants.POST_SENDEMAIL_SUCCESS,
                        payload: body
                    });
                    handleSuccess();
                }
            });
    }
}
const getTaskVisaAppointment = (id, handleSuccess, handleFailure) => {
    return function (dispatch, getState) {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_TASK_VISA_APPOINTMENT_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/counsellorvisa/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_TASK_VISA_APPOINTMENT_FAIL,
                        payload: body.error
                    });
                    if (handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_TASK_VISA_APPOINTMENT_SUCCESS,
                        payload: body
                    });
                    if (handleSuccess) handleSuccess(body);
                }
            });
    }
}
const getStudentQualification = (id, handleSuccess, handleError) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token, user, organization } } } = getState();
        dispatch({
            type: Constants.GET_STUDENT_QUALIFICATION_REQUEST
        });

        return fetch(`${APISAAS_DOMAIN}api/qualification/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_STUDENT_QUALIFICATION_FAIL,
                        payload: body.error
                    });
                    handleError()

                } else {
                    dispatch({
                        type: Constants.GET_STUDENT_QUALIFICATION_SUCCESS,
                        payload: body
                    });
                    handleSuccess()
                }
            });
    }
}
const getStudentAllDocuments = (id, handleSuccess, handleError) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token, user, organization } } } = getState();
        dispatch({
            type: Constants.GET_STUDENT_ALL_DOCUMENTS_REQUEST
        });

        return fetch(`${APISAAS_DOMAIN}api/studentdocuments/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_STUDENT_ALL_DOCUMENTS_FAIL,
                        payload: body.error
                    });
                    handleError()

                } else {
                    dispatch({
                        type: Constants.GET_STUDENT_ALL_DOCUMENTS_SUCCESS,
                        payload: body
                    });
                    handleSuccess()
                }
            });
    }
}
const getSelectedCourses = (id, handleSuccess, handleError) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token, user, organization } } } = getState();
        dispatch({
            type: Constants.GET_SELECTED_COURSES_REQUEST
        });

        return fetch(`${APISAAS_DOMAIN}api/opportunity/selectedcourse/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_SELECTED_COURSES_FAIL,
                        payload: body.error
                    });
                    handleError()

                } else {
                    dispatch({
                        type: Constants.GET_SELECTED_COURSES_SUCCESS,
                        payload: body
                    });
                    handleSuccess()
                }
            });
    }
}
const getWhatsAppTemplates = (handleSuccess, handleError) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token, user, organization } } } = getState();
        dispatch({
            type: Constants.GET_WHATSAPP_TEMPLATES_REQUEST
        });

        return fetch(`${APISAAS_DOMAIN}api/whatsapp/template/list`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_WHATSAPP_TEMPLATES_FAIL,
                        payload: body.error
                    });
                    handleError()

                } else {
                    dispatch({
                        type: Constants.GET_WHATSAPP_TEMPLATES_SUCCESS,
                        payload: body
                    });
                    handleSuccess()
                }
            });
    }
}
export default {
    postInterview,
    getAllStudents,
    getStudent,
    getCourse,
    getReport,
    upload,
    updateReport,
    sendInterviewLink,
    sendOfferLetter,
    updateStudentReport,
    getAllApplications,
    sendEmail,
    createNoteLog,
    getLogs,
    createTaskLog,
    updateTaskLog,
    getStudentAppliedCourses,
    updateAppliction,
    createStatus,
    getApplicationStatus,
    updateAssigned,
    updateStudent,
    createStudentAcademics,
    createStudentExperience,
    createStudentLanguageEfficiency,
    uploadZAOtherDocuments,
    getZADocuments,
    getLorDocuments,
    getOtherDocuments,
    getPassportDocuments,
    getVisaRefusalDocuments,
    getSopDocuments,
    uploadZADocument,
    updateZAUser,
    getUserZADetailId,
    addEducationalData,
    addExperienceData,
    addProficiencyData,
    getExperienceData,
    getProficiencyData,
    getEducationDetails,
    getUserZADocuments,
    deleteAcademics,
    deleteExperience,
    deleteLanguageEfficiency,
    uploadZilterDoc,
    upadteZilterStudent,
    updateStudentExperiance,
    updateStudentAcademics,
    deleteStudentLogs,
    paginationBack,
    paginationLead,
    paginationSearch,
    paginationAssigned,
    paginationMonth,
    paginationYear,
    paginationSource,
    getExportStudents,
    studentCourseApply,
    getZAUniversityCourses,
    getZoomabroadUniversities,
    createWhatsAppLog,
    deleteInterview,
    createApplicationStatus,
    getBalanceFeePayment,
    getAppConditionalOffer,
    getAppEnrolled,
    getAppFeedeposit,
    getAppInvoice,
    getAppLost,
    getAppVisaApplied,
    getAppVisaAppointment,
    getAppVisaGranted,
    getAppVisaRefused,
    getAppUnConditionalOffer,
    getAppUniAssessment,
    getAppCasReceived,
    getAppCasApplied,
    paginationStartDate,
    paginationEndDate,
    paginationCourse,
    paginationUniversity,
    advancedFilterApply,
    applicationModelOpen,
    paginationApplicationStatus,
    getAppRejected,
    consellorMeeting,
    ZAconsellorMeeting,
    taskAllocation,
    counsellorRequiredDoc,
    ZAcounsellorRequiredDoc,
    attendingMeeting,
    counsellorOpportunity,
    // ZAcounsellorOpportunity,
    counsellorVisaAppointment,
    ZAcounsellorVisaAppointment,
    ZAupdateAssigned,
    ZAtaskAllocation,
    getProgressCheckList,
    updateStudentStaff,
    paginationSupportStaff,
    getFeedbackForm,
    createActivity,
    za_appliaction_activity,
    getTaskVisaAppointment,
    getStudentApplicationIntake,
    getStudentQualification,
    getStudentAllDocuments,
    getSelectedCourses,
    getWhatsAppTemplates
}
