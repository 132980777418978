import React from 'react';
import { Container , Row , Button,Col } from 'react-bootstrap';
import Cards from './cards'
import './FeaturesMarkup.scss';

const FeaturesMarkup = (props) => {
    const { steps , handleclick , selectedFeatures , onDoneClick} = props;
    return (
        <div className="featuremarkup-page"> 
            <div className="features-container">
                <h1>Select Features</h1>
                <div>
                    <Row className="cardsitem">
                        {
                            steps.map((item,index)=>{
                                return(
                                    <Cards 
                                        name={item.name}
                                        logo={item.logo}
                                        index = { index }
                                        selectedFeatures = { selectedFeatures }
                                        handleclick = { handleclick }
                                    />
                                )                  
                            })
                        }
                    </Row>  
                    <Row>
                        <Col md="12" className="btncontainer">
                            <Button
                                variant="danger"
                                className="center"
                                onClick={ onDoneClick }
                                >Done
                            </Button>
                        </Col>
                        
                    </Row>              
                </div> 
            </div> 
        </div>
    )
}

export default FeaturesMarkup;