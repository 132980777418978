import {
    AUTH_LOADING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    USERID_GET_REQUEST_FAIL,
    USERID_GET_REQUEST_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    FORGETPASSWORD_SUCCESS,
    FORGETPASSWORD_ERROR,
    REFRESH_USER,
    LOGOUT_REQUEST,
    UPDATE_ERROR,
    UPDATE_SUCCESS,
    TOKENVERIFY_REQUEST,
    TOKENVERIFY_FAIL,
    TOKENVERIFY_SUCCESS
} from './type';

const INITITAL_STATE = {
    isLoading: false,
    error: '',
    user:{},
    token:{},
    isUserLoggedIn: false
}

export default (state = INITITAL_STATE, action) => {
    switch (action.type) {
        case AUTH_LOADING:
            return { ...state, isLoading: true };
        case LOGIN_SUCCESS:
            return { ...state, isLoading: false, error: '', user: action.payload, isUserLoggedIn: true };
        case LOGIN_ERROR:
            return { ...state, isLoading: false, error: action.payload, isUserLoggedIn: false };
        case REGISTER_SUCCESS:
            return { ...state, isLoading: false, error: '', isUserLoggedIn: false };
        case REGISTER_ERROR:
            return { ...state, isLoading: false, error: action.payload, isUserLoggedIn: false };
        case FORGETPASSWORD_SUCCESS:
            return { ...state, isLoading: false, error: '', isUserLoggedIn: false };
        case FORGETPASSWORD_ERROR:
            return { ...state, isLoading: false, error: action.payload, isUserLoggedIn: false };
        case USERID_GET_REQUEST_SUCCESS:
            return { ...state, isLoading: false, error: action.payload };
        case USERID_GET_REQUEST_FAIL:
            return { ...state, isLoading: false, error: action.payload };
        case REFRESH_USER:
            return { ...state, isLoading: false, error: '', user: action.payload, isUserLoggedIn: true };
        case LOGOUT_REQUEST:
            return { ...state, isLoading: false, error: '',isUserLoggedIn: false };
        case UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case UPDATE_ERROR:
            return {
                ...state,
                isLoading: false
            };
        case TOKENVERIFY_REQUEST:
            return {
                ...state,
                isLoading: false
                };
        case TOKENVERIFY_FAIL:
            return {
                ...state,
                isLoading: false
            };
        case TOKENVERIFY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                token: action.payload
            };

        
        default:
            return state;
    }
};