import Constants from './constants';
import { APISAAS_DOMAIN, API_DOMAIN } from '../../../env';

const counsellorTimeSlot = (handleSuccess, handleFailure, data) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token, user, organization } } } = getState();
        data['user_id'] = user.id
        dispatch({
            type: Constants.CREATE_TIME_SLOT_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/counsellorsetting/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.CREATE_TIME_SLOT_FAIL,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.CREATE_TIME_SLOT_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}
const ZACounsellorTimeSlot = (handleSuccess, handleFailure, data) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token, user, organization } } } = getState();
        data['counsellor_id'] = user.id
        dispatch({
            type: Constants.CREATE_TIME_SLOT_REQUEST
        });
        fetch(`${API_DOMAIN}/api/counsellorsetting/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.CREATE_TIME_SLOT_FAIL,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.CREATE_TIME_SLOT_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}
const getScheduleDetails = (id) => {
    return function (dispatch, getState) {
        dispatch({
            type: Constants.GET_SCHEDULE_DETAILS_REQUEST
        });
        return fetch(`${API_DOMAIN}/api/counsellorsetting/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_SCHEDULE_DETAILS_FAIL,
                        payload: body.error
                    });
                    // if(handleFailure) handleFailure(body);
                } else {
                    dispatch({
                        type: Constants.GET_SCHEDULE_DETAILS_SUCCESS,
                        payload: body
                    });
                    // if(handleSuccess) handleSuccess(body);
                }
            });
    }
}
export default 
{ 
    counsellorTimeSlot,
    ZACounsellorTimeSlot,
    getScheduleDetails
}