import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import actions from '../modules/actions';
import { Toast } from "../../../shared/toast/Toast";
import _ from 'lodash';
import SourceMarkup from '../components/SourceMarkup';
class Source extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createShow: false,
            upadteSource: false,
            updateId: '',
            errors: {},
            apaName: '',
            emailName: '',
            stateName: '',
            cityName: '',
            shortName: ''

        }
    }
   
    componentDidMount() {
        const { user : { organization }} = this.props;
        this.props.actions.getOrgSource(organization.organization_id);
    }

  
    onChange = (name, value ) => {
        // console.log("inputinput",input, value)
        // const { inputs  } = this.state
        // inputs[input] = value;
        // this.setState({ inputs });
        this.setState({
            [name]: value, errors:{}
        });
    }
    
    showCreate = ()=>{
        this.setState({createShow:true})
    }
    onCloseModal = () => {
        this.setState({ createShow: false })
        this.setState({ apaName: '', emailName: '', stateName: '', cityName: '', shortName: '', errors: {} })
    }

    isValid = () => {
        const { apaName, emailName, stateName, cityName, upadteSource, updateId, shortName } = this.state;
        let errors = {};
        if (apaName === "" || !/[A-Za-z0-9]/.test(apaName)) {
            errors.apaName = true;
            this.setState({ errors });
            return false;
        }
        if (shortName === "" || !/[A-Za-z0-9]/.test(shortName)) {
            errors.shortName = true;
            this.setState({ errors });
            return false;
        }
        if (emailName === "" || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailName)) {
            errors.emailName = true;
            this.setState({ errors });
            return false;
        }
        if (stateName === "" || !/[A-Za-z]/.test(stateName)) {
            errors.stateName = true;
            this.setState({ errors });
            return false;
        }
        if (cityName === "" || !/[A-Za-z]/.test(cityName)) {
            errors.cityName = true;
            this.setState({ errors });
            return false;
        }
        return true;
    }
    sourceCreate = () => {
        const { apaName, emailName, stateName, cityName, upadteSource, updateId, shortName } = this.state
        const { user : { organization }} = this.props;
        if (this.isValid()) {
            const data = {
                apa_name: apaName ? apaName : '',
                email: emailName ? emailName : '',
                state: stateName ? stateName : '',
                city: cityName ? cityName : '',
                phone_number: '',
                organization_id: organization.organization_id,
                source_short_name: shortName ? shortName : ''
            }

            const handleSuccess = (response) => {

                this.props.actions.getOrgSource(organization.organization_id);
                Toast.show({
                    message: 'Source Created Successfully',
                    timeout: 4000,
                    intent: 'success'
                });
                this.onCloseModal()

            }
            const handleFail = (response) => {
                Toast.show({
                    message: 'Source Created Unsuccessfully',
                    timeout: 4000,
                    intent: 'danger'
                });
                this.onCloseModal()

            }
            const handleUpdateSuccess = (response) => {

                this.props.actions.getOrgSource(organization.organization_id);
                Toast.show({
                    message: 'Source Update Successfully',
                    timeout: 4000,
                    intent: 'success'
                });

                this.setState({ upadteSource: false, updateId: '' })

            }
            const handleUpdateFail = (response) => {
                Toast.show({
                    message: 'Source Update Unsuccessfully',
                    timeout: 4000,
                    intent: 'danger'
                });
                this.onCloseModal()
                this.setState({ upadteSource: false, updateId: '' })
            }
            if (upadteSource) {
                this.props.actions.updateSource(data, updateId, handleUpdateSuccess, handleUpdateFail)
                this.onCloseModal()
            } else {
                this.props.actions.createSource(data, handleSuccess, handleFail)
                this.onCloseModal()
            }
        }
    }

        openSourceEdit = (id, source) => {
            const { apaName, emailName, stateName, cityName, shortName } = this.state
            this.setState({
                apaName: source.apa_name,
                emailName: source.email,
                stateName: source.state,
                cityName: source.city,
                shortName: source.source_short_name,
                upadteSource: true,
                updateId: id
            })
            this.showCreate()
        }
        openSourceDelete = (id) => {
            const { user: { organization } } = this.props;

            const handleUpdateSuccess = (response) => {

                this.props.actions.getOrgSource(organization.organization_id);
                Toast.show({
                    message: 'Source Delete Successfully',
                    timeout: 4000,
                    intent: 'success'
                });
                this.onCloseModal()
                this.setState({ upadteSource: false, updateId: '' })

            }
            const handleUpdateFail = (response) => {
                Toast.show({
                    message: 'Source Delete Unsuccessfully',
                    timeout: 4000,
                    intent: 'danger'
                });
                this.onCloseModal()
                this.setState({ upadteSource: false, updateId: '' })
            }
            this.props.actions.deleteSource(id, handleUpdateSuccess, handleUpdateFail)
    }
    focus = () => {
        this.refs.apaName.focus();
    }
    render() {
        const { sourceList, isLoading } = this.props
        const { createShow, apaName, emailName, stateName, cityName, shortName, errors } = this.state

        return(
              <SourceMarkup
                sourceList={sourceList ? sourceList : []}
                showCreate={this.showCreate}
                createShow={createShow}
                onCloseModal={this.onCloseModal}
                onChangeInput={this.onChange}
                apaName={apaName}
                emailName={emailName}
                stateName={stateName}
                cityName={cityName}
                sourceCreate={this.sourceCreate}
                openSourceEdit={this.openSourceEdit}
                openSourceDelete={this.openSourceDelete}
                isLoading={isLoading}
                shortName={shortName}
                errors={errors}
              />
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.Auth.user,
        sourceList: state.source.sourceList,
        isLoading: state.source.isLoading
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
       actions: bindActionCreators(actions, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Source));