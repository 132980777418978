import {
   
    GET_CALL_LOGS_FAIL,
    GET_CALL_LOGS_REQUEST,
    GET_CALL_LOGS_SUCCESS,
    GET_COUNSELOR_LOGS_FAIL,
    GET_COUNSELOR_LOGS_REQUEST,
    GET_COUNSELOR_LOGS_SUCCESS,
    GET_ASSINGED_LOGS_FAIL,
    GET_ASSINGED_LOGS_REQUEST,
    GET_ASSINGED_LOGS_SUCCESS,
    GET_APA_LOGS_FAIL,
    GET_APA_LOGS_REQUEST,
    GET_APA_LOGS_SUCCESS
} from './type';

// import { HUBURL, URL } from '../../../config';
import {APISAAS_DOMAIN} from '../../../env'

const getCallLogs = (organization_id,start_date,end_date, yopmail) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: GET_CALL_LOGS_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/logs/report/calls/${organization_id}/?start_date=${start_date}&end_date=${end_date}&yopmail=${yopmail}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: GET_CALL_LOGS_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: GET_CALL_LOGS_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}
const counselorReport = (organization_id,start_date,end_date, yopmail) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: GET_COUNSELOR_LOGS_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/report/counselor/${organization_id}/?start_date=${start_date}&end_date=${end_date}&yopmail=${yopmail}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: GET_COUNSELOR_LOGS_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: GET_COUNSELOR_LOGS_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}

const assignedReport = (organization_id,start_date,end_date, yopmail) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: GET_ASSINGED_LOGS_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/report/assigned/${organization_id}/?start_date=${start_date}&end_date=${end_date}&yopmail=${yopmail}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: GET_ASSINGED_LOGS_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: GET_ASSINGED_LOGS_SUCCESS,
                        payload: body
                    });
                    console.log("bodybody",body)
                }
            });
    }
}

const APAReport = (organization_id,start_date,end_date, yopmail) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: GET_APA_LOGS_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/report/apa/${organization_id}/?start_date=${start_date}&end_date=${end_date}&yopmail=${yopmail}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: GET_APA_LOGS_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: GET_APA_LOGS_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}
export default {
  
    getCallLogs,
    counselorReport,
    assignedReport,
    APAReport

}