import mirrorkey from 'mirrorkey';
export default mirrorkey([
    'GET_CHANNEL_REQUEST',
    'GET_CHANNEL_SUCCESS',
    'GET_CHANNEL_FAIL',
    'POST_CHANNEL_REQUEST',
    'POST_CHANNEL_FAIL',
    'POST_CHANNEL_SUCCESS',
    'UPDATE_CHANNEL_REQUEST',
    'UPDATE_CHANNEL_FAIL',
    'UPDATE_CHANNEL_SUCCESS',
    'DELETE_CHANNEL_REQUEST',
    'DELETE_CHANNEL_FAIL',
    'DELETE_CHANNEL_SUCCESS'
])