import React from "react";
import { Button, Card, Elevation } from "@blueprintjs/core";
import { Row, Col, OverlayTrigger , Popover} from 'react-bootstrap';
import './cards.scss'

const Cards = (props) => {
    const { logo, name, details,index,handleclick,selectedFeatures } = props;
    const isselected = selectedFeatures.some((selectedFeatures) => selectedFeatures.name === name)
    return (
        <div className="cardpage" >
            <OverlayTrigger
                placement={'top'}
                delay={{ hide: 200 }}
                overlay={<Popover className="tool-tip" id="tooltip-disabled">Click Feature to add or remove</Popover>}>
                    <Card interactive={true} elevation={Elevation.TWO} className={isselected ? "Features-card  selected" : "Features-card" } onClick={() =>handleclick(name)}>
                    <div className={isselected ? "selectedcard" : "" }>
                        <Row>
                            <Col className="img">
                                <img
                                    src={logo}
                                    alt="zoom"
                                    
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="name">{name}</div>
                                <p className="details">{details}</p>
                            </Col>
                        </Row>  
                        <Row>
                        {isselected ? 
                            <Col className="SelectedText">
                            Feature Added
                            </Col>
                            :""}
                        </Row>             
                    </div>
                </Card>
            </OverlayTrigger>           
        </div>       
    )

}
export default Cards