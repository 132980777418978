export const AUTH_LOADING = 'auth_loading';
export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_ERROR = 'login_error';
export const REGISTER_SUCCESS = 'register_success';
export const REGISTER_ERROR = 'register_error';
export const FORGETPASSWORD_SUCCESS = 'forgetpassword_success';
export const FORGETPASSWORD_ERROR = 'forgetpassword_error';
export const USERID_GET_REQUEST_FAIL = 'userid_get_request_fail';
export const USERID_GET_REQUEST_SUCCESS = 'userid_get_request_success';
export const REFRESH_USER = 'REFRESH_USER';
export const LOGOUT_REQUEST = 'logout_request';
export const UPDATE_ERROR = 'UPDATE_ERROR';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const TOKENVERIFY_FAIL = 'TOKENVERIFY_FAIL';
export const TOKENVERIFY_SUCCESS = 'TOKENVERIFY_SUCCESS';
export const TOKENVERIFY_REQUEST = 'TOKENVERIFY_REQUEST';
export const POST_RESET_PASSWORD_REQUEST = 'POST_RESET_PASSWORD_REQUEST';
export const POST_RESET_PASSWORD_FAIL = 'POST_RESET_PASSWORD_FAIL';
export const POST_RESET_PASSWORD_SUCCESS = 'POST_RESET_PASSWORD_SUCCESS';
