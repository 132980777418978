import Constants from './constants';
import { APISAAS_DOMAIN } from '../../../env';


const getMembers = (org_id) => {
    const page = 1;
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_MEMBERS_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/organizationusers/users/${org_id}/${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_MEMBERS_FAIL,
                        payload: body.errors
                    });
                } else {
                    dispatch({
                        type: Constants.GET_MEMBERS_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}

const getAllMembers = (org_id) => {
    const page = 1;
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_ALL_MEMBERS_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/organizationusers/members/${org_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_ALL_MEMBERS_FAIL,
                        payload: body.errors
                    });
                    console.log("payloadpayloadpayloadpayload",body)
                } else {
                    dispatch({
                        type: Constants.GET_ALL_MEMBERS_SUCCESS,
                        payload: body
                    });
                    console.log("payloadpayloadpayloadpayload error",body)
                }
            });
    }
}

export default {
    getMembers,
    getAllMembers
};
