import React, { useState } from "react";
import styles from "./ChatBot.module.scss";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Overlay, Spinner, Intent } from "@blueprintjs/core";

import { Configuration, OpenAIApi } from "openai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ZLoader } from "../../../shared/loader/ZLoader";

const configuration = new Configuration({
  apiKey: "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",
});
const openai = new OpenAIApi(configuration);
const ChatBot = () => {
  const [query, setQuery] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isExpand, setExpand] = useState(false);
  const [showButtons, setShowbuttons] = useState(false);
  const [result, setResult] = useState([]);

  const onFinish = async (e) => {
    setQuery(null);

    e.preventDefault();
    let newRes = [...result];
    setExpand(true);
    setShowbuttons(false);

    newRes.push({ type: "right", value: query });
    setQuery(null);

    setResult(newRes);

    setLoading(true);

    function generatePrompt(query) {
      // const capitalizedquery =
      //     query[0].toUpperCase() + query.slice(1).toLowerCase();
      return `Help an international student to study abroad and guide in a friendly tone giving specific call to action in ${query} ending your advice with pointing towards talking to a expert human advisor`;
    }
    const element = document.getElementById("chats");
    element.scrollTop = element.scrollHeight;
    try {
      const completion = await openai.createCompletion({
        model: "text-davinci-003",
        prompt: generatePrompt(query),
        temperature: 0.4,
        max_tokens: 1000,
        frequency_penalty: 0.5,
        presence_penalty: 0,
      });
      newRes.push({ type: "left", value: completion.data.choices[0].text });
      setLoading(false);
      setResult(newRes);
      setShowbuttons(true);

      const element = document.getElementById("chats");
      element.scrollTop = element.scrollHeight;
    } catch (error) {
      setLoading(true);

      alert(error);
    }
  };

  console.log(query, ":::::::::");
  return (
    <div
      className={styles.chatBot}
      style={
        isExpand ? { width: "80%", zIndex: 1 } : { width: "350px", zIndex: 1 }
      }
    >
      <div className={styles.topContainer}>
        {/* <p style={{textAlign:'right',color:'#ffffff',padding:20}}>Expand</p> */}
        <h5 style={{ padding: 20, color: "#ffffff" }}>
          What are you searching for?
        </h5>
      </div>
      <div className={styles.chatContainer}>
        <div id="chats" className={styles.chats}>
          {result && result.length ? (
            <>
              {result.map((ans) => {
                return (
                  <p
                    style={
                      ans.type == "right"
                        ? {
                            marginLeft: "10%",
                            float: "right",
                            marginTop: 10,
                            backgroundColor: "#d9d9d9",
                          }
                        : { marginRight: "10%", float: "left", marginTop: 10 }
                    }
                    className={`${styles.resultArea} small-text`}
                  >
                    {ans.value}
                  </p>
                );
              })}
            </>
          ) : (
            ""
          )}
        </div>
        {showButtons ? (
          <div className={styles.buttonContainer}>
            <a
              target="_blank"
              href="https://share.hsforms.com/143DPB2r4TwipJXe8hvZdig4gcq2"
            >
              <Button
                variant="primary"
              >
                Speak to an Advisor
              </Button>
            </a>
          </div>
        ) : (
          ""
        )}
        {isLoading ? (
          <div style={{ display: "flex", padding: "10px 0" }}>
            Generating results... <Spinner intent={Intent.PRIMARY} size={20} />
          </div>
        ) : (
          ""
        )}
        <div
          className={styles.chatInput}
          style={
            isExpand
              ? { display: "inherit", width: "100%" }
              : { display: "flex" }
          }
        >
          <Form
          >
        
            <Row>
              <Col md={isExpand ? "11" : "10"}>
                <Form.Control
                  onChange={(event) => setQuery(event.target.value)}
                  style={{ height: "40px" }}
                  value={query}
                  disabled={isLoading}
                  placeholder="Ask about studying in the UK."
                />
              </Col>
              <Col md={isExpand ? "1" : "2"}>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isLoading}

                  onClick={(e) => {
                    onFinish(e);
                    setQuery("");
                  }}
                >
                  Send
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ChatBot;
