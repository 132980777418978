import React from 'react';
import Role from "./container/TickTableContainer"
import Denied from '../../shared/denied/Denied'

export default [
    {
        path: '/userrole',
        exact: true,
        component: () => <Role />
    },{
        path: '/userrole/edit/:id',
        exact: true,
        component: () => <Role />
    },
    // {
    //     path: '/den',
    //     exact: true,
    //     component: () => <Denied />
    // }
];