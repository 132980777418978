import React from 'react';
import { Tree, Elevation, Card } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import styles from './Tree.css';

const TreeComponent = ({
	nodes,
	handleNodeClick,
	handleNodeCollapse,
	handleNodeExpand
}) => (
	<div>
		<Card interactive className={styles.fill} elevation={Elevation.ONE}>
			<Tree
				contents={nodes}
				onNodeClick={handleNodeClick}
				onNodeCollapse={handleNodeCollapse}
				onNodeExpand={handleNodeExpand}
			/>
		</Card>
	</div>
);

TreeComponent.propTypes = {
	nodes: PropTypes.array,
	handleNodeClick: PropTypes.func,
	handleNodeCollapse: PropTypes.func,
	handleNodeExpand: PropTypes.func
};
export default TreeComponent;
