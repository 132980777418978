
import Constants from './constants';

import { APISAAS_DOMAIN } from '../../../env';

const wizardstart = () =>{
    return function (dispatch) {
        dispatch({
            type: Constants.WIZARDSTART_REQUEST,
        });
    }
}
const wizardend = () =>{
    return function (dispatch) {
        dispatch({
            type: Constants.WIZARDEND_REQUEST,
        });
    }
}


const getStep = (org_id) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.GET_STEP_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/organization/${org_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                console.log("Get payload>>>>",body);
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_STEP_FAIL,
                        payload: body.errors
                    });
                } else {
                    dispatch({
                        type: Constants.GET_STEP_SUCCESS,
                        payload: body
                    });
                }
                console.log("Get payload>>>>",body);
            });
            
    }
}
const getFeaturesStep = (id) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        console.log("token>>>>>>>>>",token)
        dispatch({
            type: Constants.GET_FEATURESTEP_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/organization/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                console.log("Get payload>>>>",body);
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_FEATURESTEP_FAIL,
                        payload: body.errors
                    });
                } else {
                    dispatch({
                        type: Constants.GET_FEATURESTEP_SUCCESS,
                        payload: body
                    });
                }
                console.log("Get payload>>>>",body);
            });
            
    }
}
const updateStep = (handleSuccess, handleFailure, request,org_id) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.UPDATE_STEP_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/organization/update/${org_id}`, {
            method: 'PUT',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                console.log("update start>>>>","response", response,"body",body);
                if (!response.ok) {
                    dispatch({
                        type: Constants.UPDATE_STEP_FAIL,
                        payload: body.errors,
                    });
                    handleFailure(response, body);
                    console.log("update payload>>>>","response", response,"body",body);
                } else {
                    dispatch({
                        type: Constants.UPDATE_STEP_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
                console.log("update payloadennnnnnnd>>>>",body);
            });
    }
}
export default {
    wizardstart,
    wizardend,
    updateStep,
    getStep,
    getFeaturesStep
}