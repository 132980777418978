import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormGroup, InputGroup, Button } from "@blueprintjs/core";
import  DragAndDropComponent  from '../../../shared/dragdrop/DragAndDropComponent';
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import './SettingsMarkup.scss';
import mappingImg from '../../../assets/5.png';
import { ZLoader } from '../../../shared/loader/ZLoader';

const SettingsMarkup = (props) => {
    const {questionAccess,userRoleAccess,character, hideItNow,isLoading, inputs, disabled, onChange, wizard, create, edit, update, onEdit, questions ,gotoStep} = props;
    return (
        <Container>
            {isLoading? <ZLoader/>: null}
            
            {character ?<div className={'character-container animate '}>
            <img src={mappingImg} />
            <div className="cloud">
                <div><p className="cloud-text">Now create an interview for students</p></div>
                <div><p className="cloud-text">Provide interview set name,</p></div>
                <div><p className="cloud-text">Start guide and end guide text along with question set for the perticular interview </p></div>
                <div className="text-right"><Button variant="light" onClick={hideItNow} >Next</Button></div>
            </div>
        </div>:null}
        <Row className="interview-setting">
            <Col md={6}>
            <h1>{edit?"Edit Interview":"Create Interview"}</h1>
            </Col>
            <Col md={6} className="text-right ">
                { wizard ? '' : < a href="/interviews" ><Button className="primary-buttons back"  intent="danger" text="BACK" /></a>}
                {userRoleAccess[2]==1?<Button className="primary-buttons"  onClick={() => onEdit(inputs.id)} hidden={!disabled} intent="warning" text={"EDIT"} />:'' }
                {userRoleAccess[1]==1 || userRoleAccess[2]==1?<Button className="primary-buttons" disabled={inputs.end_text && inputs.start_text && inputs.set_name ? false : true} onClick={edit ? update : create}  hidden={disabled} intent="success" text={edit ? "UPDATE" : "CREATE"} />:''}
            </Col>
            </Row>
            <Row className="interview-setting-inputs">
                <Col>
                    <FormGroup
                        helperText="E.g UK Question Set..."
                        // label="Interview Set Name"
                        labelFor="text-input"
                        labelInfo="*"
                    >
                        <InputGroup
                            value={inputs.set_name}
                            disabled={disabled}
                            name={"set_name"}
                            onChange={(event) => {
                                onChange(event.target.value, 'set_name')
                            }
                            }
                            placeholder="Interview-Set Name"
                        />
                    </FormGroup>
                    <FormGroup
                        helperText="E.g Please click on record button to start the interview..."
                        // label="Interview Start Guid Text"
                        labelFor="text-input"
                        labelInfo="*"
                    >
                        <InputGroup
                            value={inputs.start_text}
                            disabled={disabled}
                            onChange={(event) => {
                                onChange(event.target.value, 'start_text')
                            }
                            }
                            placeholder="Start Guid Text"
                        />
                    </FormGroup>
                    <FormGroup
                        helperText="E.g Your Interview is completed, you will get the feedback shortly...."
                        // label="Interview End Guid Text"
                        labelFor="text-input"
                        labelInfo="*"
                    >
                        <InputGroup
                            value={inputs.end_text}
                            disabled={disabled}
                            onChange={(event) => {
                                onChange(event.target.value, 'end_text')
                            }
                            }
                            placeholder="End Guid Text"
                        />
                    </FormGroup>
                    {/* <DndProvider backend={Backend}> */}
                        <DragAndDropComponent
                            {...props}
                            hidden={disabled}
                            questionAccess={questionAccess}
                        />
                    {/* </DndProvider> */}
                    {/* <Col md={12} className="text-right">
                    {userRoleAccess[1]==1 || userRoleAccess[2]==1? <Button disabled={inputs.end_text && inputs.start_text && inputs.set_name ? false : true} onClick={edit ? update : create} rightIcon="arrow-right" hidden={disabled} intent="success" text={edit ? "UPDATE" : "CREATE"} />:''}
                    </Col>
                    <Col md={12} className="text-right">
                        {userRoleAccess[2]==1?<Button rightIcon="arrow-right" onClick={() => onEdit(inputs.id)} hidden={!disabled} intent="warning" text={"EDIT"} /> :''}
                    </Col> */}
                </Col>
            </Row>
        </Container>
    )
}

export default SettingsMarkup;