import mirrorkey from 'mirrorkey';

export default mirrorkey([
    'WIZARDSTART_REQUEST',
    'WIZARDEND_REQUEST',
    'GET_STEP_REQUEST',
    'GET_STEP_FAIL',
    'GET_STEP_SUCCESS',
    'UPDATE_STEP_REQUEST',
    'UPDATE_STEP_FAIL',
    'UPDATE_STEP_SUCCESS',
    'GET_FEATURESTEP_REQUEST',
    'GET_FEATURESTEP_FAIL',
    'GET_FEATURESTEP_SUCCESS',
]);