import React from 'react';
import Activity from './containers/Activity'
import Event from './containers/Event'


export default [
    {
        path: '/activity',
        exact: true,
        component: () => <Activity />
    },
    {
        path: '/activity/event',
        exact: true,
        component: () => <Event />
    }
];