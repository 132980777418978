import Constants from './Constants';

const INITIAL_STATE = {
   accessData:{},
   error:{},
   isLoading:false,
   userData:{}

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
       
        case Constants.USER_ACCESS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.USER_ACCESS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                accessData: action.payload
            };
        case Constants.USER_ACCESS_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case Constants.GET_USER_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userData: action.payload
            };
        case Constants.GET_USER_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
            case Constants.GET_USER_DATA_RESET:
                return {
                    ...state,
                    userData: []
                };
        default:
            return state;
    }
};