import {
  
    GET_ORG_USER_DETAILS_FAIL,
    GET_ORG_USER_DETAILS_REQUEST,
    GET_ORG_USER_DETAILS_SUCCESS,

} from './type';

const INITIAL_STATE = {
    isLoading: false,
    error: '',
    userDetails: {},
    userData: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      
        case GET_ORG_USER_DETAILS_FAIL:
            return { ...state, isLoading: false, error: action.payload, userData:{}};
        case GET_ORG_USER_DETAILS_REQUEST:
            return { ...state, isLoading: true };
        case GET_ORG_USER_DETAILS_SUCCESS:
            return { ...state, isLoading: false, error: '', userDetails:action.payload, userData:{} };
        default:
            return state;
    }
};