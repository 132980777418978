import {
    DASHBOARD_LOADING,
    DASHBOARD_SUCCESS,
    DASHBOARD_ERROR,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    DEAL_SUCCESS,
    DEAL_ERROR,
    GET_LEADS_FAIL,
    GET_LEADS_REQUEST,
    GET_LEADS_SUCCESS,
    GET_AVERAGE_REQUEST,
    GET_AVERAGE_FAIL,
    GET_AVERAGE_SUCCESS
} from './type';

const INITIAL_STATE = {
    isLoading: false,
    error: '',
    contactList: [],
    deals: [],
    leads: {},
    averageScore: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DASHBOARD_LOADING:
            return { ...state, isLoading: true };
        case DASHBOARD_SUCCESS:
            return { ...state, isLoading: false, error: '', contactList: action.payload.contacts };
        case DASHBOARD_ERROR:
            return { ...state, isLoading: false, error: action.payload, contactList: [] };
        case CHANGE_PASSWORD_SUCCESS:
            return { ...state, isLoading: false, error: '' };
        case CHANGE_PASSWORD_ERROR:
            return { ...state, isLoading: false, error: action.payload };
        case DEAL_SUCCESS:
            return { ...state, isLoading: false, error: '', deals: action.payload.deals };
        case DEAL_ERROR:
            return { ...state, isLoading: false, error: action.payload, deals: [] };
        case GET_LEADS_FAIL:
            return { ...state, isLoading: false, error: action.payload, leads: {} };
        case GET_LEADS_REQUEST:
            return { ...state, isLoading: true };
        case GET_LEADS_SUCCESS:
            return { ...state, isLoading: false, error: '', leads: action.payload };
        case GET_AVERAGE_FAIL:
            return { ...state, isLoading: false, error: action.payload, leads: {} };
        case GET_AVERAGE_REQUEST:
            return { ...state, isLoading: true };
        case GET_AVERAGE_SUCCESS:
            return { ...state, isLoading: false, error: '', averageScore: action.payload };
        default:
            return state;
    }
};