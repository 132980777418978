import React, { Component } from 'react';
import { Tabs, Tab } from "@blueprintjs/core";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { Button, Form, Row, Col } from 'react-bootstrap';
import actions from '../../modules/actions';
import { bindActionCreators } from 'redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ZLoader } from "../../../../shared/loader/ZLoader"
import { Toast } from '../../../../shared';
import './Whatsapp.scss';


class Whatsapp extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    subject: '',
    courseName: '',
    whatsappMsg: '',
    selectedTabId: ''
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onSubjectChange = (event) => {
    this.setState({
      subject: event.target.value,
    });
  };

  onChangeInput = (name, value) => {
    this.setState({
      [name]: value,
    });
  }
  handleTabChange =(e) =>{
    this.setState({
      selectedTabId: e
    })
  }

  sendWhatsappMessage = () => {
    const { student, userProfile, actions: { sendEmail, createLog }, courseName, closeModal, subject,
      editorState, editState, editId, onEditState, noteStatusValue, oneditorWhatsChange,
      editorWhatsState, phoneNumber, whatsAppTemplates } = this.props;

    const { whatsappMsg,selectedTabId } = this.state
    let key = ''
    if (whatsAppTemplates && whatsAppTemplates.templates.length && whatsappMsg) {
      key = whatsAppTemplates.templates.find(obj => obj.id == whatsappMsg)
    }
    let messageText = ''

    if(selectedTabId== 'ng'){
      messageText = draftToHtml(convertToRaw(editorWhatsState.getCurrentContent()))
    }
    else{
      let message = key ? key.data : ''
      messageText = message.replace("{{1}}", student.first_name)
      messageText = messageText.replace("{{2}}", "https://app.ctcglobal.co.uk/")
    }
    const log = {
      "std": student.id,           ////student_id
      "u_id": userProfile ? userProfile.organization.user_id : null,
      "student_courses_id": courseName ? courseName : null,
      "subject": "Counsellor-message",
      "type": 'whats_app',
      "note": messageText,
      "note_status": null,
      "student_name": student.first_name,
      "student_email": student.email,
      "organization_id": userProfile ? userProfile.organization.organization_id : null,
      "phone_number": phoneNumber,
      "templateId": whatsappMsg && selectedTabId !== 'ng'? whatsappMsg : null
    }
    const handleSuccess = () => {
      closeModal();
      onEditState();
      Toast.show({
        message: "Note saved successfully!",
        timeout: 4000,
        intent: 'success'
      })

      const page = 1
      this.props.actions.getLogs(student.id, page);

    }
    const handleError = () => {
      Toast.show({
        message: "Note saving failed!",
        timeout: 4000,
        intent: 'danger'
      })
    }
    if (whatsappMsg || (messageText !== "" && messageText !== "<p></p>\n")) {
      closeModal();
      if (editState) {
        this.props.actions.updateTaskLog(editId, log, handleSuccess, handleError);
      }
      else {
        this.props.actions.createWhatsAppLog(log, handleSuccess, handleError);
      }
    }
    else {
      Toast.show({
        message: "Please select/enter message!",
        timeout: 4000,
        intent: 'danger'
      })
    }

  }

  render() {
    const { courseName, courses, onSubjectChange, subject, editorWhatsState,
      oneditorWhatsChange, noteStatusValue, isLoading, onChange, phoneNumber, whatsAppTemplates, whatsappMsg } = this.props;
     
     
      if(whatsAppTemplates?.templates){
      let select = {'data': 'select', 'id': '', 'status':'APPROVED'}
      let index = whatsAppTemplates?.templates.findIndex(x => x.data == "select");
      let mg = index === -1 ? whatsAppTemplates?.templates.unshift(select) : console.log("object already exists")
      }
    return (
      <div className="email-composer">
        {isLoading ? <ZLoader /> : null}
        {/* <div className="select-name">
          <Form.Control type="text" placeholder="Subject" value={subject} onChange={(event) => onSubjectChange("subject", event.target.value)} />
        </div> */}
        <Row>
          <Col>

            <div className="email-btn-toolbar">
              <strong>Phone Number (with country code): </strong> &nbsp;
              <Form.Control
                placeholder="Phone Number"
                className="form-control modal-email"
                type="phone"
                onChange={(event) => onChange('phoneNumber', event)}
                value={phoneNumber}
              />
            </div>
          </Col>
        </Row>
        {courses && courses.length ?
          <div className="select-name">
            <Form.Control
              placeholder="From"
              className="form-control modal-email"
              as="select"
              onChange={(event) => this.onChangeInput("courseName", event.target.value)}
              value={courseName}
            >
              {
                courses && courses.length ? courses.map((course) => {
                  return (
                    <option value={course.id}>{course.applied_courses}</option>
                  )
                })
                  : ''}
            </Form.Control>
          </div>
          : ''}
        <Tabs
          animate={true}
          id="TabsExample"
          key={"horizontal"}
          renderActiveTabPanelOnly={true}
          onChange={this.handleTabChange}
        >
          <Tab id="rx" title="Template messages" panel={
            <div>{whatsAppTemplates.hasOwnProperty('templates') ?
              <div className="select-name">
                <Form.Control
                  placeholder="From"
                  className="form-control modal-email"
                  as="select"
                  onChange={(event) => this.onChangeInput("whatsappMsg", event.target.value)}
                  value={whatsappMsg}
                >
                  {
                    whatsAppTemplates && whatsAppTemplates.templates.length ? whatsAppTemplates.templates.map((template) => {
                      if (template.status == "APPROVED") {
                        return (
                          <option title={template.data} value={template.id}>{`${template.data.slice(0, 100)} ...`}</option>
                        )
                      }

                    })
                      : ''}
                </Form.Control>
              </div>
              : ''}</div>
          }>

          </Tab>
          <Tab id="ng" title="Custom message" panel={
            <Editor
              editorState={editorWhatsState}
              wrapperClassName="email-wrapper"
              editorClassName="email-editor"
              onEditorStateChange={oneditorWhatsChange}
            />} >
          </Tab>
        </Tabs>
        <div className="email-btn-toolbar">
          <Button className="pull-right" variant="primary" onClick={() => this.sendWhatsappMessage()}>Send Message</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),

  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Whatsapp));
