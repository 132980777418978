import React from "react";

import { Button, Card, Elevation } from "@blueprintjs/core";
import { Row, Col } from 'react-bootstrap';
import './cards.scss'

const Cards = (props) => {
    const { logo, name, details, app, connect, importcsv, onShowModal, onCloseModal, showModal, uploadFile, onDropStudentFile, studentFile, buttonText } = props;
    return (
        <>
            <Card interactive={true} className="integration-card">
                <Row>
                    <Col md="4" className="image-container">
                        <img
                            src={logo}
                            alt="zoom"
                        // height={46}
                        />
                    </Col>
                    <Col md="8">
                        <h5>{name}</h5>
                        <Button onClick={() => connect(app)}>{buttonText}</Button>
                    </Col>
                </Row>
            </Card>
        </>
    )

}
export default Cards