import React from 'react';
import { Row, Col,Form, Button } from 'react-bootstrap';
import './LoginMarkupNew.scss';
import background from '../../../assets/background.png'
import zilter from '../../../assets/zilterWhite1.png'
import automation from '../../../assets/auto (1).png'
import Input from '../../../shared/input/Input'
import { CLOUDFROND_ASSET_URL,BRAND_IMAGE_WHITE_LINK } from '../../../env';

const LoginMarkupNew = (props) => {
    const { error, onLogin, loginErrorMessage,history, onKeyPress} = props;

    return (
        <div className="new-login-markup" >
           <Row className="outer-row">
               <Col md="7" lg="7" xs="12" sm="12" className="left-side-login slide-right">
               <div ><img src={`${CLOUDFROND_ASSET_URL}${BRAND_IMAGE_WHITE_LINK}`} height={50}/></div>
               <div><img src={automation} height={370} width={550}/></div>
               <div className="text-container-login">Few more click to unlock <br/><span>Zilter's</span> experience</div>
               </Col>
              
               <Col>
               <div className="login-box slide-left">
                    <h3>Sign In</h3>
                    <Form.Group>
                    <Form.Control 
                    type="email" 
                    placeholder="Email" 
                    onChange={props.onInputChange}
                    onKeyPress={onKeyPress}
                    name="email"

                    />
                    {error.email ? <span className="error">Please Enter Registered Email ID</span> : ''}

                    <Input
                     type="password"
                      placeholder="Password" 
                      onChange={props.onInputChange}
                      onKeyPress={onKeyPress}
                      name="password"
                      appendPasswordEye
                      />
                    {error.password ? <span className="error">Please Enter Valid Password</span> : ''}

                    <div className="forgot-pass" onClick={()=>history.push("/forgetpassword")}><a>Forgot Password ?</a></div>
                    <Button className="login-button" onClick={()=>onLogin()}>Login</Button>
                    {/* <Button className="login-button signup" onClick={()=>history.push("/register")}>Signup</Button> */}

                    </Form.Group>
                
                </div>
               </Col>
           </Row>
            </div>
       
    )
}

export default LoginMarkupNew;