import Constants from './constants';
import { APISAAS_DOMAIN } from '../../../env';


const getOrgSource = (org_id) => {    
    return (dispatch) => {
        dispatch({
            type: Constants.GET_SOURCE_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/source/organizationsource/${org_id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_SOURCE_FAIL,
                        payload: body.errors
                    });
                } else {
                    dispatch({
                        type: Constants.GET_SOURCE_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}

const createSource = (data, handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.POST_SOURCE_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/source/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_SOURCE_FAIL,
                        payload: body.errorsupdatesource
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.POST_SOURCE_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

const updateSource = (data, id, handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.UPDATE_SOURCE_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/source/update/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.UPDATE_SOURCE_FAIL,
                        payload: body.errorsupdatesource
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.UPDATE_SOURCE_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

const deleteSource = (id, handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.DELETE_SOURCE_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/source/delete/${id}`, {
            method: 'DELETE',
            // body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }) 
            .then(({ response, body }) => {
                if (!response) {
                    dispatch({
                        type: Constants.DELETE_SOURCE_SUCCESS,
                        // payload: body.errorsDELETEsource
                    });
                    handleSuccess(response);
                } else {
                    dispatch({
                        type: Constants.DELETE_SOURCE_FAIL,
                        // payload: body
                    });
                    handleSuccess();
                }
            });
    }
}
export default {
    getOrgSource,
    createSource,
    updateSource,
    deleteSource
};
