import React from "react";
import { Row, Col, Button } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cards from "./cards"
import { ZLoader } from '../../../shared/loader/ZLoader';
import { Toast } from '../../../shared';
import "./IntegrationMarkup.scss"
import Modal from '../../../shared/model/model'
import Dropzone from 'react-dropzone';
import integrationImg from '../../../assets/1.png';
import integrationImg2 from '../../../assets/2.png';
import { URL } from '../../../env';

const IntegrationMarkup = (props) => {
    const { pathname, itemResult, search, onChange, connect, isLoading, isCSV, step, nextStep,continuebtn, uploadFile, studentFile, onDropStudentFile, importcsv, showModal, onCloseModal, onShowModal, showFormModal, showZilterFormModal, user } = props;
    const filterItems = itemResult.filter(items => {
        return items.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });
    let userId = "";
    let organizationId = "";
    if(user && user.organization) {
        userId = user.organization.user_id
        organizationId = user.organization.organization_id
    }
    let character = null;
    const formLink = `${URL}/application/${organizationId}?id=${userId}`;
    const zilterformLink = `${URL}/form/${organizationId}?id=${userId}`;
    if (pathname == "?wizard=true") {
        switch (step) {
            case 0:
                character = <div className="character-container animate">
                    <img src={integrationImg} />
                    <div className="cloud">
                        <div><p className="cloud-text">Hi User,</p></div>
                        <div><p className="cloud-text">Welcome to zilter.</p></div>
                        <div><p className="cloud-text">Our team will guide you through the setup process.</p></div>
                        <div className="text-right"><Button variant="light" onClick={() => nextStep(1)}>Next</Button></div>
                    </div>
                </div>;
                break;
            case 1:
                character = <div className={step == 1 ? "character-container animate-two" : "character-container"}>
                    <img src={integrationImg2} />
                    <div className="cloud next">
                        <div><p className="cloud-text">Use the search box to search for you CRM,</p></div>
                        <div><p className="cloud-text">Click on connect button to connect it to zilter.</p></div>
                        <div><p className="cloud-text">Will we redirect you to next step.</p></div>
                        <div className="text-right"><Button variant="light" onClick={() => nextStep(2)}>Next</Button></div>
                    </div>
                </div>;
                break;
            default:
                character = null;
                break;
        }
    }
    return (
        <div className="container">
            <div className="head">
                {isCSV ? <ZLoader /> :
                    <div>
                        <Row >
                            <Col md="8">
                                <h3> Integrations </h3>
                            </Col>
                            <Col md="4" className="text-right">
                                <div className="bp3-input-group .modifier">
                                    <span className="bp3-icon bp3-icon-search"></span>
                                    <input onKeyUp={onChange} className="bp3-input" modifier type="search" placeholder="Search integration" dir="auto" />
                                </div>

                            </Col>
                        </Row>
                        <Row>

                            {!filterItems.length && <span className="text-center">No data found</span>}
                            {filterItems.map(item => {
                                return (
                                    <Col md="4" className="card-container">
                                        <Cards
                                            {...item}
                                            connect={connect}
                                            importcsv={importcsv}
                                            uploadFile={uploadFile}
                                            studentFile={studentFile}
                                            onDropStudentFile={onDropStudentFile}

                                        />
                                    </Col>
                                )
                            })}
                        </Row>
                        <Modal
                            modalTitle={"Student Data"}
                            isModalOpen={showModal}
                            onCloseModal={onCloseModal}
                        >
                            <Dropzone onDrop={files => onDropStudentFile(files)}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({
                                        className: studentFile && studentFile.length ? 'dropzone active' : 'dropzone',
                                        onDrop: event => event.stopPropagation()
                                    })}>
                                        <input {...getInputProps()} />
                                        <p>Click to select file</p>
                                        {studentFile && studentFile.length ? <span className="file-name">Selected File: {studentFile[0].name}</span> : <em>(Only *.csv file type will be accepted)</em>}
                                    </div>
                                )}
                            </Dropzone>
                            <div className="import">
                                <Row>
                                    <Col className="download-csv"  >
                                        <a className="download text-left" href="https://zilter.s3.amazonaws.com/Student.csv">Download Sample Student CSV</a>
                                    </Col>
                                    <Col>
                                        <Button className="upload-button" disabled={studentFile ? false : true} onClick={uploadFile} >Upload</Button>
                                    </Col>
                                </Row>
                            </div>
                        </Modal>
                        <Modal
                            modalTitle={"Application Form"}
                            isModalOpen={showFormModal}
                            onCloseModal={onCloseModal}
                        >
                            <Row>
                                <Col md={12}>
                                    <Row className="application-link">
                                        <Col md={11}>{formLink}</Col>
                                        <Col md={1}>
                                            <CopyToClipboard
                                                text={formLink} onCopy={ () => {
                                                    Toast.show({
                                                        message: "Copied to clipboard",
                                                        timeout: 4000,
                                                        intent: 'success'

                                                    })
                                                }}>
                                                <FontAwesomeIcon size="lg" className="icons" icon={['fas', 'copy']}/>
                                            </CopyToClipboard>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={12}>
                                    <Button className="pull-right" onClick={continuebtn} >Continue</Button>
                                </Col>
                            </Row>
                        </Modal>
                        <Modal
                            modalTitle={"Zilter Form"}
                            isModalOpen={showZilterFormModal}
                            onCloseModal={onCloseModal}
                        >
                            <Row>
                                <Col md={12}>
                                    <Row className="application-link">
                                        <Col md={11}>{zilterformLink}</Col>
                                        <Col md={1}>
                                            <CopyToClipboard
                                                text={zilterformLink} onCopy={ () => {
                                                    Toast.show({
                                                        message: "Copied to clipboard",
                                                        timeout: 4000,
                                                        intent: 'success'

                                                    })
                                                }}>
                                                <FontAwesomeIcon size="lg" className="icons" icon={['fas', 'copy']}/>
                                            </CopyToClipboard>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={12}>
                                    <Button className="pull-right" onClick={continuebtn} >Continue</Button>
                                </Col>
                            </Row>
                        </Modal>
                    </div>
                }
            </div>
            {character}
        </div>
    );
}

export default IntegrationMarkup;
