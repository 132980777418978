import React, {Component} from 'react';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, InputGroup, FormControl, Row, Col } from 'react-bootstrap'
import { Spinner, Intent } from "@blueprintjs/core";
import './ChatMessageMarkup.scss'
 
let client = null;
class ChatMessageMarkup extends Component {
   constructor(props) {
       super(props)
       this.state = {
           VisaRefusedVal: '',
           id: '',
           errors: {},
           room:'cccsdsd',
           messages: [],
           value: '',
           name: 'counslor',
       }
   }
 
   // client = new W3CWebSocket('ws://127.0.0.1:8000/ws/chat/' + room + '/');
 
 
   componentDidMount() {
       console.log("this.props", this.props)
       let room = `${this.props.student.id}_${this.props.student.assigned_id}`
       room = room.replace(/[^a-zA-Z0-9\w]/g,'')
       client = new W3CWebSocket('ws://127.0.0.1:8000/ws/chat/' + room + '/');
       client.onopen = () => {
         console.log('WebSocket Client Connected', client);
       };
       client.onmessage = (message) => {
         const dataFromServer = JSON.parse(message.data);
         if (dataFromServer) {
           this.setState((state) =>
             ({
               messages: [...state.messages,
               {
                 msg: dataFromServer.message,
                 name: dataFromServer.name,
               }]
             })
           );
         }
       };
     }
   //   static getDerivedStateFromProps(props, state) {
   //     const {
          
   //     } = props;
      
   //     if (state.id != props.student.id) {
   //         return {
   //             name: props.student.first_name
   //         }
   //     }
   // }
     onChangeInput = (name, value) => {
       this.setState({
           [name]: value
       })
   }
     onButtonClicked = (e) => {
       client.send(JSON.stringify({
         type: "message",
         message: this.state.value,
         name: this.state.name
       }));
       this.state.value = ''
       // e.preventDefault();
     }
   render() {
       const { onChange, minimize,
           closeModal, student, sending
       } = this.props;
    
       const { messages, value } = this.state
       console.log("message", messages)
       return (
           <div className="za-chat">
               <Row>
                   <Col>
                       <h5 className="header" onClick={() => closeModal()}>
                           <FontAwesomeIcon size={'sm'} className="icons" icon={['fas', 'chevron-left']} />
                           {student ? student.first_name : 'Message'}
                       </h5>
                   </Col>
               </Row>
               <Row>
                   <Col className={`message-wrap expand`}>
                       <Row>
                           {messages && messages ? <Col id="message-list" className="message-list">
                               {messages.map((message, index) => {
                                   return (
                                       <>
                                       <div>
                                       { message.name =='counslor' ?
                                       <Col md='10' sm='10' xs='10' lg='10' className='text-right float-right'>
                                           <span className='msg-sender'>{message.msg}</span>
                                           </Col>
                                           : ''}
  
                                       <div>
                                      { message.name =='student' ?
                                           <Col md='10' sm='10' xs='10' lg='10' className='text-left'>
                                           <span className='msg-res'>{message.msg} </span>
                                           </Col>
                                            : ''}
                                       </div>
                                       </div>
 
                                       </>
                                   )
                               })}
                           </Col> : <Col id="message-list" className="message-list">
 
                           </Col>}
                       </Row>
                       <Row>
                           <Col>
                               <InputGroup className="input-field mb-3">
                                   <FormControl
                                       placeholder="Recipient's username"
                                       aria-label="Recipient's username"
                                       aria-describedby="basic-addon2"
                                       placeholder="Send a message..."
                                       multiline={true}
                                       value={value}
                                       onChange={(event) => this.onChangeInput('value', event.target.value)}
                                       onKeyPress={(e) => {
                                           if (e.shiftKey && e.charCode === 13) {
                                               return true;
                                           }
                                           if (e.charCode === 13) {
                                               this.onButtonClicked();
                                               e.preventDefault();
                                               return false;
                                           }
                                       }}
                                   />
                                   <InputGroup.Append>
                                       <Button disabled={sending} variant="primary" onClick={() => this.onButtonClicked()}>
                                           <FontAwesomeIcon icon={["fab", "telegram-plane"]} />
                                       </Button>
                                   </InputGroup.Append>
                               </InputGroup>
                           </Col>
                       </Row>
                   </Col>
               </Row>
           </div>
       )
   }
}
const mapStateToProps = (state) => {
   return {
       isLoading: state.students.isLoading,
 
   }
}
const mapDispatchToProps = (dispatch) => {
   return {
   };
};
export default withRouter(connect(
   mapStateToProps,
   mapDispatchToProps
)(ChatMessageMarkup));
