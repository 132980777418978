import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { APISAAS_DOMAIN } from '../../../env'
import moment from 'moment';
import actions from '../modules/actions';
import Markup from '../components/Markup';
import axios from 'axios';
class Interview extends Component {
    constructor(props) {
        super(props)
        this.player = null;
        this.state = {
            avatar:'',
            uploadPercentage:0,
            uploading: true,
            timer: {
                days: undefined,
                hours: undefined,
                minutes: undefined,
                seconds: undefined,
            },
            interview: {},
            question: null,
            number: 0,
            thinking: false,
            application_id: null,
            before: {
            },
            after1: {
                message: 'Your Interview is completed, you will get the feedback shortly.'
            },
            after: null,
            questions: [{
                question: "Why do you wish to study in the UK?",
                time: {
                    hours: 0,
                    minutes: 0,
                    seconds: 10
                },
                thinking: {
                    hours: 0,
                    minutes: 0,
                    seconds: 10
                }
            }, {
                question: "Why do you want to go abroad to pursue your higher education?",
                time: {
                    hours: 0,
                    minutes: 2,
                    seconds: 30
                },
                thinking: {
                    hours: 0,
                    minutes: 0,
                    seconds: 10
                }
            }, {
                question: "Why are you choosing UK over other developed nations like USA, Canada, Australia?",
                time: {
                    hours: 0,
                    minutes: 5,
                    seconds: 30
                },
                thinking: {
                    hours: 0,
                    minutes: 1,
                    seconds: 0
                }
            }, {
                question: "What are the benefits of studying in the UK over your home country?",
                time: {
                    hours: 1,
                    minutes: 10,
                    seconds: 30
                },
                thinking: {
                    hours: 0,
                    minutes: 1,
                    seconds: 0
                }
            }]
        }
    }

    static getDerivedStateFromProps(props, state) {

        const { studentInterview } = props;
        if (Array.isArray(studentInterview.response)) {
            props.history.push('/complete');
        }
        else {

            if (studentInterview.response) {
                if (state.interview.id !== studentInterview.response.id) {
                    const { studentInterview: { response } } = props;
                        const inputs = { ...response };
                        return {
                            before: { message: inputs.start_text },
                            questions: JSON.parse(inputs.question_set),
                            interview: { ...response }
                        }
                    
                }
            }
        }
    }
    componentDidMount() {
        const { match: { params }, location: { search } } = this.props;
        let student_id
        if(search) {
            const query = new URLSearchParams(search);
            const application_id = query.get('interview_student_id');
            student_id =application_id 
            this.setState({application_id});
        }
        if(params && params.id) {
            this.props.actions.getStudeudentInterview(student_id);
        }
    }

    startInterview = () => {
        const { questions, number } = this.state;
        const question = questions[number];
        const parsedQuestion = {
            ...question,
            thinking: JSON.parse(question.thinking),
            time: JSON.parse(question.answer)
        }
        this.setState({ question: parsedQuestion, number: 0, before: null });
        this.thinkingTimer(parsedQuestion);
    }

    changeQuestion = (number) => {
        const { questions } = this.state;
        if(questions.length > number) {
            const question = questions[number];
            const parsedQuestion = {
                ...question,
                thinking: JSON.parse(question.thinking),
                time: JSON.parse(question.answer)
            }
            clearInterval(this.thinkInterval);
            clearInterval(this.interval);
            this.thinkingTimer(parsedQuestion);
            this.setState({ question: parsedQuestion, number });
        } else {
            this.player.record().stop();
            clearInterval(this.thinkInterval);
            clearInterval(this.interval);
        }
    }

    thinkingTimer(question) {
        this.setState({ thinking: true });
        const { thinking: {hours, minutes, seconds } } = question;
        const then = moment().add(hours, 'hours').add(minutes, 'minutes').add(seconds, 'seconds');
        this.thinkInterval = setInterval(() => {
            const now = moment();
            const countdown = moment.duration(then.diff(now));
            const days = countdown.get('days') ? countdown.get('days') : null;
            const hour = countdown.get('hours') ? countdown.get('hours') : null;
            const minute = countdown.get('minutes');
            const second = countdown.get('seconds');
            const timer = { days, hours: hour, minutes: minute, seconds: second };
            this.setState({ timer });
            if(days <= 0 && hour <= 0 && minute <= 0 && second <= 0) {
                clearInterval(this.thinkInterval);
                this.startTimer(question);
            }
        }, 1000);
    }

    skipThinkingTime = (question) =>{
        clearInterval(this.thinkInterval);
        this.startTimer(question);
    }
    startTimer(question) {
        const { number } = this.state;
        const { time: { hours, minutes, seconds } } = question;
        const then = moment().add(hours, 'hours').add(minutes, 'minutes').add(seconds, 'seconds');
        this.interval = setInterval(() => {
            const now = moment();
            const countdown = moment.duration(then.diff(now));
            const days = countdown.get('days') ? countdown.get('days') : null;
            const hour = countdown.get('hours') ? countdown.get('hours') : null;
            const minute = countdown.get('minutes');
            const second = countdown.get('seconds');
            const timer = { days, hours: hour, minutes: minute, seconds: second };
            this.setState({ timer, thinking: false });
            if(days <= 0 && hour <= 0 && minute <= 0 && second <= 0) {
                this.changeQuestion(number+1);
            }
        }, 1000);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
        if (this.thinkInterval) {
            clearInterval(this.thinkInterval);
        }
    }

    setPlayer = (player) => {
        this.player = player;
    }

    interviewFinished = (recordedData) => {
        const { after1, application_id , uploadPercentage} = this.state;
        this.setState({ after: after1 });
		const formData = new FormData()
		formData.append("interview_file", recordedData, recordedData.name);
        formData.append("interview_id", application_id);
        const options = {
            onUploadProgress: (progressEvent) => {
              const {loaded, total} = progressEvent;
              let percent = Math.floor( (loaded * 100) / total )
              console.log( `${loaded}kb of ${total}kb | ${percent}%` );
              console.log( "uploadPercentage::::OUT", uploadPercentage, "percent",percent );
              console.log( "loaded::::OUT", loaded, "total",total );
           if(loaded)
           {
            console.log( "loadeduploadPercentage::::OUT", uploadPercentage, "percent",percent );
            console.log( "loaded::::OloadedUT", loaded, "total",total ); 
           }
              if( loaded < total){
                console.log( "uploadPercentage::IN", uploadPercentage, "percent",percent );
                  if(percent == 100){
                    this.setState({ uploadPercentage: 0 })
                  }else{
                    this.setState({ uploadPercentage: percent })
                  }
              }
            }
          }
      
          axios.post(`${APISAAS_DOMAIN}api/interviewdetails/student/upload/`, formData, options).then(res => { 
              this.setState({uploading:false})
            //   console.log(res)
            //   this.setState({ avatar: res.data.url, uploadPercentage: 100 }, ()=>{
            //     setTimeout(() => {
            //       this.setState({ uploadPercentage: 0 })
            //     }, 1000);
            //   })
          })
        }
        // this.props.actions.uploadInterview(formData);
    

    render() {
        const { question, number, questions, timer, thinking, before, after,uploadPercentage, uploading } = this.state;
        console.log(uploadPercentage,"uploadPercentageuploadPercentage" );
        
        return (
            <Markup
                question={question}
                uploadPercentage={uploadPercentage}
                number={number}
                changeQuestion={this.changeQuestion}
                max={questions.length}
                timer={timer}
                thinking={thinking}
                before={before}
                after={after}
                startInterview={this.startInterview}
                setPlayer={this.setPlayer}
                interviewFinished={this.interviewFinished}
                isLoading={this.props.isLoading}
                skipThinkingTime={this.skipThinkingTime}
                uploading={uploading}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.Auth.user,
        studentInterview: state.interview.studentInterview,
        isLoading: state.interview.isLoading
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Interview));