import React from 'react';
import Student from "./container/Student"
import Result from "./container/Result"
import StudentView from "./container/StudentView"
import StudentEditor from './container/StudentEdit'

export default [
    {
        path: '/students',
        exact: true,
        component: () => <Student />
    },
    {
        path: '/student/result/:id',
        exact: true,
        component: () => <Result />
    },
    {
        path: '/student/view/:id',
        exact: true,
        component: () => <StudentView />
    },
    {
        path: '/student/edit/:id',
        exact: true,
        component: () => <StudentEditor />

    }


];