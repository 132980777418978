import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FormMarkup from "../components/Form"
import { bindActionCreators } from 'redux';
import { Toast } from '../../../shared';
import { validation } from '../../../utils';
import actions from '../../Students/modules/actions'
import courseactions from '../../Course/modules/action'
import integrationAction from '../modules/actions'
import countryList from '../modules/countryList'

class UCAMForm extends Component {
    constructor(props) {
        super(props);
        this.state =
            this.state = {
                step: 0,
                courses: [],
                eduFileMarksheet:null,
                eduFileDegree:null,
                identity_proof:null,
                expFile:null,
                langFile:null,
                cards: [{
                    name: "Programme",
                    title: "Select the programme you want to apply for:",
                    input: [{
                        'order': 0,
                        'id': 'course_type',
                        'type': 'select',
                        "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                        'label': 'Study Type',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please select an option.',
                        'icon': '',
                        'options': [
                            { "label": "Masters", "value": "Masters" },
                            { "label": "Bachelors", "value": "Bachelors" },
                            { "label": "Diploma", "value": "Diploma" },
                        ]
                    }, {
                        'order': 1,
                        'id': 'applied_course',
                        'type': 'select',
                        "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                        'label': 'Study Option',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please select an option.',
                        'icon': '',
                        'options': []
                    }, {
                        'order': 2,
                        'id': 'permanence_type',
                        "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                        'type': 'radio',
                        'label': 'Permanence Type',
                        'value': 'permanence',
                        'placeholder': 'City/Town',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please select option.',
                        'icon': '',
                        'options': [
                            { "label": "Request full permanence type of regime", "value": "permanence" },
                            { "label": "Request partial permanence type of regime", "value": "nonpermanence" },
                        ]
                    }, {
                        'order': 3,
                        'id': 'student_comment',
                        'type': 'textarea',
                        "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                        'label': 'Student Comment',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid value.',
                        'icon': 'business',
                    }
                ]
                }, {
                    name: "Personal Details",
                    title: "Personal Details",
                    input: [{
                        'order': 0,
                        'id': 'first_name',
                        'type': 'input',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'First Name',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid first name',
                        'icon': 'business',
                    }, {
                        'order': 1,
                        'id': 'middle_name',
                        'type': 'input',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Middle Name',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory':0,
                        'errorText': 'Please enter valid middle name.',
                        'icon': 'business',
                    }, {
                        'order': 2,
                        'id': 'last_name',
                        'type': 'input',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Last Name',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid last name.',
                        'icon': 'business',
                    }, {
                        'order': 3,
                        'id': 'gender',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'type': 'radio',
                        'label': 'Gender',
                        'value': '',
                        'placeholder': 'City/Town',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please select gender',
                        'icon': '',
                        'options': [
                            { "label": "Male", "value": "male" },
                            { "label": "Female", "value": "female" },
                        ]
                    }, {
                        'order': 4,
                        'id': 'email',
                        'type': 'input',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Email Address',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid email.',
                        'icon': 'business',
                    }, {
                        'order': 5,
                        'id': 'phone_number',
                        'type': 'input',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Mobile',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid phone number.',
                        'icon': 'business',
                    }, {
                        'order': 6,
                        'id': 'dob',
                        'type': 'date',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Date of Birth',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid date.',
                        'icon': 'business',
                    }, {
                        'order': 7,
                        'id': 'address',
                        'type': 'input',
                        "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                        'label': 'Address (Street, landmark)',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid address.',
                        'icon': 'business',
                    }, {
                        'order': 8,
                        'id': 'country',
                        'type': 'select',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Country',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please select country.',
                        'icon': '',
                        'options': countryList
                    }, {
                        'order': 9,
                        'id': 'provice_state',
                        'type': 'input',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Provice/State',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid state',
                        'icon': 'business',
                    }, {
                        'order': 10,
                        'id': 'city_town',
                        'type': 'input',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'City/Town',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid city',
                        'icon': 'business',
                    }, {
                        'order': 11,
                        'id': 'postal_code',
                        'type': 'input',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Postal Code',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid postal code',
                        'icon': 'business',
                    },
                    {
                        'order': 12,
                        'id': 'identity_proof',
                        'type': 'file',
                        'fileName':'identityProof',
                        "layout": { "lg": 6, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Upload Proof of Identity document',
                        'value': '',
                        'error': false,
                        'mandatory': 0,

                    },]
                },
                {
                    name: "Previous Education",
                    title: "Previous Education",
                    input: [{
                        'order': 0,
                        'id': 'home_university',
                        'type': 'input',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Home university',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid university name',
                        'icon': 'business',
                    }, {
                        'order': 1,
                        'id': 'degree_name',
                        'type': 'input',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Exact title of diploma',
                        'value': '',
                        'placeholder': 'Ex: BE Electronics and Telecommunication',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid degree and cours name',
                        'icon': 'business',
                    },
                    {
                        'order': 2,
                        'id': 'degree_type',
                        'type': 'select',
                        "layout": { "lg": 6, "md": 6, "sm": 6, "xs": 6 },
                        'label': 'Diploma type',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please select degree type',
                        'icon': '',
                        'options': [
                            { "label": "Masters", "value": "Masters" },
                            { "label": "Bachelors", "value": "Bachelors" },
                        ]
                    },
                    {
                        'order': 3,
                        'id': 'completed_year',
                        'type': 'date',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'When was your degree issued?',
                        'value': '',
                        'placeholder': 'Ex: 3',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid year.',
                        'icon': 'business',
                    },
                    {
                        'order': 4,
                        'id': 'score',
                        'type': 'input',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Marks',
                        'value': '',
                        'placeholder': 'Marks in %',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid percent.',
                        'icon': 'business',
                    },
                    {
                        'order': 5,
                        'id': 'file',
                        'type': 'file',
                        'fileName':'eduFileDegree',
                        "layout": { "lg": 6, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Upload Degree/Diploma document',
                        'value': '',
                        'error': false,
                        'mandatory': 0,

                    },
                       {
                        'order': 6,
                        'id': 'file_1',
                        'type': 'file',
                        'fileName':'eduFileMarksheet',
                        "layout": { "lg": 6, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Transcript/Marksheet',
                        'value': '',
                        'error': false,
                        'mandatory': 0,

                    },
                    ]
                },
                {
                    name: "Language Proficiency",
                    title: "Language Proficiency",
                    input: [
                        {
                            'order': 0,
                            'id': 'study_option',
                            'type': 'select',
                            "layout": { "lg": 6, "md": 6, "sm": 6, "xs": 6 },
                            'label': 'Language proficiency Type',
                            'value': '',
                            'placeholder': 'Name',
                            'error': false,
                            'mandatory': 0,
                            'errorText': 'Please select Language Proficiency type',
                            'icon': '',
                            'options': [
                                { "label": "IELTS", "value": "ielts" },
                                { "label": "TOEFL", "value": "toefl" },
                                { "label": "PTE", "value": "pte" },

                            ]
                        },
                        {
                            'order': 1,
                            'id': 'first_name',
                            'type': 'input',
                            "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                            'label': 'Overall',
                            'value': '',
                            'placeholder': 'Ex: 6',
                            'error': false,
                            'mandatory': 0,
                            'errorText': 'Please enter valid score.',
                            'icon': 'business',
                        },
                        {
                            'order': 2,
                            'id': 'listening',
                            'type': 'input',
                            "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                            'label': 'Listening',
                            'value': '',
                            'placeholder': 'Ex: 6',
                            'error': false,
                            'mandatory': 0,
                            'errorText': 'Please enter valid score.',
                            'icon': 'business',
                        },
                        {
                            'order': 3,
                            'id': 'reading',
                            'type': 'input',
                            "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                            'label': 'Reading',
                            'value': '',
                            'placeholder': 'Ex: 6',
                            'error': false,
                            'mandatory': 0,
                            'errorText': 'Please enter valid score.',
                            'icon': 'business',
                        },
                        {
                            'order': 4,
                            'id': 'speaking',
                            'type': 'input',
                            "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                            'label': 'Speaking',
                            'value': '',
                            'placeholder': 'Ex: 6',
                            'error': false,
                            'mandatory': 0,
                            'errorText': 'Please enter valid score.',
                            'icon': 'business',
                        },
                        {
                            'order': 5,
                            'id': 'writing',
                            'type': 'input',
                            "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                            'label': 'Writing',
                            'value': '',
                            'placeholder': 'Ex: 6',
                            'error': false,
                            'mandatory': 0,
                            'errorText': 'Please enter valid score.',
                            'icon': 'business',
                        },
                        
                        {
                            'order': 6,
                            'id': 'file',
                            'type': 'file',
                            'fileName':'langFile',
                            "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                            'label': 'Upload Language proficiency document',
                            'value': '',
                            'error': false,
                            'mandatory': 0,
                            "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },

                        }
                    ]
                },
                {
                    name: "Experience",
                    title: "Experience",
                    input: [{
                        'order': 0,
                        'id': 'company_name',
                        'type': 'input',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Company Name',
                        'value': '',
                        'placeholder': 'Enter company name which you have worked for.',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid value',
                        'icon': 'business',
                    }, {
                        'order': 1,
                        'id': 'experience',
                        'type': 'input',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Duration',
                        'value': '',
                        'placeholder': 'Enter duration in years Ex: 2',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid duration.',
                        'icon': 'business',
                    }
                    ,
                    {
                        'order': 2,
                        'id': 'file',
                        'type': 'file',
                        'fileName':'expFile',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Upload experience document',
                        'value': '',
                        'error': false,
                        'mandatory': 0,
                        "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },

                    }
                ]
                },
                {
                    name: "Key Highlights",
                    title: "Key Highlights",
                    input: [{
                        'order': 0,
                        'id': 'key_highlights',
                        'type': 'textarea',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Key Highlights',
                        'value': '',
                        'placeholder': 'Enter key highlights',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid details.',
                        'icon': 'business',
                    }]
                },
                {
                    name: "Statistical Data",
                    title: "Statistical Data",
                    input: [{
                        'order': 0,
                        'id': 'come_to_know_us',
                        'type': 'checkbox',
                        "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                        'label': 'How have you come to known us?',
                        'value': [],
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please select values.',
                        'icon': '',
                        'options': [
                            { "label": "Media", "value": "media" },
                            { "label": "External advertising", "value": "external_advertising" },
                            { "label": "Digital advertising", "value": "Digital_advertising" },
                            { "label": "Counseior or Institute", "value": "Counseior_or_Institute" },
                            { "label": "Internet", "value": "internet" },
                            { "label": "Sports", "value": "sports" },
                            { "label": "Friend or acqaintance", "value": "friend_or_acqaintance" },
                            { "label": "Coliaborating centers", "value": "coliaborating_centers" },


                        ]
                    },
                    {
                        'order': 1,
                        'id': 'factor_consider',
                        'type': 'checkbox',
                        "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                        'label': 'Factors considered most important when choosing university',
                        'value': [],
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please select values.',
                        'icon': '',
                        'options': [
                            { "label": "Price", "value": "price" },
                            { "label": "Closeness", "value": "closeness" },
                            { "label": "Reputation and ranking", "value": "reputation_and_ranking" },
                            { "label": "Personal attention", "value": "personal_attention" },
                            { "label": "Campus service", "value": "campus_service" },
                            { "label": "Investigation", "value": "investigation" },
                            { "label": "Sports service and activities", "value": "sports_service_and_activities" },
                            { "label": "Ideology or Religion", "value": "ideology_or_religion" },
                            { "label": "International mobility", "value": "international_mobility" },
                            { "label": "Facilities and matarials", "value": "facilities_and_matarials" },


                        ]
                    },

                    ]
                },
                {
                    name: "Payment",
                    title: "Payment",
                    input: [
                        {
                            'order': 0,
                            'id': 'payment_method',
                            'type': 'select',
                            "layout": { "lg": 6, "md": 6, "sm": 6, "xs": 6 },
                            'label': 'Payment method',
                            'value': '',
                            'placeholder': 'Name',
                            'error': false,
                            'mandatory': 1,
                            'errorText': 'Please select Payment option',
                            'icon': '',
                            'options': [
                                { "label": "By Bank - Flywire", "value": "wire" },

                            ]
                        }]
                }
                ]
            };
    }
    componentDidMount() {
        const { match: { params }, courses } = this.props
        let org_id = params.org_id
        this.props.courseactions.getOrganizationStudentsCourses(org_id);
    }

    static getDerivedStateFromProps(props, state) {
        const { courses: { course } } = props
        let courses = []
        if (course && state.courses !== course) {
            courses = course.map(item => {
                //  console.log("hiiiiiii>>>>>>", state.cards[0].input[0].value.value, ">>>>>", item.course_type);
                if(state.cards[0].input[0].value.value == item.course_type){
                    return { "label": item.course_name, "value": item.course_name }
                }
                else {
                    return {}
                }

            });
            let { cards } = state;
            const input = cards[0].input;
            courses = courses.filter(value => Object.keys(value).length !== 0);
            // console.log("courses>>>>>>>><<<<<<<<<<<<<<<<<<<<<", courses)
            input[1].options = courses;
            cards[0].input = input; 
            return {
                courses,
                cards
            }
    }
}

    updateValue = (e, key) => {
        if (key !== undefined ) {
            let { cards, step } = this.state;
            const input = cards[step].input;
            input[key].value = e.target.value;
            cards[step].input = input;
            this.setState({
                cards,
            });
        }
    };
    updateStep = (newStep) => {
        const { cards, step } = this.state;
        const
            input = validation(cards[step].input);
        cards[step].input = input;
        this.setState({
            cards,
        });
        if (input.valid) {
            this.setState({ step: newStep });
        }
    }

    onDropFile=(file,name, key)=>{
        const formData = new FormData()
        formData.append('doc_file',file[0]);
        
        const handleSuccess = (body) => {
            Toast.show({
                message: "File uploaded Successfully",
                timeout: 4000,
                intent: 'success'
            })
            if (key !== undefined ) {
                let { cards, step } = this.state;
                const input = cards[step].input;
                input[key].value = file[0];
                input[key].path = body.data;
                cards[step].input = input;
                this.setState({
                    cards,
                });
            }
        }
        const handleFailure = () => {
            Toast.show({
                message: "File upload failed",
                timeout: 100000,
                intent: 'danger'
            })
        }
        this.props.integrationAction.documentUpload(handleSuccess,handleFailure, formData)
    }
   
    submit = () => {
        const { match: { params } } = this.props
        const queryParams = new URLSearchParams(this.props.location.search);
        const id = queryParams.get('id') ? queryParams.get('id') : '';
        const { cards } = this.state;
        const { user: { user }, user: { organization } } = this.props
        console.log("afsfsdfdsf",cards[1])
        const form =
            [{
                "first_name": cards[1].input[0].value,
                "middle_name": cards[1].input[1].value,
                "last_name": cards[1].input[2].value,
                "organization_id": params.org_id,
                "financial_capacity": null,
                "v_id": null,
                "address": cards[1].input[7].value,
                "email": cards[1].input[4].value,
                "phone_number": cards[1].input[5].value,
                "gap": 0,
                "regional_cap": null,
                "student_status": "1",
                "status": null,
                "assigned_id":null,
                "user_id": id,
                "applied_course": cards[0].input[1].value.value,
                "course_type": cards[0].input[0].value.value,
                "permanence_type": cards[0].input[2].value,
                "student_comment": cards[0].input[3].value,
                "gender": cards[1].input[3].value,
                "dob": cards[1].input[6].value,
                "country": cards[1].input[8].value.value,
                "provice_state": cards[1].input[9].value,
                "city_town": cards[1].input[10].value,
                "postal_code": cards[1].input[11].value,
                "home_university": cards[2].input[0].value,
                "come_to_know_us": cards[6].input[0].value.toString(),
                "factor_consider": cards[6].input[1].value.toString(),
                "payment_method": cards[7].input[0].value.value,
                "identity_proof": `https://zoomabroadv2.s3.amazonaws.com/${cards[1].input[12].path}`,
                "academic": [
                    {
                        "degree_name": cards[2].input[1].value,
                        "degree_type": cards[2].input[2].value.value,
                        "completed_year": cards[2].input[3].value,
                        "doc_url_degree":`https://zoomabroadv2.s3.amazonaws.com/${cards[2].input[5].path}`,
                        "doc_url_marksheet":`https://zoomabroadv2.s3.amazonaws.com/${cards[2].input[6].path}`,
                        "score": cards[2].input[4].value,
                        "verified": 0
                    }
                ],
                "experience": [
                    {

                        "company_name": cards[4].input[0].value,
                        "experience": cards[4].input[1].value,
                        "exp_doc_url":`https://zoomabroadv2.s3.amazonaws.com/${cards[4].input[2].path}`,
                        "exp_verified": null
                    }
                ],
                "letter_of_recommendation": [
                    {

                        "doc_url": null,
                        "verified": null
                    }
                ],
                "sop": [
                    {

                        "SOP_doc_url": null,
                        "SOP_verified": null
                    }
                ],
                "key_highlights": [
                    {
                        "key_highlights": cards[5].input[0].value,
                        "level":3
                    }
                ],
                "language_efficiency": [
                    {
                        "ielts_score": cards[3].input[0].value.value == 'ielts' ? cards[3].input[1].value : null,
                        "toefl_score": cards[3].input[0].value.value == 'toefl' ? cards[3].input[1].value : null,
                        "pte_score": cards[3].input[0].value.value == 'pte' ? cards[3].input[1].value : null,
                        "listening": cards[3].input[2].value,
                        "speaking": cards[3].input[4].value,
                        "writing": cards[3].input[5].value,
                        "reading": cards[3].input[3].value,
                        "exemptions_equivalent": null,
                        "second_language": null,
                        "lang_doc_url":`https://zoomabroadv2.s3.amazonaws.com/${cards[3].input[6].path}`
                    }],
                "competetive_test": [
                    {
                        "gre": null,
                        "gmat": null,
                        "sat": null,
                        "act": null
                    }
                ]
            }];
        const handleCreateSuccess = () => {
            Toast.show({
                message: "Form submitted Successfully",
                timeout: 4000,
                intent: 'success'
            })
            this.props.history.push('/studentsuccess')
        }
        const handleFailure = () => {
            Toast.show({
                message: "Could not submit form, Please try again",
                timeout: 100000,
                intent: 'danger'
            })
        }
        console.log(form, "formformform");
        this.props.integrationAction.createStudent(form, handleCreateSuccess, handleFailure)
    }
    render() {
        const { cards, step ,eduFileDegree} = this.state;
        
        return (
            <FormMarkup
                isLoading={this.props.isLoading}
                isLoadingIntegration={this.props.isLoadingIntegration}
                updateValue={this.updateValue}
                cards={cards}
                step={step}
                updateStep={this.updateStep}
                submit={this.submit}
                onDropFile={this.onDropFile}
                summaryStep = {7}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.Auth.isLoading,
        isLoadingIntegration: state.Integration.isLoading,
        isCSV: state.students.isLoading,
        error: state.Auth.error,
        user: state.Auth.user,
        courses: state.courses.courseList,

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
        integrationAction: bindActionCreators(integrationAction, dispatch),
        courseactions: bindActionCreators(courseactions, dispatch),

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UCAMForm));