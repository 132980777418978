import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import { withRouter, } from 'react-router-dom';
import { Button, Collapse } from "@blueprintjs/core";
import Dustbin from './Dustbin'
import QuestionsContainer from './QuestionsContainer';
import Paginator from '../pagination/pagination'
import Box from './Box'
import './DragAndDropComponent.scss'

class DragAndDropComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
        }
    }
    
    // handleClick = () => {
    //     this.setState({ isOpen: !this.state.isOpen });
    // }
    createQuestions = () => {
        this.props.wizard ?  this.props.gotoStep("Question") : this.props.history.push('/questions/settings');
    }
    render() {
        const { props } = this;
        const questionSet = props.questions ? props.questions :props.questions
        return (
            <Row className="drag-drop-container">
                 <Col md={12}>
                    <Row>
                        {props.questionAccess[1]==1?<Col md={2}><Button className="createQuestions" onClick={this.createQuestions} >
                            Create Questions
                            </Button>
                        </Col>:''}
                        <Col md={4}>
                        <input class="bp3-input .modifier"
                            type="text"
                            placeholder="Question Search"
                            dir="auto"
                            onKeyUp={props.onChangeSearch}
                        />
                           <Button className="search-button btn btn-info" onClick={props.onSearch} >Search</Button> 
                           </Col>
                        {/* <Col >  <Button className="hideQuestions" onClick={this.handleClick} hidden={props.disabled}>
                            {this.state.isOpen ? "Hide" : "Click Here To Add Your "} Questions
                            </Button>
                        </Col> */}
                    </Row>
                </Col>
                <Col md={6}>
                    <Collapse isOpen={this.state.isOpen}>
                        <Col className="bp3-card">
                            {
                            props.questionAccess[0]==1 &&questionSet ?  questionSet.map((question) => {
                                    return <Box item={question} onDrop={props.onDrop} onClickAdd={props.onClickAdd}/>
                                })
                                : []
                            }
                              <Paginator
                                 count={props.count}
                                 pages={props.pages}
                                 onPageChange={props.onPageChange}
                                 itemRender={props.itemRender}
                                 pageIndex={props.pageIndex}

                              />
                        </Col>
                    </Collapse>
                </Col>
                <Col md={6}>
                    { props.disabled ? <QuestionsContainer {...props}/> : <Dustbin
                        {...props}
                    /> }
                </Col>
               
            </Row>
        )
    }
}

export default withRouter(DragAndDropComponent) ;