import React, { Component } from "react";
import { DateInput } from "@blueprintjs/datetime";
import { RadioGroup, Radio } from "@blueprintjs/core";
import moment from 'moment';
import {
    Label
} from "@blueprintjs/core";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Row, Col, Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { CLOUDFROND_ASSETS3_URL } from '../../../../../env'
import integrationAction from '../../../../Integration/modules/actions'
import action from '../../../modules/actions'
import { Toast } from "../../../../../shared/toast/Toast";
import './VisaGranted.scss';
class VisaGranted extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visaGrantedDate: new Date(),
            visaGrantedDoc: '',
            id: '',
            docFile: '',
            docUrl: '',
            errors:{}
        }
    }

    componentDidMount() {
        const { applicationStatusId } = this.props;
        // if(applicationStatusId && applicationStatusId){
        // this.props.action.getAppVisaGranted(applicationStatusId)
        // }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.visaGranted && props.visaGranted.length) {
            if (props.visaGranted[0].id != state.id) {
                return {
                    visaGrantedDate: props.visaGranted[0].visa_granted_date? new Date(props.visaGranted[0].visa_granted_date): new Date(),
                    docUrl: props.visaGranted[0].visa_granted_doc,
                    id: props.visaGranted[0].id
                };
            }
        }
        // else {
        //     return {
        //         visaGrantedDate: new Date(),
        //         visaGrantedDoc: '',
        //         id: '',
        //         docFile: '',
        //         docUrl: '',
        //         errors:{}
        //     }
        // }
    }
    onDropFile = (event) => {
        this.setState({
            docFile: event.target.files[0]
        });
    }
    clearForm = () =>{
        this.setState({
            visaGrantedDate: new Date(),
            visaGrantedDoc: '',
            id: '',
            docFile: '',
            docUrl: '',
            errors:{}
        })
        this.props.setClearForm()
    }
    isvalid = () => {
        const { visaGrantedDate, docFile } = this.state;
        let errors = {};
        if(visaGrantedDate == ''){
            errors.visaGrantedDate = true;
            this.setState ({ errors });
            return false;
          }
          if(docFile == ''){
            errors.docFile = true;
            this.setState ({ errors });
            return false;
          }
          return true;
    };
    onSubmit = () => {
        const { visaGrantedDate, docFile, docUrl } = this.state
        const { match: { params }, userProfile, onCloseModal, applicationStatusId } = this.props;
        if(this.isvalid()){

        if (docFile) {
            const formData = new FormData()
            formData.append('doc_file', docFile);

            const handleSuccess = (response) => {

                const data = {
                    'student_id': params.id,
                    'user_id': userProfile.organization.user_id,
                    'organization_id': userProfile.organization.organization_id,
                    'visa_granted_date': visaGrantedDate,
                    'visa_granted_doc': `${CLOUDFROND_ASSETS3_URL}/${response.data}`,
                    'application_id': applicationStatusId
                }
                const handlesuccessfull = () => {
                    Toast.show({
                        message: 'Save successfully',
                        timeout: 4000,
                        intent: 'success'
                    });
                    onCloseModal()
                    this.setState({
                        visaGrantedDate: new Date(),
                        visaGrantedDoc: '',
                        id: '',
                        docFile: '',
                        docUrl: ''
                    })
                };
                const handleFailure = () => {
                    Toast.show({
                        message: 'Save unsuccessfully',
                        timeout: 4000,
                        intent: 'danger'
                    });
                    onCloseModal()
                    this.setState({
                        visaGrantedDate: new Date(),
                        visaGrantedDoc: '',
                        id: '',
                        docFile: '',
                        docUrl: ''
                    })
                }
                this.props.action.createApplicationStatus(data, handlesuccessfull, handleFailure, 'granted')
            }
            const handleDocFailure = () => { }

            this.props.integrationAction.documentUpload(handleSuccess, handleDocFailure, formData)
        }
        else {
            const data = {
                'student_id': params.id,
                'user_id': userProfile.organization.user_id,
                'organization_id': userProfile.organization.organization_id,
                'visa_granted_date': visaGrantedDate,
                'visa_granted_doc': docFile,
                'application_id': applicationStatusId
            }
            const handlesuccessfull = () => {
                Toast.show({
                    message: 'Save successfully',
                    timeout: 4000,
                    intent: 'success'
                });
                onCloseModal()
                this.setState({
                    visaGrantedDate: new Date(),
                    visaGrantedDoc: '',
                    id: '',
                    docFile: '',
                    docUrl: ''
                })
            };
            const handleFailure = () => {
                Toast.show({
                    message: 'Save unsuccessfully',
                    timeout: 4000,
                    intent: 'danger'
                });
                onCloseModal()
                this.setState({
                    visaGrantedDate: new Date(),
                    visaGrantedDoc: '',
                    id: '',
                    docFile: '',
                    docUrl: ''
                })
            }
            this.props.action.createApplicationStatus(data, handlesuccessfull, handleFailure, 'granted')
        }
    }
    }

    onChangeInput = (name, value) => {
        this.setState({
            [name]: value, errors:{}
        })
    }
    userDocument = (doc_url) => {
        if (doc_url) {
            return (
                <a href={`${doc_url}`}>
                    <span>
                        <OverlayTrigger
                            placement={'top'}
                            delay={{ hide: 200 }}
                            overlay={<Popover className="tool-tip" id="tooltip-disabled">Download</Popover>}>
                            <i class="fa fa-cloud-download" aria-hidden="true"></i>
                        </OverlayTrigger>
                    </span>
                </a>

            );
        }
    }
    render() {
        const { visaGrantedDate, docUrl, errors } = this.state;
        const { clearModel } =this.props
        if(!clearModel){
            this.clearForm()
        }
        return (
            <div className="visa-applied">
                <Col>
                    <Label className="applied-date" htmlFor="input-b">Granted date *
                <DateInput
                            formatDate={date => moment(date).format("DD/MM/YYYY")}
                            onChange={(date) => this.onChangeInput("visaGrantedDate", date)}
                            parseDate={str => new Date(str)}
                            placeholder={"DD/MM/YYYY"}
                            defaultValue={new Date()}
                            maxDate={new Date('1/1/2030')}
                            value={visaGrantedDate}
                        />
                    </Label>
                    {errors.visaGrantedDate ? <span className="errors">Please Enter Visa Granted Date </span> : ''}
                </Col>
                {
                    docUrl && docUrl ? <Col>
                        {this.userDocument(docUrl)}
                    </Col> : ''}
                <Col>
                    <Form.File
                        id="custom-file"
                        label="Upload Document *"
                        val
                        accept=".png, .jpg, .jpeg, .pdf"
                        name="docFile"
                        onChange={this.onDropFile}
                    />
                </Col>
                {errors.docFile ? <span className="errors">Please Upload Document File (".png, .jpg, .jpeg, .pdf")</span> : ''}
                <Col className="button-save">
                    <Button className="save-offer" onClick={this.onSubmit}>Save</Button>
                </Col>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        visaGranted: state.students.visaGranted
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators(action, dispatch),
        integrationAction: bindActionCreators(integrationAction, dispatch),

    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VisaGranted));
