import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { components } from 'react-select';
import { bindActionCreators } from 'redux';
import CallingReportMarkup from '../components/CallingReportMarkup'
import Denied from '../../../shared/denied/Denied'
import moment from 'moment';
import actions from '../modules/actions'
import memberActions from '../../Members/modules/actions'
import _ from 'lodash'

class CallingReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start_date: moment().add(-1, "weeks").toDate(),
            end_date: moment().add(0, "days").toDate(),
            yopmailVal: false,
            memberValue:'',
            viewAccess: true,
            leadStatusValue: [],
            SelectedLeadStatus:[],
        }
    }
    static getDerivedStateFromProps(props, state) {
        const { allLeads , user} = props
    
            const access = user.organization.access
    
    
            let finalData = access ? JSON.parse(access) : [{ "operations": [1, 1, 1, 1], "name": "MAPPING" }, { "operations": [1, 1, 1, 1], "name": "QUESION" }, { "operations": [1, 1, 1, 1], "name": "INTERVIEW" }, { "operations": [1, 1, 1, 1], "name": "COURSE" }, { "operations": [1, 1, 1, 1], "name": "REPORT" }, { "operations": [1, 1, 1, 1], "name": "STUDENTS" }, {
          "name":"DASHBOARD",
          "operations":[
             1,
             0,
             0,
             0
          ]
       }, {
          "name":"COUNSELOR-REPORT",
          "operations":[
             1,
             0,
             0,
             0
          ]
       }]
       const dashboard = _.find(finalData, { "name" : "COUNSELOR-REPORT"})
       
          let viewAccess = false
    
          if(dashboard && dashboard){
            if(dashboard.operations[0] == 1){
              viewAccess = true
              }
          }
          if(!user.user.is_counselor){
            viewAccess = true
          }
          console.log("viewAccess", viewAccess)
          return {viewAccess}
          
    
        
      }
    componentDidMount() {
        const { user: { organization, user } } = this.props;
        const { start_date, end_date, yopmailVal } = this.state;
        const yopmail = JSON.parse(localStorage.getItem('yopmail'));

        let startdate = start_date ? moment(start_date).format("YYYY-MM-DD HH:mm:ss") : '';
        let enddate = end_date ? moment(end_date).format("YYYY-MM-DD HH:mm:ss") : '';
        this.props.memberActions.getAllMembers(organization.organization_id);

        // this.props.actions.getCustomerReport(organization.organization_id, startdate, enddate, user.email);

    }
    handleRangeChange = (event) => {
        this.setState({ start_date: event[0], end_date: event[1] })
    }

    getreport = (value) => {
        const { user: { organization, user } } = this.props;
        const { start_date, end_date, memberValue, leadStatusValue,SelectedLeadStatus } = this.state;        let statuses = leadStatusValue && leadStatusValue.length ? leadStatusValue : [];
        let selectedstatuses = SelectedLeadStatus && SelectedLeadStatus.length ? SelectedLeadStatus : [];
        this.setState({SelectedLeadStatus:selectedstatuses,leadStatusValue:statuses})
        const yopmail = JSON.parse(localStorage.getItem('yopmail'));

        let startdate = start_date ? moment(start_date).format("YYYY-MM-DD") : '';
        let enddate = end_date ? moment(end_date).format("YYYY-MM-DD 23:59:00") : '';
        if(memberValue == '') alert("Please select Owner")
        else this.props.actions.getCallingReport(organization.organization_id, startdate, enddate, user.email, memberValue,statuses);
    }
    onChangeInput = (name, value) => { 
        const {leadStatusValue,SelectedLeadStatus} = this.state;
        if(name == 'leadStatusValue')
        {
            if(leadStatusValue.indexOf(`'${value}'`) >= 0)
            { 
                return
            }
            else {
                    leadStatusValue.push(`'${value}'`);
                    const spacesReplaced = value.replaceAll('_', ' ');
                    let showvalue = spacesReplaced.charAt(0).toUpperCase() + spacesReplaced.slice(1);
                    SelectedLeadStatus.push(`'${showvalue}'`);
                    this.setState({leadStatusValue,SelectedLeadStatus});
            }
        }
        else {
            this.setState({
                [name]: value,
            })
        }
    }    
    onDeselect = (value) => {   
        const {leadStatusValue,SelectedLeadStatus} = this.state;
        let SelectedLeadStatusarr = SelectedLeadStatus.filter(e => e !== value);      
        const spacesReplaced = value.replaceAll(' ', '_');
        let showvalue = spacesReplaced.toLowerCase();     
        let leadStatusValuearr = leadStatusValue.filter(e => e !== showvalue);
        this.setState({leadStatusValue:leadStatusValuearr,SelectedLeadStatus:SelectedLeadStatusarr});
    }
    render() {
        const { start_date, end_date, memberValue, viewAccess,leadStatusValue,SelectedLeadStatus } = this.state
        const { allMembers, isLoading, callingReport } = this.props;
        return (
            viewAccess ?
            <CallingReportMarkup
                start_date={start_date}
                end_date={end_date}
                onChangeInput={this.onChangeInput}
                getreport={this.getreport}
                handleRangeChange={this.handleRangeChange}
                allMembers={allMembers && allMembers ? allMembers : []}
                isLoading={isLoading}
                memberValue={memberValue}
                callingReport={callingReport && callingReport ? callingReport : []}
                leadStatusValue={leadStatusValue}
                SelectedLeadStatus={SelectedLeadStatus}
                onDeselect={this.onDeselect}
            />
            : <Denied/>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.customerReport.isLoading,
        user: state.Auth.user,
        allMembers: state.members.allMembers,
        callingReport: state.customerReport.callingReport       
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
        memberActions:bindActionCreators(memberActions, dispatch),

    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CallingReport))