import React from 'react';
import { Overlay, Spinner, Intent } from "@blueprintjs/core";
import './ZLoader.scss';

const ZLoader = () => { 
    return (
        <Overlay className="zloader" isOpen={true}>
            <Spinner className="spinner" intent={Intent.PRIMARY} size={50} />
        </Overlay>
    );
};
export { ZLoader };
