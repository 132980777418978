import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import "./Thanks.scss"
import hubs from '../../../img/thanks.png'


const Thanks = (props) => {
    return (
        <Container className="thanks">
            <div className="thanks-img">
            <img src={hubs} 
            height={600}/>
                
            </div>


        </Container>
    );
}

export default Thanks;