import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Row, Col, Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { CLOUDFROND_ASSETS3_URL } from '../../../../../env'
import integrationAction from '../../../../Integration/modules/actions'
import action from '../../../modules/actions'
import { Toast } from "../../../../../shared/toast/Toast";
import './VisaRefused.scss';
class VisaRefused extends Component {
    constructor(props) {
        super(props)
        this.state = {
            VisaRefusedVal: '',
            financial: false,
            credibility_interview: false,
            immigration_history: false,
            language: false,
            other: false,
            visaRefusalDoc: '',
            id: '',
            docFile: '',
            docUrl: '',
            errors:{}
        }
    }
    componentDidMount() {
        const { applicationStatusId } = this.props;
        // if(applicationStatusId && applicationStatusId){
        // this.props.action.getAppVisaRefused(applicationStatusId)
        // }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.refusalData && props.refusalData.length) {
            if (props.refusalData[0].id != state.id) {
                return {
                    VisaRefusedVal: props.refusalData[0].visa_refused_comment,
                    financial: props.refusalData[0].financial,
                    credibility_interview: props.refusalData[0].credibility_interview,
                    immigration_history: props.refusalData[0].immigration_history,
                    language: props.refusalData[0].language,
                    other: props.refusalData[0].other,
                    docUrl: props.refusalData[0].visa_refusal_doc,
                    id: props.refusalData[0].id
                };
            }
        }
        // else {
        //     return {
        //         VisaRefusedVal: '',
        //     financial: false,
        //     credibility_interview: false,
        //     immigration_history: false,
        //     language: false,
        //     other: false,
        //     visaRefusalDoc: '',
        //     id: '',
        //     docFile: '',
        //     docUrl: '',
        //     errors:{}
        //     }
        // }
    }
    onDropFile = (event) => {
        this.setState({
            docFile: event.target.files[0]
        });
    }
    clearForm = () =>{
        this.setState({
            VisaRefusedVal: '',
            financial: false,
            credibility_interview: false,
            immigration_history: false,
            language: false,
            other: false,
            visaRefusalDoc: '',
            id: '',
            docFile: '',
            docUrl: '',
            errors:{}
        })
        this.props.setClearForm()
    }
    isvalid = () => {
        const { VisaRefusedVal, financial, credibility_interview, immigration_history, language, other, docFile } = this.state;
        let errors = {};
        if (financial == '' && credibility_interview == '' && immigration_history == '' && language == '' && other == '') {
            errors.visaerror = true;
            this.setState({ errors });
            return false;
        }
        if(/* !other == '' &&  */VisaRefusedVal == ''){
            errors.VisaRefusedVal = true;
            this.setState ({ errors });
            return false;
          }
        if (docFile == '') {
            errors.docFile = true;
            this.setState({ errors });
            return false;
        }
        
        return true;
    };
    onSubmit = () => {
        const { VisaRefusedVal, financial, credibility_interview, immigration_history, language, other, docFile, docUrl } = this.state
        const { match: { params }, userProfile, onCloseModal, applicationStatusId } = this.props;
        if(this.isvalid()){
        if (docFile) {
            const formData = new FormData()
            formData.append('doc_file', docFile);

            const handleSuccess = (response) => {
                const data = {
                    'student_id': params.id,
                    'user_id': userProfile.organization.user_id,
                    'organization_id': userProfile.organization.organization_id,
                    'visa_refused_comment': VisaRefusedVal,
                    'financial': financial,
                    'credibility_interview': credibility_interview,
                    'immigration_history': immigration_history,
                    'language': language,
                    'other': other,
                    'visa_refusal_doc': `${CLOUDFROND_ASSETS3_URL}/${response.data}`,
                    'application_id': applicationStatusId
                }
                const handlesuccessfull = () => {
                    Toast.show({
                        message: 'Save successfully',
                        timeout: 4000,
                        intent: 'success'
                    });
                    onCloseModal()
                    this.setState({
                        VisaRefusedVal: '',
                        financial: false,
                        credibility_interview: false,
                        immigration_history: false,
                        language: false,
                        other: false,
                        visaRefusalDoc: '',
                        id: '',
                        docFile: '',
                        docUrl: ''
                    })
                };
                const handleFailure = () => {
                    Toast.show({
                        message: 'Save unsuccessfully',
                        timeout: 4000,
                        intent: 'danger'
                    });
                    onCloseModal()
                    this.setState({
                        VisaRefusedVal: '',
                        financial: false,
                        credibility_interview: false,
                        immigration_history: false,
                        language: false,
                        other: false,
                        visaRefusalDoc: '',
                        id: '',
                        docFile: '',
                        docUrl: ''
                    })
                }
                this.props.action.createApplicationStatus(data, handlesuccessfull, handleFailure, 'refusal')
            }
            const handleDocFailure = () => { }

            this.props.integrationAction.documentUpload(handleSuccess, handleDocFailure, formData)
        }
        else {
            const data = {
                'student_id': params.id,
                'user_id': userProfile.organization.user_id,
                'organization_id': userProfile.organization.organization_id,
                'visa_refused_comment': VisaRefusedVal,
                'financial': financial,
                'credibility_interview': credibility_interview,
                'immigration_history': immigration_history,
                'language': language,
                'other': other,
                'visa_refusal_doc': docUrl,
                'application_id': applicationStatusId
            }
            const handlesuccessfull = () => {
                Toast.show({
                    message: 'Save successfully',
                    timeout: 4000,
                    intent: 'success'
                });
                onCloseModal()
                this.setState({
                    VisaRefusedVal: '',
                    financial: false,
                    credibility_interview: false,
                    immigration_history: false,
                    language: false,
                    other: false,
                    visaRefusalDoc: '',
                    id: '',
                    docFile: '',
                    docUrl: ''
                })
            };
            const handleFailure = () => {
                Toast.show({
                    message: 'Save unsuccessfully',
                    timeout: 4000,
                    intent: 'danger'
                });
                onCloseModal()
                this.setState({
                    VisaRefusedVal: '',
                    financial: false,
                    credibility_interview: false,
                    immigration_history: false,
                    language: false,
                    other: false,
                    visaRefusalDoc: '',
                    id: '',
                    docFile: '',
                    docUrl: ''
                })
            }
            this.props.action.createApplicationStatus(data, handlesuccessfull, handleFailure, 'refusal')
        }
    }
    }


    onChangeInput = (name, value) => {
        this.setState({
            [name]: value,errors:{}
        })
    }
    userDocument = (doc_url) => {
        if (doc_url) {
            return (
                <a href={`${doc_url}`}>
                    <span>
                        <OverlayTrigger
                            placement={'top'}
                            delay={{ hide: 200 }}
                            overlay={<Popover className="tool-tip" id="tooltip-disabled">Download</Popover>}>
                            <i class="fa fa-cloud-download" aria-hidden="true"></i>
                        </OverlayTrigger>
                    </span>
                </a>

            );
        }
    }
    render() {
        const { VisaRefusedVal, financial, credibility_interview, immigration_history, language, other, docUrl, errors } = this.state;
        const { clearModel } =this.props
        if(!clearModel){
            this.clearForm()
        }
        return (
            <div className="conditional">
                <Col>
                    <Form.Label className="title">Visa refused *</Form.Label>
                    <Row>
                        <Form.Group >
                            <Form.Check
                                name="financial"
                                type="checkbox"
                                label="Financial"
                                className="check-box"
                                value={financial}
                                checked={ financial ? true : false}
                                onChange={(event) => this.onChangeInput('financial', event.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Check
                                name="credibility_interview"
                                type="checkbox"
                                label="Credibility interview"
                                className="check-box"
                                value={credibility_interview}
                                checked={ credibility_interview ? true : false}
                                onChange={(event) => this.onChangeInput('credibility_interview', event.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Check
                                name="immigration_history"
                                type="checkbox"
                                label="Immigration history"
                                className="check-box"
                                value={immigration_history}
                                checked={ immigration_history ? true : false}
                                onChange={(event) => this.onChangeInput('immigration_history', event.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Check
                                name="language"
                                type="checkbox"
                                label="Language"
                                className="check-box"
                                value={language}
                                checked={ language ? true : false}
                                onChange={(event) => this.onChangeInput('language', event.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Check
                                name="other"
                                type="checkbox"
                                label="Other"
                                className="check-box"
                                value={other}
                                checked={ other ? true : false}
                                onChange={(event) => this.onChangeInput('other', event.target.checked)}
                            />
                        </Form.Group>
                    </Row>
                    {errors.visaerror ? <span className="errors">Please Select Appropriate Visa Refusal Check Box</span> : ''}
                </Col>
                <Col>
                    <Form.Label className="title">Comment *</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={2}
                        placeholder="Reason for Visa Refusal"
                        className="form-control text-box"
                        value={VisaRefusedVal}
                        onChange={(event) => this.onChangeInput('VisaRefusedVal', event.target.value)}
                    />
                    {errors.VisaRefusedVal ? <span className="errors">Please Enter Reason for Visa Refusal</span> : ''}
                </Col>
                {
                    docUrl && docUrl ? <Col>
                        {this.userDocument(docUrl)}
                    </Col> : ''}
                <Col>
                    <Form.File
                        id="custom-file"
                        label="Upload Document *"
                        val
                        accept=".png, .jpg, .jpeg, .pdf"
                        name="docFile"
                        onChange={this.onDropFile}
                    />
                </Col>
                {errors.docFile ? <span className="errors">Please Upload Document File (".png, .jpg, .jpeg, .pdf")</span> : ''}
                <Col>
                    <Button className="save-offer" onClick={this.onSubmit}>Save</Button>
                </Col>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        refusalData: state.students.refusalData
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators(action, dispatch),
        integrationAction: bindActionCreators(integrationAction, dispatch)
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VisaRefused));
