import './ProficiencyUpdate.scss';
import React, { Component } from 'react';
import {
  Row, Col, Button, Container, Form, Table,
} from 'react-bootstrap';

class ProficiencyUpdate extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      proficiencyDetilsState,
      addFile,
      deleteProficiency,
      onProficiencyUpdate,
      proficiencyType,
      onChangeInput,
      listening,
      reading,
      writing,
      speaking,
      overall,
      errors,
      proficiencyEdit
    } = this.props;
    console.log("errors",errors,"listening",listening,"reading",reading,"writing","speaking",speaking,"overall","proficiencyDetilsState",proficiencyDetilsState)
    return (
      <Container
        className= 'proficiency-update-container'
      >
        <div>
          {
                proficiencyDetilsState
                    && proficiencyDetilsState.length
            ? proficiencyDetilsState.map((proficiency) => (
              <div>
                <p>Added Proficiency</p>

                <Table key="" striped bordered>
                  <thead>
                    <tr>
                      <th>Proficiency type</th>
                      <th>Overall</th>
                      <th>Listening</th>
                      <th>Reading</th>
                      <th>Writing</th>
                      <th>Speaking</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {proficiency.type}
                        {' '}
                      </td>
                      <td>{proficiency.ielts_score ? proficiency.ielts_score : proficiency.pte_score}</td>
                      <td>{proficiency.listening}</td>
                      <td>{proficiency.reading}</td>
                      <td>{proficiency.writing}</td>
                      <td>{proficiency.speaking}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            ))
            : null}
        </div>
          <h4>Add Language Proficiency Details</h4>
        <Form>
          <Form.Group>
            <Form.Label>Language Proficiency Test Type *</Form.Label>
            <Form.Control
              name="proficiencyType"
              as="select"
              placeholder="Proficiency Type"
              value={proficiencyType}
              onChange={(event) => onChangeInput(event.target.name, event.target.value)}
            >
              <option value="">Select Language Test</option>
              <option value="ielts">IELTS</option>
              <option value="toefl">TOEFL</option>
              <option value="pte">PTE</option>
              {/* <option value="cefr">CEFR</option> */}
            </Form.Control>
          </Form.Group>
          <Form.Group>
          <Form.Label>Overall *</Form.Label>
            <Form.Control
              value={overall}
              name="overall"
              onChange={(event) => onChangeInput(event.target.name, event.target.value)}
              className="form-input-field"
              placeholder="Overall Score *"
            />
             {errors && errors.overallErr ? (
                          <span className="error">
                            Please enter valid score Ex:6.0 or 5.5
                          </span>
                        ) : (
                          ''
                        )}
                        {errors && errors.overallErr2 ? (
                          <span className="error">
                            Please enter valid score Ex: 76
                          </span>
                        ) : (
                          ''
                        )}
                        {errors && errors.overallErr3 ? (
                          <span className="error">
                            Please enter valid score Ex: 34
                          </span>
                        ) : (
                          ''
                        )}
          </Form.Group>
          <Form.Group>
          <Form.Label>Listening *</Form.Label>
            <Form.Control
              className="form-input-field"
              name="listening"
              placeholder="Listening"
              //disabled={!overall}
              onChange={(event) => onChangeInput(event.target.name, event.target.value)}
              value={listening}
            />
            {errors && errors.listeningErr ? (
                          <span className="error">
                            Please enter valid score Ex:6.0 or 5.5
                          </span>
                        ) : (
                          ''
                        )}
                        {errors && errors.listeningErr2 ? (
                          <span className="error">
                            Please enter valid score Ex: 15
                          </span>
                        ) : (
                          ''
                        )}
                        {errors && errors.listeningErr3 ? (
                          <span className="error">
                            Please enter valid score Ex: 32
                          </span>
                        ) : (
                          ''
                        )}
          </Form.Group>
          {/* <Form.Group >
                        <Form.Label>Certificate</Form.Label>
                        <Form.Control className="form-input-field" placeholder="Certificate" />
                    </Form.Group> */}
          <Form.Group>
          <Form.Label>Reading *</Form.Label>
            <Form.Control
              value={reading}
              className="form-input-field"
              name="reading"
              //disabled={!listening}
              placeholder="Reading"
              onChange={(event) => onChangeInput(event.target.name, event.target.value)}
            />
              {errors && errors.readingErr ? (
                          <span className="error">
                            Please enter valid score Ex:6.0 or 5.5
                          </span>
                        ) : (
                          ''
                        )}
                        {errors && errors.readingErr2 ? (
                          <span className="error">
                            Please enter valid score Ex:15
                          </span>
                        ) : (
                          ''
                        )}
                        {errors && errors.readingErr3 ? (
                          <span className="error">
                            Please enter valid score Ex:32
                          </span>
                        ) : (
                          ''
                        )}
          </Form.Group>
          <Form.Group>
          <Form.Label>Writing *</Form.Label>
            <Form.Control
              value={writing}
              className="form-input-field"
              name="writing"
              //disabled={!reading}
              placeholder="Writing"
              onChange={(event) => onChangeInput(event.target.name, event.target.value)}
            />
            {errors && errors.writingErr ? (
                          <span className="error">
                            Please enter valid score Ex:6.0 or 5.5
                          </span>
                        ) : (
                          ''
                        )}
                        {errors && errors.writingErr2 ? (
                          <span className="error">
                            Please enter valid score Ex:15
                          </span>
                        ) : (
                          ''
                        )}
                        {errors && errors.writingErr3 ? (
                          <span className="error">
                            Please enter valid score Ex:32
                          </span>
                        ) : (
                          ''
                        )}
          </Form.Group>
          <Form.Group>
          <Form.Label>Speaking *</Form.Label>
            <Form.Control
              value={speaking}
              className="form-input-field"
              name="speaking"
              //disabled={!writing}
              placeholder="Speaking"
              onChange={(event) => onChangeInput(event.target.name, event.target.value)}
            />
            {errors && errors.speakingErr ? (
                          <span className="error">
                            Please enter valid score Ex:6.0 or 5.5
                          </span>
                        ) : (
                          ''
                        )}
                        {errors && errors.speakingErr2 ? (
                          <span className="error">
                            Please enter valid score Ex: 15
                          </span>
                        ) : (
                          ''
                        )}
                        {errors && errors.speakingErr3 ? (
                          <span className="error">
                            Please enter valid score Ex: 32
                          </span>
                        ) : (
                          ''
                        )}
          </Form.Group>
          <Form.Group>
            <Form.File
              id="exampleFormControlFile1"
              label="Upload Document"
              val
              accept=".png, .jpg, .jpeg, .pdf"
              name="documentFile"
              onChange={addFile}
            />

          </Form.Group>

          <Row>
            {/* <Col>
              {' '}
              <Button className="button-delete" onClick={() => deleteProficiency()}>
                Delete
              </Button>
            </Col> */}
            <Col>
              <Button
                className="button-delete save"
                disabled={!(overall || listening || writing || reading || speaking)}
                onClick={() => onProficiencyUpdate()}
              >
               {proficiencyEdit ? "Update" : " Save" }
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}
export default ProficiencyUpdate;
