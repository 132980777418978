import mirrorkey from 'mirrorkey';

export default mirrorkey([
    'SAVE_LOADING',
    'SAVE_SUCCESS',
    'SAVE_ERROR',
    'GET_ALL_COURSES_REQUEST',
    'GET_ALL_COURSES_FAIL',
    'GET_ALL_COURSES_SUCCESS',
    'SAAS_UPLOAD_REQUEST',
    'SAAS_UPLOAD_REQUEST_FAIL',
    'SAAS_UPLOAD_REQUEST_SUCCESS',
    'DELETE_COURSE_LOADING',
    'DELETE_COURSE_ERROR',
    'DELETE_COURSE_SUCCESS',
    'POST_APPLY_NOW_REQUEST',
    'POST_APPLY_NOW_FAIL',
    'POST_APPLY_NOW_SUCCESS',
    'POST_CREATE_UNIVERSITY_REQUEST',
    'POST_CREATE_UNIVERSITY_FAIL',
    'POST_CREATE_UNIVERSITY_SUCCESS',
    'POST_CREATE_UNIVERSITY_COURSE_REQUEST',
    'POST_CREATE_UNIVERSITY_COURSE_FAIL',
    'POST_CREATE_UNIVERSITY_COURSE_SUCCESS'
]);