import React, { Component } from "react";
import { Card, Elevation, MenuItem, Toaster } from "@blueprintjs/core";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import actions from '../modules/actions';
import memberActions from '../../Members/modules/actions';
import integrationAction from '../../Integration/modules/actions'
import { CLOUDFROND_ASSETS3_URL } from '../../../env';
import { Toast } from "../../../shared/toast/Toast";
import _ from 'lodash';
import moment from 'moment'
import StudentEditorMarkup from '../components/StudentEditMarkup'
import { WEB_URL } from '../../../env';

class StudentEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: {
                showDocModal: false,
                viewDocFile: '',
                firstName: '',
                intakeMonth: '',
                intakeYear: '',
                lastName: '',
                academics: [],
                experience: [],
                languageEfficiency: [],
                educationModal: false,
                degreeType: '',
                eduInstitute: '',
                eduName: '',
                eduCourse: '',
                eduStart: '',
                eduEnd: '',
                eduStartMonth: '',
                eduEndMonth: '',
                eduOption: '',
                eduMark: '',
                eduDescription: '',
                educationDetailsState: [],
                documentEduFile: '',
                errors: {},
                experienceModal: false,
                titleExp: '',
                companyExp: '',
                locationExp: '',
                typeExp: '',
                expStartMonth: '',
                expEndMonth: '',
                descriptionExp: '',
                endExp: '',
                startExp: '',
                proficiencyModal: false,
                proficiencyType: '',
                email: '',
                phoneNumber: '',
                paymentMethod: '',
                financialCapacity: '',
                gap: '',
                country: '',
                docName: '',
                sopValue: '',
                visaValue: '',
                lorValue: '',
                otherdocValue: '',
                passportValue: '',
                documentNewFile: {},
                count: 0,
                experienceDetilsState: [],
                proficiencyDetilsState: [],
                documentFile: '',
                documentExpFile: '',
                experienceEdit: false,
                proficiencyEdit: false,
                expUpdateID: '',
                sop: [],
                lor: [],
                passport: '',
                visa: '',
                otherDoc: '',
                eduacationEdit: false,
                educationUpdateID: '',
                addEducation: false,
                otherDocDescription: '',
                visaDocDescription: '',
                passportDocDescription: '',
                Listening: '',
                Reading: '',
                Writing: '',
                Speaking: '',
                alternativeEmail: [],
                alternativeEmailModal: false,
                editAlternativeEmail: '',
                altEmailEdit: false,
                altEmailEditIndex: '',

                alternativePhoneNumber: [],
                alternativePhoneModal: false,
                editPhoneNumber: '',
                altPhoneEdit: false,
                altPhoneEditIndex: '',
                editmailerror: false,
                existerror: false,
                cvDoc:'',
                cvValue:'',
                cvDocDescription:'',
                langEfficiency:[]
            },
            errors:{},
            educationModal: false,
            proficiencyModal: false,

        }
    }
    componentDidMount() {
        const { match: { params }, studentZA } = this.props;
        this.props.actions.getStudent(params.id)
        const handleUserSuccess = (body) => {
            this.props.actions.getEducationDetails(body.id);
            this.props.actions.getProficiencyData(body.id);
            this.props.actions.getExperienceData(body.id);
            this.props.actions.getUserZADocuments(body.id);
            this.props.actions.getSopDocuments(body.id);
            this.props.actions.getPassportDocuments(body.id);
            this.props.actions.getLorDocuments(body.id);
            this.props.actions.getOtherDocuments(body.id)
        }
        const handleUserFailure = () => {

        }
    }

    static getDerivedStateFromProps(props, state) {
        const {
            educationData: { education },
            proficiency: { proficiency },
            experience: { experience }
        } = props;
        let altEmailArray = []
        let altPhoneArray = []


        if (props.student.alternative_email) {
            let altEmail = props.student.alternative_email
            let resAltEmail = altEmail.replace("[", "");
            let result = resAltEmail.replace("]", "");
            let altEmailData = result.replace(/'/g, '"')
            altEmailArray = JSON.parse("[" + altEmailData + "]");
        }
        if (props.student.alternative_phone_number) {
            let altPhone = props.student.alternative_phone_number
            let resAltPhone = altPhone.replace("[", "");
            let result = resAltPhone.replace("]", "");
            let altPhoneData = result.replace(/'/g, '"')
            altPhoneArray = JSON.parse("[" + altPhoneData + "]");
        }
        console.log("props.student", props.student)

        if (state.inputs !== props.student) {
            return {
                inputs: props.student,
                firstName: props.student.first_name,
                intakeYear: props.student.intake_year,
                intakeMonth: props.student.intake_month,
                lastName: props.student.last_name,
                academics: props.student.academic,
                email: props.student.email,
                phoneNumber: props.student.phone_number,
                paymentMethod: props.student.payment_method,
                financialCapacity: props.student.financial_capacity,
                gap: props.student.gap,
                country: props.student.country,
                experience: props.student.experience,
                languageEfficiency: props.student.language_efficiency,
                sop: props.student.sop,
                lor: props.student.letter_of_recommendation,
                passport: props.student.passport_doc_url,
                visa: props.student.visa_doc_url,
                otherDoc: props.student.other_documents,
                otherDocDescription: props.student.other_doc_description,
                visaDocDescription: props.student.visa_doc_description,
                passportDocDescription: props.student.passport_doc_description,
                alternativeEmail: altEmailArray,
                alternativePhoneNumber: altPhoneArray,
                educationDetailsState: props.student.academic,
                count: 1,
                proficiencyDetilsState: proficiency,
                experienceDetilsState: props.student.experience,
                cvDoc: props.student.cv_doc_url,
                cvDocDescription: props.student.cv_doc_description,
                langEfficiency: props.student.language_efficiency,
            }

        }
        // if ((state.count == 0 && education, proficiency, experience)) {
        //     return {
        //         educationDetailsState: props.student.academic,
        //         count: 1,
        //         proficiencyDetilsState: proficiency,
        //         experienceDetilsState: props.student.experience,

        //     };
        // }
    }
    onChangeInput = (name, value) => {
        if (name == 'degreeType') {
            this.setState({
                [name]: value,errors:{},
                eduInstitute: '',
                eduName: '',
                eduCourse: '',
                eduStart: '',
                eduEnd: '',
                eduStartMonth: '',
                eduEndMonth: '',
                eduOption: '',
                eduMark: '',
                eduDescription: '',
            });
        }
        if (name == 'eduOption') {
            this.setState({
                [name]: value,errors:{},
                eduMark: '',
            });
        }
        if (name == 'paymentMethod') {
            this.setState({
                [name]: value,errors:{},
                financialCapacity: '',
            });
        }
        if (name == 'proficiencyType') {
            this.setState({
                [name]: value,errors:{},
                overall: '',
                listening: '',
                reading: '',
                writing: '',
                speaking: '',

            });
        }
        else {
            this.setState({
                [name]: value,errors:{}
            });
        }

    }

    addFile = (event) => {
        this.setState({
            documentFile: event.target.files[0]
        });
    };

    onStudentList = () => {
        this.props.history.push(`/students`)
    }

    addNewDocument = (event, name) => {
        this.setState({
            documentNewFile: event.target.files[0],
            docName: name
        });
    };

    addexperienceFile = (event) => {
        const { id } = this.props;
        this.setState({
            documentExpFile: event.target.files[0]
        });
    };

    handleAcademicsType = (degree_type) => {
        if (degree_type == 'SSC') {
            return 1;
        }
        if (degree_type == 'HSC') {
            return 2;
        }
        if (degree_type == 'Bachelors') {
            return 3;
        } if (degree_type == 'Masters') {
            return 4;
        }
    }
    uploadZilter = (docName, response) => {
        const { match: { params }, studentZA } = this.props
        const {
            sopValue,
            lorValue,
            otherdocValue,
            passportValue,
            visaValue,
            cvValue,
        } = this.state;

        const handleSuccess = (response) => {
            this.props.actions.getStudent(params.id)
            this.setState({
                documentNewFile: '',
                sopValue: '',
                lorValue: '',
                otherdocValue: '',
                passportValue: '',
                visaValue: '',
                docName: '',
                cvValue:''
            })
        }

        let id = params.id
        if (docName == 'sop') {
            let sopData = {
                student_id: id,
                SOP_doc_url: studentZA.id ? `${CLOUDFROND_ASSETS3_URL}/sopdocuments/${response.sop.filename}` : `${CLOUDFROND_ASSETS3_URL}/${response.data}`,
                description: studentZA.id ? `${response.sop.description}` : sopValue,
                SOP_verified: 0
            }
            this.props.actions.uploadZilterDoc(sopData, docName, handleSuccess, () => { })

        }
        if (docName == 'lor') {
            let lorData = {
                student_id: id,
                doc_url: studentZA.id ? `${CLOUDFROND_ASSETS3_URL}/lordocuments/${response.lor.filename}` : `${CLOUDFROND_ASSETS3_URL}/${response.data}`,
                description: studentZA.id ? `${response.lor.description}` : lorValue,
                verified: 0
            }
            this.props.actions.uploadZilterDoc(lorData, 'recommendation', handleSuccess, () => { })

        }
        if (docName == 'passport') {
            let passportData = {
                passport_doc_url: studentZA.id ? `${CLOUDFROND_ASSETS3_URL}/passportdocuments/${response.passport.filename}` : `${CLOUDFROND_ASSETS3_URL}/${response.data}`,
                passport_doc_description: studentZA.id ? `${response.passport.description}` : passportValue
            }
            this.props.actions.upadteZilterStudent(passportData, id, handleSuccess, () => { })

        }
        if (docName == 'cvDoc') {
            let cvData = {
                cv_doc_url: studentZA.id ? `${CLOUDFROND_ASSETS3_URL}/cvdocuments/${response.cv.filename}` : `${CLOUDFROND_ASSETS3_URL}/${response.data}`,
                cv_doc_description: studentZA.id ? `${response.cv.description}` : cvValue
            }
            this.props.actions.upadteZilterStudent(cvData, id, handleSuccess, () => { })

        }
        // cvDoc
        if (docName == 'visarefusal') {
            let visarefusalData = {
                visa_doc_url: studentZA.id ? `${CLOUDFROND_ASSETS3_URL}/visarefusaldocuments/${response.visarefusal.filename}` : `${CLOUDFROND_ASSETS3_URL}/${response.data}`,
                visa_doc_description: studentZA.id ? `${response.visarefusal.description}` : visaValue
            }
            this.props.actions.upadteZilterStudent(visarefusalData, id, handleSuccess, () => { })

        }
        if (docName == 'otherdocuments') {
            let otherdocumentsData = {
                student_id: id,
                other_documents_url: studentZA.id ? `${CLOUDFROND_ASSETS3_URL}/otherdocuments/${response.otherdocuments.filename}` : `${CLOUDFROND_ASSETS3_URL}/${response.data}`,
                description: studentZA.id ? `${response.otherdocuments.description}` : otherdocValue,
                other_verified:0
            }

            this.props.actions.uploadZilterDoc(otherdocumentsData, 'otherdocuments', handleSuccess, () => { })

        }
    }
    onSaveDocument = (fileindex) => {
        const {
            documentNewFile,
            sopValue,
            lorValue,
            otherdocValue,
            passportValue,
            visaValue,
            docName,
            cvValue
        } = this.state;
        const {
            studentZA,
            match: { params }
        } = this.props;

        const handleValue = () => {
            switch (fileindex) {
                case 11:
                    return sopValue;
                case 12:
                    return passportValue;
                case 13:
                    return lorValue;
                case 14:
                    return visaValue;
                case 15:
                    return otherdocValue;
                case 16: 
                    return cvValue;
            }
        };
        if (studentZA.id) {


            const formData = new FormData();

            formData.append('file', documentNewFile);
            formData.append('type', fileindex);
            formData.append('description', handleValue());

            const handleSuccess = (response) => {
                Toast.show({
                    message: 'Details Updated Successfully',
                    timeout: 4000,
                    intent: 'success'
                });
                // this.props.actions.getStudent(params.id)
                this.props.actions.getZADocuments(studentZA.id, docName);

                this.uploadZilter(docName, response)
                this.closeModal();

            };
            const handleFailure = () => {
                Toast.show({
                    message: 'Updation Failed',
                    timeout: 4000,
                    intent: 'danger'
                });
            };

            if (docName == 'sop') {
                // uploadDocumentsz
                this.props.actions.uploadZAOtherDocuments(formData, handleSuccess, handleFailure, docName, studentZA.id);
            }
            if (docName == 'passport') {
                this.props.actions.uploadZAOtherDocuments(formData, handleSuccess, handleFailure, docName, studentZA.id);
            }
            if (docName == 'visarefusal') {
                this.props.actions.uploadZAOtherDocuments(formData, handleSuccess, handleFailure, docName, studentZA.id);
            }
            if (docName == 'lor') {
                this.props.actions.uploadZAOtherDocuments(formData, handleSuccess, handleFailure, docName, studentZA.id);
            }
            if (docName == 'otherdocuments') {
                this.props.actions.uploadZAOtherDocuments(formData, handleSuccess, handleFailure, docName, studentZA.id);
            }
        }
        else {
            const formData = new FormData()
            formData.append('doc_file', documentNewFile);
            const handleSuccess = (response) => {
                Toast.show({
                    message: 'Details Updated Successfully',
                    timeout: 4000,
                    intent: 'success'
                });
                // this.props.actions.getStudent(params.id)    
                this.uploadZilter(docName, response)

            }
            const handleFailure = () => {
                Toast.show({
                    message: 'Updation Failed',
                    timeout: 4000,
                    intent: 'danger'
                });
            };
            this.props.integrationAction.documentUpload(handleSuccess, handleFailure, formData)
        }
    };



    isValid = () => {
        const {
            eduMark: marks,
            eduOption,
            eduStart,
            eduEnd,
            eduStartMonth,
            eduEndMonth,
            eduInstitute,
            eduCourse,
            eduName,
            degreeType
        } = this.state;
        let errors = {};
        // if (marks == "") {
        //     errors.perError = true;
        //     this.setState({ errors });
        //     return false;
        // }

        if(degreeType==""){
            errors.degreeType = true;
            this.setState({ errors });
            return false;

        }
        if(degreeType=='1'||degreeType=='2'||degreeType=='3'||degreeType=='4'){
            if(eduInstitute=="" || !/[A-Za-z]{1}/.test(eduInstitute)){
                errors.eduInstitute = true;
                this.setState({ errors });
                return false;
            }
        }
        if(degreeType=='3'||degreeType=='4'){
            if(eduName=="" || !/[A-Za-z]{1}/.test(eduName)){
                errors.eduName = true;
                this.setState({ errors });
                return false;
    
            }
       
            if(eduCourse=="" || !/[A-Za-z]{1}/.test(eduCourse)){
                errors.eduCourse = true;
                this.setState({ errors });
                return false;
    
            }
        }

        if (eduOption == 'Percentage') {
            if (!/[0-9]{2,3}/.test(marks) || marks == '' || marks > 100 || marks < 0) {
                errors.perError = true;
                this.setState({ errors });
                return false;
            }
        }

        // if (eduOption == 'Percentage' && marks != '' && !/[0-9]{2,3}/.test(marks) && marks > 100  && marks < 0 ) {
        //     errors.perError = true;
        //     this.setState({ errors });
        //     return false;
        // }
        // if (marks <= 0 && eduOption == 'Percentage' && marks != '') {
        //     errors.perError = true;
        //     this.setState({ errors });
        //     return false;
        // }
        if (eduStart == eduEnd) {
            if (eduStartMonth > eduEndMonth) {
                errors.dateError = true;
                this.setState({ errors });
                return false;
            }
        }
        /* if (eduStart == eduEnd) {
            errors.sameError = true;
            this.setState({ errors });
            return false;
        } */
        if (eduStart == '' && eduEnd == '' || eduStart == eduEnd) {
            errors.blankError = true;
            this.setState({ errors });
            return false;
        }
        if (eduStart != '' && eduEnd == '') {
            errors.eduendError = true;
            this.setState({ errors });
            return false;
        }

        if (eduEnd != '' && eduStart == '') {
            errors.edustartError = true;
            this.setState({ errors });
            return false;
        }
        if (eduStart > eduEnd) {
            errors.dateError = true;
            this.setState({ errors });
            return false;
        }
        if(eduOption==''){
            errors.eduOption = true;
            this.setState({ errors });
            return false;
        }
        if (eduOption == 'Percentage' && !/[0-9]{2,3}/.test(marks) && marks != '') {
            errors.perError = true;
            this.setState({ errors });
            return false;
        }
        if (eduOption == 'Grade' && !/[A-Za-z]{1}/.test(marks) && marks != '') {
            errors.gradeError = true;
            this.setState({ errors });
            return false;
        }
        if (eduOption == 'GPA' && !/^[0-9]{1}\.\d+$/.test(marks) && marks != '') {
            errors.gpaError = true;
            this.setState({ errors });
            return false;
        }
       

        return true;
    };
    addEduFile = (event) => {
        this.setState({
            documentEduFile: event.target.files[0]
        });
    };
    onEducationUpdate = (updateEdu) => {

        if (this.isValid()) {
            const {
                eduStartMonth,
                eduEndMonth,
                eduInstitute,
                eduDescription,
                eduStart,
                eduEnd,
                degreeType,
                documentEduFile,
                eduCourse,
                eduName,
                eduMark,
                eduOption,
                educationDetailsState,
                eduIndex,
                addEducation,
                eduacationEdit,
                educationUpdateID,
                academics
            } = this.state;
            console.log("studentAcademic",  academics, eduIndex)

            this.setState({ errors: {} })
            const { studentZA } = this.props;

            const handleName = () => {
                if (degreeType == 1) {
                    return 'secondary';
                }
                if (degreeType == 2) {
                    return 'higherSecondary';
                } else {
                    return eduName;
                }
            };
            const data = {
                user_id: studentZA.id,
                type: degreeType ? degreeType : 1,
                name: handleName(),
                course: eduCourse,
                option: eduMark == '' ? '' : eduOption,
                mark: eduMark ? eduMark : 0,
                status: 1,
                description: eduDescription,
                start: eduStart ? `${eduStart}-${eduStartMonth}-01` : null,
                end: eduEnd ? `${eduEnd}-${eduEndMonth}-01` : null,
                institute: eduInstitute
            };

            let za = {}
            let educationDetailsData = []
            if (addEducation) {
                educationDetailsState.push(data);
                this.setState({
                    educationDetailsState
                });
                if (educationDetailsState && educationDetailsState.length) {
                    for (let i = 0; i < educationDetailsState.length; i++) {

                        za.type = educationDetailsState[i].type ? educationDetailsState[i].type : this.handleAcademicsType(educationDetailsState[i].degree_type);
                        za.name = educationDetailsState[i].name ? educationDetailsState[i].name : educationDetailsState[i].degree_name;
                        za.course = educationDetailsState[i].course ? educationDetailsState[i].course : educationDetailsState[i].field_of_study;
                        za.option = educationDetailsState[i].option ? educationDetailsState[i].option : null;
                        za.mark = educationDetailsState[i].mark ? educationDetailsState[i].mark : educationDetailsState[i].score;
                        za.status = 1;
                        za.description = educationDetailsState[i].description ? educationDetailsState[i].description : educationDetailsState[i].description;
                        za.start = educationDetailsState[i].start ? educationDetailsState[i].start : educationDetailsState[i].started_year;
                        za.end = educationDetailsState[i].end ? educationDetailsState[i].end : educationDetailsState[i].completed_year;
                        za.institute = educationDetailsState[i].institute ? educationDetailsState[i].institute : educationDetailsState[i].college_name;
                        za.user_id = studentZA.id;
                        educationDetailsData.push(za);
                        za = {}
                    }
                }
            } else {
                if (educationDetailsState && educationDetailsState.length) {
                    for (let i = 0; i < educationDetailsState.length; i++) {

                        za.type = educationDetailsState[i].type ? educationDetailsState[i].type : this.handleAcademicsType(educationDetailsState[i].degree_type);
                        za.name = educationDetailsState[i].name ? educationDetailsState[i].name : educationDetailsState[i].degree_name;
                        za.course = educationDetailsState[i].course ? educationDetailsState[i].course : educationDetailsState[i].field_of_study;
                        za.option = educationDetailsState[i].option ? educationDetailsState[i].option : null;
                        za.mark = educationDetailsState[i].mark ? educationDetailsState[i].mark : educationDetailsState[i].score;
                        za.status = 1;
                        za.description = educationDetailsState[i].description ? educationDetailsState[i].description : educationDetailsState[i].description;
                        za.start = educationDetailsState[i].start ? educationDetailsState[i].start : educationDetailsState[i].started_year;
                        za.end = educationDetailsState[i].end ? educationDetailsState[i].end : educationDetailsState[i].completed_year;
                        za.institute = educationDetailsState[i].institute ? educationDetailsState[i].institute : educationDetailsState[i].college_name;
                        za.user_id = studentZA.id;
                        educationDetailsData.push(za);
                        za = {}
                    }
                }
                let dataEdu = educationDetailsData && educationDetailsData.length ? educationDetailsData.splice(eduIndex, 1, data) : [];
                // dataEdu = educationDetailsState && educationDetailsState.length ? educationDetailsState.splice(eduIndex, 1, data) : [];
            }


            const handleAcademicsName = () => {
                if (degreeType == 1) {
                    return 'SSC';
                }
                if (degreeType == 2) {
                    return 'HSC';
                } else {
                    return eduName;
                }
            };
            const handleAcademicsType = () => {
                if (degreeType == 1) {
                    return 'SSC';
                }
                if (degreeType == 2) {
                    return 'HSC';
                }
                if (degreeType == 3) {
                    return 'Bachelors';
                } if (degreeType == 4) {
                    return 'Masters';
                }
            }
            const { match: { params }, student } = this.props;

            const academicsData = {
                student_id: params.id,
                degree_name: handleAcademicsName(),
                degree_type: handleAcademicsType(),
                doc_url_marksheet: academics && eduIndex  ? academics[eduIndex].doc_url_marksheet: null,
                doc_url_degree: academics && eduIndex  ? academics[eduIndex].doc_url_degree: null,
                verified: 0,
                score: eduMark ? eduMark : null,
                description: eduDescription ? eduDescription : null,
                started_year: eduStart ? `${eduStart}-${eduStartMonth}-01` : null,
                completed_year: eduEnd ? `${eduEnd}-${eduEndMonth}-01` : null,
                college_name: eduInstitute ? eduInstitute : null,
                field_of_study: eduCourse ? eduCourse : null
            }

            const handleAcademicsSuccess = () => {
                this.setState({
                    eduCourse: '',
                    eduMark: '',
                    eduName: '',
                    documentEduFile: '',
                    eduInstitute: '',
                    eduStart: '',
                    eduEnd: '',
                    eduStartMonth: '',
                    eduEndMonth: '',
                    errors: {},
                    eduDescription: '',
                    eduOption:''
                });
                Toast.show({
                    message: 'Education Details Updated Successfully',
                    timeout: 4000,
                    intent: 'success'
                });
                this.props.actions.getStudent(params.id)
                this.getStudentReport()
                this.closeModal();
            }

            const handleZilterDocFailure = (response) => {
                Toast.show({
                    message: 'File upload fail',
                    timeout: 1000,
                    intent: 'danger'
                });
            };
            const handleAcademicsFailure = () => {
                Toast.show({
                    message: 'Education Details Updated Unsuccessfully',
                    timeout: 4000,
                    intent: 'danger'
                });
            }
            if (!documentEduFile) {
                if (eduacationEdit) {
                    academicsData.id = educationUpdateID
                    this.props.actions.updateStudentAcademics(
                        academicsData,
                        handleAcademicsSuccess,
                        handleAcademicsFailure
                    );

                } else {
                    this.props.actions.createStudentAcademics(
                        academicsData,
                        handleAcademicsSuccess,
                        handleAcademicsFailure
                    );
                }
            }
            else {
                const formData = new FormData()
                formData.append('doc_file', documentEduFile);
                const handleZilterDocSuccess = (response) => {
                    academicsData['doc_url_degree'] = `${CLOUDFROND_ASSETS3_URL}/${response.data}`
                    if (eduacationEdit) {
                        academicsData.id = educationUpdateID
                        this.props.actions.updateStudentAcademics(
                            academicsData,
                            handleAcademicsSuccess,
                            handleAcademicsFailure
                        );

                    } else {
                        this.props.actions.createStudentAcademics(
                            academicsData,
                            handleAcademicsSuccess,
                            handleAcademicsFailure
                        );
                    }
                }
                this.props.integrationAction.documentUpload(handleZilterDocSuccess, handleZilterDocFailure, formData)
            }
            if (studentZA.id) {

                const handleSuccess = () => { };
                const fileSuccess = () => { };
                const handleFailure = () => { };
                const fileFail = () => { };
                const formData = new FormData();

                formData.append('file', documentEduFile);
                formData.append('type', degreeType);

                this.props.actions.addEducationalData(
                    handleSuccess,
                    handleFailure,
                    educationDetailsData,
                    studentZA.id
                );
                this.props.actions.uploadZADocument(
                    formData,
                    fileFail,
                    fileSuccess,
                    studentZA.id);
            }
        }
    };

    isValidExp = () => {
        let errors = {};
        const {
            descriptionExp,
            locationExp,
            companyExp,
            typeExp,
            titleExp,
            expEndMonth,
            expStartMonth,
            startDate,
            endDate
        } = this.state;
        /* if (startDate == endDate) {
            if (expStartMonth > expEndMonth) {
                errors.expDateError = true;
                this.setState({ errors });
                return false;
            }
        }
        if (startDate > endDate) {
            errors.expDateError = true;
            this.setState({ errors });
            return false;
        } */
        if (titleExp == '' || !/[A-Za-z]{1}/.test(titleExp)) {
            errors.titleErr = true;
            this.setState({ errors });
            return false;
        }
        if (typeExp == '' || !/[A-Za-z]{1}/.test(typeExp)) {
            //alert("typeerrrr")
            errors.typeErr = true;
            this.setState({ errors });
            return false;
        }
        if (companyExp == '' || !/[A-Za-z]{1}/.test(companyExp)) {
            errors.companyErr = true;
            this.setState({ errors });
            return false;
        }
        if (startDate == endDate) {
            if (expStartMonth > expEndMonth) {
                errors.expDateError = true;
                this.setState({ errors });
                return false;
            }
        }
        if (startDate == '' && endDate == '' || startDate == endDate ) {
            if (expStartMonth == expEndMonth) {
            errors.blankError = true;
            this.setState({ errors });
            return false;
            }
        }
        if (startDate != '' && endDate == '') {
            errors.expendError = true;
            this.setState({ errors });
            return false;
        }

        if (endDate != '' && startDate == '') {
            errors.startDateError = true;
            this.setState({ errors });
            return false;
        }
        if (startDate > endDate) {
            errors.expDateError = true;
            this.setState({ errors });
            return false;
        }

        if (locationExp == '' || !/[A-Za-z]{1}/.test(locationExp)) {
            errors.locationErr = true;
            this.setState({ errors });
            return false;
        }
        /* if (descriptionExp == '' && !/\w+/.test(descriptionExp)) {
            errors.descriptionErr = true;
            this.setState({ errors });
            return false;
        } */
        return true;
    };

    onExperienceUpdate = () => {
        const {
            titleExp,
            companyExp,
            endExp,
            startExp,
            locationExp,
            startDate,
            endDate,
            documentExpFile,
            expStartMonth,
            expEndMonth,
            typeExp,
            experienceDetilsState,
            expIndex,
            descriptionExp,
            experienceAdd,
            experienceEdit,
            expUpdateID,
            experience
        } = this.state;
        const { studentZA } = this.props;

        if (this.isValidExp()) {
            const data = {
                user_id: studentZA.id,
                title: titleExp,
                type: typeExp,
                company: companyExp,
                location: locationExp,
                description: descriptionExp,
                start: startDate ? `${startDate}-${expStartMonth}-01` : null,
                end: endDate ? `${endDate}-${expEndMonth}-01` : null
            };
            let exp = moment.duration(moment(`${startDate}-${expStartMonth}-01`).diff(moment(`${endDate}-${expEndMonth}-01`))).asYears().toFixed(2);

            let za = {}
            let experienceDetilsData = []

            if (experienceAdd) {
                experienceDetilsState.push(data);
                this.setState({
                    experienceDetilsState
                });
                if (experienceDetilsState && experienceDetilsState.length) {
                    for (let i = 0; i < experienceDetilsState.length; i++) {
                        za.company = experienceDetilsState[i].company ? experienceDetilsState[i].company : experienceDetilsState[i].company_name;
                        za.title = experienceDetilsState[i].title;
                        za.type = experienceDetilsState[i].type ? experienceDetilsState[i].type : experienceDetilsState[i].experience_type;
                        za.location = experienceDetilsState[i].location;
                        za.start = experienceDetilsState[i].start ? experienceDetilsState[i].start : experienceDetilsState[i].started_year;
                        za.end = experienceDetilsState[i].end ? experienceDetilsState[i].end : experienceDetilsState[i].completed_year;
                        za.description = experienceDetilsState[i].description;
                        za.user_id = studentZA.id;
                        experienceDetilsData.push(za);
                        za = {}
                    }
                }
            } else {
                if (experienceDetilsState && experienceDetilsState.length) {
                    for (let i = 0; i < experienceDetilsState.length; i++) {
                        za.company = experienceDetilsState[i].company_name;
                        za.title = experienceDetilsState[i].title;
                        za.type = experienceDetilsState[i].experience_type;
                        za.location = experienceDetilsState[i].location;
                        za.start = experienceDetilsState[i].started_year;
                        za.end = experienceDetilsState[i].completed_year;
                        za.description = experienceDetilsState[i].description;
                        za.user_id = studentZA.id;
                        experienceDetilsData.push(za);
                        za = {}
                    }
                }
                let exs = experienceDetilsData && experienceDetilsData.length ? experienceDetilsData.splice(expIndex, 1, data) : [];
                exs = experienceDetilsState && experienceDetilsState.length ? experienceDetilsState.splice(expIndex, 1, data) : [];
            }

            const { match: { params } } = this.props;

            const experienceList = {
                student_id: params.id,
                company_name: companyExp,
                experience: Math.abs(exp),
                exp_doc_url: experience && expIndex ? experience[expIndex].exp_doc_url: null,
                exp_verified: 0,
                title: titleExp,
                experience_type: typeExp,
                location: locationExp,
                description: descriptionExp,
                started_year: startDate ? `${startDate}-${expStartMonth}-01` : null,
                completed_year: endDate ? `${endDate}-${expEndMonth}-01` : null

            }

            const handleExpSuccess = () => {
                Toast.show({
                    message: 'Experience Details Updated Successfully',
                    timeout: 4000,
                    intent: 'success'
                });
                this.closeModal();
                this.setState({
                    titleExp: '',
                    companyExp: '',
                    endExp: '',
                    startExp: '',
                    locationExp: '',
                    startDate: '',
                    endDate: '',
                    documentExpFile: '',
                    expStartMonth: '',
                    expEndMonth: '',
                    typeExp: '',
                    expIndex: '',
                    descriptionExp: '',
                    errors: {}
                });
                this.props.actions.getStudent(params.id)
                this.getStudentReport()
            }

            const handleExpFailure = () => {
                Toast.show({
                    message: 'Experience Details Updated Unsuccessfully',
                    timeout: 4000,
                    intent: 'danger'
                });
            };

            if (!documentExpFile) {
                if (experienceEdit) {
                    experienceList.id = expUpdateID
                    this.props.actions.updateStudentExperiance(
                        experienceList,
                        handleExpSuccess,
                        handleExpFailure
                    );
                }
                else {
                    this.props.actions.createStudentExperience(
                        experienceList,
                        handleExpSuccess,
                        handleExpFailure
                    );
                }
            }
            else {
                const formData = new FormData()
                formData.append('doc_file', documentExpFile);

                const handleZilterDocSuccess = (response) => {
                    Toast.show({
                        message: 'File upload Successfully',
                        timeout: 4000,
                        intent: 'success'
                    });
                    experienceList['exp_doc_url'] = `${CLOUDFROND_ASSETS3_URL}/${response.data}`
                    if (experienceEdit) {
                        experienceList.id = expUpdateID
                        this.props.actions.updateStudentExperiance(
                            experienceList,
                            handleExpSuccess,
                            handleExpFailure
                        );
                    }
                    else {
                        this.props.actions.createStudentExperience(
                            experienceList,
                            handleExpSuccess,
                            handleExpFailure
                        );
                    }
                }
                const handleZilterDocFailure = () => {

                }
                this.props.integrationAction.documentUpload(
                    handleZilterDocSuccess,
                    handleZilterDocFailure,
                    formData
                )
            }
            if (studentZA.id) {
                const formData = new FormData();
                formData.append('file', documentExpFile);
                formData.append('type', 6);

                const handleSuccess = () => { };
                const fileSuccess = () => { };
                const handleFailure = () => { };
                const fileFail = () => { };

                this.props.actions.uploadZADocument(
                    formData,
                    fileFail,
                    fileSuccess,
                    studentZA.id
                );
                this.props.actions.addExperienceData(
                    experienceDetilsData,
                    handleSuccess,
                    handleFailure,
                    studentZA.id
                );
            }

        }
    }

    isValidProf = () => {
        let errors = {};
        const { overall, listening, reading, writing, speaking, proficiencyType } = this.state;

        if (proficiencyType == 'ielts') {
            if (
                !overall == '' &&
                !/^[1-9]{1}(\.\d)?$/.test(overall) &&
                proficiencyType == 'ielts'
            ) {
                errors.overallErr = true;
                this.setState({ errors });
                return false;
            }
            if (
                !listening == '' &&
                !/^[1-9]{1}(\.\d)?$/.test(listening) &&
                proficiencyType == 'ielts'
            ) {
                errors.listeningErr = true;
                this.setState({ errors });
                return false;
            }
            if (
                !reading == '' &&
                !/^[1-9]{1}(\.\d)?$/.test(reading) &&
                proficiencyType == 'ielts'
            ) {
                errors.readingErr = true;
                this.setState({ errors });
                return false;
            }
            if (
                !writing == '' &&
                !/^[1-9]{1}(\.\d)?$/.test(writing) &&
                proficiencyType == 'ielts'
            ) {
                errors.writingErr = true;
                this.setState({ errors });
                return false;
            }
            if (
                !speaking == '' &&
                !/^[1-9]{1}(\.\d)?$/.test(speaking) &&
                proficiencyType == 'ielts'
            ) {
                errors.speakingErr = true;
                this.setState({ errors });
                return false;
            }
        }
        if (proficiencyType == 'toefl') {
            if (!/^([1-9]{1,2}|([1-9]0))$/.test(overall) || overall < 1 || overall > 120) {
                errors.overallErr2 = true;
                this.setState({ errors });
                return false;
            }
            if (!/^([1-9]{1,2}|([1-3]0))$/.test(listening) || listening < 1 || listening > 30) {
                errors.listeningErr2 = true;
                this.setState({ errors });
                return false;
            }
            if (!/^([1-9]{1,2}|([1-3]0))$/.test(reading) || reading < 1 || reading > 30) {
                errors.readingErr2 = true;
                this.setState({ errors });
                return false;
            }
            if (!/^([1-9]{1,2}|([1-3]0))$/.test(writing) || writing < 1 || writing > 30) {
                errors.writingErr2 = true;
                this.setState({ errors });
                return false;
            }
            if (!/^([1-9]{1,2}|([1-3]0))$/.test(speaking) || speaking < 1 || speaking > 30) {
                errors.speakingErr2 = true;
                this.setState({ errors });
                return false;
            }
        }
        if (proficiencyType == 'pte') {
            if (!/^([1-9]{1,2}|([1-9]0))$/.test(overall) || overall < 11 || overall > 90) {
                errors.overallErr3 = true;
                this.setState({ errors });
                return false;
            }
            if (!/^([1-9]{1,2}|([1-3]0))$/.test(listening) || listening < 11 || listening > 90) {
                errors.listeningErr3 = true;
                this.setState({ errors });
                return false;
            }

            if (!/^([1-9]{1,2}|([1-3]0))$/.test(reading) || reading < 11 || reading > 90) {
                errors.readingErr3 = true;
                this.setState({ errors });
                return false;
            }

            if (!/^([1-9]{1,2}|([1-3]0))$/.test(writing) || writing < 11 || writing > 90) {
                errors.writingErr3 = true;
                this.setState({ errors });
                return false;
            }
            if (!/^([1-9]{1,2}|([1-3]0))$/.test(speaking) || speaking < 11 || speaking > 90) {
                errors.speakingErr3 = true;
                this.setState({ errors });
                return false;
            }
        }
        return true;
    };

    getStudentReport = () => {
        const { match: { params } } = this.props;

        // this.props.actions.getReport(
        //     { id: userDetails.zilter_id, token: zilterToken },
        //     () =>{} ,
        //     () =>{}
        // );
    }

    onProficiencyUpdate = () => {
        if (this.isValidProf()) {
            const {
                documentFile,
                overall,
                proficiencyType,
                listening,
                reading,
                speaking,
                writing,
                langEfficiency
            } = this.state;
            const { studentZA, userIdData } = this.props;
            const { id } = this.props;
      
            const formData = new FormData();

            formData.append('doc_file', documentFile);
            // formData.append('type', 5);

            const { match: { params } } = this.props;


            const languageEfficiency = {
                student_id: params.id,
                ielts_score: proficiencyType == 'ielts' ? overall : null,
                toefl_score: proficiencyType == 'toefl' ? overall : null,
                pte_score: proficiencyType == 'pte' ? overall : null,
                listening: listening ? listening : null,
                speaking: speaking ? speaking : null,
                writing: writing ? writing : null,
                reading: reading ? reading : null,
                exemptions_equivalent: null,
                second_language: null,
                lang_doc_url: null
            }

            
          
            const handleFailure = () => {
                Toast.show({
                    message: 'Operation Failed',
                    timeout: 4000,
                    intent: 'danger'
                });
            };
            const handleLESuccess = () => {
                this.closeModal();
                Toast.show({
                    message: 'Proficiency Details Updated Successfully',
                    timeout: 4000,
                    intent: 'success'
                });
                this.props.actions.getStudent(params.id)
                this.getStudentReport()
            }
            const fileSuccess = (response) => {
                Toast.show({
                    message: 'File upload Successfully',
                    timeout: 4000,
                    intent: 'success'
                });
                console.log("response", response)
                languageEfficiency['lang_doc_url'] = `${CLOUDFROND_ASSETS3_URL}/${response.data}`

                this.props.actions.createStudentLanguageEfficiency(
                    languageEfficiency,
                    handleLESuccess,
                    handleFailure
                );               
            };
            const fileFail = () => {
                Toast.show({
                    message: 'File upload fail',
                    timeout: 4000,
                    intent: 'danger'
                });
            };
            
            if (!documentFile) {
                this.props.actions.createStudentLanguageEfficiency(
                    languageEfficiency,
                    handleLESuccess,
                    handleFailure
                );
            }
            else{
                this.props.integrationAction.documentUpload(fileSuccess, fileFail, formData)

                
            }
        };
    }

    isvalidedu = ()=>{
        const{ financialCapacity, paymentMethod, gap ,firstName}=this.state;
        let errors={};
            if (paymentMethod == '' && !financialCapacity == '') {
               // if(!financialCapacity == ''){
                errors.paymentMethod = true;
                this.setState({ errors });
                return false;
            //}
            } 
            if (paymentMethod == 'selfFamily' || paymentMethod == 'scholarshipAid' || paymentMethod == 'educationLoan') {
                if(!/^[0-9]{2,9}$/.test(financialCapacity)){
                errors.financialCapacity = true;
                this.setState({ errors });
                return false;
            }
            } 
             if (firstName == '') {
                errors.firstName = true;
                this.setState({ errors });
                return false;
            }
            /*if (!/^[0-9]{2,9}$/.test(financialCapacity) || !financialCapacity == "") {
                errors.financialCapacity = true;
                this.setState({ errors });
                return false;
            }
            if (gap == '') {
                errors.gap = true;
                this.setState({ errors });
                return false;
            } */
            return true;
    };
    onSave = () => {
        const { match: { params }, student, userProfile : {user} } = this.props;
        console.log("userProfile", user)
       const { firstName, lastName, paymentMethod, financialCapacity, gap, country, alternativeEmail, alternativePhoneNumber, intakeMonth, intakeYear, email, phoneNumber } = this.state
        if(this.isvalidedu()){
        const data = {
            first_name: firstName,
            intake_year: intakeYear,
            intake_month: intakeMonth,
            last_name: lastName,
            id: params.id,
            payment_method: paymentMethod,
            financial_capacity: financialCapacity,
            gap: gap,
            country: country,
            alternative_email: alternativeEmail,
            alternative_phone_number: alternativePhoneNumber,
            email: email,
            phone_number: phoneNumber,
            organization_id: student ? student.organization_id : null,
            u_id: user ? user.id : null,
        }
        const handleSuccess = () => {
            Toast.show({
                message: "Profile Updated Successfully..!!!",
                timeout: 4000,
                intent: 'success'
            });
        }
        const handleFailure = () => {
            Toast.show({
                message: "Operation Failed, Please try again",
                timeout: 4000,
                intent: 'danger'
            })
        }
        this.props.actions.updateStudent(data, handleSuccess, handleFailure)
        }    

    }

    openEducationModal = (education, index) => {
        const { educationDetailsState } = this.state;
        const handleAcademicsType = () => {
            if (education.degree_type == 'SSC') {
                return 1;
            }
            if (education.degree_type == 'HSC') {
                return 2;
            }
            if (education.degree_type == 'Bachelors') {
                return 3;
            } if (education.degree_type == 'Masters') {
                return 4;
            }
        }
        if (education == 'add') {
            this.setState({
                educationModal: true,
                eduacationEdit: false,
                eduCourse: '',
                eduMark: '',
                eduName: '',
                addEducation: true,
                documentFile: '',
                eduInstitute: '',
                eduStart: '',
                eduEnd: '',
                eduStartMonth: '',
                eduEndMonth: '',
                isAddEdu: true,
                degreeType: '',
                eduDescription: '',
                eduOption:''
            });
        } else {
            this.setState({
                educationModal: true,
                addEducation: false,
                eduacationEdit: true,
                degreeType: handleAcademicsType(),
                eduCourse: education.field_of_study,
                eduMark: education.score,
                eduName: education.degree_name,
                eduOption: education.option,
                eduIndex: index,
                eduDescription: education.description,
                eduInstitute: education.college_name,
                eduStart: moment(education.started_year).format('YYYY'),
                eduEnd: moment(education.completed_year).format('YYYY'),
                eduStartMonth: moment(education.started_year).format('MM'),
                eduEndMonth: moment(education.completed_year).format('MM'),
                educationUpdateID: education.id
            });
        }
    };
    // openEducationModal = () => {
    //     // const { educationModal } = this.state;
    //     this.setState({
    //         educationModal: true
    //     })
    // }

    // openExperienceModal = () => {
    //     // const { educationModal } = this.state;
    //     this.setState({
    //         experienceModal: true
    //     })
    // }
    // openProficiencyModal = () => {
    //     // const { educationModal } = this.state;
    //     this.setState({
    //         proficiencyModal: true
    //     })
    // }
    openProficiencyModal = (proficiency) => {
        if (proficiency == 'add') {
            this.setState({
                proficiencyModal: true,
                overall: '',
                listening: '',
                reading: '',
                speaking: '',
                writing: '',
                proficiencyEdit: false
            });
        } else {
            this.setState({
                proficiencyModal: true,
                proficiencyEdit: true,
                //proficiencyType: proficiency.type,
                proficiencyType: proficiency.ielts_score ? 'ielts' : proficiency.toefl_score ? 'toefl' : proficiency.pte_score ? 'pte' : null,
                overall: proficiency.ielts_score ? proficiency.ielts_score : proficiency.toefl_score ? proficiency.toefl_score : proficiency.pte_score ? proficiency.pte_score : 0,
                listening: proficiency.listening,
                reading: proficiency.reading,
                speaking: proficiency.speaking,
                writing: proficiency.writing
            });
        }
    };


    closeModal = () => {
        const { eduacationEdit } = this.state;
        this.setState({
            educationModal: false,
            experienceModal: false,
            proficiencyModal: false,
            alternativeEmailModal: false,
            alternativePhoneModal: false,
            editAlternativeEmail: '',
            editPhoneNumber: '',
            typeExp: '',
            showDocModal: false,
            viewDocFile: ''
        })
        if (eduacationEdit == false) {
            this.setState({ errors: {} })
        }
    }

    deleteEducation = (education, index) => {
        const { eduIndex, academics, educationDetailsState } = this.state;
        const { studentZA, match: { params } } = this.props
        let za = {}
        let educationDetailsData = []
        let ad = educationDetailsState && educationDetailsState.length ? educationDetailsState.splice(index, 1) : []
        if (educationDetailsState && educationDetailsState.length) {
            for (let i = 0; i < educationDetailsState.length; i++) {

                za.type = educationDetailsState[i].type ? educationDetailsState[i].type : this.handleAcademicsType(educationDetailsState[i].degree_type);
                za.name = educationDetailsState[i].name ? educationDetailsState[i].name : educationDetailsState[i].degree_name;
                za.course = educationDetailsState[i].course ? educationDetailsState[i].course : educationDetailsState[i].field_of_study;
                za.option = educationDetailsState[i].option ? educationDetailsState[i].option : null;
                za.mark = educationDetailsState[i].mark ? educationDetailsState[i].mark : educationDetailsState[i].score;
                za.status = 1;
                za.description = educationDetailsState[i].description ? educationDetailsState[i].description : educationDetailsState[i].description;
                za.start = educationDetailsState[i].start ? educationDetailsState[i].start : educationDetailsState[i].started_year;
                za.end = educationDetailsState[i].end ? educationDetailsState[i].end : educationDetailsState[i].completed_year;
                za.institute = educationDetailsState[i].institute ? educationDetailsState[i].institute : educationDetailsState[i].college_name;
                za.user_id = studentZA.id;
                educationDetailsData.push(za);
                za = {}
            }
        }
        // educationDetailsData.splice(index, 1);
        let acd = academics.splice(index, 1);
        const handleSuccess = () => {
            Toast.show({
                message: 'Education Details Deleted Successfully',
                timeout: 4000,
                intent: 'success'
            });
            this.closeModal();
            this.props.actions.getStudent(params.id)
        };
        const handleFailure = () => {
            Toast.show({
                message: 'Operation Failed',
                timeout: 4000,
                intent: 'danger'
            });
            this.props.actions.getStudent(params.id)
        };
        this.props.actions.addEducationalData(handleSuccess, handleFailure, educationDetailsData, studentZA.id);
        this.props.actions.deleteAcademics(acd[0].id)
    };
    deleteProficiency = () => {
        const { languageEfficiency } = this.state
        const {
            proficiency: { proficiency },
            studentZA,
            match: { params }
        } = this.props;

        const { id } = this.props;
        let le = languageEfficiency.pop();
        const handleSuccess = () => {
            Toast.show({
                message: 'Proficiency Details Deleted Successfully',
                timeout: 4000,
                intent: 'success'
            });
            this.closeModal();
            this.props.actions.getStudent(params.id)
            this.props.actions.getProficiencyData(id);
        };
        const handleFailure = () => {
            Toast.show({
                message: 'Proficiency Details Deleted Successfully',
                timeout: 4000,
                intent: 'success'
            });
            this.closeModal();
            this.props.actions.getProficiencyData(id);
        };
        this.props.actions.addProficiencyData(proficiency, handleSuccess, handleFailure, studentZA.id);
        this.props.actions.deleteLanguageEfficiency(le.id)

    };

    deleteExperience = (index) => {
        const { expIndex, experienceDetilsState, experience } = this.state;
        const { studentZA, match: { params } } = this.props
        let exp = experience.splice(index, 1);
        let za = {}
        let experienceDetilsData = []
        if (experienceDetilsState && experienceDetilsState.length) {
            for (let i = 0; i < experienceDetilsState.length; i++) {
                za.company = experienceDetilsState[i].company ? experienceDetilsState[i].company : experienceDetilsState[i].company_name;
                za.title = experienceDetilsState[i].title;
                za.type = experienceDetilsState[i].type ? experienceDetilsState[i].type : experienceDetilsState[i].experience_type;
                za.location = experienceDetilsState[i].location;
                za.start = experienceDetilsState[i].start ? experienceDetilsState[i].start : experienceDetilsState[i].started_year;
                za.end = experienceDetilsState[i].end ? experienceDetilsState[i].end : experienceDetilsState[i].completed_year;
                za.description = experienceDetilsState[i].description;
                za.user_id = studentZA.id;
                experienceDetilsData.push(za);
                za = {}
            }
        }
        experienceDetilsData.splice(index, 1);
        const handleSuccess = () => {
            Toast.show({
                message: 'Experience data Deleted Successfully',
                timeout: 4000,
                intent: 'success'
            });
            this.closeModal();
            this.props.actions.getStudent(params.id)
        };
        const handleFailure = () => {
            Toast.show({
                message: 'Operation Failed',
                timeout: 4000,
                intent: 'danger'
            });
            this.props.actions.getStudent(params.id)
        };
        this.props.actions.addExperienceData(experienceDetilsData, handleSuccess, handleFailure, studentZA.id);
        this.props.actions.deleteExperience(exp[0].id)

    };
    openAltenativeEmailModal = (emailEdit, index) => {
        if (emailEdit == 'add') {
            this.setState({
                alternativeEmailModal: true,
                altEmailEdit: false
            }
            )
        }
        else {
            this.setState({
                alternativeEmailModal: true,
                editAlternativeEmail: emailEdit,
                altEmailEdit: true,
                altEmailEditIndex: index
            }
            )
        }
    }
    openAltenativePhoneModal = (phoneEdit, index) => {
        if (phoneEdit == 'add') {
            this.setState({
                alternativePhoneModal: true,
                altPhoneEdit: false
            }
            )
        }
        else {
            this.setState({
                alternativePhoneModal: true,
                editPhoneNumber: phoneEdit,
                altPhoneEdit: true,
                altPhoneEditIndex: index
            }
            )
        }
    }
    openExperienceModal = (exp, index) => {
        if (exp == 'add') {
            this.setState({
                experienceModal: true,
                titleExp: '',
                typeExp: '',
                companyExp: '',
                startExp: '',
                endExp: '',
                locationExp: '',
                experienceAdd: true,
                descriptionExp: '',
                startDate: '',
                endDate: '',
                expEndMonth: '',
                expStartMonth: '',
                experienceEdit: false,
            });
        } else {
            this.setState({
                experienceModal: true,
                experienceAdd: false,
                titleExp: exp.title,
                companyExp: exp.company_name,
                startDate: exp.start,
                typeExp: exp.experience_type,
                endDate: exp.end,
                locationExp: exp.location,
                expIndex: index,
                descriptionExp: exp.description,
                startDate: moment(exp.started_year).format('YYYY'),
                endDate: moment(exp.completed_year).format('YYYY'),
                expStartMonth: moment(exp.started_year).format('MM'),
                expEndMonth: moment(exp.completed_year).format('MM'),
                experienceEdit: true,
                expUpdateID: exp.id
            });
        }
    };
    deleteAltenativeEmail = (email, index) => {
        const { editAlternativeEmail, alternativeEmail, altEmailEdit, altEmailEditIndex } = this.state;

        alternativeEmail.splice(index, 1);
        this.setState({ alternativeEmail: alternativeEmail })
    }
    addAlternativeEmail = () => {
        const { editAlternativeEmail, alternativeEmail, altEmailEdit, altEmailEditIndex } = this.state;
        let isValidemail = Boolean;
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(editAlternativeEmail)) {
            isValidemail = false;
        }
        else {
            isValidemail = true;
        }
        if (isValidemail) {
            if (!alternativeEmail.includes(editAlternativeEmail)) {
                if (altEmailEdit) {
                    alternativeEmail[altEmailEditIndex] = editAlternativeEmail;
                }
                else {
                    alternativeEmail.push(editAlternativeEmail)
                }
                this.setState({ alternativeEmail: alternativeEmail })
                this.closeModal()
            }
            else {
                Toast.show({
                    message: 'Email Address already exists',
                    timeout: 10000,
                    intent: 'success'
                });
            }
        }
        else {
            this.setState({ editmailerror: true })
        }
    }
    deleteAltenativePhone = (email, index) => {
        const { editPhoneNumber, alternativePhoneNumber, altPhoneEdit, altPhoneEditIndex } = this.state
        alternativePhoneNumber.splice(index, 1);
        this.setState({ alternativePhoneNumber: alternativePhoneNumber })
    }

    addAlternativePhone = () => {
        const { editPhoneNumber, alternativePhoneNumber, altPhoneEdit, altPhoneEditIndex } = this.state
        if (!alternativePhoneNumber.includes(editPhoneNumber)) {
            if (altPhoneEdit) {
                alternativePhoneNumber[altPhoneEditIndex] = editPhoneNumber;
            }
            else {
                alternativePhoneNumber.push(editPhoneNumber)
            }
            this.setState({ alternativePhoneNumber: alternativePhoneNumber })
            this.closeModal()
        }
        else {
            Toast.show({
                message: 'Phone number already exists',
                timeout: 10000,
                intent: 'success'
            });
        }

    }
    // addAlternativePhone
    openDocModal = (data, type) => {
        if (type == "exp") {
            this.setState({
                showDocModal: true,
                viewDocFile: data.doc_url_degree
            })
        }
        if (type == "edu") {
            this.setState({
                showDocModal: true,
                viewDocFile: data.doc_url_marksheet
            })
        }
        if (type == "lang") {
            this.setState({
                showDocModal: true,
                viewDocFile: data.lang_doc_url
            })
        }
        if (type == "exp") {
            this.setState({
                showDocModal: true,
                viewDocFile: data.exp_doc_url
            })
        }

    }
    render() {
        const { student, studentZA, educationData, isSaasOfferLoading, isAcademicLoading, isLoading, isLoadingIntegration } = this.props
        const { firstName, lastName, academics, educationModal, degreeType, eduInstitute, eduName, eduCourse,
            eduStart, eduEnd, eduStartMonth, eduEndMonth, eduOption, eduMark, eduDescription, errors, experienceModal,
            typeExp, titleExp, companyExp, locationExp, expStartMonth, expEndMonth, descriptionExp, endExp, startExp, proficiencyModal, listening,
            reading, writing, speaking, overall, proficiencyType, email, phoneNumber, paymentMethod, financialCapacity, gap, country, sopValue,
            lorValue, visaValue, passportValue, otherdocValue, experience, languageEfficiency, documentExpFile, documentFile, sop, lor, passport, visa,
            otherDoc, otherDocDescription, visaDocDescription, passportDocDescription, startDate, endDate, alternativePhoneNumber, alternativeEmail, alternativeEmailModal,
            editAlternativeEmail, eduacationEdit, experienceEdit, proficiencyEdit, alternativePhoneModal, altPhoneEdit, editmailerror, altEmailEdit, editPhoneNumber, intakeMonth,
             intakeYear, showDocModal, viewDocFile, cvDoc, cvValue, cvDocDescription } = this.state
        return (
            <div className='container'>
                <Card elevation={Elevation.THREE}>
                    <StudentEditorMarkup
                        showDocModal={showDocModal}
                        onStudentList={this.onStudentList}
                        educationState={academics}
                        experienceState={experience}
                        languageEfficiencyState={languageEfficiency}
                        student={student}
                        firstName={firstName}
                        lastName={lastName}
                        educationModal={educationModal}
                        onChangeInput={this.onChangeInput}
                        openEducationModal={this.openEducationModal}
                        closeModal={this.closeModal}
                        onSave={this.onSave}
                        degreeType={degreeType}
                        eduInstitute={eduInstitute}
                        eduName={eduName}
                        eduCourse={eduCourse}
                        eduStart={eduStart}
                        eduEnd={eduEnd}
                        eduStartMonth={eduStartMonth}
                        eduEndMonth={eduEndMonth}
                        eduOption={eduOption}
                        eduMark={eduMark}
                        eduDescription={eduDescription}
                        onEducationUpdate={this.onEducationUpdate}
                        errors={errors}
                        experienceModal={experienceModal}
                        openExperienceModal={this.openExperienceModal}
                        titleExp={titleExp}
                        companyExp={companyExp}
                        locationExp={locationExp}
                        typeExp={typeExp}
                        onExperienceUpdate={this.onExperienceUpdate}
                        expStartMonth={expStartMonth}
                        expEndMonth={expEndMonth}
                        descriptionExp={descriptionExp}
                        endExp={endExp}
                        startExp={startExp}
                        proficiencyModal={proficiencyModal}
                        openProficiencyModal={this.openProficiencyModal}
                        listening={listening}
                        reading={reading}
                        writing={writing}
                        speaking={speaking}
                        overall={overall}
                        onProficiencyUpdate={this.onProficiencyUpdate}
                        proficiencyType={proficiencyType}
                        email={email}
                        phoneNumber={phoneNumber}
                        paymentMethod={paymentMethod}
                        financialCapacity={financialCapacity}
                        gap={gap}
                        country={country}
                        addNewDocument={this.addNewDocument}
                        onSaveDocument={this.onSaveDocument}
                        sopValue={sopValue}
                        lorValue={lorValue}
                        visaValue={visaValue}
                        passportValue={passportValue}
                        otherdocValue={otherdocValue}
                        deleteProficiency={this.deleteProficiency}
                        deleteExperience={this.deleteExperience}
                        deleteEducation={this.deleteEducation}
                        addFile={this.addFile}
                        addEduFile={this.addEduFile}
                        documentExpFile={documentExpFile}
                        documentFile={documentFile}
                        addexperienceFile={this.addexperienceFile}
                        sopData={sop}
                        lorData={lor}
                        passportData={passport}
                        visaData={visa}
                        otherDocData={otherDoc}
                        otherDocDescription={otherDocDescription}
                        visaDocDescription={visaDocDescription}
                        passportDocDescription={passportDocDescription}
                        startDate={startDate}
                        endDate={endDate}
                        alternativeEmail={alternativeEmail}
                        alternativeEmailModal={alternativeEmailModal}
                        openAltenativeEmailModal={this.openAltenativeEmailModal}
                        editAlternativeEmail={editAlternativeEmail}
                        addAlternativeEmail={this.addAlternativeEmail}
                        alternativePhoneNumber={alternativePhoneNumber}
                        alternativePhoneModal={alternativePhoneModal}
                        openAltenativePhoneModal={this.openAltenativePhoneModal}
                        editPhoneNumber={editPhoneNumber}
                        addAlternativePhone={this.addAlternativePhone}
                        intakeYear={intakeYear}
                        intakeMonth={intakeMonth}
                        editmailerror={editmailerror}
                        altEmailEdit={altEmailEdit}
                        altPhoneEdit={altPhoneEdit}
                        deleteAltenativeEmail={this.deleteAltenativeEmail}
                        deleteAltenativePhone={this.deleteAltenativePhone}
                        eduacationEdit={eduacationEdit}
                        experienceEdit={experienceEdit}
                        proficiencyEdit={proficiencyEdit}
                        isLoading={isLoading}
                        isAcademicLoading={isAcademicLoading}
                        isSaasOfferLoading={isSaasOfferLoading}
                        openDocModal={this.openDocModal}
                        viewDocFile={viewDocFile}
                        isLoadingIntegration={isLoadingIntegration}
                        cvDoc={cvDoc}
                        cvValue={cvValue}
                        cvDocDescription={cvDocDescription}
                    />
                </Card>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        isAcademicLoading: state.students.isAcademicLoading,
        isSaasOfferLoading: state.students.isSaasOfferLoading,
        isLoading: state.students.isLoading,
        student: state.students.student,
        logsDetails: state.students.logsDetails,
        userProfile: state.Auth.user,
        studentCourses: state.students.studentCourses,
        applicationStatus: state.students.applicationStatus,
        members: state.members.Members,
        studentZA: state.students.studentZA,
        proficiency: state.students.proficiency,
        experienceData: state.students.experienceData,
        experience: state.students.experience,
        educationData: state.students.educationData,
        isLoadingIntegration: state.Integration.isLoading,
        // documents: state.user.documents,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
        memberActions: bindActionCreators(memberActions, dispatch),
        integrationAction: bindActionCreators(integrationAction, dispatch)
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(StudentEditor));