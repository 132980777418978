import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import { Button, Form, Row, Col } from 'react-bootstrap';
import actions from '../../modules/actions';
import { bindActionCreators } from 'redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Toast } from '../../../../shared';
import './Status.scss';


class StatusCreator extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    status: '',
    color: '',
    order: '',
    success: '',
    fail: '',
    description:'',
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onChangeInput = (name, value)=>{
    console.log("log>>>>>",value)

    this.setState({
      [name]: value,
  });
  }

  createStatus = () => {
    const {status, description, success, fail, order, color } = this.state;
    const { student, closeModal } = this.props;
    console.log("student>>>>>",student)
    const data = {
      "key": status,
      "label": "",
      "organization_id": student.organization_id,    
      "color": color,    
      "description": description,
      "success_id": success,
      "fail_id": fail,
      "order": order,
    }
    const handleSuccess = () => {
      closeModal();
      Toast.show({
        message: "Status create successfully!",
        timeout: 4000,
        intent: 'success'
      })

      this.props.actions.getApplicationStatus(student.organization_id);
    }
    const handleError = () => {
      Toast.show({
        message: "Status create failed!",
        timeout: 4000,
        intent: 'danger'
      })
    }
    closeModal();
    this.props.actions.createStatus(data, handleSuccess, handleError);
  }

  render() {
    const {status, color, description, order, success, fail } = this.state;
    return (
      <div className="email-composer">
          <Row>
              <Col>
        <div>
          <Form.Control 
            type="text" 
            className="task-title" 
            placeholder="Status" 
            value={status}
            onChange={(e) => this.onChangeInput("status", e.target.value)} 
            />
        </div>
        </Col>
        <Col>
        <div>
        {/* <Form.Control
                                className="select-options"
                                name={`applicationStatus${acad.id}`}
                                as="select"
                                placeholder="Status"
                                value={myState && myState[`applicationStatus${acad.id}`] ?  myState[`applicationStatus${acad.id}`] : null}
                                onChange={(event) => onChangetext(event.target.name, event, acad.id, acad.id)}
                            > */}

          <Form.Control 
             as="select"
            className="task-title" 
            placeholder="Status color" 
            value={color} 
            onChange={(e) => this.onChangeInput("color", e.target.value)} 
            >
            <option value="">Select...</option>
                                    <option style={{backgroundColor: "#28a745" ,color: "white"}} value="28a745">Success</option>
                                    <option style={{backgroundColor: "#6c757d",color: "white"}} value="#6c757d">Secondary</option>
                                    <option style={{backgroundColor: "#ffc107",color: "white"}} value="#ffc107">Warning</option>
                                    <option style={{backgroundColor: "#007bff",color: "white"}} value="#007bff">Primary</option>
                                    <option style={{backgroundColor: "#dd3d45",color: "white"}} value="#dd3d45">Danger</option>
                                    <option style={{backgroundColor: "#17a2b8",color: "white"}} value="#17a2b8">Info</option>
                                    <option style={{backgroundColor: "#343a40",color: "white"}} value="#343a40">Dark</option>

                                    </Form.Control>
        </div>
        </Col>
        </Row>
        <Row>
            <Col>
        <div>
          <Form.Control 
            type="text" 
            className="task-title" 
            placeholder="Order" 
            value={order} 
            onChange={(e) => this.onChangeInput("order", e.target.value)} 
            />
        </div>
        </Col>

        <Col>
        <div>
          <Form.Control 
            type="text" 
            className="task-title" 
            placeholder="Success" 
            value={success} 
            onChange={(e) => this.onChangeInput("success", e.target.value)} 
            />
        </div>
        </Col>
        <Col>
        <div>
          <Form.Control 
            type="text" 
            className="task-title" 
            placeholder="Fail" 
            value={fail} 
            onChange={(e) => this.onChangeInput("fail", e.target.value)} 
            />
        </div>
        </Col>
        </Row>
        <div>
          <Form.Control 
            type="text"  
            as="textarea" 
            rows="3" 
            className="task-title" 
            placeholder="Description" 
            value={description} 
            onChange={(e) => this.onChangeInput("description",e.target.value)} 
            />
        </div>
        <div className="email-btn-toolbar">
          <Button className="pull-right" variant="primary" onClick={() => this.createStatus()}>Create Status</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
	return {
	}

}

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch),

	};
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StatusCreator));
