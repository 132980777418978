import React, { Component } from 'react';
import { InputGroup, FormControl, Form,Row,Col } from 'react-bootstrap';
import './InputCrossed.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon, Intent, Label, Slider } from "@blueprintjs/core";

class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: props.type || "text",
            show: false,
        }
    }

    showPassword = () => {
        const { show } = this.state;
        this.setState({
            show: !show,
        }, () => {
            this.setState({
                type: this.state.show ? 'text' : 'password'
            })
        })
    }

    render() {
        const { name, placeholder, onChange,className,resetFilter, value,onClick,FilterType, ...otherProps } = this.props;
       console.log("render props",this.props)
        return (
            <Row style={{maxWidth:'min-content'}}>
                <InputGroup >
                    <Row>
                        <div style={{paddingLeft:10}}>
                            <FormControl
                                { ...otherProps }
                                name={name}
                                className={`${className}`}
                                value={`${FilterType}-${value}`} 
                                // disabled={true} 
                                onClick={(event) =>resetFilter(event.target.name)}              
                                /> 
                            </div>                            
                            <div className ="filtered-icon">
                                {/* <InputGroup.Append> */}
                                 { value=='none' ? '':   <i className="fa fa-times-circle-o delete-icon" onClick={(event) =>resetFilter(event.target.name)} aria-hidden="true"></i>}
                            {/* </InputGroup.Append> */}
                        </div> 
                    </Row>
                </InputGroup>                
            </Row>
            

            // <InputGroup className={groupClassName} >
            //     <FormControl
            //         {...otherProps}
            //         type={'button'}
            //         name={name}
            //         // placeholder={placeholder}
            //         value={value}
            //         onChange={onChange}
            //         required
            //     />
            //     {
            //         appendPasswordEye &&
            //         <InputGroup.Append onClick={(e) => this.showPassword(e)}>
            //             <Icon className="icon-btn" icon={ show ?"delete":"delete"} iconSize="18px"/> 
            //         </InputGroup.Append>
            //     }
            // </InputGroup>
        )
    }
}


export default Input;




























// <InputGroup className="mb-3">
// <InputGroup.Prepend>
//   <InputGroup.Text>$</InputGroup.Text>
// </InputGroup.Prepend>
// <FormControl aria-label="Amount (to the nearest dollar)" />
// <InputGroup.Append>
//   <InputGroup.Text>.00</InputGroup.Text>
// </InputGroup.Append>
// </InputGroup>