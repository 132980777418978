import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import "./MappedConnect.scss"
import hubs from '../../../img/hubs-pot.png'


const MappedConnectMarkup = (props) => {
    return (
        <Container className="mapped-connect-markup">
            <div>

                <img src={hubs} />
                <div className="loader">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>

                </div>
                <a className="navbar-brand">
                    <div><a className="title">Zi</a>lter</div>
                    <div className="sub">by Zoomabroad</div>
                </a>

                <p>Fetching data...</p>
            </div>


        </Container>
    );
}

export default MappedConnectMarkup;