
export const GET_DIRECT_INDIRECT_FAIL = 'GET_DIRECT_INDIRECT_FAIL';
export const GET_DIRECT_INDIRECT_REQUEST='GET_DIRECT_INDIRECT_REQUEST';
export const GET_DIRECT_INDIRECT_SUCCESS = 'GET_DIRECT_INDIRECT_SUCCESS';
export const GET_SOURSE_REPORT_FAIL = 'GET_SOURSE_REPORT_FAIL';
export const GET_SOURSE_REPORT_REQUEST='GET_SOURSE_REPORT_REQUEST';
export const GET_SOURSE_REPORT_SUCCESS = 'GET_SOURSE_REPORT_SUCCESS';
export const GET_ASSINGED_LOGS_FAIL = 'GET_ASSINGED_LOGS_FAIL';
export const GET_ASSINGED_LOGS_REQUEST = 'GET_ASSINGED_LOGS_REQUEST';
export const GET_ASSINGED_LOGS_SUCCESS = 'GET_ASSINGED_LOGS_SUCCESS'
export const GET_APA_LOGS_FAIL = 'GET_APA_LOGS_FAIL';
export const GET_APA_LOGS_REQUEST = 'GET_APA_LOGS_REQUEST';
export const GET_APA_LOGS_SUCCESS = 'GET_APA_LOGS_SUCCESS'