import React, { Component } from "react";
import "./MultiSelectFormatter.scss";
import { Form, Col } from 'react-bootstrap';
import _ from 'lodash';
import { Suggest } from "@blueprintjs/select";
import { MenuItem, Intent } from "@blueprintjs/core";
const MultiSelectFormatter = ({ filterOptions, values, index, type, handleValueChange, selected, disabled }) => {
	let isControlOpen = true;

    const renderInputValue = products => products.name;
	const filterProducts = (query, product) => {
		return (
			`${product.name.toLowerCase()}`.indexOf(query.toLowerCase()) >= 0
		);
	};
	const highlightText = (text, query) => {
		let lastIndex = 0;
		const words = query
			.split(/\s+/)
			.filter(word => word.length > 0)
			.map(escapeRegExpChars);
		if (words.length === 0) {
			return [text];
		}
		const regexp = new RegExp(words.join('|'), 'gi');
		const tokens: React.ReactNode[] = [];
		while (true) {
			const match = regexp.exec(text);
			if (!match) {
				break;
			}
			const length = match[0].length;
			const before = text.slice(lastIndex, regexp.lastIndex - length);
			if (before.length > 0) {
				tokens.push(before);
			}
			lastIndex = regexp.lastIndex;
			tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
		}
		const rest = text.slice(lastIndex);
		if (rest.length > 0) {
			tokens.push(rest);
		}
		return tokens;
	};
	const escapeRegExpChars = text => {
		return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
	};
	const renderProducts = (product, { handleClick, modifiers, query }) => {
		if (!modifiers.matchesPredicate) {
			return null;
		}
		const text = `${product.name}`;
		return (
			<MenuItem
				active={modifiers.active}
				disabled={modifiers.disabled}
				label={product.price}
				key={product.name}
				onClick={handleClick}
				text={highlightText(text, query)}
			/>
		);
    };  
    return (
		 <Suggest
            openOnKeyDown={true}
            itemPredicate={filterProducts}
            itemRenderer={renderProducts}
            items={filterOptions}
            inputValueRenderer={renderInputValue}
			selectedItem={selected ? values : values[index]}
			disabled={disabled}
            fill={true}
            noResults={
                <MenuItem
                    intent={Intent.SUCCESS}
                    icon="add-to-artifact"
                    text="No Result"
                />
            }
            popoverProps={{ minimal: true }}
            resetOnSelect={true}
			onItemSelect={(e) => handleValueChange(e, index, type)}
        />
		
    )
}
export default MultiSelectFormatter

