import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CreateUserMarkup from '../components/CreateUserMarkup';
import { createContact, updateContact, getContactById } from '../modules/actions';
import { Toast } from '../../../shared';

class CreateUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstNameVal: "",
            lastNameVal: "",
            emailVal: "",
            phoneVal: "",
            overallMarksVal: "",
            hscMarkSheetVal: "",
            bachelorsVal: "",
            mastersVal: "",
            anyOtherQulVal: "",
            bachelorsMarksheetVal: "",
            mastersMarksheetVal: "",
            otherMarksheetVal: "",
            countryVal: "",
            cityVal: "",
            gapInEducationVal: "",
            reasonForGapVal: "",
            marksInEnglishVal: "",
            languageTestVal: "",
            englishLanguageVal: "",
            budgetVal: "",
            experienceVal: "",
            interestedCoursesVal: "",
            desiredCourseLevelVal: "",
            intakeVal: "",
            interestedUniversityVal: "",
            destinationCountryVal: "",
            interestedCityVal: "",
            TraveledabroadVal: "",
            anyVisaRejectionsVal: "",
            sopVal: "",
            lorVal: "",
            passportVal: "",
            agreeVal: "",
            sourceVal: "",
            errors: {},
            userDetail: {},
            vid: null,
        }
    }

    componentDidMount() {
        let obj = JSON.parse(localStorage.getItem('pms_user'));
        const { match: { params: { vid } } } = this.props;
        this.setState({ userDetail: obj.details, sourceVal: obj.details.organizationname, });
        let handleSuccess = (data) => {
            this.setState({
                vid: data.vid,
                firstNameVal: data.properties.firstname.value,
                lastNameVal: data.properties.lastname.value,
                emailVal: data.properties.email.value,
                phoneVal: data.properties.phone.value,
                overallMarksVal: data.properties.n12th_overall_marks ? data.properties.n12th_overall_marks.value : "",
                hscMarkSheetVal: data.properties.n12th_mark_sheet ? data.properties.n12th_mark_sheet.value : "",
                bachelorsVal: data.properties.bachelors ? data.properties.bachelors.value : "",
                mastersVal: data.properties.masters ? data.properties.masters.value : "",
                anyOtherQulVal: data.properties.any_other_qualification ? data.properties.any_other_qualification.value : "",
                bachelorsMarksheetVal: data.properties.bachelors_marksheet_and_degree ? data.properties.bachelors_marksheet_and_degree.value : "",
                mastersMarksheetVal: data.properties.masters_marksheet_and_degree ? data.properties.masters_marksheet_and_degree.value : "",
                otherMarksheetVal: data.properties.other_qualification_marksheet ? data.properties.other_qualification_marksheet.value : "",
                countryVal: data.properties.zbcountry ? data.properties.zbcountry.value : "",
                cityVal: data.properties.zbcity ? data.properties.zbcity.value : "",
                gapInEducationVal: data.properties.gap_in_education_years_ ? data.properties.gap_in_education_years_.value : "",
                reasonForGapVal: data.properties.reason_for_gap_by_year_ ? data.properties.reason_for_gap_by_year_.value : "",
                marksInEnglishVal: data.properties.marks_in_english ? data.properties.marks_in_english.value : "",
                languageTestVal: data.properties.language_test ? data.properties.language_test.value : "",
                englishLanguageVal: data.properties.english_language_ielts_scorecard_ ? data.properties.english_language_ielts_scorecard_.value : "",
                budgetVal: data.properties.budget ? data.properties.budget.value : "",
                experienceVal: data.properties.experience_and_other_credentials ? data.properties.experience_and_other_credentials.value : "",
                interestedCoursesVal: data.properties.interested_courses ? data.properties.interested_courses.value : "",
                desiredCourseLevelVal: data.properties.desired_course_level ? data.properties.desired_course_level.value : "",
                intakeVal: data.properties.intake ? data.properties.intake.value : "",
                interestedUniversityVal: data.properties.interested_university_if_any_ ? data.properties.interested_university_if_any_.value : "",
                destinationCountryVal: data.properties.interested_destination_country ? data.properties.interested_destination_country.value : "",
                interestedCityVal: data.properties.interested_city ? data.properties.interested_city.value : "",
                TraveledabroadVal: data.properties.have_you_traveled_abroad_ ? data.properties.have_you_traveled_abroad_.value : "",
                anyVisaRejectionsVal: data.properties.any_visa_rejections_ ? data.properties.any_visa_rejections_.value : "",
                sopVal: data.properties.sop ? data.properties.sop.value : "",
                lorVal: data.properties.lor ? data.properties.lor.value : "",
                passportVal: data.properties.passport ? data.properties.passport.value : "",
                agreeVal: true
            });
        }
        if (vid) {
            this.props.getContactById(handleSuccess, () => { }, vid);
        }
    }

    onInputChange = (e) => {
        let errors = this.state.errors;
        errors[`${e.target.name}`] = false;
        if (e.target.name == 'sop' || e.target.name == 'hscMarkSheet' || e.target.name == 'bachelorsMarksheet' || e.target.name == 'mastersMarksheet' || e.target.name == 'otherMarksheet' || e.target.name == 'englishLanguage' || e.target.name == 'lor' || e.target.name == 'passport') {
            this.setState({
                [`${e.target.name}Val`]: e.target.files[0]
            });
        } else {
            this.setState({
                [`${e.target.name}Val`]: e.target.value,
                errors
            });
        }
    }

    isValid = () => {
        const {
            firstNameVal: firstName,
            lastNameVal: lastName,
            emailVal: email,
            phoneVal: phone,
            cityVal: city
        } = this.state;
        let errors = {};
        let valid = true;

        if (firstName === "" || !/[A-Za-z]/.test(firstName)) {
            errors.firstName = true;
            this.setState({ errors });
            valid = false;
        }
        if (lastName === "" || !/[A-Za-z]/.test(lastName)) {
            errors.lastName = true;
            this.setState({ errors });
            valid = false;
        }
        if (email === "" || !/[\w+-_.]@[\w+]/.test(email)) {
            errors.email = true;
            this.setState({ errors });
            valid = false;
        }
        if (phone === "" || !/[0-9]/.test(phone)) {
            errors.phone = true;
            this.setState({ errors });
            valid = false;
        }
        if (city === "" || !/[A-Za-z]/.test(city)) {
            errors.city = true;
            this.setState({ errors });
            valid = false;
        }

        return valid;
    }

    createNew() {
        const {
            firstNameVal: firstName,
            lastNameVal: lastName,
            emailVal: email,
            phoneVal: phone,
            overallMarksVal: overallMarks,
            bachelorsVal: bachelors,
            mastersVal: masters,
            anyOtherQulVal: anyOtherQul,
            countryVal: country,
            cityVal: city,
            gapInEducationVal: gapInEducation,
            reasonForGapVal: reasonForGap,
            marksInEnglishVal: marksInEnglish,
            languageTestVal: languageTest,
            budgetVal: budget,
            experienceVal: experience,
            interestedCoursesVal: interestedCourses,
            desiredCourseLevelVal: desiredCourseLevel,
            interestedUniversityVal: interestedUniversity,
            destinationCountryVal: destinationCountry,
            intakeVal: intake,
            interestedCityVal: interestedCity,
            TraveledabroadVal: Traveledabroad,
            anyVisaRejectionsVal: anyVisaRejections,
            sopVal: sop,
            lorVal: lor,
            passportVal: passport,
            hscMarkSheetVal: hscMarkSheet,
            bachelorsMarksheetVal: bachelorsMarksheet,
            mastersMarksheetVal: mastersMarksheet,
            otherMarksheetVal: otherMarksheet,
            englishLanguageVal: englishLanguage,
            sourceVal: source,
            vid
        } = this.state;
        if (this.isValid()) {
            let formData = new FormData();
            if (hscMarkSheet !== '') {
                formData.append('files', hscMarkSheet, 'hscMarkSheet');
            }
            if (bachelorsMarksheet !== '') {
                formData.append('files', bachelorsMarksheet, 'bachelorsMarksheet');
            }
            if (mastersMarksheet !== '') {
                formData.append('files', mastersMarksheet, 'mastersMarksheet');
            }
            if (otherMarksheet !== '') {
                formData.append('files', otherMarksheet, 'otherMarksheet');
            }
            if (englishLanguage !== '') {
                formData.append('files', englishLanguage, 'englishLanguage');
            }
            if (sop !== '') {
                formData.append('files', sop, 'sop');
            }
            if (lor !== '') {
                formData.append('files', lor, 'lor1');
            }
            if (passport !== '') {
                formData.append('files', passport, 'passport');
            }
            const data = {
                "properties": [
                    {
                        "property": "email",
                        "value": email
                    },
                    {
                        "property": "firstname",
                        "value": firstName
                    },
                    {
                        "property": "lastname",
                        "value": lastName
                    },
                    {
                        "property": "company",
                        "value": "HubSpot"
                    },
                    {
                        "property": "phone",
                        "value": phone
                    },
                    {
                        "property": "address",
                        "value": "25 First Street"
                    },
                    {
                        "property": "city",
                        "value": "Cambridge"
                    },
                    {
                        "property": "state",
                        "value": "MA"
                    },
                    {
                        "property": "zip",
                        "value": "02139"
                    },
                    {
                        "property": "n12th_overall_marks",
                        "value": overallMarks
                    },
                    {
                        "property": "bachelors",
                        "value": bachelors
                    },
                    {
                        "property": "masters",
                        "value": masters
                    },
                    {
                        "property": "any_other_qualification",
                        "value": anyOtherQul
                    },
                    {
                        "property": "zbcountry",
                        "value": country
                    },
                    {
                        "property": "zbcity",
                        "value": city
                    },
                    {
                        "property": "gap_in_education_years_",
                        "value": gapInEducation
                    },
                    {
                        "property": "reason_for_gap_by_year_",
                        "value": reasonForGap
                    },
                    {
                        "property": "marks_in_english",
                        "value": marksInEnglish
                    },
                    {
                        "property": "language_test",
                        "value": languageTest
                    },
                    {
                        "property": "budget",
                        "value": budget
                    },
                    {
                        "property": "experience_and_other_credentials",
                        "value": experience
                    },
                    {
                        "property": "interested_courses",
                        "value": interestedCourses
                    },
                    {
                        "property": "desired_course_level",
                        "value": desiredCourseLevel
                    },
                    {
                        "property": "intake",
                        "value": intake
                    },
                    {
                        "property": "interested_university_if_any_",
                        "value": interestedUniversity
                    },
                    {
                        "property": "interested_destination_country",
                        "value": destinationCountry
                    },
                    {
                        "property": "interested_city",
                        "value": interestedCity
                    },
                    {
                        "property": "have_you_traveled_abroad_",
                        "value": Traveledabroad
                    },
                    {
                        "property": "any_visa_rejections_",
                        "value": anyVisaRejections
                    },
                    {
                        "property": "source",
                        "value": source
                    },
                    {
                        "property": "hs_lead_status",
                        "value": "NEW"
                    }
                ]
            }

            const handleSuccess = () => {
                Toast.show({
                    message: vid ? 'Contact Updated Successfully !!!' : "Contact Created Successfully !!!!",
                    timeout: 10000,
                    intent: 'success'
                });
                this.props.history.goBack();
            };
            if (vid) {
                this.props.updateContact(handleSuccess, () => { }, data, vid);
            } else {
                this.props.createContact(handleSuccess, () => { }, data, this.state.userDetail.listId, formData);
            }
        }
    }


    render() {
        return (
            <CreateUserMarkup
                isLoading={this.props.isLoading}
                error={this.props.error}
                onInputChange={this.onInputChange}
                firstNameVal={this.state.firstNameVal}
                lastNameVal={this.state.lastNameVal}
                emailVal={this.state.emailVal}
                phoneVal={this.state.phoneVal}
                overallMarksVal={this.state.overallMarksVal}
                hscMarkSheetVal={this.state.hscMarkSheetVal}
                bachelorsVal={this.state.bachelorsVal}
                mastersVal={this.state.mastersVal}
                anyOtherQulVal={this.state.anyOtherQulVal}
                bachelorsMarksheetVal={this.state.bachelorsMarksheetVal}
                mastersMarksheetVal={this.state.mastersMarksheetVal}
                otherMarksheetVal={this.state.otherMarksheetVal}
                countryVal={this.state.countryVal}
                cityVal={this.state.cityVal}
                gapInEducationVal={this.state.gapInEducationVal}
                reasonForGapVal={this.state.reasonForGapVal}
                marksInEnglishVal={this.state.marksInEnglishVal}
                languageTestVal={this.state.languageTestVal}
                englishLanguageVal={this.state.englishLanguageVal}
                budgetVal={this.state.budgetVal}
                experienceVal={this.state.experienceVal}
                interestedCoursesVal={this.state.interestedCoursesVal}
                desiredCourseLevelVal={this.state.desiredCourseLevelVal}
                intakeVal={this.state.intakeVal}
                interestedUniversityVal={this.state.interestedUniversityVal}
                destinationCountryVal={this.state.destinationCountryVal}
                interestedCityVal={this.state.interestedCityVal}
                TraveledabroadVal={this.state.TraveledabroadVal}
                anyVisaRejectionsVal={this.state.anyVisaRejectionsVal}
                sopVal={this.state.sopVal}
                lorVal={this.state.lorVal}
                passportVal={this.state.passportVal}
                agreeVal={this.state.agreeVal}
                sourceVal={this.state.sourceVal}
                vid={this.state.vid}
                errors={this.state.errors}
                createNew={() => this.createNew()}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.CreateUser.isLoading,
        error: state.CreateUser.error,
        contactDetails: state.CreateUser.contactDetails
    }
}

export default withRouter(connect(mapStateToProps, { createContact, updateContact, getContactById })(CreateUser));