import React from 'react'
import ItemTypes from './ItemTypes'
import { DragSource } from 'react-dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Popover } from "react-bootstrap";
const style = {
  border: '1px dashed gray',
  backgroundColor: 'white',
  padding: '0.5rem 1rem',
  marginRight: '1rem',
  marginBottom: '1rem',
  cursor: 'move',
}
const styleAdd ={
  float: 'right',
 color: 'green',
fontSize: '1.3rem'
}
const Box = ({ item, isDragging, connectDragSource ,onClickAdd}) => {
  const opacity = isDragging ? 0.4 : 1
  return (
    <div ref={connectDragSource} style={{ ...style, opacity }}>
      {item.question}  
      <OverlayTrigger
                    placement={'top'}
                    delay={{ hide: 200 }}
                    overlay={<Popover className="tool-tip" id="tooltip-disabled">Add</Popover>}>
                    <FontAwesomeIcon className="icon-btn" style = {{ ...styleAdd}} onClick={ () => onClickAdd(item)}  icon="plus-square" />
      </OverlayTrigger>
    </div>

  )
}
export default DragSource(
  ItemTypes.BOX,
  {
    beginDrag: props => ({ item: props.item }),
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),  
  }),
)(Box)
