import { APISAAS_DOMAIN, URL } from "../../../env"
import {
    AUTH_LOADING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    FORGETPASSWORD_SUCCESS,
    FORGETPASSWORD_ERROR,
    REFRESH_USER,
    LOGOUT_REQUEST,
    UPDATE_ERROR,
    UPDATE_SUCCESS,
    TOKENVERIFY_REQUEST,
    TOKENVERIFY_SUCCESS,
    TOKENVERIFY_FAIL,
    POST_RESET_PASSWORD_REQUEST,
    POST_RESET_PASSWORD_FAIL,
    POST_RESET_PASSWORD_SUCCESS
} from './type';

const login = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: AUTH_LOADING
        });
        fetch(`${APISAAS_DOMAIN}api/organization/login/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: LOGIN_ERROR,
                        payload: body.message
                    });
                    handleFailure(response);
                } else {
                    localStorage.setItem('saas_user', JSON.stringify(body));
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: body
                    });
                    
                    handleSuccess(body);
                }
            })
            .catch((error) => {
                dispatch({
                    type: LOGIN_ERROR,
                    payload: error
                });
                handleFailure('', error);
            });
    }
}


const ssologin = (handleSuccess, handleFailure, apikey) => {
    return (dispatch) => {
        dispatch({
            type: AUTH_LOADING
        });
        fetch(`${APISAAS_DOMAIN}api/organizationusers/apikey/${apikey}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: LOGIN_ERROR,
                        payload: body.message
                    });
                    handleFailure(response);
                } else {
                    localStorage.setItem('saas_user', JSON.stringify(body));
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: body
                    });
                    
                    handleSuccess(body);
                }
            })
            .catch((error) => {
                dispatch({
                    type: LOGIN_ERROR,
                    payload: error
                });
                handleFailure('', error);
            });
    }
}
const logOut = () =>{
    localStorage.clear();
    return function (dispatch) {
        dispatch({
            type: LOGOUT_REQUEST,
        });
    }
}
const register = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {
        dispatch({
            type: AUTH_LOADING
        });
        fetch(`${APISAAS_DOMAIN}api/organization/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: REGISTER_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: REGISTER_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

const update = (handleSuccess, handleFailure, data) => {
    return (dispatch, getState) => {
        const { Auth: { user: { organization, token } } } = getState();
        const id = organization.organization_id
        dispatch({
            type: AUTH_LOADING
        });
        fetch(`${APISAAS_DOMAIN}api/organization/update/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: UPDATE_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: UPDATE_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

const forgetPassword = (handleSuccess, handleFailure, data) => {
    return (dispatch) => {  
        dispatch({
            type: AUTH_LOADING
        });
        fetch(`${APISAAS_DOMAIN}api/organization/forgotpasswordemail/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: FORGETPASSWORD_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: FORGETPASSWORD_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

const tokenVerify = (handleSuccess, handleFailure, token) => {
    return (dispatch, getState) => {
        dispatch({
            type:TOKENVERIFY_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/organization/tokenverify/${token.token}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type:TOKENVERIFY_FAIL,
                        payload: body.error
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type:TOKENVERIFY_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
            
    }
}


const resetPassword = (handleSuccess, handleFailure, data) => {
    return function (dispatch) {
        dispatch({
            type: POST_RESET_PASSWORD_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/organization/resetpassword/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: POST_RESET_PASSWORD_FAIL,
                        payload: body.error
                    });
                    handleFailure(body.error);
                } else {
                    dispatch({
                        type: POST_RESET_PASSWORD_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

const updateMember = (handleSuccess, handleFailure, data, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AUTH_LOADING
        });
        fetch(`${APISAAS_DOMAIN}api/organizationusers/memberupadte/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: UPDATE_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: UPDATE_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}


const refreshUser = (user, callback) => {
    return (dispatch) => {
        dispatch({
            type: REFRESH_USER,
            payload: user
        });
        callback(true);
    }
}

export default {
    login,
    ssologin,
    register,
    forgetPassword,
    refreshUser,
    logOut,
    update,
    tokenVerify,
    resetPassword,
    updateMember
}