import Constants from './constants';

const INITIAL_STATE = {
    isLoading: false,
    error: false,
    saasData: null,
    saasResult: null,
    hubspotProperties: null,
    hubspotToken: null,
    mapping:{},
    document:null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
       
        case Constants.AUTH_LOADING:
            return {
                ...state,
                isLoading:true
                
            };
        case Constants.REGISTER_ERROR:
            return {
                ...state,
                isLoading:false

                
            };
        case Constants.REGISTER_SUCCESS:
            return {
                ...state,
                error: action.error,
                isLoading:false

            };
        case Constants.UPDATE_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case Constants.UPDATE_SUCCESS:
            return { 
                ...state,
                isLoading: false,
                error: ''
             };
        case Constants.UPDATE_FAIL:
            return { 
                ...state,
                isLoading: false, 
                error: action.payload
            };
        case Constants.GET_HUBSPOT_PROPERTIES_ERROR:
            return {
                ...state,
                isLoading: false, 
            };
        case Constants.GET_HUBSPOT_PROPERTIES_SUCCESS:
            return {
                ...state,
                isLoading: false, 
                hubspotProperties: action.payload
            };
        case Constants.GET_HUBSPOT_TOKEN_ERROR:
            return {
                ...state,
                isLoading: false, 
            };
        case Constants.GET_HUBSPOT_TOKEN_SUCCESS:
            return {
                ...state,
                isLoading: false, 
                hubspotToken: action.payload
            };
        case Constants.GET_MAPPING_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_MAPPING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                mapping: action.payload
            };
        case Constants.GET_MAPPING_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.CREATE_HUBSPOT_PROPERTIES_ERROR:
            return {
                ...state,
                isLoading: false, 
            };
        case Constants.CREATE_HUBSPOT_PROPERTIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.UPDATE_HUBSPOT_CONTACT_ERROR:
            return {
                ...state,
                isLoading: false, 
            };
        case Constants.UPDATE_HUBSPOT_CONTACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.CREATE_HUBSPOT_NOTE_ERROR:
            return {
                ...state,
                isLoading: false, 
            };
        case Constants.CREATE_HUBSPOT_NOTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
            case Constants.SAAS_DOCUMENT_UPLOAD_REQUEST:
                return {
                    ...state,
                    isLoading: true
                };
            case Constants.SAAS_DOCUMENT_UPLOAD_REQUEST_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    document: action.payload
                };
            case Constants.SAAS_DOCUMENT_UPLOAD_REQUEST_FAIL:
                return {
                    ...state,
                    isLoading: false,
                    error: action.error
                };
        default:
            return state;
    }
};
