import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { Button, Form, Row } from "react-bootstrap";
import actions from "../../modules/actions";
import { DateInput } from "@blueprintjs/datetime";
import {
  InputGroup,
  Label,
  MenuItem,
  TextArea,
  Position,
  Checkbox,
} from "@blueprintjs/core";
import moment from "moment";
import { bindActionCreators } from "redux";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ZLoader } from "../../../../shared/loader/ZLoader";
import { Toast } from "../../../../shared";
import "./FeedbackForm.scss";
import FeedbackData from './feedback.json'
class FeedbackForm extends Component {
  state = {
    interview: null,
  };

  onChangeInput = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onSubmit = () => {
    const { interview } = this.state;

    const data = {
      interview: interview,
    };
    const handleSuccess = () => {
      Toast.show({
        message: "Task saved successfully!",
        timeout: 4000,
        intent: "success",
      });
    };
    const handleError = () => {
      Toast.show({
        message: "Task saving failed!",
        timeout: 4000,
        intent: "danger",
      });
    };
    console.log(data, ">>>>?//?");
    this.props.closefeedbackModal()
  };

  render() {
    const { duedate, interview } = this.state;
    const {
      isLoading,getFeedbackForm,onSubmitFeedback
    } = this.props;
    console.log("interviewinterviewinterview", interview);

    return (
      <div className="feedback-composer">
        {isLoading ? <ZLoader /> : null}
        {/* <div>
          <Form.Control type="text" className="task-title" placeholder="Title" value={subject} onChange={(event) => onSubjectChange("subject", event.target.value)} />
        </div> */}
        <div className="question">
          <p>Did student complete the interview?</p>
          <Form.Check
            inline
            type={"radio"}
            label="Yes"
            name="interview"
            id={"feed1"}
            onChange={(e) =>{this.props.onChangeFeedback(true, 0);this.onChangeInput("interview",true)}}
          />
          <Form.Check
            inline
            type={"radio"}
            label="No"
            id={"feed2"}
            name="interview"
            onChange={(e) =>{this.props.onChangeFeedback(false, 0);;this.onChangeInput("interview",false)}}
          />
        </div>
        {interview ? (
            <>
          {getFeedbackForm && getFeedbackForm.form_questions &&  getFeedbackForm.form_questions.length?
          <>
         { getFeedbackForm.form_questions.map((data,index) =>{
           if(index!=0){
              return(
                <div className="question">
                <p>{data.placeholder}</p>
                <Form.Control onChange={(e)=>this.props.onChangeFeedback(e.target.value,index)}/>
              </div>
              )
          }})}</>:null
             }
          </>
        ) : null}

        <div className="feedback-btn">
          <Button
            className=""
            variant="primary"
            onClick={() => this.props.onSubmitFeedback()}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedbackForm)
);
