import React from 'react';
import { Container, Table, Form, Button, Row,Col } from 'react-bootstrap';
import './TickTable.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ZLoader } from '../../../shared/loader/ZLoader';

const TickTable = (props) => {
    const {mappingView,mappingCreate,
        mappingEdit,
        mappingDelete,
        questionView,
        questionCreate,
        questionEdit,
        questionDelete,
        interviewView,
        interviewCreate,
        interviewEdit,
        interviewDelete,
        courseView,
        courseCreate,
        courseEdit,
        courseDelete,
        reportView,
        reportCreate,
        reportEdit,
        reportDelete,
        studentsView,
        studentsCreate,
        studentsEdit,
        studentsDelete, 
        dashboardView,
        dashboardCreate,
        dashboardEdit,
        dashboardDelete,
        counselorReportView,
        counselorReportCreate,
        counselorReportEdit,
        counselorReportDelete,
        onCheckChange,changeText,emailVal,onSend,onback,isLoading,isSuperuser,finalData,edit,onUpdate,userData, isCounsellor} =props;
        console.log("editeditediteditediteditediteditedit>>.................", edit)
     
    return (
        <Container className="table-component-container">
             {isSuperuser?
           <div>
            {isLoading? <ZLoader/>:""}
            
            <h1>User Access</h1>
            <Row>
                <Col md="1">
                    <div className="button-container">
                        <Button className="save-button" onClick={onback}> 
                        <i class="fa fa-arrow-left" aria-hidden="true">
                            Back</i></Button>
                    </div>
                </Col>
                <Col md="4">  
                    <Row>                                            
                        <Col md="2" style={{marginTop:10}}>
                            Email
                        </Col>
                        <Col md="10">
                            <Form.Control onChange={changeText} value={emailVal} className="text-input" size="sm" type="email" placeholder="Email" />
                        </Col>
                    </Row> 
                </Col>
                <Col md="2">  
                    <Row> 
                        <Col style={{alignSelf: 'center'}}>
                            <p>Counsellor</p>
                        </Col>                                           
                        <Col>
                        <Form.Group >
                                <Form.Check 
                                    style={{paddingLeft: 0, paddingBottom:'7px'}}
                                     name="isCounsellor" 
                                     type="checkbox"  
                                     onChange={onCheckChange} 
                                     checked={isCounsellor ?true:false} 
                                     value={isCounsellor}
                      
                                       />
                            </Form.Group>
                        </Col>
                    </Row> 
                </Col>
                <Col>
                    <div className="button-container" >
                        <Button className="save-button" onClick={edit ? onUpdate :onSend } disabled={/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(emailVal) ? false : true } >{edit? "Update": "Invite"}</Button>
                    </div>
                </Col>
            </Row>
            <Table >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>FEATURE</th>
                        <th>VIEW</th>
                        <th>CREATE</th>
                        <th>EDIT</th>
                        <th>DELETE</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td>Integration</td>
                        <td>
                            <Form.Group >
                                <Form.Check name="mappingView" type="checkbox" onChange={onCheckChange} checked={ mappingView?true:false}  value={ mappingView}/>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="mappingCreate" type="checkbox"  onChange={onCheckChange} checked={ mappingCreate?true:false}  value={ mappingCreate} />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="mappingEdit" type="checkbox"  onChange={onCheckChange} checked={ mappingEdit?true:false}  value={ mappingEdit}  />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="mappingDelete" type="checkbox"  onChange={onCheckChange} checked={ mappingDelete?true:false}  value={ mappingDelete} />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Question</td>
                        <td>
                            <Form.Group >
                                <Form.Check name="questionView" type="checkbox" onChange={onCheckChange} checked={ questionView?true:false}  value={questionView}/>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="questionCreate" type="checkbox"  onChange={onCheckChange} checked={ questionCreate?true:false}  value={questionCreate} />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="questionEdit" type="checkbox"  onChange={onCheckChange} checked={ questionEdit?true:false}  value={questionEdit} />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="questionDelete" type="checkbox"  onChange={onCheckChange} checked={ questionDelete?true:false}  value={questionDelete} />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Interview</td>
                        <td>
                            <Form.Group >
                                <Form.Check name="interviewView" type="checkbox" onChange={onCheckChange} checked={ interviewView?true:false}  value={interviewView}/>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="interviewCreate" type="checkbox"  onChange={onCheckChange} checked={ interviewCreate?true:false}  value={interviewCreate} />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="interviewEdit" type="checkbox"  onChange={onCheckChange} checked={ interviewEdit?true:false}  value={interviewEdit} />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="interviewDelete" type="checkbox"  onChange={onCheckChange} checked={ interviewDelete?true:false}  value={interviewDelete} />
                            </Form.Group>
                        </td>                    </tr>
                    <tr>
                        <td></td>
                        <td>Course</td>
                        <td>
                            <Form.Group >
                                <Form.Check name="courseView" type="checkbox" onChange={onCheckChange} checked={ courseView?true:false}  value={courseView}/>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="courseCreate" type="checkbox"  onChange={onCheckChange} checked={ courseCreate?true:false}  value={courseCreate} />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="courseEdit" type="checkbox"  onChange={onCheckChange} checked={ courseEdit?true:false}  value={courseEdit} />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="courseDelete" type="checkbox"  onChange={onCheckChange} checked={ courseDelete?true:false}  value={courseDelete} />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Report</td>
                        <td>
                            <Form.Group >
                                <Form.Check name="reportView" type="checkbox" onChange={onCheckChange} checked={ reportView?true:false}  value={reportView}/>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="reportCreate" type="checkbox"  onChange={onCheckChange} checked={ reportCreate?true:false}  value={reportCreate} />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="reportEdit" type="checkbox"  onChange={onCheckChange} checked={ reportEdit?true:false}  value={reportEdit} />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="reportDelete" type="checkbox"  onChange={onCheckChange} checked={ reportDelete?true:false}  value={reportDelete} />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Students</td>
                        <td>
                            <Form.Group >
                                <Form.Check name="studentsView" type="checkbox" onChange={onCheckChange} checked={ studentsView?true:false}   value={studentsView}/>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="studentsCreate" type="checkbox"  onChange={onCheckChange} checked={ studentsCreate?true:false}   value={studentsCreate} />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="studentsEdit" type="checkbox"  onChange={onCheckChange} checked={ studentsEdit?true:false}   value={studentsEdit} />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="studentsDelete" type="checkbox"  onChange={onCheckChange} checked={ studentsDelete?true:false}   value={studentsDelete} />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Dashboard</td>
                        <td>
                            <Form.Group >
                                <Form.Check name="dashboardView" type="checkbox" onChange={onCheckChange} checked={ dashboardView?true:false}   value={dashboardView}/>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="dashboardCreate" type="checkbox"  onChange={onCheckChange} checked={ dashboardCreate?true:false}   value={dashboardCreate} />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="dashboardEdit" type="checkbox"  onChange={onCheckChange} checked={ dashboardEdit?true:false}   value={dashboardEdit} />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="dashboardDelete" type="checkbox"  onChange={onCheckChange} checked={ dashboardDelete?true:false}   value={dashboardDelete} />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Conselor Report</td>
                        <td>
                            <Form.Group >
                                <Form.Check name="counselorReportView" type="checkbox" onChange={onCheckChange} checked={ counselorReportView?true:false}   value={counselorReportView}/>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="counselorReportCreate" type="checkbox"  onChange={onCheckChange} checked={ counselorReportCreate?true:false}   value={counselorReportCreate} />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="counselorReportEdit" type="checkbox"  onChange={onCheckChange} checked={ counselorReportEdit?true:false}   value={counselorReportEdit} />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group >
                                <Form.Check name="counselorReportDelete" type="checkbox"  onChange={onCheckChange} checked={ counselorReportDelete?true:false}   value={counselorReportDelete} />
                            </Form.Group>
                        </td>
                    </tr>

                </tbody>
            </Table>
            </div>:"Not a superuser"}
        </Container>
    )
}

export default TickTable;
