
export const GET_CALL_LOGS_FAIL = 'GET_CALL_LOGS_FAIL';
export const GET_CALL_LOGS_REQUEST='GET_CALL_LOGS_REQUEST';
export const GET_CALL_LOGS_SUCCESS = 'GET_CALL_LOGS_SUCCESS';
export const GET_COUNSELOR_LOGS_FAIL = 'GET_COUNSELOR_LOGS_FAIL';
export const GET_COUNSELOR_LOGS_REQUEST='GET_COUNSELOR_LOGS_REQUEST';
export const GET_COUNSELOR_LOGS_SUCCESS = 'GET_COUNSELOR_LOGS_SUCCESS';
export const GET_ASSINGED_LOGS_FAIL = 'GET_ASSINGED_LOGS_FAIL';
export const GET_ASSINGED_LOGS_REQUEST = 'GET_ASSINGED_LOGS_REQUEST';
export const GET_ASSINGED_LOGS_SUCCESS = 'GET_ASSINGED_LOGS_SUCCESS'
export const GET_APA_LOGS_FAIL = 'GET_APA_LOGS_FAIL';
export const GET_APA_LOGS_REQUEST = 'GET_APA_LOGS_REQUEST';
export const GET_APA_LOGS_SUCCESS = 'GET_APA_LOGS_SUCCESS'