import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { bindActionCreators } from 'redux';
import actions from '../../Auth/modules/actions';
import profileActions from '../module/actions';
import userRoleActions from '../../UserRole/modules/actions'
import { Toast } from "../../../shared/toast/Toast";
import { ZLoader } from '../../../shared/loader/ZLoader';
import _ from 'lodash';
import ProfileDetailsMarkup from '../components/ProfileDetailsMarkup';

class ProfileDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            mode: null,
            editorEmailstate: EditorState.createEmpty(),
            inputs: {
                user_id: "",
                email: "",
                first_name: "",
                last_name: "",
                phone_number: "",
                organization_name: "",
                studentLimit: 0,
                countryName: '',
                selectedInterest: [],
                studentLimitStatus: false,
                googleAccessToken: '',
                googleId: '',
                googleSync: '',
                confirm_new_password: '',
                new_password: '',

            },
            user: {},
            profile: [],
            change_password: false,
            error: {}
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { user: { user, organization }, userDetails, userDetailsData } = props;
        if (userDetails && userDetails.organization) {
            const { organization } = userDetails
            let stateSignatureHTML= ''
            if (JSON.stringify(state.user) !== JSON.stringify(userDetails.organization)) {
                if (organization.user_id) {
                    const inputs = { ...organization };
                    let somedata = organization.counsellor_interest && organization.counsellor_interest ? organization.counsellor_interest : ''
                    if (somedata) {
                        let data = organization.counsellor_interest.replace(/'/g, '"')
                        somedata = JSON.parse(data)
                    }
                    if (organization.email_signature) {
                        let signatureHTML = organization.email_signature ? organization.email_signature : ''
                        let blocksFromHTML = convertFromHTML(signatureHTML);
                            stateSignatureHTML = ContentState.createFromBlockArray(
                            blocksFromHTML.contentBlocks,
                            blocksFromHTML.entityMap,
                        );

                    }
                    
                    return {
                        inputs: {
                            id: inputs.user_id,
                            email: inputs.email,
                            first_name: inputs.first_name ? inputs.first_name : '',
                            last_name: inputs.last_name ? inputs.last_name : '',
                            phone_number: inputs.phone_number,
                            organization_name: organization.organization_name,
                            studentLimit: organization.student_limit ? organization.student_limit : 0,
                            countryName: organization.counsellor_countries ? organization.counsellor_countries : '',
                            selectedInterest: somedata,
                            studentLimitStatus: organization.no_limit,
                            googleAccessToken: inputs.google_access_token,
                            googleId: inputs.google_id,
                            googleSync: inputs.google_sync,
                            confirm_new_password: '',
                            new_password: '',
                        },
                        user: { ...organization },
                        change_password: false,
                        editorEmailstate: stateSignatureHTML ? EditorState.createWithContent(stateSignatureHTML) : EditorState.createEmpty()
                    }
                }
            }

        }

    }
    componentDidMount() {
        const { user: { user } } = this.props
        console.log("paramsparams", this.props)
        if (user && user.id) {
            this.props.profileActions.getOrgUserDetails(user.id);
        }
        const { location: { pathname } } = this.props;
        const urlParts = pathname.split("/");
        this.setState({ mode: urlParts[2], id: urlParts[3] })
    }
    isValid = () => {
        const {
            inputs,
        } = this.state;
        const newPassword = inputs.new_password
        const password = inputs.confirm_new_password

        let error = {};
        if (newPassword === "" || newPassword.length < 6) {
            error.newPasswordErr = true;
            this.setState({ error });
            return false;
        }
        if (password !== newPassword) {
            error.newPassword = true;
            this.setState({ error });
            return false;
        }
        //    if(password===""||password.length<6)
        //        { error.password=true;
        //        this.setState({error});
        //        return false;
        //    }       
        this.setState({ error });
        return true;
    }
    onFailure = (error) => {

    };
    onEditorStateChange = (editorEmailstate) => {
        this.setState({
            editorEmailstate,
        });
      };

    socialResponse = (response, provider, error) => {
        const { inputs } = this.state;

        const userData = {
            google_access_token: response ? response.accessToken : '',
            google_id: response ? response.googleId : '',
            google_sync: response ? response : ''
        }
        const handleSuccess = () => {
            Toast.show({
                message: "User update successfully..!",
                timeout: 4000,
                intent: 'success'
            })
        }
        const handleFailure = () => {
            Toast.show({
                message: "User update failed",
                timeout: 10000,
                intent: 'danger'

            })
        }
        if (response) {
            this.props.profileActions.updateUser(userData, inputs.id, handleSuccess, handleFailure)
        }
    };

    update = () => {
        const { user: { user, organization } } = this.props;
        if (user) {
            const { inputs, change_password, editorEmailstate } = this.state;

            let orgUserData = {
                // organization_name: inputs.organization_name,
                first_name: inputs.first_name,
                last_name: inputs.last_name,
                phone_number: inputs.phone_number,
                student_limit: inputs.studentLimit,
                counsellor_countries: inputs.countryName,
                counsellor_interest: JSON.stringify(inputs.selectedInterest),
                email_signature :draftToHtml(convertToRaw(editorEmailstate.getCurrentContent())),
                no_limit: inputs.studentLimitStatus ? 1 : 0
            }

            const handleFailure = (res, body) => {
                this.setState({
                    loginErrorMessage: Toast.show({
                        message: "Update Failed Please try again",
                        timeout: 4000,
                        intent: 'danger'
                    })
                })
            }
            const handleSuccess = () => {
                this.setState({ user: '' })
                this.props.profileActions.getOrgUserDetails(inputs.id);
                this.props.history.push(`/profile/view/${inputs.id}`);
                Toast.show({
                    message: "Profile updated successfully !!!!",
                    timeout: 3000,
                    intent: 'success'
                })
                if (change_password) {
                    const password = inputs.confirm_new_password
                    const email = inputs.email
                    this.props.actions.login(() => { }, () => { }, { email, password });
                }
            };
            if (change_password && this.isValid()) {
                orgUserData['password'] = inputs.confirm_new_password
                this.props.profileActions.updateOrgUser(orgUserData, inputs.id, handleSuccess, handleFailure)


            }
            if (!change_password) {
                this.props.profileActions.updateOrgUser(orgUserData, inputs.id, handleSuccess, handleFailure)
            }
        }
    }

    onChange = (value, input) => {
        const { inputs } = this.state;
        inputs[input] = value;
        this.setState({ inputs });
        if (input == 'confirm_new_password') {
            this.isValid()
        }
    }
    onCheckChange = (event) => {
        let nameVal = event.target.name
        this.setState({
            [nameVal]: event.target.checked
        })
    }

    onEdit = (id) => {
        this.props.history.push(`/Profile/edit/${id}`);
    }
    onCounsellorInterest = (selceted) => {
        const { inputs } = this.state
        inputs.selectedInterest.indexOf(selceted) === -1 ? inputs.selectedInterest.push(selceted) : console.log("This item already exists");
        this.setState({ inputs })
    }

    onUnselectInterest = (unSelceted) => {
        const { inputs } = this.state

        let index = inputs.selectedInterest.indexOf(unSelceted)

        inputs.selectedInterest.slice(index, 1)

        this.setState({ inputs })
    }

    onChangeInput = (value) => {
        const { inputs } = this.state
        inputs.selectedInterest = value
        this.setState({ inputs })
    }


    render() {
        const { mode, inputs, change_password, error,editorEmailstate } = this.state
        const { userDetailsLoading } = this.props

        return (<>
            {userDetailsLoading ?
                <ZLoader />
                :
                <ProfileDetailsMarkup
                    inputs={inputs}
                    disabled={mode === 'view'}
                    edit={mode === 'edit'}
                    onEdit={this.onEdit}
                    update={this.update}
                    onChange={this.onChange}
                    onUnselectInterest={this.onUnselectInterest}
                    onCounsellorInterest={this.onCounsellorInterest}
                    onChangeInput={this.onChangeInput}
                    socialResponse={this.socialResponse}
                    onFailure={this.onFailure}
                    onCheckChange={this.onCheckChange}
                    change_password={change_password}
                    error={error}
                    onEditorStateChange={this.onEditorStateChange}
                    editorEmailstate={editorEmailstate}

                />
            }
        </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.Auth.user,
        isLoading: state.userRole.isLoading,
        userData: state.userRole.userData,
        userDetails: state.userDetails.userDetails,
        userDetailsLoading: state.userDetails.isLoading,
        userDetailsData: state.userDetails.userData,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
        profileActions: bindActionCreators(profileActions, dispatch),
        userRoleActions: bindActionCreators(userRoleActions, dispatch)

    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileDetails));