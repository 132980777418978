import React from 'react'
import { DropTarget } from 'react-dnd'
import ItemTypes from './ItemTypes'
import Sortable from './Sortable'
import './Dustbin.scss'
import dropFile from '../../assets/drop-file.png'
const Dustbin = ({ canDrop, isOver, connectDropTarget, selected, onClickRemove}) => {
  return (
    <div ref={connectDropTarget} className="zb-dustbin">
      { selected.length ? <Sortable selected={selected} onClickRemove={onClickRemove}/>  : <img src={dropFile}/> }
    </div>
  )
}
export default DropTarget(
  ItemTypes.BOX,
  {

    drop(props, monitor) {
      const { selected, onClickRemove } = props;
      selected.push(monitor.getItem().item);
      props.onDrop(selected, monitor.getItem().item)
    },
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }),
)(Dustbin)
