import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import ActivityMarkup from '../components/ActivityMarkup';
class Activity extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
        }
    }
    render() {
        const { } =this.state
        return(
              <ActivityMarkup
              onRoute={this.props.history}
              />
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.Auth.user,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Activity));