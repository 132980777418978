import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import LoginMarkup from '../components/LoginMarkupNew';
import actions from '../modules/actions';
import {Toast} from "../../../shared/toast/Toast"

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            emailVal: "",
            isFeatureallowed:false,
            passwordVal: "",
            loginErrorMessage: '',
            error:{}
        }
    }
    onInputChange = (e) => {
        this.setState({
            [`${e.target.name}Val`]: e.target.value,
            loginErrorMessage: ''
        });
    }    
    isValid = ()=>{ 
         const {
            emailVal: email,
            passwordVal: password,
        } = this.state;
        let error = {};
        if(email==="" || !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email )){
           error.email=true;
           this.setState({error});
           return false;
        }
        if(password===""||password.length<6)
            { error.password=true;
            this.setState({error});
            return false;
        }       
        this.setState({error});
        return true;
    }
    onLogin =(a) => {
        const { emailVal: email, passwordVal: password } = this.state;         
        if(this.isValid()){
        const handleFailure = (res) => {
            if (res.status === 401) {
                this.setState({
                    emailVal:"",
                    passwordVal:"",
                    loginErrorMessage: Toast.show({
                        message: "Invalid Email Id Or Password",
                        timeout: 4000,
                        intent: 'danger'
                    })
                })
            }
            else{
                this.setState({
                    emailVal:"",
                    passwordVal:"",
                    loginErrorMessage: Toast.show({
                        message: "Invalid Email Id Or Password",
                        timeout: 4000,
                        intent: 'danger'
                    })
                })

            }
        }
        const handleSuccess = () => {
            let isFeatureallowed = undefined;   
            const { user } = this.props;     
            if(user) {
                if(user.user)
                {
                    if(user.user.is_superuser)
                    {
                        if(user.organization.feature) { 
                            isFeatureallowed = "No"
                        }
                        else { isFeatureallowed = "YES"}
                    }
                }
            } 
            
            Toast.show({
                message: "Logged In Successfully",
                timeout: 4000,
                intent: 'success'
            })
            { isFeatureallowed =="YES" ? this.props.history.push('/features') : this.props.history.push('/integration')}
        };
        this.props.actions.login(handleSuccess, handleFailure, { email, password });
        }
    }
    onKeyPress = (e) => {
        if(e.which === 13) {
            this.onLogin();
        }
      }

    render() {
        const { emailVal, passwordVal, loginErrorMessage,error } = this.state;
        return (
            <LoginMarkup
                isLoading={this.props.isLoading}
                error={error}
                emailVal={emailVal}
                passwordVal={passwordVal}
                loginErrorMessage={loginErrorMessage}
                onInputChange={this.onInputChange}
                onLogin={this.onLogin}
                onKeyPress = {this.onKeyPress }
                history={this.props.history}
            />
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.Auth.isLoading,
        error: state.Auth.error,
        user : state.Auth.user
    }
}
const mapDispatchToProps = (dispatch) => {
	return {
	    actions: bindActionCreators(actions, dispatch),
	};
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));