import React, {useState} from 'react'
import './Guide.scss'
import ChatBot from '../component/ChatBot'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Guide =()=>{
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className="open-ai-container">
        {isOpen? <ChatBot/>:<></>}
        <div className='openai-chat' onClick={()=> setIsOpen(!isOpen)}> 
        {!isOpen?<FontAwesomeIcon onClick={()=>setIsOpen(false)} className="external-icon" icon={["fas", "comment"]} />:
       <FontAwesomeIcon onClick={()=>setIsOpen(true)} className="external-icon" icon={["fas", "times-circle"]} />}
        </div>
        </div>
    )
}

export default Guide