import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { Row, Col } from 'react-bootstrap';
import HeaderNew from './shared/top-container/Header'
import Routes from "./routes";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import 'react-virtualized/styles.css'
import 'react-virtualized-select/styles.css'
import ReactGA from 'react-ga';
import "./App.css";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import authActions from '../src/screens/Auth/modules/actions';
import Footer from './screens/Footer/Footer';
import LeftDrawer from './shared/left-drawer/LeftDrawer';
import Notifications from './shared/notofication/Notification';
import Design from './screens/design-new/container/Design'
import {
    faChevronRight,
    faChevronDown,
    faChevronLeft,
    faSpinner,
    faQuoteLeft,
    faSquare,
    faCheckSquare,
    faFilePdf,
    faCheck,
    faTrashAlt,
    faPlusCircle,
    faClone,
    faFileAlt,
    faEdit,
    faTimesCircle,
    faEye,
    faMinusSquare,
    faEyeSlash,
    faExpand,
    faArrowsAlt,
    faMinusCircle,
    faMicrophone,
    faCamera,
    faQuestionCircle,
    faFileInvoice,
    faStream,
    faEnvelope,
    faPlusSquare,
    faVideo,
    faBook,
    faLink,
    faUsers,
    faBackspace,
    faArrowsAltH,
    faUserPlus,
    faUserCircle,
    faUserGraduate,
    faExternalLinkAlt,
    faCopy,
    faCalendarPlus,
    faTrash,
    faIdBadge,
    faAddressCard,
    faAddressBook,
    faHome,
    faCalendarWeek,
    faCalendarDay,
    faLongArrowAltRight,
    faPlus,
    faFileContract,
    faIdCard,
    faChartLine,
    faCubes,
    faFile,
    faPoll,
    faChartBar,
    faFilePowerpoint,
    faComment


} from '@fortawesome/free-solid-svg-icons';
import {
    faTelegramPlane,
    faWhatsapp
} from '@fortawesome/free-brands-svg-icons';

import {
    faFileVideo,

}
    from '@fortawesome/free-regular-svg-icons';
import {
    Drawer,
    Position,
    Alignment,
    Navbar,
    NavbarDivider,
    NavbarGroup,
    NavbarHeading,
    Icon,
    Menu,
    MenuDivider,
    Popover,
    MenuItem,

} from "@blueprintjs/core";
import TreeComponent from './screens/tree/containers/TreeContainer';

library.add(
    fab,
    faChevronRight,
    faChevronLeft,
    faSpinner,
    faQuoteLeft,
    faSquare,
    faCheckSquare,
    faFilePdf,
    faCheck,
    faTrashAlt,
    faPlusCircle,
    faClone,
    faFileAlt,
    faEdit,
    faFileVideo,
    faVideo,
    faTimesCircle,
    faEye,
    faEyeSlash,
    faExpand,
    faArrowsAlt,
    faMinusCircle,
    faMicrophone,
    faCamera,
    faQuestionCircle,
    faFileInvoice,
    faStream,
    faEnvelope,
    faPlusSquare,
    faTelegramPlane,
    faMinusSquare,
    faMicrophone,
    faCamera,
    faQuestionCircle,
    faBook,
    faLink,
    faUsers,
    faBackspace,
    faArrowsAltH,
    faUserPlus,
    faUserCircle,
    faUserGraduate,
    faExternalLinkAlt,
    faCopy,
    faCalendarPlus,
    faTrash,
    faIdBadge,
    faAddressCard,
    faAddressBook,
    faHome,
    faCalendarWeek,
    faCalendarDay,
    faLongArrowAltRight,
    faPlus,
    faFileContract,
    faIdCard,
    faChevronDown,
    faChartLine,
    faCubes,
    faFile,
    faPoll,
    faChartBar,
    faWhatsapp,
    faComment

)

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUserLoggedIn: false,
            userRoleAccess: [],
            drawer: {
                autoFocus: true,
                canEscapeKeyClose: true,
                canOutsideClickClose: true,
                enforceFocus: true,
                hasBackdrop: true,
                isOpen: false,
                position: Position.LEFT,
                size: '250px',
            }
        }
    }

    componentWillMount() {
        const user = JSON.parse(localStorage.getItem('saas_user'));
        if (user) {
            const { authActions } = this.props;
            authActions.refreshUser(user, this.onUserLoggedIn);
        }

    }

    componentDidMount() {
        ReactGA.initialize('UA-183497896-1');
        ReactGA.pageview('/');
    }
    notificationToggle = () => {
        if (!this.state.notify) {
            this.setState({
                notify: true
            })
        }
        else {
            this.setState({
                notify: false
            })
        }
    }

    onLogout = () => {

        this.props.authActions.logOut();
    }

    onUserLoggedIn = (status) => {
        this.setState({
            isUserLoggedIn: status
        })
    }

    handleOpen = () => {
        const { drawer } = this.state;
        drawer.isOpen = true;
        this.setState({ drawer });
    }

    handleClose = () => {
        const { drawer } = this.state;
        drawer.isOpen = false;
        this.setState({ drawer });
    }

    render() {
        const { drawer, notify, userRoleAccess } = this.state;
        let loginPage = window.location.href.indexOf("login") > -1 ? true : false
        
        const { auth: { isUserLoggedIn }, wizardMode } = this.props;

        const userMenu = (
            <Menu>
                <MenuItem icon="graph" text="Profile" />
                <MenuDivider />
                <MenuItem href="/" onClick={this.onLogout} icon="map" text="Log Out" />
            </Menu>
        );
        return (
            <Router>
                <div>
                    {/* {isUserLoggedIn?<LeftDrawer user={this.props.user} userRoleAccess={this.props.user}/>:null}
                    {wizardMode?'':<Drawer
                        title="Menu"
                        {...drawer}
                        onClose={this.handleClose}
                    >
                        <TreeComponent
                            onSidbarClose={this.handleClose}
                        />
                    </Drawer>}
                    { isUserLoggedIn? <Navbar fixedToTop={true} className="zb-navigation">
                        <NavbarGroup align={Alignment.LEFT}>
                            <Icon onClick={this.handleOpen} icon={'menu'} className={wizardMode?"wizard":''} />

                            <NavbarDivider />
                            <NavbarHeading>Zilter</NavbarHeading>
                        </NavbarGroup>
                        <NavbarGroup align={Alignment.RIGHT}>
                        <Popover content={ <Notifications/>} position={Position.BOTTOM_RIGHT}>

                            <Icon onClick={this.notificationToggle} icon={'notifications'} />
                            </Popover>
                            <NavbarDivider />
                            <Popover content={userMenu} position={Position.BOTTOM}>
                                <Icon icon={'user'} />
                            </Popover>
                        </NavbarGroup>
                    </Navbar>:''} */}
                    <Row>
                        {isUserLoggedIn && !loginPage ? <Col md="2" lg="2" xs="12" sm="12" className="left-app-container">
                            <Design wizardMode={wizardMode} />
                        </Col> : ''}
                        <Col md={isUserLoggedIn && !loginPage ? "10" : "12"} lg={isUserLoggedIn && !loginPage? "10" : "12"} xs="12" sm="12" className="right-app-container">
                            <div className="outer-div-app" >
                                <div className={`zb-container ${isUserLoggedIn}`} >
                                    {isUserLoggedIn && !loginPage? <div className="right-top-container-markup">
                                        <HeaderNew />
                                    </div> : ''}
                                    {Routes.map((route, index) => (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    ))}
                                </div>
                            </div>
                            {/* <Footer/> */}
                        </Col>
                    </Row>
                </div>

            </Router>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.Auth,
        wizardMode: state.wizard.wizardMode,
        user: state.Auth.user


    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        authActions: bindActionCreators(authActions, dispatch),
    };
};

export default (connect(
    mapStateToProps,
    mapDispatchToProps
)(App));
