import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { Button, Form, Row } from 'react-bootstrap';
import actions from '../../modules/actions';
import { DateInput } from "@blueprintjs/datetime";
import {
    InputGroup,
    Label,
    MenuItem,
    TextArea,
    Position,
    Checkbox
} from "@blueprintjs/core";
import moment from 'moment';
import { bindActionCreators } from 'redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ZLoader } from "../../../../shared/loader/ZLoader"
import { Toast } from '../../../../shared';
import './TaskCreate.scss';


class TaskCreator extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    subject: '',
    duedate: null,
    courseName:''
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onSubjectChange = (event) => {
    this.setState({
      subject: event.target.value,
    });
  };

  onChangeInput = (name, value)=>{
    this.setState({
      [name]: value,
  });
  }

  sendEmail = () => {
    const {  duedate, courseName } = this.state;
    const { student, userProfile, actions: { sendEmail, createLog },editorState, subject, closeModal, editState, editId } = this.props;
    
    const log = {
      "std": student.id,           ////student_id
      "u_id": userProfile ? userProfile.organization.user_id : null,
      "student_courses_id": courseName ? courseName : null,
      "subject": subject,
      "due_date": duedate,
      "type": 'task',
      "status":'inprogress',
      "note": draftToHtml(convertToRaw(editorState.getCurrentContent())),
      "organization_id":userProfile ? userProfile.organization.organization_id: null,

    }
    const handleSuccess = () => {
      Toast.show({
        message: "Task saved successfully!",
        timeout: 4000,
        intent: 'success'
      })
      const page = 1
      this.props.actions.getLogs(student.id, page);
    }
      closeModal();
      this.setState({
        courseName:"",
        duedate:null
      })
    const handleError = () => {
      Toast.show({
        message: "Task saving failed!",
        timeout: 4000,
        intent: 'danger'
      })
    }
    // closeModal();
    if(editState){
      this.props.actions.updateTaskLog(editId, log, handleSuccess, handleError);

    }else{
      console.log("loglog",log)
      this.props.actions.createTaskLog(log, handleSuccess, handleError);
    }
  }

  render() {
    const { duedate, courseName } = this.state;
    const { student, userAuthDetails, courses,  subject, editorState, onSubjectChange , editState, editId, onEditState, onEditorStateChange, isLoading  } = this.props;
    // console.log("isLoadingisLoading", isLoading);
    
    return (
      <div className="email-composer">
         {isLoading ? <ZLoader/>: null }
        <div>
          <Form.Control type="text" className="task-title" placeholder="Title" value={subject} onChange={(event) => onSubjectChange("subject", event.target.value)} />
        </div>
        {  courses && courses.length ? 
        <div className="select-name">
          <Form.Control placeholder="From" className="form-control modal-email" as="select" onChange={(e) => this.onChangeInput("courseName",e.target.value)} value={courseName} >
          <option value="">Select Course Name</option>
          {
                                    courses && courses.length ? courses.map((course) => {
                                        return (
                                          <option value={course.id}>{course.applied_courses} / {course.university_name} / {course.application_intake_month}-{course.application_intake_year}</option>
                                        )
                                    })
                                        : ''}
          </Form.Control>
        </div>
        :''}
        <Editor
          editorState={editorState}
          wrapperClassName="email-wrapper"
          editorClassName="email-editor"
          onEditorStateChange={onEditorStateChange}
        />
        <Row className="field-wrapper">
          <Label className="due-date" htmlFor="input-b">Due Date
            <DateInput
              formatDate={date => moment(date).format("DD/MM/YYYY")}
              onChange={(date)=>this.onChangeInput("duedate",date)}
              parseDate={str => new Date(str)}
              placeholder={"DD/MM/YYYY"}
              defaultValue={new Date()}
              maxDate= {new Date('1/1/2030')}
              value={duedate}
            /> 
          </Label>
        </Row>

        <div className="email-btn-toolbar">
          <Button className="pull-right" variant="primary" onClick={() => this.sendEmail()} disabled={!(subject && editorState && duedate)}>Create Task</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
	return {
	}

}

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch),

	};
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskCreator));
