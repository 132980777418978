import React from 'react';
import { Container, Row, Col, Button, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VideojsRecordPlayer, CountDown } from '../../../shared';
import './Markup.scss';
import { ZLoader } from '../../../shared/loader/ZLoader';
import micIcon from '../../../assets/mic.png'
import $ from "jquery";
const Markup = (props) => {
    const { isLoading,uploadPercentage, uploading } = props;
    const videoJsOptions = {
        controls: false,
        width: 500,
        height: 500,
        fluid: false,
        plugins: {
            record: {
                audio: true,
                video: true,
                maxLength: 7200,
                debug: true
            }
        }
    };

    if (!!window.opera || navigator.userAgent.indexOf('OPR/') !== -1) {
        videoJsOptions.plugins.record.videoMimeType = 'video/webm\;codecs=vp8'; // or vp9
    }
    const { skipThinkingTime, question, number, changeQuestion, max, timer, thinking, before, after, startInterview } = props;
    const minutesValid = question && question.time ? question.time.minutes : 0;
    const secondsValid = question && question.time ? question.time.seconds : 0;
    const thinkMinutes = question && question.thinking ? question.thinking.minutes : 0;
    const thinkSeconds = question && question.thinking ? question.thinking.seconds : 0;
    navigator.getUserMedia = navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;
    // if (navigator.getUserMedia) {
    //     navigator.getUserMedia({
    //         audio: true
    //     },
    //         function (stream) {
    //             let audioContext = new AudioContext();
    //             let analyser = audioContext.createAnalyser();
    //             let microphone = audioContext.createMediaStreamSource(stream);
    //             let javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

    //             analyser.smoothingTimeConstant = 0.8;
    //             analyser.fftSize = 1024;

    //             microphone.connect(analyser);
    //             analyser.connect(javascriptNode);
    //             javascriptNode.connect(audioContext.destination);

    //             let canvasContext = $("#canvas")[0].getContext("2d");

    //             javascriptNode.onaudioprocess = function () {
    //                 var array = new Uint8Array(analyser.frequencyBinCount);
    //                 analyser.getByteFrequencyData(array);
    //                 var values = 0;

    //                 var length = array.length;
    //                 for (var i = 0; i < length; i++) {
    //                     values += (array[i]);
    //                 }

    //                 var average = values / length;

    //                 //          console.log(Math.round(average - 40));

    //                 canvasContext.clearRect(0, 0, 150, 300);
    //                 canvasContext.fillStyle = '#BadA55';
    //                 canvasContext.fillRect(0, 300 - average, 150, 300);
    //                 canvasContext.fillStyle = '#262626';

    //             } // end fn stream
    //         },
    //         function (err) {
    //             console.log("The following error occured: " + err.name)
    //         });
    // } else {
    //     console.log("getUserMedia not supported");
    // }
    // console.log(after, "%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%");

    return (
        <>
            <div className="container student-interview-container">
            {after? null: <div className="helper-text">{question ? <span>{thinking ? `( You have ${thinkMinutes} minutes ${thinkSeconds} seconds for thinking )` : `( You have ${minutesValid} minutes ${secondsValid} seconds to answer )`}</span> : <span>Click on   <img src={micIcon} height={20} />   to start interview</span>}</div>}
            {after ? <Col style={{padding:99}} className="justify-content-md-center text-center">
                                            <h1>{after.message}</h1>
                                           { uploading ? <>
                                            <p style={{margin:11}}>Please wait while we are uploading your interview</p>
                    
                                            <ProgressBar now={uploadPercentage}  label={`${uploadPercentage}%`}/>
                                            </>:
                                             <form action="https://www.zoomabroad.com">
                                             <Button type="submit" variant="danger" style={{marginTop:21}}>Go To ZoomAbroad</Button>
                                         </form>
                                            }
                                           
                                        </Col> :
                <Row>
                    <Col md="6" lg="6" sm="12" xs="12">
                        <div className="video">
                            <Container className="zb-video-record">
                                {isLoading ? <ZLoader /> : null}
                                <Row className="justify-content-md-center">
                                    <Col md="auto">
                                       
                                            <div style={videoJsOptions} className="video-wrapper">


                                                <VideojsRecordPlayer
                                                    {...videoJsOptions}
                                                    startInterview={startInterview}
                                                    setPlayer={props.setPlayer}
                                                    interviewFinished={props.interviewFinished}
                                                />

                                            </div>
                                        
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                   {after?null :<Col md="6" lg="6" sm="12" xs="12" >
                        <h5>{before ? before.message : `Question ${question ? number + 1 : ''}/${question ? max : ''}`}</h5>
                        <p>{question ? question.question : ""}</p>
                    </Col>}
                </Row>}
               {after?null: <Row className="abc">
                    <div className="col-md-6">
                        <Row className="icons-container">
                            <Col md="3" lg="3" sm="2" xs="2"><span><FontAwesomeIcon className="icons" icon={['fas', 'microphone']} />  <audio /> </span></Col>
                            <Col md="6" lg="6" sm="6" xs="6"><span><FontAwesomeIcon className="icons" icon={['fas', 'camera']} /> web camera</span></Col>
                            <Col md="3" lg="3" sm="4" xs="4"> <span><FontAwesomeIcon className="icons" icon={['fas', 'question-circle']} /> Help</span></Col>
                        </Row>
                    </div>
                    <div className="col-md-6">
                        <Row classname="time-container">
                            {question ? <Col className="text-timing" md="3" lg="3" xs="4" sm="4"> <span>{thinking ? "thinking Time: " : "Answering Time"}</span></Col> : ""}
                            {question ? <Col md="5" lg="5" sm="8" xs="8">
                                <span className="timer-content">{timer.hours ? timer.hours : "00"} : {timer.minutes ? timer.minutes : "00"} : {timer.seconds ? timer.seconds : "00"}</span>{thinking ? <Button onClick={() => skipThinkingTime(question)} className="skip-button">Skip</Button> : null}</Col> : ''}
                            {before ? null : <Col md="4" lg="4" sm="12" xs="12" className="btn-section text-right" ><Button className="next" disabled={number >= max} variant="dark" onClick={() => changeQuestion(number + 1)}>{number + 1 == max ? "Finish Interview" : "Next Question"}</Button></Col>}</Row>
                    </div>
                </Row>}
            </div>

            {/* {after?null:<canvas id="canvas" width="150" height="300"></canvas>} */}
        </>
    )
}

export default Markup;