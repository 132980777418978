import React from "react";
import { FormGroup, RadioGroup, Radio, InputGroup, Card, Elevation } from "@blueprintjs/core";
import { Row, Col, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import "./ResultMarkup.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pdfs from "../../../assets/HS.pdf"
import moment from 'moment';
import Modal from "../../../shared/model/model"
import screenshot from "../../../img/experience.png"
import { Player } from 'video-react';
// import logger from 'logging-library';
// import { CustomErrorComponent } from 'custom-error';
import { ZLoader } from '../../../shared/loader/ZLoader';
import FileViewer from 'react-file-viewer';

import "../../../../node_modules/video-react/dist/video-react.css";
const ResultMarkup = (props) => {
    const handleStatus = (status) => {
        if (status == 1) {
            return <span style={{ color: "#5f964e" }}>Unconditional</span>
        }
        if (status == 2) {
            return <span style={{ color: "#8b4298" }}>Conditional</span>
        }
        if (status == 3) {
            return <span style={{ color: "#c56c06" }}>Refered to Academic team</span>
        }
        if (status == 4) {
            return <span style={{ color: "#246fa5" }}>Rejected</span>
        }

    }
    const handleAcademic = (academic, selectedCourse) => {
        if (academic.degree_type == "SSC") {
            return selectedCourse.ssc_percent
        }
        if (academic.degree_type == "HSC") {
            return selectedCourse.hsc_percent
        }
        if (academic.degree_type == "Bachelors") {
            return selectedCourse.bac_percent
        }
        if (academic.degree_type == "Masters") {
            return selectedCourse.mas_percent
        }
        if (academic.degree_type == "Diploma") {
            return selectedCourse.diploma_percent
        }
    }
    const handleLink = (academic) => {
        if (academic && academic.length) {
            let link = [];
            for (let i = 0; i < academic.length; i++) {
                if (academic[i].degree_type == "SSC") {
                    link.push(`ssc_grdScale=${academic[i].score}`)
                }
                if (academic[i].degree_type == "HSC") {
                    link.push(`hsc_grdScale=${academic[i].score}`)

                }
                if (academic[i].degree_type == "Bachlore") {
                    link.push(`bac_grdScale=${academic[i].score}`)

                }
                if (academic[i].degree_type == "Master") {
                    link.push(`mas_grdScale=${academic[i].score}`)

                }
                if (academic[i].degree_type == "Diploma") {
                    link.push(`Diploma=${academic[i].score}`)

                }

            }
            return link.toString().replace(/,/g, '&')
        }


    }

    const handleEligible = (eligible) => {


        if (eligible == "NA" || eligible == "" || eligible == null) {
            return "NA"
        }
        if (eligible == "Eligible") {
            return "eligible"
        }
        if (eligible == "Not Eligible") {
            return "not-eligible"
        }
    }
    const handleInterview = (handleClick, interviewScore, selectedInterview, videoLink, onOpenModel, interviewName, interviewId, history, applicationID, onSendInterview, reportData, count, openInterviewModel, onDeleteInterview) => {
        if (videoLink) {
            // return <a href={`https://zoomabroadv2.s3.amazonaws.com/${videoLink}`}>vbnhdfghdfghdgfhgfhdfhgfh<FontAwesomeIcon className="plus" icon="file-video" ></FontAwesomeIcon></a>
            return (
                <>
                    <OverlayTrigger
                        placement={'top'}
                        delay={{ hide: 200 }}
                        overlay={<Popover className="tool-tip" id="tooltip-disabled">View Interview</Popover>}>
                        {/* <FontAwesomeIcon onClick={handleClick} className="interview-icon" icon="plus-square"/> */}
                        <FontAwesomeIcon onClick={() => onOpenModel(reportData)} className="video-file" icon={['far', 'file-video']} ></FontAwesomeIcon>

                    </OverlayTrigger>
                    {/* <FontAwesomeIcon onClick={onOpenModel} className="video-file"  icon={['far', 'file-video']} ></FontAwesomeIcon> */}
                    <span className="video-interview-name">{reportData.interview_name} </span>
                    {reportData.send_date ? <p className="interview-date">Send:&nbsp;{moment(reportData.send_date).format('LLLL')}</p> : ''}
                    {reportData.receive_date ? <p className="interview-date">Uploaded:&nbsp;{moment(reportData.receive_date).format('LLLL')}</p> : ''}

                </>
            )
        }
        else if (interviewId || reportData.interview_name) {
            // return <a onClick={()=>history.push(`/interview/check/${interviewId}?application_id=${applicationID}`)}><Button onClick={()=>onSendInterview(reportData)}>Send Interview to Student</Button></a>
            return <div className="interview-name-button"><span>{reportData.interview_name}</span>
                <OverlayTrigger
                    placement={'top'}
                    delay={{ hide: 200 }}
                    overlay={<Popover className="tool-tip" id="tooltip-disabled">Delete Interview</Popover>}>
                    <FontAwesomeIcon onClick={() => onDeleteInterview(reportData.id)} className="cross-icon" icon={['fas', 'minus-circle']} />

                </OverlayTrigger>
                <Button className="link-interview" onClick={() => openInterviewModel(reportData)}>{reportData.interview_status == 1 ? "Resend Interview to Student" : "Send Interview to Student"}</Button>
                {reportData.send_date ? <p className="interview-date">Send:&nbsp;{moment(reportData.send_date).format('LLLL')}</p> : ''}
            </div>

        }
        else if (selectedInterview) {
            // return <a onClick={()=>history.push(`/interview/check/${selectedInterview}?application_id=${applicationID}`)}>{interviewName}Interview Link</a>
            return <div className="interview-name-button"><span>{reportData.interview_name}</span><FontAwesomeIcon onClick={onOpenModel} className="cross-icon" icon={['fas', 'times-circle']} /><Button className="link-interview" onClick={openInterviewModel}>{reportData.interview_status == 1 ? "Resend Interview to Student" : "Send Interview to Student"}</Button></div>

        }
        else {
            // return <Button onClick={handleClick} className="interview-icon" icon="plus-square">Assign interview</Button>
            {/* <OverlayTrigger
                placement={'top'}
                delay={{ hide: 200 }}
                overlay={<Popover className="tool-tip" id="tooltip-disabled">Add Interview</Popover>}>
                <FontAwesomeIcon onClick={handleClick} className="interview-icon" icon="plus-square"/>
                
            </OverlayTrigger> */}
        }

    }
    const handleProficiencyName = (type) => {
        if (type.ielts_score) {
            return "IELTS"
        }
        if (type.toefl_score) {
            return "TOEFL"
        }
        if (type.pte_score) {
            return "PTE"
        }
    }

    const handelEdit = (handleTextChange, isEditing, sopScore) => {

        if (isEditing || !sopScore) {
            return <input className="input-box" id="sop" onChange={(event) => handleTextChange('sop_val', event)} type="number" min="0" max="100" />
        }
        else if (sopScore) {
            return sopScore
        }
    }
    const handelEditInterview = (handleTextChange, isEditing, interviewScore) => {

        if (isEditing || !interviewScore) {
            return <input onChange={(event) => handleTextChange('interview_val', event)}
                className="input-box" id="sop" type="number" min="0" max="100" />
        }
        else if (interviewScore) {
            return interviewScore
        }
    }

    const { userRoleAccess, onDeleteInterview, handleChangeCk, interviewEmailContent, openUnconditionalModel, onSendConditional, onSendUnonditional, interviewEmail, showInterview, openInterviewModel, docUrl, count, selectedCourse, onSendInterview, isLoading, applicationID, varification, onCloseAcademicModal, showUnconditionalModal,
        onOpenAcademicModel, showAcademicModal, onEdit, isEditing, interviewName, items, disableButton, showModal, onOpenModel, onCloseModal, reportData, onSave, link, handleChange, offerStatus,
        handleChangeInput, handleClick, selectedInterview, inputChange, handleTextChange, interview_val, sop_val,
        showVideo, onPlay, history, showOfferModal, openOfferModel, interviewVideoLink } = props;
    console.log(docUrl, "docUrldocUrldocUrl");
    return (
        <div className="container result-container" style={isEditing ? { backgroundColor: "#FFF8F0" } : null}>
            {isLoading ? <ZLoader /> : null}
            <Modal
                modalTitle={"Send Conditional Offer"}
                isModalOpen={showOfferModal}
                onCloseModal={onCloseModal}
            >
                <h6>Email: </h6>
                <input class="form-control" type="email" onChange={(event) => handleTextChange('interviewEmail', event)} value={interviewEmail}></input>
                {/* <Ckeditor onChange={(event) => handleChangeCk(event)} value={interviewEmailContent} name={"interviewEmailContent"} /> */}
                <Button className="send-button-offer" onClick={() => onSendConditional(reportData, interviewEmail)}>Send</Button>

            </Modal>
            <Modal
                modalTitle={"Send Unconditional Offer"}
                isModalOpen={showUnconditionalModal}
                onCloseModal={onCloseModal}
            >
                <h6>Email: </h6>
                <input class="form-control" type="email" onChange={(event) => handleTextChange('interviewEmail', event)} value={interviewEmail}></input>
                {/* <Ckeditor onChange={(event) => handleChangeCk(event)} value={interviewEmailContent} name={"interviewEmailContent"} /> */}
                <Button className="send-button-offer" onClick={() => onSendUnonditional(reportData, interviewEmail)}>Send</Button>

            </Modal>
            <FormGroup>
                <Row>
                    <Col md="6">
                        <h4 className="header">{reportData.first_name} {reportData.middle_name} {reportData.last_name} &emsp; {reportData.overall_score ? Math.round(reportData.overall_score) : reportData.overall_score} / 1000</h4>

                    </Col>
                    <Col className="text-right head-buttons"> <a onClick={() => history.push("/students")}><Button className="back">Back</Button></a>
                        {userRoleAccess[4].operations[2] == 1 || userRoleAccess[4].operations[1] == 1 ? <a><Button className="back edit" onClick={onEdit} >Edit</Button></a> : ''}
                        {userRoleAccess[4].operations[2] == 1 || userRoleAccess[4].operations[1] == 1 ? <a><Button className="back save" onClick={onSave} >Save Draft</Button></a> : ''}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md="7">
                        <h6>Applied Course : {reportData.applied_course}, {reportData.course_type} </h6>
                    </Col>
                    <Col className="text-rigt">
                        <div className={`status-card `}>
                            <Card elevation={Elevation.THREE} className={handleEligible(reportData.eligible)}>
                                Student Status: <br />{reportData.eligible ? reportData.eligible : "NA"}
                            </Card>
                        </div>
                    </Col>

                    <Col md="7">
                        <h6>Overall Comparision</h6>
                    </Col>
                    <Col className="text-rigt">
                        <div className="status-card">
                            <Card elevation={Elevation.THREE}>
                                Application Status: <br />{handleStatus(reportData.status)}
                            </Card>
                        </div>
                    </Col>
                </Row>
                <p>Score against successful grade avg: </p>
                <Row>
                    <Col>
                        <h6>Predictive success :</h6>
                        <p>Completion : {reportData.completion} %</p>
                        <p>Employability : {reportData.employbility} %</p>
                        <p>Visa : {reportData.visa_prediction} %</p>

                    </Col>

                </Row>
                <Row>
                    <Col md="8">
                        <h6>Contextual Info : </h6>
                    </Col>
                </Row>
                <table class="table table-sm table-bordered table-responsive-sm text-center ">
                    <thead>
                        <tr>
                            <th>Adult HE 2011</th>
                            <th>Polar 3</th>
                            <th>Polar 4</th>
                            <th>UCAS MEM</th>
                            <th>Ethinicity</th>
                        </tr>
                    </thead>
                    {/* {reportData.academic ? reportData.academic.map((acad, index) => {
                        return ( */}
                    <tbody>
                        <tr>
                            <th scope="row" style={{ fontWeight: 400 }}>{reportData.adult_HE}</th>
                            <td>{reportData.polar_3}</td>
                            <td>{reportData.polar_4}</td>
                            <td>{reportData.UCAS_MEM}</td>
                            {/* <td>{reportData.ethinicity}</td>  */}
                            <td>NA</td>
                        </tr>
                    </tbody>
                    {/* )
                    })
                        : ''} */}
                </table>
                <table class="table table-sm table-bordered table-responsive-sm text-center ">
                    <thead>
                        <th>Gender</th>
                        <th>Disability</th>
                        <th>FSM</th>
                        <th>ACORN</th>
                        <th>School Type</th>
                        <th>Uni. Connect Target Area</th>

                    </thead>

                    <tbody>
                        <tr>
                            {/* <th scope="row" style= {{fontWeight:400}}>{reportData.gender}</th> */}
                            {/* <td>{reportData.disability}</td> */}
                            {/* <td>{reportData.FSM}</td> */}
                            {/* <td>{reportData.school_type}</td> */}

                            <th scope="row" style={{ fontWeight: 400 }}>NA</th>
                            <td>NA</td>
                            <td>NA</td>
                            <td>{reportData.ACORN}</td>
                            <td>NA</td>
                            <td>{reportData.uni_connect_target_area}</td>

                        </tr>
                    </tbody>
                </table>
                <Row> <Col md="8"> <h6>Academic : {reportData.academic_score} / 600</h6></Col>
                    <Col className="text-rigt">
                        <div className="status-card">
                            <a href={`https://www.zoomabroad.com/courses?${handleLink(reportData.academic)} `} target="_blank" ><Button className="interview-icon" icon="plus-square">Recommend Course</Button></a>


                        </div>
                    </Col>
                </Row>
                <table class="table table-sm table-bordered table-responsive-sm text-center ">
                    <thead>
                        <th>Qualification</th>
                        <th>Candidate Score</th>
                        <th>Required Score</th>
                        <th>Document</th>
                        <th>Verified</th>
                    </thead>
                    {reportData.academic ? reportData.academic.map((acad, index) => {
                        return (
                            <tbody>
                                <tr>
                                    <th scope="row">{acad.degree_name}</th>
                                    <td>{acad.score}%</td>
                                    <td>{handleAcademic(acad, selectedCourse)} %</td>
                                    <td className="pdf-icon">{acad.doc_url_degree ? <FontAwesomeIcon onClick={() => onOpenAcademicModel(acad.doc_url_degree, acad)} className="icon-font-pdf" icon="file-pdf" /> : ""}
                                        <Modal
                                            id={`pdfModel${index}`}
                                            modalTitle={"Document"}
                                            isModalOpen={showAcademicModal}
                                            onCloseModal={onCloseAcademicModal}
                                            showLargeModal={true}
                                            showDocumentModalClass
                                        >
                                            <>
                                                {/* <iframe className="frame" src={docUrl} /> */}
                                                {docUrl ? <FileViewer
                                                    fileType={docUrl.split('.').pop().toLowerCase()}
                                                    filePath={docUrl}
                                                    // errorComponent={CustomErrorComponent}
                                                    onError={() => console.log("error")}
                                                /> : ''}
                                                <Button className="model-button" onClick={varification}>Mark as Verified</Button>
                                            </>
                                        </Modal>

                                    </td>
                                    <td>{acad.verified ? <FontAwesomeIcon className="icon-font-tick" icon="check" /> : ''} </td>
                                </tr>
                            </tbody>
                        )
                    })
                        : ''}
                </table>
                <h6>Experience : {reportData.experience_score} / 100</h6>
                <table class="table table-sm table-bordered table-responsive-sm text-center">
                    <thead>
                        <th>Company</th>
                        <th>Experience (years/months)</th>
                        <th>Required Experience</th>
                        <th>Document</th>
                        <th>Verified</th>
                    </thead>
                    {reportData.experience ? reportData.experience.map((exp, index) => {
                        return (
                            <tbody>
                                <tr>
                                    <th scope="row">{exp.company_name}</th>
                                    <td>{exp.experience}</td>
                                    <td>{selectedCourse.experience ? selectedCourse.experience : 0}</td>
                                    <td className="pdf-icon">{exp.exp_doc_url ? <a><FontAwesomeIcon onClick={() => onOpenAcademicModel(exp.exp_doc_url, exp)} className="icon-font-pdf" icon="file-pdf" /></a> : ""}</td>
                                    {/* <Modal
                                modalTitle={"Document"}
                                isModalOpen={showAcademicModal}
                                onCloseModal={onCloseAcademicModal}
                                showLargeModal={true}
                            >
                                <iframe className="frame" src={pdfs}/>
                                <Button className="model-button" onClick={()=> varification(exp,index)}>Mark as Varified</Button>
                            </Modal> */}
                                    <td>{exp.exp_verified ? <FontAwesomeIcon className="icon-font-tick" icon="check" /> : ''}</td>
                                </tr>
                            </tbody>
                        )
                    })
                        : ''}
                </table>
                <h6>Language Proficiency : {reportData.language_score} / 30</h6>
                <table class="table table-sm table-bordered table-responsive-sm text-center">
                    <thead>
                        <th>Qualification</th>
                        <th>Candidate Score</th>
                        <th>Required Score</th>
                        <th>Document</th>
                        <th>Verified</th>
                    </thead>
                    {reportData.language_efficiency && reportData.language_efficiency.length ?
                        <tbody>

                            {reportData.language_efficiency[0].ielts_score || reportData.language_efficiency[0].toefl_score || reportData.language_efficiency[0].pte_score ? <tr>
                                <th scope="row">{handleProficiencyName(reportData.language_efficiency[0])}</th>
                                <td>{reportData.language_efficiency[0].ielts_score || reportData.language_efficiency[0].toefl_score || reportData.language_efficiency[0].pte_score ? reportData.language_efficiency[0].ielts_score || reportData.language_efficiency[0].toefl_score || reportData.language_efficiency[0].pte_score : ''}</td>
                                <td>{reportData.language_efficiency[0].ielts_score || reportData.language_efficiency[0].toefl_score || reportData.language_efficiency[0].pte_score ? selectedCourse.ielts : ''}</td>
                                {/* <td>{reportData.language_efficiency[0].lang_doc_url?reportData.language_efficiency[0].lang_doc_url:''}</td> */}
                                <td className="pdf-icon">{reportData.language_efficiency[0].lang_doc_url ? <a><FontAwesomeIcon onClick={() => onOpenAcademicModel(reportData.language_efficiency[0].lang_doc_url, reportData.language_efficiency[0])} className="icon-font-pdf" icon="file-pdf" /></a> : ""}</td>

                                <td></td>
                            </tr> : ''}
                            {reportData.language_efficiency[0].exemptions_equivalent ? <tr>
                                <th scope="row">Exemptions Equivalent</th>
                                <td>{reportData.language_efficiency[0].exemptions_equivalent ? reportData.language_efficiency[0].exemptions_equivalent : ''}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr> : ''}
                            {reportData.language_efficiency[0].second_language ? <tr>
                                <th scope="row">Second Language</th>
                                <td>{reportData.language_efficiency[0].second_language ? reportData.language_efficiency[0].second_language : ''}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr> : ''}
                        </tbody>


                        : []}
                </table>
                <h6>Key Highlight : {reportData.highlight_score} / 50</h6>
                {reportData.key_highlights ? reportData.key_highlights.map(high => {
                    return (
                        <p>{high.key_highlights}</p>
                    )
                })
                    : ''}
                <Col className="bottom-div">
                    <Row>
                        <Col md="8" xs="8"><h6>Letter of recommendation : {reportData.lor_score} / 25</h6></Col>
                        {reportData.letter_of_recommendation ? reportData.letter_of_recommendation.map(lor => {
                            return (<>
                                <Col md="2" className="text-left icon-div" xs="2"  >{lor.doc_url ? <FontAwesomeIcon onClick={() => onOpenAcademicModel(lor.doc_url, lor)} className="icon-font-pdf" icon="file-pdf" /> : ""}</Col>
                                <Col md="2" xs="2" className="text-left"><h6>{lor.verified ? <FontAwesomeIcon className="icon-font-tick" icon="check" /> : ''}</h6></Col>
                            </>)
                        }) : ''}
                    </Row>
                    <Row>
                        <Col md="8" xs="6"><h6>Interview :{handelEditInterview(handleTextChange, isEditing, reportData.interview_score)}  / 100</h6>
                        </Col>
                        <Col md="4" className="text-left icon-div" xs="2" >
                            <Row>
                                {reportData.interview && reportData.interview.length ? reportData.interview.map(inter => (
                                    <Col md="12" className='video-play-icon'>
                                        {handleInterview(handleClick, inter.interview_score, selectedInterview, inter.interview_url, onOpenModel, inter.interview_name, inter.interview_id, history, inter.interview_id, onSendInterview, inter, count, openInterviewModel, onDeleteInterview)}
                                    </Col>
                                )) : ''}

                            </Row>
                            <Button style={{ marginTop: 15 }} onClick={handleClick} className="interview-icon" icon="plus-square">Assign interview</Button>

                            {/* {handleInterview(handleClick, reportData.interview_score, selectedInterview, reportData.application_url, onOpenModel, interviewName, reportData.interview_id, history, applicationID, onSendInterview, reportData, count, openInterviewModel, onDeleteInterview)} */}

                            <Modal
                                modalTitle={"Interview"}
                                isModalOpen={showModal}
                                onCloseModal={onCloseModal}
                            >
                                <Player
                                    playsInline
                                    poster={screenshot}
                                    src={`https://zoomabroadv2.s3.amazonaws.com/${interviewVideoLink}`} //{`https://zoomabroadv2.s3.amazonaws.com/interview/${videoLink}`}
                                    className="players"

                                />

                            </Modal>
                            <Modal
                                modalTitle={"Send Interview"}
                                isModalOpen={showInterview}
                                onCloseModal={onCloseModal}
                            >
                                <h6>Email: </h6>
                                <input class="form-control" type="email" onChange={(event) => handleTextChange('interviewEmail', event)} value={interviewEmail}></input>
                                {/* <Ckeditor onChange={(event) => handleChangeCk(event)} value={interviewEmailContent} name={"interviewEmailContent"} /> */}
                                <Button className="send-button" onClick={() => onSendInterview(reportData, interviewEmail, interviewEmailContent)}>Send</Button>

                            </Modal>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="8" xs="8"><h6>SOP : {handelEdit(handleTextChange, isEditing, reportData.sop_score)} / 100</h6></Col>
                        {reportData.sop ? reportData.sop.map(sopp => {
                            return (<>
                                <Col md="2" className="text-left icon-div" xs="2" >{sopp.SOP_doc_url ? <FontAwesomeIcon onClick={() => onOpenAcademicModel(sopp.SOP_doc_url, sopp)} className="icon-font-pdf" icon="file-pdf" /> : ""}</Col>
                                <Col md="2" xs="2" className="text-left icon-div"><h6>{sopp.SOP_verified === 1 ? <FontAwesomeIcon className="icon-font-tick icon-div" icon="check" /> : ''}</h6></Col>
                            </>)
                        }) : ''}
                    </Row>
                    <Row>
                        <Col md="8" xs="8"><h6>Financial Capacity : {reportData.financial_capacity}</h6></Col>
                        <Col md="3" className="text-left icon-div" xs="2"><span>Meets the requirement</span></Col>
                    </Row>
                    <Row>
                        <Col md="8" xs="8"><h6>Academic Gap : {reportData.gap}</h6></Col>
                        <Col md="3" className="text-left icon-div" xs="2">{selectedCourse && reportData && reportData.gap <= selectedCourse.gap ? <span>Meets the requirement</span> : <span className="requirement-error">Requirement not meet</span>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md="8" xs="8"><h6>Regional Cap :</h6></Col>
                        <Col md="3" className="text-left icon-div" xs="2" ><span>Meets the requirement</span></Col>
                    </Row>
                </Col>
                <Col>
                    {userRoleAccess[4].operations[2] == 1 || userRoleAccess[4].operations[1] == 1 ? <Row>
                        <Col >
                            <Button disabled={(disableButton && !isEditing) || (reportData.status && !isEditing) ? true : false} onClick={() => { offerStatus(1); openUnconditionalModel() }} className={`uncondition ${(disableButton && !isEditing) || (reportData.status && !isEditing) ? 'btn-secondary disabled point' : ''}`}>Unconditional Offer</Button>

                            {/* 1 */}
                        </Col>

                        <Col >
                            <Button disabled={(disableButton && !isEditing) || (reportData.status && !isEditing) ? true : false} onClick={() => { offerStatus(2); openOfferModel() }} className={`condition${(disableButton && !isEditing) || (reportData.status && !isEditing) ? ' btn-secondary disabled point' : ''}`}>Conditional Offer</Button>
                            {/* 2 */}
                        </Col>
                        <Col >
                            <Button onClick={() => offerStatus(3)} className={`academic-team${(disableButton && !isEditing) || (reportData.status && !isEditing) ? ' btn-secondary disabled point' : ''}`}>To Academic Team</Button>
                            {/* 3 */}
                        </Col >
                        <Col  >
                            <Button onClick={() => offerStatus(4)} className={`reject${(disableButton && !isEditing) || (reportData.status && !isEditing) ? ' btn-secondary disabled point' : ''}`}>Reject Application</Button>
                            {/* 4 */}
                        </Col>
                    </Row> : ''}
                </Col>
            </FormGroup>
        </div>
    )
}

export default ResultMarkup