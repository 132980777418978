import React, { useState } from "react";
import styles from "./Guide.scss";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Configuration, OpenAIApi } from "openai";

import { Spinner, Intent } from "@blueprintjs/core";
const configuration = new Configuration({
  apiKey: "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",
});
const openai = new OpenAIApi(configuration);
const GuideMarkup = () => {
  const [query, setQuery] = useState("");

  const [isLoading, setLoading] = useState(false);
  const [isExpand, setExpand] = useState(false);
  const [showButtons, setShowbuttons] = useState(false);
  const [result, setResult] = useState([]);

  const onFinish = async (e) => {
    e.preventDefault();

    setLoading(true);
    function generatePrompt(query) {
      const capitalizedquery =
        query[0].toUpperCase() + query.slice(1).toLowerCase();
      return `Help an international student to study abroad and guide in a friendly tone giving specific call to action in ${query} ending your advice with pointing towards talking to a expert human advisor`;
    }
    const element = document.getElementById("results");
    console.log(element,"element");

    element.scrollTop = element.scrollHeight;
    try {
      const completion = await openai.createCompletion({
        model: "text-davinci-003",
        prompt: generatePrompt(query),
        temperature: 0.5,
        max_tokens: 2048,
        frequency_penalty: 0.5,
        presence_penalty: 0,
      });
      // res.status(200).json({ result: completion.data.choices[0].text });
      //   setResult(completion.data.choices[0].text)
      let newRes = [...result];
      newRes.push({ key: query, value: completion.data.choices[0].text });
      //   this.setState({result:newRes})
      setResult(newRes);
      setLoading(false);
      const element = document.getElementById("results");
      console.log(element,"element");
  
      element.scrollTop = element.scrollHeight;
    } catch (error) {
      // Consider adjusting the error handling logic for your use case
      // if (error.response) {
      //   console.error(error.response.status, error.response.data);
      //   res.status(error.response.status).json(error.response.data);
      // } else {
      //   console.error(`Error with OpenAI API request: ${error.message}`);
      //   res.status(500).json({
      //     error: {
      //       message: 'An error occurred during your request.',
      //     }
      //   });
      // }
      setLoading(false);

      alert(error);
    }
  };
  return (
    <div className={"contactusmarkup"}>
      <>
        <div className={"contactusmarkup_content"}>
          <>
          <div id="results" style={{
                height: '80vh',
                overflowY: 'scroll'
          }}>
            {result && result.length ? (
           <>
                {result.map((ans) => {
                  return (
                    <p
                      style={{
                        whiteSpace: "pre-wrap",
                        padding: 20,
                        // marginLeft: 40,
                        backgroundColor: "#ffffff",
                        boxShadow:
                          "inset 0 0 0 1px rgb(16 22 26 / 20%), inset 0 -1px 0 rgb(16 22 26 / 10%)",
                        marginBottom: 20,
                      }}
                      className="small-text"
                    >
                      <h4>{ans.key}</h4>
                      {ans.value}
                    </p>
                  );
                })}

                <a target="_blank" href="https://www.zoomabroad.com/register/">
                  <Button variant="primary" style={{ marginBottom: 10 }}>
                    Speak to an Advisor
                  </Button>
                </a>
                </>
             
            ) : (
              ""
            )}
            </div>
         
            <div className={"content"}>
            {isLoading ? (
              <div
                style={{ display: "flex", padding: "10px 0", color: "#ffffff" }}
              >
                Generating results...{" "}
                <Spinner intent={Intent.PRIMARY} size={20} />
              </div>
            ) : (
              ""
            )}
              <div className={"contact_form"}>
                <Form>
                  <Row>
                    <Col md="9">
                      <Form.Control
                        onChange={(event) => setQuery(event.target.value)}
                        style={{ height: "40px" }}
                        value={query}
                        placeholder="Ask me anything about studying abroad..."
                    disabled={isLoading}
                        
                      />
                    </Col>
                    <Col md="1">
                      <Button
                    disabled={isLoading}

                        variant="primary"
                        type="submit"
                        onClick={(e) => {
                          onFinish(e);
                          setQuery("");
                        }}
                      >
                        Send
                      </Button>
                    </Col>
                  </Row>
                </Form>
                             </div>
            </div>
          </>
        </div>
      </>
    </div>
  );
};

export default GuideMarkup;
