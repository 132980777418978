import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Tooltip } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import TreeComponent from '../components/TreeComponent';
import './TreeContainer.scss'
import { connect } from 'react-redux';


const INITIAL_STATE = [
	// {
	// 	id: 1,
	// 	icon: 'application',
	// 	path: '/dashboard',
	// 	hasCaret: false,
	// 	isExpanded: true,
	// 	label: 'Dashboard'
	// },
	{
		id: 2,
		icon: 'learning',
		path: '/course',
		hasCaret: false,
		isExpanded: true,
		label: 'Course'
	},
	{
		id: 2,
		icon: 'people',
		path: '/students',
		hasCaret: false,
		isExpanded: true,
		label: 'Students'
	},
	{
		id: 2,
		icon: 'mobile-video',
		path: '/interviews',
		hasCaret: false,
		isExpanded: true,
		label: 'Interview'
	},
	{
		id: 2,
		icon: 'help',
		path: '/questions',
		hasCaret: false,
		isExpanded: true,
		label: 'Question'
	},
	{
		id: 2,
		icon: 'link',
		path: '/integration',
		hasCaret: false,
		isExpanded: true,
		label: 'Integration'
	},
	{
		id: 2,
		icon: 'chart',
		path: '/report',
		hasCaret: false,
		isExpanded: true,
		label: 'Report'
	},
	{
		id: 2,
		icon: 'inherited-group',
		path: '/members',
		hasCaret: false,
		isExpanded: true,
		label: 'Members'
	},
];

class TreeContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nodes: []

		};
	}

	static getDerivedStateFromProps(props, state) {
		const{character,user}=props 
		const access = user.organization.access
		let finalData = access? JSON.parse(access):[{"operations": [1, 1, 1, 1], "name": "MAPPING"}, {"operations": [1, 1, 1, 1], "name": "QUESION"}, {"operations": [1, 1, 1, 1], "name": "INTERVIEW"}, {"operations": [1, 1, 1, 1], "name": "COURSE"}, {"operations": [1, 1, 1, 1], "name": "REPORT"}, {"operations": [1, 1, 1, 1], "name": "STUDENTS"}]
		console.log(">>fdsfsdfsdfsdfsdfsdf>>>>>>>userrr",access);
		let newArr = []
		if(finalData){
			for(let i=0; i<finalData.length; i++){
				if(finalData[i].name == "COURSE" && (finalData[i].operations[0]==1 || finalData[i].operations[1]==1 || finalData[i].operations[2]==1 || finalData[i].operations[3]==1)) {
					newArr.push({
						id: 2,
						icon: 'learning',
						path: '/course',
						hasCaret: false,
						isExpanded: true,
						label: 'Course'
					})
				}
				if(finalData[i].name == "STUDENTS" && (finalData[i].operations[0]==1 || finalData[i].operations[1]==1 || finalData[i].operations[2]==1 || finalData[i].operations[3]==1)) {
					newArr.push({
						id: 2,
						icon: 'people',
						path: '/students',
						hasCaret: false,
						isExpanded: true,
						label: 'Students'
					})
				}
				if(finalData[i].name == "INTERVIEW" && (finalData[i].operations[0]==1 || finalData[i].operations[1]==1 || finalData[i].operations[2]==1 || finalData[i].operations[3]==1)) {
					newArr.push( {
						id: 2,
						icon: 'mobile-video',
						path: '/interviews',
						hasCaret: false,
						isExpanded: true,
						label: 'Interview'
					})
				}
				if(finalData[i].name == "QUESION" && (finalData[i].operations[0]==1 || finalData[i].operations[1]==1 || finalData[i].operations[2]==1 || finalData[i].operations[3]==1)) {
					newArr.push( {
						id: 2,
						icon: 'help',
						path: '/questions',
						hasCaret: false,
						isExpanded: true,
						label: 'Question'
					})
				}

				if(user.user.is_superuser && i==0){
					newArr.push({
						id: 2,
						icon: 'link',
						path: '/integration',
						hasCaret: false,
						isExpanded: true,
						label: 'Integration'
					})
				}
			}
			
			
			
				
			
			return{
				nodes:newArr
			}
		}
	}

	handleNodeClick = (nodeData, _nodePath, e) => {
		if(nodeData && nodeData.path) {
			const { history, onSidbarClose } = this.props;
			const originallySelected = nodeData.isSelected;
			if (!e.shiftKey) {
				const { nodes } = this.state;
				this.forEachNode(nodes, n => (n.isSelected = false));
			}
			nodeData.isSelected =
				originallySelected == null ? true : !originallySelected;
			history.push(nodeData.path);
			this.setState(this.state);
			onSidbarClose();
		}
	};

	handleNodeCollapse = nodeData => {
		nodeData.isExpanded = false;
		this.setState(this.state);
	};

	handleNodeExpand = nodeData => {
		nodeData.isExpanded = true;
		this.setState(this.state);
	};

	forEachNode = (nodes, callback) => {
		if (nodes == null) {
			return;
		}
		for (const node of nodes) {
			callback(node);
			this.forEachNode(node.childNodes, callback);
		}
	};

	render() {
		const { nodes } = this.state;
		return (
			<TreeComponent
				nodes={nodes}
				handleNodeClick={this.handleNodeClick}
				handleNodeCollapse={this.handleNodeCollapse}
				handleNodeExpand={this.handleNodeExpand}
			/>
		);
	}
}

TreeContainer.propTypes = {
	history: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		user: state.Auth.user,
	}
}
// export default withRouter(TreeContainer);
export default withRouter(connect(mapStateToProps,null)(TreeContainer));
