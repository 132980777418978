import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import actions from '../modules/actions';
import authActions from '../../Auth/modules/actions'
import MappingMarkup from "../components/MappingMarkup"
import { Toast } from '../../../shared';
import "../components/MappingMarkup.scss";
import filterOptions from '../modules/staticOptions';
import zoomabroad1 from '../modules/staticOptions';
import Wizardactions from '../../../screens/Wizards/modules/actions';
import { hide } from '@blueprintjs/core/lib/esm/components/context-menu/contextMenu';
class Mapping extends Component {
	constructor(props) {
		super(props);
		this.state = {
			character:false,
			zoomabroad: [],
			integration: [],
			filterUIOption:[],
			mappingCount:0
		}
	}
	static getDerivedStateFromProps(props, state) {
		const { hubspotProperties , character} = props;

		if(hubspotProperties && hubspotProperties.length !== state.mappingCount){
		let filterUIOption=[]
		if(hubspotProperties) {
			filterUIOption=hubspotProperties.map((student,studentindex)=> {				
				return {
					id:student.name,
					name:student.label,
				}			
			})
			return{
				filterUIOption,
				character,
				mappingCount:hubspotProperties.length
			}
		}
	}
}
	
	componentDidMount() {
		const { hubs } = this.props;
		console.log("hubs>>>", hubs);
		const handleFailure = (res, body) => {
			this.props.history.push( '/integration')
		}
	   const handleSuccess = () => {};
	   { hubs ? this.props.actions.getHubSpotContactsProperties(handleSuccess, handleFailure,hubs):
		this.props.history.push( '/integration');
		this.props.Wizardactions.wizardend();
	}
	}

	handleValueChange = (event, index, type) => {
		switch(type) {
			case 'zoomabroad':
				const { zoomabroad } = this.state;
				zoomabroad[index] = event;
				this.setState({zoomabroad});
				break
			default:
				const { integration } = this.state;
				integration[index] = event;
				this.setState({integration});
				break;
		}
	}
	saveMappingInfo = () => {
	
		const { user: { user ,organization } } = this.props;
		const { zoomabroad, integration } = this.state;	

		 if (user) {		
		 	const mapping = {}
			for(let i =0; i<zoomabroad1.length;i++) {				
				const key = zoomabroad1[i]?zoomabroad1[i].id:'';
				const value = integration[i]?integration[i].id:'';
				mapping[key] = value
			}
			const handleFailure = (res, body) => {
				 Toast.show({
						message: "Error Missing some fields",
						timeout: 5000,
						intent: 'danger'
					})
			}
			const handleSuccess = () => {
				Toast.show({
					message: "Field name updated successfully !!!!",
					timeout: 10000,
					intent: 'success'
				})
				this.props.history.push('/integration/mappedconnect');
			};
			const request = {
				 'mapping': JSON.stringify(mapping)
			}

			
			this.props.actions.updateMapping(handleSuccess, handleFailure, request, organization.organization_id)
		}
	}
	hideItNow=()=>{
		this.setState({
			character:false
		})
	}
	render() {
		const { zoomabroad, integration,filterUIOption,hideIt, character, wizardMode} = this.state;
		return (
			<MappingMarkup
				filterOptions={filterOptions}
				filterUIOption={filterUIOption}
				zoomabroad={zoomabroad}
				integration={integration}
				handleValueChange={this.handleValueChange}
				saveMappingInfo={this.saveMappingInfo}
				character={character}
				hideItNow={this.hideItNow}
			/>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		user: state.Auth.user,
		hubs: state.Integration.hubspotToken,
		hubspotProperties: state.Integration.hubspotProperties
	}
}
const mapDispatchToProps = (dispatch) => {
	return {

		authActions: bindActionCreators(authActions, dispatch),
		actions: bindActionCreators(actions, dispatch),
		Wizardactions: bindActionCreators(Wizardactions, dispatch)
	};
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Mapping));