import {
    DASHBOARD_LOADING,
    DASHBOARD_SUCCESS,
    DASHBOARD_ERROR,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    DEAL_SUCCESS,
    DEAL_ERROR,
    GET_LEADS_FAIL,
    GET_LEADS_REQUEST,
    GET_LEADS_SUCCESS,
    GET_AVERAGE_REQUEST,
    GET_AVERAGE_FAIL,
    GET_AVERAGE_SUCCESS
} from './type';

// import { HUBURL, URL } from '../../../config';
import {APISAAS_DOMAIN} from '../../../env'

//  const getContact = (listId, handleSuccess, handleFailure) => {
//     const url = `${HUBURL}contacts/v1/lists/${listId}/contacts/all?hapikey=bc42d833-f807-468e-8d0a-3dd61567de4c&property=EMAIL&property=firstname&property=lastname&property=hs_lead_status&property=phone`;
//     return (dispatch) => {
//         dispatch({
//             type: DASHBOARD_LOADING
//         });
//         fetch(`${url}`, {
//             mode: 'cors',
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         })
//             .then(response => response.json().then(body => ({ response, body })))
//             .then(({ response, body }) => {
//                 if (!response.ok) {
//                     dispatch({
//                         type: DASHBOARD_ERROR,
//                         payload: body.error
//                     });

//                     handleFailure(response, body);
//                 } else {
//                     dispatch({
//                         type: DASHBOARD_SUCCESS,
//                         payload: body
//                     });
//                     handleSuccess(body);
//                 }
//             })
//             .catch((error) => {
//                 dispatch({
//                     type: DASHBOARD_ERROR,
//                     payload: error.message
//                 });

//                 handleFailure(error, error.message);
//             });
//     }
// }

//  const changePassword = (handleSuccess, handleFailure, data) => {
//     return (dispatch) => {
//         dispatch({
//             type: DASHBOARD_LOADING
//         });
//         fetch(`${URL}/pmschangepassword`, {
//             method: 'POST',
//             body: JSON.stringify(data),
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         })
//             .then(response => response.json().then(body => ({ response, body })))
//             .then(({ response, body }) => {
//                 if (!response.ok) {
//                     dispatch({
//                         type: CHANGE_PASSWORD_ERROR,
//                         payload: body.message
//                     });
//                     handleFailure(response, body.message);
//                 } else {
//                     dispatch({
//                         type: CHANGE_PASSWORD_SUCCESS,
//                         payload: body
//                     });
//                     handleSuccess(body);
//                 }
//             });
//     }
// }

//  const getDeal = (vid, handleSuccess, handleFailure) => {
//     const url = `${HUBURL}deals/v1/deal/associated/contact/${vid}/paged?hapikey=bc42d833-f807-468e-8d0a-3dd61567de4c&includeAssociations=true&properties=dealstage&properties=dealname`;
//     return (dispatch) => {
//         dispatch({
//             type: DASHBOARD_LOADING
//         });
//         fetch(`${url}`, {
//             mode: 'cors',
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         })
//             .then(response => response.json().then(body => ({ response, body })))
//             .then(({ response, body }) => {
//                 if (!response.ok) {
//                     dispatch({
//                         type: DEAL_ERROR,
//                         payload: body.error
//                     });

//                     handleFailure(response, body);
//                 } else {
//                     dispatch({
//                         type: DEAL_SUCCESS,
//                         payload: body
//                     });
//                     handleSuccess(body);
//                 }
//             })
//             .catch((error) => {
//                 dispatch({
//                     type: DEAL_ERROR,
//                     payload: error.message
//                 });

//                 handleFailure(error, error.message);
//             });
//     }
// }
const getLeads = (orgId, yopmail) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: GET_LEADS_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/dashboard/count/${orgId}?yopmail=${yopmail}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: GET_LEADS_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: GET_LEADS_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}
const getAverage = (averageBy,orgId, yopmail) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: GET_AVERAGE_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/dashboard/${averageBy}/${orgId}?yopmail=${yopmail}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: GET_AVERAGE_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: GET_AVERAGE_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}
export default {
    // changePassword,
    // getContact,
    // getDeal,
    getLeads,
    getAverage

}