import React from 'react';
import { Button, Row, Col, Form, Popover, Overlay } from "react-bootstrap";
import { DateInput } from "@blueprintjs/datetime";
import { Label } from "@blueprintjs/core";
import MultiSelectTag from '../../../shared/table/TagInputs'
import { H5, MenuItem, Switch } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import { filterYears } from '../modules/years'
import { filterMonths } from '../modules/months'
import moment from 'moment';
import './AdvancedFilters.scss';

export const renderItem = (item, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    return (
        <MenuItem
            // className="form-generator-menu-item"
            active={modifiers.active}
            disabled={modifiers.disabled}
            text={item.label ? item.label.toString() : null}
            key={item.label}
            onClick={handleClick}
        />
    );
};
export function createFilm(value, options) {
    return {
        value: value,
        label: value,
    };
}

const filterItems = (query, options) => {
    return options.label ? options.label.toLowerCase().indexOf(query.toLowerCase()) !== -1 : [];
};

export const renderCreateFilmOption = (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>,
) => (
    <MenuItem
        icon="add"
        text={`Create "${query}"`}
        active={active}
        onClick={handleClick}
        shouldDismissPopover={false}
    />
);

const maybeCreateNewItemFromQuery = true ? createFilm : undefined;
const maybeCreateNewItemRenderer = true ? renderCreateFilmOption : null;
const renderInputValue = (options) => options.label;

const AdvancedFilters = ({ onSearch, intakeMonthSearch, intakeYearSearch, members, sourceName, 
    sourceSearch, onChangeInput, assignedIdSearch, leadStatusSearch,
    children, showLargeModal, id, width, studentStartDate, studentEndDate, universitySearch,
     courseSearch, zoomabroadUniversity, universitySearchObj, applicationStatusSearch, 
     applicationStatus, supportStaffIdSearch, supportMembers, user, selectedInterest, 
     onChangeMultiFilter, selectedMonthInterest, onChangeMonthFilter }) => {
    const style = {};
    if (width) {
        style.width = width;
        style.maxWidth = width;
    }
    let zaUniversityData = []
    if (zoomabroadUniversity && zoomabroadUniversity) {
        for (let i = 0; i < zoomabroadUniversity.university_name.length; i++) {
            const data = {};
            data.value = zoomabroadUniversity.university_name[i].id;
            data.label = zoomabroadUniversity.university_name[i].university_name;
            let index = zaUniversityData.findIndex(x => x.value == data.value);
            index === -1 ? zaUniversityData.splice(0, 0, data) : console.log("object already exists")
        }
    }
    return (
        <div className="slide">
            {/* ref={ref} content {...props} */}
            <Row>
                <Col>
                    {user && user ?
                        <Form.Group >
                            <Form.Control
                                className={user.is_counselor ? `disable-filter` : `righ-side-filter`}
                                name={"assignedIdSearch"}
                                as="select"
                                disabled={user.is_counselor ? true : false}
                                placeholder="Status"
                                value={assignedIdSearch ? assignedIdSearch : ''}
                                onChange={(event) => onChangeInput(event.target.name, event.target.value, event)}
                            >
                                {
                                    members && members.length ? members.map((members) => {
                                        if(members.first_name || members.last_name) {
                                        return (
                                            <option value={members.user_id}>{members.first_name} {members.last_name}</option>
                                        )}
                                    })
                                        : ''}

                            </Form.Control>
                        </Form.Group>
                        : ''}
                </Col>
                {user && user ?
                    <Col>
                        <Form.Group >
                            <Form.Control
                                className={user.is_counselor ? `disable-filter` : `righ-side-filter`}
                                name={"supportStaffIdSearch"}
                                as="select"
                                disabled={user.is_counselor ? true : false}
                                placeholder="Status"
                                value={supportStaffIdSearch ? supportStaffIdSearch : ''}
                                onChange={(event) => onChangeInput(event.target.name, event.target.value, event)}
                            >
                                {
                                    supportMembers && supportMembers.length ? supportMembers.map((supportMembers) => {
                                        if(supportMembers.first_name || supportMembers.last_name) {
                                        return (
                                            <option value={supportMembers.user_id}>{supportMembers.first_name}</option>
                                        )}
                                    })
                                        : ''}

                            </Form.Control>
                        </Form.Group >

                    </Col>
                    : ''}

            </Row>
            <Row>
                <Col>
                    <Form.Control
                        className="righ-side-filter"
                        name={"leadStatusSearch"}
                        as="select"
                        placeholder="Status"
                        value={leadStatusSearch ? leadStatusSearch : ''}
                        onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                    >
                        <option value="">Select Status</option>
                        <option value="international">International</option>
                        <option value="subscriber">Subscriber</option>
                        <option value="ringing">Ringing</option>
                        <option value="others">Others</option>
                        <option value="lead">Lead</option>
                        <option value="hot_lead">Hot Lead</option>
                        <option value="customer">Customer</option>
                        <option value="beyond_intake">Beyond Intake</option>
                    </Form.Control>
                </Col>

                <Col>
                    <Form.Control
                        className="righ-side-filter"
                        name={"sourceSearch"}
                        as="select"
                        placeholder="Status"
                        value={sourceSearch ? sourceSearch : ''}
                        onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                    >
                        {
                            sourceName && sourceName.length ? sourceName.map((members) => {
                                return (
                                    <option value={members.id}>{members.source_short_name}</option>
                                )
                            })
                                : ''}
                    </Form.Control>
                </Col>
            </Row>
            <Row>
                <Col md="6" lg="6" sm="6" xs="6">
                    <Form.Label>Intake Month</Form.Label>
                    <MultiSelectTag
                        filterOptions={filterMonths}
                        selected={false}
                        disabled={false}
                        myColumnToggle={onChangeMonthFilter}
                        selectedInterest={selectedMonthInterest}
                    />
                </Col>
                <Col md="6" lg="6" sm="6" xs="6">
                    <Form.Label>Intake Year</Form.Label>
                    <MultiSelectTag
                        filterOptions={filterYears}
                        selected={false}
                        disabled={false}
                        myColumnToggle={onChangeMultiFilter}
                        selectedInterest={selectedInterest}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Control
                        className="righ-side-filter"
                        name={"applicationStatusSearch"}
                        as="select"
                        placeholder="Status"
                        value={applicationStatusSearch ? applicationStatusSearch : ''}
                        onChange={(event) => onChangeInput('applicationStatusSearch', event.target.value)}
                    >
                        <option value="">Select appliaction status</option>

                        {
                            applicationStatus && applicationStatus.length ? applicationStatus.map((applications) => {
                                return (
                                    <option value={applications.id}>{applications.key}</option>
                                )
                            })
                                : ''}
                        {/* <option value="">Select appliaction status</option>
                        <option value="Visa Rejected">Visa Rejected</option>
                        <option value="1bf083b6ab0049f0bbc4207758e524a2">Offer Letter / Conditional</option>
                        <option value="Visa Appointment Book">Visa Appointment Book</option>
                        <option value="Invoice">Invoice</option>
                        <option value="Visa Applied">Visa Applied</option>
                        <option value="Enrolled In University">Enrolled In University</option>
                        <option value="Application">Application</option>
                        <option value="Fee Deposit">Fee Deposit</option>
                        <option value="Post Arrival Services">Post Arrival Services</option>
                        <option value="Inprogress">Inprogress</option>
                        <option value="Pre Departure Pack">Pre Departure Pack</option>
                        <option value="Lost">Lost</option>
                        <option value="Unconditional Offer Letter">Unconditional Offer Letter</option>
                        <option value="Visa Granted">Visa Granted</option>
                        <option value="University Assessment / Interview">University Assessment / Interview</option>
                        <option value="Visa Documents Checked">Visa Documents Checked</option>
                        <option value="CAS Applied">CAS Applied</option>
                        <option value="CAS Received">CAS Received</option>
                        <option value="Balance Fee Payment">Balance Fee Payment</option> */}
                    </Form.Control>
                </Col>
                {/* <Col>
                        <DateInput
                            className="start-date"
                            formatDate={date => moment(date).format("DD/MM/YYYY")}
                            onChange={(date) => onChangeInput("studentStartDate", date)}
                            parseDate={str => new Date(str)}
                            placeholder={"DD/MM/YYYY"}
                            defaultValue={new Date()}
                            maxDate={new Date('1/1/2030')}
                            value={studentStartDate ? studentStartDate : new Date()}
                        />
                    
                </Col>
                <Col>
                        <DateInput
                            className="start-date"
                            formatDate={date => moment(date).format("DD/MM/YYYY")}
                            onChange={(date) => onChangeInput("studentEndDate", date)}
                            parseDate={str => new Date(str)}
                            placeholder={"DD/MM/YYYY"}
                            defaultValue={new Date()}
                            maxDate={new Date('1/1/2030')}
                            value={studentEndDate ? studentEndDate : new Date()}
                        />

                </Col> */}
                <Col>
                    <Suggest
                        className="righ-side-filter"

                        // createNewItemFromQuery={maybeCreateNewItemFromQuery}
                        // createNewItemRenderer={maybeCreateNewItemRenderer}
                        items={zaUniversityData ? zaUniversityData : []}
                        itemRenderer={renderItem}
                        noResults={<MenuItem disabled={true} text="No results." />}
                        filterable={true}
                        minimal={true}
                        itemPredicate={filterItems}
                        selectedItem={universitySearchObj ? universitySearchObj : 'select'}
                        inputValueRenderer={renderInputValue}
                        inputProps={{ placeholder: 'Search university' }}
                        onItemSelect={(e) => {
                            const event = {
                                target: {
                                    value: e,
                                }
                            }
                            onChangeInput('universitySearch', event.target.value)
                        }}
                    />
                </Col>

            </Row>
            <Row>
                <Col>
                    <input
                        name={"courseSearch"}
                        value={courseSearch ? courseSearch : ""}
                        onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                        // onKeyPress={this.handleKeyPress}
                        className="bp3-input righ-side-filter" modifier type="search"
                        placeholder="Search course" dir="auto" />
                </Col>
            </Row>
            <Row style={{ textAlign: 'center' }}>
                <Col style={{ paddingTop: 10 }}>
                    <Button className="search-button btn btn-info" disabled={!(leadStatusSearch || assignedIdSearch || supportStaffIdSearch || sourceSearch || intakeMonthSearch || intakeYearSearch || studentStartDate || studentEndDate || universitySearch || courseSearch || applicationStatusSearch)} onClick={onSearch} >Search</Button>
                </Col>
            </Row>

        </div>
    );
};
export default AdvancedFilters;