import React, { Component } from "react";
import { Row, Col, Button, Container } from 'react-bootstrap';
import Table from '../../../shared/table/Table';
import MultiSelectFormatter from '../../../shared/table/MultiSelectFormatter'
import mappingImg from '../../../assets/4.png';

const MappingMarkup = (props) =>{
    const {hideItNow,hideIt,character, filterOptions, filterUIOption, zoomabroad, integration, handleValueChange, saveMappingInfo } = props;
    return (
        <Container>
         {character ?<div className={'character-container animate '}>
            <img src={mappingImg} />
            <div className="cloud">
                <div><p className="cloud-text">Now map your CRM data with Zilter as per your requirement</p></div>
                <div><p className="cloud-text">On Integration column click on search and select your CRM fields to map with Zoomabroad fields</p></div>
                <div><p className="cloud-text"></p></div>
                <div className="text-right"><Button variant="light" onClick={hideItNow} >Next</Button></div>
            </div>
        </div>:null}
            <div className="Mapping-page">
                <Row>
                <Col md="6" lg="6" sm="6" xs="6" className="title" >
                   Mapping                            
                </Col>
                <Col md="6" lg="6" sm="6" xs="6"  className="btn-section text-right">
                    <Button className="btn" onClick= { saveMappingInfo }>Update</Button>
                </Col>  
                </Row>
                <Row>
                <Col className="table-section" md="12" lg="12" sm="12" xs="12" >
                    <Col className="table-class">
                        <Row>
                            <Col className="table-head">
                                Zoomabroad
                            </Col>  
                            <Col className="table-head">
                                Integration
                            </Col> 
                        </Row>                        
                        {  filterOptions.map((result,index) =>(                
                            <Row>                               
                                <>
                                <Col className="table-row">
                                    <MultiSelectFormatter
                                        filterOptions={filterOptions}
                                        values={result}
                                        index={index}
                                        handleValueChange={handleValueChange}
                                        type="zoomabroad"
                                        selected={true}
                                        disabled={true}
                                    />
                                </Col>  
                                <Col className="table-row" title="press shift to change">
                                    <MultiSelectFormatter
                                        filterOptions={filterUIOption}
                                        values={integration}
                                        index={index}
                                        handleValueChange={handleValueChange}
                                        type="integration"
                                    />
                                </Col> 
                                </>
                            </Row>
                            ))
                        }                        
                    </Col>                    
                </Col>  
                </Row>                 
            </div>
        </Container>
    )
}
export default MappingMarkup