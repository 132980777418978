import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import actions from '../modules/action';
import { Toast } from "../../../shared/toast/Toast";
import _ from 'lodash';
import ChannelMarkup from '../Component/ChannelMarkup';
class Channel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createShow: false,
            upadteChannel: false,
            updateId: '',
            channelName: '',
            emailName: '',
            stateName: '',
            cityName: '',
            shortName: '',
            errors:{}
        }
    }
   
    componentDidMount() {
        const { user : { organization }} = this.props;
        this.props.actions.getOrgChannel(organization.organization_id);
    }

  
    onChange = (name, value ) => {
        // console.log("inputinput",input, value)
        // const { inputs  } = this.state
        // inputs[input] = value;
        // this.setState({ inputs });
        this.setState({
            [name]: value, errors:{}
        });
    }
    
    showCreate = ()=>{
        this.setState({createShow:true})
    }
    onCloseModal =() =>{
        this.setState({createShow:false})
        this.setState({ channelName: '', emailName: '', stateName: '', cityName: '', shortName: '', errors: {} })

    }
    isValid = () => {
        const { channelName, emailName, stateName, cityName, upadteChannel, updateId, shortName } = this.state;
        let errors = {};
        if (channelName === "" || !/[A-Za-z0-9]/.test(channelName)) {
            errors.channelName = true;
            this.setState({ errors });
            return false;
        }
        if (shortName === "" || !/[A-Za-z0-9]/.test(shortName)) {
            errors.shortName = true;
            this.setState({ errors });
            return false;
        }
        if (emailName === "" || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailName)) {
            errors.emailName = true;
            this.setState({ errors });
            return false;
        }
        if (stateName === "" || !/[A-Za-z]/.test(stateName)) {
            errors.stateName = true;
            this.setState({ errors });
            return false;
        }
        if (cityName === "" || !/[A-Za-z]/.test(cityName)) {
            errors.cityName = true;
            this.setState({ errors });
            return false;
        }
        return true;
    }
    channelCreate = () => {
        const { channelName, emailName, stateName, cityName, upadteChannel, updateId, shortName } = this.state
        const { user: { organization } } = this.props;
        if (this.isValid()) {
            const data = {
                channel_name: channelName ? channelName : '',
                email: emailName ? emailName : '',
                state: stateName ? stateName : '',
                city: cityName ? cityName : '',
                phone_number: '',
                organization_id: organization.organization_id,
                channel_short_name: shortName ? shortName : ''
            }

            const handleSuccess = (response) => {

                this.props.actions.getOrgChannel(organization.organization_id);
                Toast.show({
                    message: 'Channel Created Successfully',
                    timeout: 4000,
                    intent: 'success'
                });
                this.onCloseModal()

            }
            const handleFail = (response) => {
                Toast.show({
                    message: 'Channel Created Unsuccessfully',
                    timeout: 4000,
                    intent: 'danger'
                });
                this.onCloseModal()

            }
            const handleUpdateSuccess = (response) => {

                this.props.actions.getOrgChannel(organization.organization_id);
                Toast.show({
                    message: 'Channel Update Successfully',
                    timeout: 4000,
                    intent: 'success'
                });

                this.setState({ upadteChannel: false, updateId: '' })

            }
            const handleUpdateFail = (response) => {
                Toast.show({
                    message: 'Channel Update Unsuccessfully',
                    timeout: 4000,
                    intent: 'danger'
                });
                this.onCloseModal()
                this.setState({ upadteChannel: false, updateId: '' })
            }
            if (upadteChannel) {
                this.props.actions.updateChannel(data, updateId, handleUpdateSuccess, handleUpdateFail)
                this.onCloseModal()
            } else {
                this.props.actions.createChannel(data, handleSuccess, handleFail)
                this.onCloseModal()
            }
        }
    }

    openChannelEdit = (id, channel) =>{
        const { channelName, emailName, stateName, cityName, shortName } = this.state
        this.setState({
            channelName: channel.channel_name,
            emailName: channel.email,
            stateName: channel.state,
            cityName: channel.city,
            shortName: channel.channel_short_name,
            upadteChannel: true,
            updateId:id
        })
        this.showCreate()
    }
    openChannelDelete = (id) =>{
        const { user : { organization }} = this.props;

        const handleUpdateSuccess = (response) => {
          
            this.props.actions.getOrgChannel(organization.organization_id);
            Toast.show({
                message: 'Channel Delete Successfully',
                timeout: 4000,
                intent: 'success'
            });
            this.onCloseModal()
            this.setState({upadteChannel: false, updateId:''})
            
        }
        const handleUpdateFail = (response) => {
            Toast.show({
                message: 'Channel Delete Unsuccessfully',
                timeout: 4000,
                intent: 'danger'
            });
            this.onCloseModal()
            this.setState({upadteChannel: false, updateId:''})
        }
        this.props.actions.deleteChannel(id, handleUpdateSuccess, handleUpdateFail)
    }
    render() {
        const { channelList, isLoading } = this.props
        const { createShow, channelName, emailName, stateName, cityName, shortName, errors } = this.state

        return(
              <ChannelMarkup
                channelList={channelList ? channelList : []}
                showCreate={this.showCreate}
                createShow={createShow}
                onCloseModal={this.onCloseModal}
                onChangeInput={this.onChange}
                channelName={channelName}
                emailName={emailName}
                stateName={stateName}
                cityName={cityName}
                channelCreate={this.channelCreate}
                openChannelEdit={this.openChannelEdit}
                openChannelDelete={this.openChannelDelete}
                isLoading={isLoading}
                shortName={shortName}
                errors={errors}
              />
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.Auth.user,
        channelList: state.channel.channelList,
        isLoading: state.channel.isLoading
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
       actions: bindActionCreators(actions, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Channel));