import {
   
    GET_CUSTOMER_REPORT_FAIL,
    GET_CUSTOMER_REPORT_REQUEST,
    GET_CUSTOMER_REPORT_SUCCESS,
    GET_CALLING_REPORT_FAIL,
    GET_CALLING_REPORT_REQUEST,
    GET_CALLING_REPORT_SUCCESS,
} from './type';

// import { HUBURL, URL } from '../../../config';
import {APISAAS_DOMAIN} from '../../../env'

const getCustomerReport = (organization_id, start_date, end_date, email, lead, application_status,month,year,unique) => {
    console.log("unique",unique)
    let query =""
    if(unique == 'noStatus') {
        query = `${APISAAS_DOMAIN}api/export/customer/${organization_id}/?start_date=${start_date}&end_date=${end_date}&email=${email}&lead_status=[${lead}]&application_status=[${application_status}]&month=${month}&year=${year}`
    }
    else {
        query = `${APISAAS_DOMAIN}api/export/customer/${organization_id}/?start_date=${start_date}&end_date=${end_date}&email=${email}&lead_status=[${lead}]&application_status=[${application_status}]&month=${month}&year=${year}&unique=${unique}`
    }
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: GET_CUSTOMER_REPORT_REQUEST
        });
        return fetch(query, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: GET_CUSTOMER_REPORT_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: GET_CUSTOMER_REPORT_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}
const getCallingReport = (organization_id, start_date, end_date, email, userId,leadstatus) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: GET_CALLING_REPORT_REQUEST
        });
        return fetch(`${APISAAS_DOMAIN}api/export/calling/${organization_id}/?start_date=${start_date}&end_date=${end_date}&email=${email}&user_id=${userId}&lead_status=[${leadstatus}]`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: GET_CALLING_REPORT_FAIL,
                        payload: body.error
                    });
                } else {
                    dispatch({
                        type: GET_CALLING_REPORT_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}

export default {
  
    getCustomerReport,
    getCallingReport
}