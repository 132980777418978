import mirrorkey from 'mirrorkey';

export default mirrorkey([
    'AUTH_LOADING',
    'REGISTER_ERROR',
    'REGISTER_SUCCESS',
    'UPDATE_REQUEST',
    'UPDATE_SUCCESS',
    'UPDATE_FAIL',
    'GET_HUBSPOT_PROPERTIES_ERROR',
    'GET_HUBSPOT_PROPERTIES_SUCCESS',
    'GET_HUBSPOT_TOKEN_ERROR',
    'GET_HUBSPOT_TOKEN_SUCCESS',
    'GET_MAPPING_REQUEST',
    'GET_MAPPING_FAIL',
    'GET_MAPPING_SUCCESS',
    'CREATE_HUBSPOT_PROPERTIES_ERROR',
    'CREATE_HUBSPOT_PROPERTIES_SUCCESS',
    'UPDATE_HUBSPOT_CONTACT_ERROR',
    'UPDATE_HUBSPOT_CONTACT_SUCCESS',
    'CREATE_HUBSPOT_NOTE_ERROR',
    'CREATE_HUBSPOT_NOTE_SUCCESS',
    'SAAS_DOCUMENT_UPLOAD_REQUEST_FAIL',
    'SAAS_DOCUMENT_UPLOAD_REQUEST_SUCCESS',
    'SAAS_DOCUMENT_UPLOAD_REQUEST',
]);