import React, { Component } from 'react';
import { InputGroup, FormControl, Form } from 'react-bootstrap';
import './input.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon, Intent, Label, Slider } from "@blueprintjs/core";

class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: props.type || "text",
            show: false,
        }
    }

    showPassword = () => {
        const { show } = this.state;
        this.setState({
            show: !show,
        }, () => {
            this.setState({
                type: this.state.show ? 'text' : 'password'
            })
        })
    }

    render() {
        const { name, placeholder, onChange, value, appendPasswordEye, groupClassName = '', ...otherProps } = this.props;
        const { type, show } = this.state;
        return (

            <InputGroup className={groupClassName} >
                <FormControl
                    {...otherProps}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    aria-label={placeholder}
                    onChange={onChange}
                    required
                />
                {
                    appendPasswordEye &&
                    <InputGroup.Append onClick={(e) => this.showPassword(e)}>
                        <Icon className="eye-icon-btn" icon={ show ?"eye-open":"eye-off"} /> 
                    </InputGroup.Append>
                }
            </InputGroup>
        )
    }
}


export default Input;




