import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReportMarkup from '../components/ReportMarkup';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import callLogData from './callLogData.json'
import actions from '../modules/actions'
import sourceAction from '../../Source/modules/actions'
import Denied from '../../../shared/denied/Denied'
import moment from 'moment';
class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      yopmailVal:false,
      viewAccess: true,
      callLogSeries: [{
        data: []
      }],
      callLogOptions: {
        chart: {
          height: 350,
          type: 'bar',
          events: {
            click: function (chart, w, e) {
            }
          }
        },
        // colors: colors,
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true
          }
        },
        title: {
          text: 'Call Logs',
          align: 'left'
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              // colors: colors,
              fontSize: '12px'
            }
          }
        }
      },

      counselorWiseSeries: [
        {
          name: '',
          data: []
        }
      ],
      counselorWiseOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        title: {
          text: 'Counselor lead status',
          align: 'left'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        fill: {
          opacity: 1
        }
      },
      directOrganicSeries: [{
        name: 'series1',
        data: [31, 40, 28, 51, 42, 109, 100]
      }, {
        name: 'series2',
        data: [11, 32, 45, 32, 34, 52, 41]
      }],
      directOrganicOptions: {
        chart: {
          height: 350,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
      },
      assignSeries: [],
      assignOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        title: {
          text: '% of student assigned',
          align: 'left'
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      aPASeries: [],
      aPAOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        title: {
          text: "APA's students status",
          align: 'left'
        },
        plotOptions: {
          bar: {
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: [],
        }
      },
      start_date: moment().add(-1, "weeks").toDate(),
      end_date: moment().add(0, "days").toDate()
    };
  }


  static getDerivedStateFromProps(props, state) {
    const { counselorLogs, callLogsDetails, assignedLog, APALogs, user } = props
    console.log("useruser", user)
    let { callLogOptions, counselorWiseOptions, counselorWiseSeries, assignOptions, assignSeries, aPAOptions, aPASeries } = state
    let callLogSeries = []
    if (callLogsDetails && callLogsDetails.assinged_name && callLogsDetails.assinged_name.length) {

      callLogSeries = [{
        data: callLogsDetails.data
      }]
      let callogs = callLogOptions.xaxis
      callogs.categories = callLogsDetails.assinged_name
    }
    if (counselorLogs && counselorLogs.assinged_name && counselorLogs.assinged_name.length) {
      let counselors = counselorWiseOptions.xaxis
      counselors.categories = counselorLogs.assinged_name
    }

    if (assignedLog && assignedLog.assinged_name && assignedLog.assinged_name.length) {
      assignOptions.labels = assignedLog.assinged_name
      assignSeries = assignedLog.data
    }

    if (APALogs && APALogs.source_name && APALogs.source_name.length) {
      let apaLog = aPAOptions.xaxis
      apaLog.categories = APALogs.source_name
      aPASeries = [{
        data: APALogs.data
      }]
    }

    const capitalizeFirstLetter = (string) => {
      if(string){
          var str
          str = string[0].toUpperCase() + string.slice(1);
          return str.replace(/_/g, " ");
      }    
    }

    if (counselorLogs && counselorLogs.data && counselorLogs.data.length) {
      for(let i=0; i<counselorLogs.data.length; i++)
      {
        counselorLogs.data[i].name = capitalizeFirstLetter(counselorLogs.data[i].name)
      }
      counselorWiseSeries = counselorLogs.data
    }

    return {
      callLogSeries,
      callLogOptions,
      counselorWiseOptions,
      assignOptions,
      assignSeries,
      aPAOptions,
      aPASeries,
      // counselorWiseSeries:[{"name":"lead","data":[0]},{"name":"others","data":[0]},{"name":"ringing","data":[0]},{"name":"hot_lead","data":[0]},{"name":"customer","data":[0]},{"name":"beyond_intake","data":[0]},{"name":"subscriber","data":[0]}]
      counselorWiseSeries
    }


  }
  static getDerivedStateFromProps(props, state) {
    const { allLeads, user } = props
    console.log("useruseruseruser>>>>>>", user)
    const access = user.organization.access

    let finalData = access ? JSON.parse(access) : [{ "operations": [1, 1, 1, 1], "name": "MAPPING" }, { "operations": [1, 1, 1, 1], "name": "QUESION" }, { "operations": [1, 1, 1, 1], "name": "INTERVIEW" }, { "operations": [1, 1, 1, 1], "name": "COURSE" }, { "operations": [1, 1, 1, 1], "name": "REPORT" }, { "operations": [1, 1, 1, 1], "name": "STUDENTS" }, {
        "name": "DASHBOARD",
        "operations": [
            1,
            0,
            0,
            0
        ]
    }, {
        "name": "COUNSELOR-REPORT",
        "operations": [
            1,
            0,
            0,
            0
        ]
    }]
    const dashboard = _.find(finalData, { "name": "COUNSELOR-REPORT" })

    let viewAccess = false

    if (dashboard && dashboard) {
        if (dashboard.operations[0] == 1) {
          viewAccess = true
      }  
    }
    if(!user.user.is_counselor){
      viewAccess = true
    }

    return { viewAccess }


}
  componentDidMount() {
    const { user: { organization } } = this.props;
    const { start_date, end_date, yopmailVal } = this.state;
    const yopmail = JSON.parse(localStorage.getItem('yopmail'));

    let startdate = start_date ? moment(start_date).format("YYYY-MM-DD HH:mm:ss") : '';
    let enddate = end_date ? moment(end_date).format("YYYY-MM-DD HH:mm:ss") : '';
    this.props.actions.getCallLogs(organization.organization_id, startdate, enddate, yopmail);
    this.props.actions.counselorReport(organization.organization_id, startdate, enddate, yopmail);
    this.props.actions.assignedReport(organization.organization_id, startdate, enddate, yopmail);
    this.props.actions.APAReport(organization.organization_id, startdate, enddate, yopmail);
  }
  handleRangeChange = (event) => {
    this.setState({ start_date: event[0], end_date: event[1] })
  }

  getreport = (value) => {
    const { user: { organization } } = this.props;
    const { start_date, end_date, yopmailVal } = this.state;
    const yopmail = JSON.parse(localStorage.getItem('yopmail'));

    let startdate = start_date ? moment(start_date).format("YYYY-MM-DD") : '';
    let enddate = end_date ? moment(end_date).format("YYYY-MM-DD 23:59:00") : '';

    this.props.actions.getCallLogs(organization.organization_id, startdate, enddate, yopmail);
    this.props.actions.counselorReport(organization.organization_id, startdate, enddate, yopmail);
    this.props.actions.assignedReport(organization.organization_id, startdate, enddate, yopmail);
    this.props.actions.APAReport(organization.organization_id, startdate, enddate, yopmail);
  }
  onChangeInput = (name, value) =>{
    this.setState({
      [name]: value,
      })
    this.getreport(value)
  }
  render() {
    const { callLogOptions, callLogSeries, counselorWiseSeries, counselorWiseOptions, directOrganicOptions, directOrganicSeries, assignSeries,
       assignOptions, aPASeries, aPAOptions, start_date, end_date, yopmailVal, viewAccess } = this.state
    const { isLoading, counselorLogs, callLogsDetails, assignedLog, APALogs } = this.props
    return (
      viewAccess ?
      <>
        <ReportMarkup
          isLoading={isLoading}
          callLogSeries={callLogsDetails && callLogsDetails.data ? callLogsDetails.data : []}
          callLogOptions={callLogsDetails && callLogsDetails.assinged_name ? callLogsDetails.assinged_name : []}
          counselorWiseSeries={counselorWiseSeries}
          counselorWiseOptions={counselorLogs && counselorLogs.assinged_name ? counselorLogs.assinged_name : []}
          directOrganicOptions={directOrganicOptions}
          directOrganicSeries={directOrganicSeries}
          assignOptions={assignedLog && assignedLog.assinged_name ? assignedLog.assinged_name : []}
          assignSeries={assignedLog && assignedLog.data ? assignedLog.data : []}
          aPAOptions={APALogs && APALogs.source_name ? APALogs.source_name : []}
          aPASeries={APALogs && APALogs.data ? APALogs.data : []}
          handleRangeChange={this.handleRangeChange}
          start_date={start_date}
          end_date={end_date}
          getreport={this.getreport}
          yopmailVal={yopmailVal}
          onChangeInput={this.onChangeInput}
        />
      </>
      : <Denied/>
    )
    
  }
}
const mapStateToProps = (state) => {
  return {
    isLoading: state.reports.isLoading,
    user: state.Auth.user,
    callLogsDetails: state.reports.callLogs,
    counselorLogs: state.reports.counselorLogs,
    assignedLog: state.reports.assignedLog,
    APALogs: state.reports.APALogs,
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));