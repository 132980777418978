
import Constants from './constants';

const INITIAL_STATE = {
    isLoading: false,
    error: '',
    contactList: [],
    deals: [],
    interviews: {},
    interviewsAll:[],
    interview: {},
    register: {},
    error: undefined,
    listError: undefined,
    questionList:[],
    getQuestion:{},
    details:{},
    paginationQuestion : {},
    interviewPagination : {},
    studentInterview : {},
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Constants.DASHBOARD_LOADING:
            return { 
                ...state, 
                isLoading: true 
            };
        case Constants.DASHBOARD_SUCCESS:
            return { 
                ...state, isLoading: false, 
                error: '', 
                contactList: action.payload.contacts 
            };
        case Constants.DASHBOARD_ERROR:
            return { 
                ...state, isLoading: false, 
                error: action.payload, 
                contactList: [] 
            };
        case Constants.CHANGE_PASSWORD_SUCCESS:
            return { 
                ...state, 
                isLoading: false, 
                error: '' 
            };
        case Constants.CHANGE_PASSWORD_ERROR:
            return { 
                ...state, 
                isLoading: false, 
                error: action.payload 
            };
        case Constants.DEAL_SUCCESS:
            return { 
                ...state, 
                isLoading: false, 
                error: '', 
                deals: action.payload.deals };
        case Constants.DEAL_ERROR:
            return { 
                ...state, 
                isLoading: false, 
                error: action.payload, deals: [] 
            };
        case Constants.GET_ALL_INTERVIEWS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_ALL_INTERVIEWS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                interviewsAll: action.payload,
                interviewPagination : action.payload.pagination
            };
        case Constants.GET_ALL_INTERVIEWS_FAIL:
            return {
                ...state,
                isLoading: false,
                listError: action.error
            };
        case Constants.GET_INTERVIEW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                interview: action.payload
            };
        case Constants.GET_INTERVIEW_FAIL:
            return {
                ...state,
                isLoading: false,
                listError: action.error
            };
        case Constants.QUESTION_REQUEST:
            return { 
                ...state, 
                isLoading: true 
            };
        case Constants.QUESTION_REGISTER_ERROR:
            return {
                 ...state,
                  isLoading: false 
                };
        case Constants.QUESTION_REGISTER_SUCCESS:
            return {
                 ...state, 
                 isLoading: false 
                };
        case Constants.POST_INTERVIEW_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case Constants.POST_INTERVIEW_SUCCESS:
            return {
                ...state,
                isFetching: false,
                register: action.payload
            };
        case Constants.POST_INTERVIEW_FAIL:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        case Constants.UPDATE_INTERVIEW_REQUEST:
        return {
            ...state,
            isFetching: true
        };
        case Constants.UPDATE_INTERVIEW_SUCCESS:
            return {
                ...state,
                isFetching: false,
                register: action.payload
            };
        case Constants.UPDATE_INTERVIEW_FAIL:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        case Constants.GET_ALL_QUESTIONS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_ALL_QUESTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                questionList: action.payload,
                paginationQuestion : action.payload.pagination
            };
        case Constants.GET_ALL_QUESTIONS_FAIL:
            return {
                ...state,
                isLoading: false,
                questionList: action.error
            };
        
        case Constants.UPDATE_QUESTION_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case Constants.UPDATE_QUESTION_SUCCESS:
            return {
                ...state,
                isFetching: false,
                details: action.payload 
            };
        case Constants.UPDATE_QUESTION_FAIL:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        case Constants.GET_QUESTION_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_QUESTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                getQuestion: action.payload
            };
        case Constants.GET_QUESTION_FAIL:
            return {
                ...state,
                isLoading: false,
                getQuestion: action.error
            };
            case Constants.GET_SEARCH_INTERVIEWS_REQUEST:
                return {
                    ...state,
                    isLoading: true
                };
            case Constants.GET_SEARCH_INTERVIEWS_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    interviews: action.payload,
                    
                };
            case Constants.GET_SEARCH_INTERVIEWS_FAIL:
                return {
                    ...state,
                    isLoading: false,
                    listError: action.error
                };
            case Constants.SAAS_UPLOAD_INTERVIEW_REQUEST:
                return { 
                    ...state, 
                    isLoading: true 
                };
            case Constants.SAAS_UPLOAD_INTERVIEW_REQUEST_SUCCESS:
                return { 
                    ...state, isLoading: false, 
                    error: '', 
                    uploadInterview:action.payload
                };
            case Constants.SAAS_UPLOAD_INTERVIEW_REQUEST_FAIL:
                return { 
                    ...state, isLoading: false, 
                    error:action.error
                };
        case Constants.GET_STUDENT_INTERVIEW_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_STUDENT_INTERVIEW_SUCCESS:
            return {
                ...state, isLoading: false,
                error: '',
                studentInterview: action.payload
            };
        case Constants.GET_STUDENT_INTERVIEW_FAIL:
            return {
                ...state, isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};
