import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import actions from '../modules/actions';
import { Toast } from '../../../shared';
import authActions from '../../Auth/modules/actions'
import wizardactions from '../../Wizards/modules/actions'
import MappedConnectMarkup from "../components/MappedConnect"

class MappedConnect extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    handleCreateSuccess = (body) => {
        const { user: { user, organization }, } = this.props;
        Toast.show({
            message: "Sync Successfully",
            timeout: 4000,
            intent: 'success'

        })
        const request = {
            step: "Question",
        }
        const handleSuccess=()  => {}
        const handleFailure=()  => {}
        this.props.wizardactions.updateStep(handleSuccess,handleFailure, request, organization.organization_id)
        this.props.history.push('/ziltersteps');
    }

    handleCreateFailure = (response, body) => {
        Toast.show({
            message: "Sync went wrong",
            timeout: 4000,
            intent: 'Danger'

        })
        // this.props.history.push('/interviews');
    }

    handleContactSuccess = (data) => {
        let studentData = []
        const { user: { user , organization } } = this.props;

        data.contacts.map(prop => {
            let propertiesData = prop.properties
            const { mapping: { mapping  } } = this.props
            let mappingData = JSON.parse(mapping)
            

            
            let academics = [];
            let experiences = [];
            let lors =[];
            let language_efficiency = [];
            let key_highlights = [];
            let competetive_tests = [];
            
            if (propertiesData[mappingData.HSC]) {
                if (!isNaN(propertiesData[mappingData.HSC].value)) {
                    const academic = {
                        degree_name: 'HSC',
                        degree_type: 'HSC',
                        score: propertiesData[mappingData.HSC].value ? propertiesData[mappingData.HSC].value : 0,
                        doc_url: propertiesData[mappingData.HSC_certificate] && propertiesData[mappingData.HSC_certificate].value ? propertiesData[mappingData.HSC_certificate].value : ""
                    }
                    academics.push(academic);
                }
                else {
                    const academic = {
                        degree_name: 'HSC',
                        degree_type: 'HSC',
                        score: 0,
                        doc_url:propertiesData[mappingData.HSC_certificate] && propertiesData[mappingData.HSC_certificate].value ? propertiesData[mappingData.HSC_certificate].value : ""
                    }
                    academics.push(academic);
                }
                    
            }
           
            if (propertiesData[mappingData.UG]) {
                if (!isNaN(propertiesData[mappingData.UG].value)) {
                    const academic = {
                        degree_name: 'Bachelors',
                        degree_type: 'Bachelors',
                        score: propertiesData[mappingData.UG].value ? propertiesData[mappingData.UG].value : 0,
                        doc_url:propertiesData[mappingData.UG_certificate] && propertiesData[mappingData.UG_certificate].value ? propertiesData[mappingData.UG_certificate].value : ""
    
                    }
                    academics.push(academic);
                }
                else {
                    const academic = {
                        degree_name: 'Bachelors',
                        degree_type: 'Bachelors',
                        score: 0,
                        doc_url:propertiesData[mappingData.UG_certificate] && propertiesData[mappingData.UG_certificate].value ? propertiesData[mappingData.UG_certificate].value : ""
                
                    }
                    academics.push(academic);

                }
                
            }
            if (propertiesData[mappingData.PG]) {
                if (!isNaN(propertiesData[mappingData.PG].value)) {
                    const academic = {
                        degree_name: 'Masters',
                        degree_type: 'Masters',
                        score: propertiesData[mappingData.PG].value ? propertiesData[mappingData.PG].value : 0,
                        doc_url:propertiesData[mappingData.PG_certificate] && propertiesData[mappingData.PG_certificate].value ? propertiesData[mappingData.PG_certificate].value : ""
    
                    }
                    academics.push(academic);
                }
                else {
                    const academic = {
                        degree_name: 'Masters',
                        degree_type: 'Masters',
                        score: 0,
                        doc_url:propertiesData[mappingData.PG_certificate] && propertiesData[mappingData.PG_certificate].value ? propertiesData[mappingData.PG_certificate].value : ""
                    }
                    academics.push(academic);

                }


            }
            if (propertiesData[mappingData.SSC]) {

                if (!isNaN(propertiesData[mappingData.SSC].value)) {
                    const academic = {
                        degree_name: 'SSC',
                        degree_type: 'SSC',
                        score: propertiesData[mappingData.SSC].value ? propertiesData[mappingData.SSC].value : 0,
                        doc_url:propertiesData[mappingData.SSC_certificate] && propertiesData[mappingData.SSC_certificate].value ? propertiesData[mappingData.SSC_certificate].value : ""
    
                    }
                    academics.push(academic);
                }
                else {
                    const academic = {
                        degree_name: 'SSC',
                        degree_type: 'SSC',
                        score: 0,
                        doc_url:propertiesData[mappingData.SSC_certificate] && propertiesData[mappingData.SSC_certificate].value ? propertiesData[mappingData.SSC_certificate].value : ""
                    }
                    academics.push(academic);

                }
            }
            if (propertiesData[mappingData.diploma]) {
            if (!isNaN(propertiesData[mappingData.diploma].value)) {
                const academic = {
                    degree_name: 'Diploma',
                    degree_type: 'Diploma',
                    score: propertiesData[mappingData.diploma].value ? propertiesData[mappingData.diploma].value : 0,
                    doc_url:propertiesData[mappingData.diploma_certificate] && propertiesData[mappingData.diploma_certificate].value ? propertiesData[mappingData.diploma_certificate].value : ""

                }
                academics.push(academic);
            }
            else {
                const academic = {
                    degree_name: 'Diploma',
                    degree_type: 'Diploma',
                    score: 0,
                    doc_url:propertiesData[mappingData.diploma_certificate] && propertiesData[mappingData.diploma_certificate].value ? propertiesData[mappingData.diploma_certificate].value : ""
                }
                academics.push(academic);         
            }
        }
            if (propertiesData[mappingData.one_year_degree]) {

                if (!isNaN(propertiesData[mappingData.one_year_degree].value)) {
                    const academic = {
                        degree_name: 'One_year_degree',
                        degree_type: 'One_year_degree',
                        score: propertiesData[mappingData.one_year_degree].value ? propertiesData[mappingData.one_year_degree].value : 0,
                        doc_url:propertiesData[mappingData.one_year_degree_certificate] && propertiesData[mappingData.one_year_degree_certificate].value ? propertiesData[mappingData.one_year_degree_certificate].value : ""
              
    
                    }
                    academics.push(academic);
                }
                else {
                    const academic = {
                        degree_name: 'One_year_degree',
                        degree_type: 'One_year_degree',
                        score: 0,
                        doc_url:propertiesData[mappingData.one_year_degree_certificate] && propertiesData[mappingData.one_year_degree_certificate].value ? propertiesData[mappingData.one_year_degree_certificate].value : ""
              
                    }
                    academics.push(academic);
                }
            }
            // if (propertiesData[mappingData.certificate]) {
            //     const academic = {
            //         degree_name: 'Certificate',
            //         degree_type: 'Certificate',
            //         score: propertiesData[mappingData.certificate].value ? propertiesData[mappingData.certificate].value : 0

            //     }
            //     academics.push(academic);
            // }
            if (propertiesData[mappingData.two_year_degree]) {
                if (!isNaN(propertiesData[mappingData.two_year_degree].value)){
                const academic = {
                    degree_name: 'Two_year_degree',
                    degree_type: 'Two_year_degree',
                    score: propertiesData[mappingData.two_year_degree].value ?  propertiesData[mappingData.two_year_degree].value : 0,
                    doc_url:propertiesData[mappingData.two_year_degree_certificate] && propertiesData[mappingData.two_year_degree_certificate].value ? propertiesData[mappingData.two_year_degree_certificate].value : ""
              

                }
                academics.push(academic);
            }

            else {
                const academic = {
                    degree_name: 'Two_year_degree',
                    degree_type: 'Two_year_degree',
                    score: propertiesData[mappingData.two_year_degree].value ?  propertiesData[mappingData.two_year_degree].value : 0,
                    doc_url:propertiesData[mappingData.two_year_degree_certificate] && propertiesData[mappingData.two_year_degree_certificate].value ? propertiesData[mappingData.two_year_degree_certificate].value : ""
              

                }
                academics.push(academic);
            }

            }
            if (propertiesData[mappingData.Experiance]) {

                if (!isNaN(propertiesData[mappingData.Experiance].value)) {
                    const experience = {
                        experience: propertiesData[mappingData.Experiance].value ? propertiesData[mappingData.Experiance].value : 0,
                        doc_url:propertiesData[mappingData.Experiance_certificate] && propertiesData[mappingData.Experiance_certificate].value ? propertiesData[mappingData.Experiance_certificate].value : ""

                    }
                    experiences.push(experience);
                    
                }
                else {
                    const experience = {
                        experience: 0,
                        doc_url:propertiesData[mappingData.Experiance_certificate] && propertiesData[mappingData.Experiance_certificate].value ? propertiesData[mappingData.Experiance_certificate].value : ""
                    }
                    experiences.push(experience);
                }
            }
            if (propertiesData[mappingData.letter_of_recommendation_certificate]) {
                const lor = {
                    doc_url:propertiesData[mappingData.letter_of_recommendation_certificate] && propertiesData[mappingData.letter_of_recommendation_certificate].value ? propertiesData[mappingData.letter_of_recommendation_certificate].value : ""

                }
                lors.push(lor);
            }

            if (propertiesData[mappingData.Industry]) {
                const experience = {
                    company_name: propertiesData[mappingData.Industry].value ?propertiesData[mappingData.Industry].value : ""

                }
                experiences.push(experience);
            }

            if (propertiesData[mappingData.IELTS]) {
                if (!isNaN(propertiesData[mappingData.IELTS].value)) {
                    const ielts_score = {
                        ielts_score: propertiesData[mappingData.IELTS].value ? propertiesData[mappingData.IELTS].value : 0
                    }
                    language_efficiency.push(ielts_score);  
            }
            else {
                const ielts_score = {
                    ielts_score: 0
                }
                language_efficiency.push(ielts_score);
            }
            }

            if (propertiesData[mappingData.school_co_curricular]) {
                const key_highlight = {
                    key_highlights: "School_Co_curricular_acchivements",
                    level : propertiesData[mappingData.school_co_curricular].value
                }
                key_highlights.push(key_highlight);
            }

            if (propertiesData[mappingData.school_awards_achivements]) {
                const key_highlight = {
                    key_highlights: "School_Co_curricular_acchivements",
                    level : propertiesData[mappingData.school_awards_achivements].value
                }
                key_highlights.push(key_highlight);
            }

            if (propertiesData[mappingData.local_achivements]) {
                const key_highlight = {
                    key_highlights: "local_achivements",
                    level : propertiesData[mappingData.local_achivements].value
                }
                key_highlights.push(key_highlight);
            }

            if (propertiesData[mappingData.regional_achivements]) {
                const key_highlight = {
                    key_highlights: "regional_achivements",
                    level : propertiesData[mappingData.regional_achivements].value
                }
                key_highlights.push(key_highlight);
            }

            if (propertiesData[mappingData.national_achivements]) {
                const key_highlight = {
                    key_highlights: "national_achivements",
                    level : propertiesData[mappingData.national_achivements].value ? propertiesData[mappingData.national_achivements].value:""
                }
                key_highlights.push(key_highlight);
            }

            if (propertiesData[mappingData.GMAT]) {
                const competetive_test = {
                    gmat: propertiesData[mappingData.GMAT].value ? propertiesData[mappingData.GMAT].value :0

                }
                competetive_tests.push(competetive_test);
            }
            if (propertiesData[mappingData.GRE]) {
                const competetive_test = {
                    gre: propertiesData[mappingData.GRE].value ?  propertiesData[mappingData.GRE].value :0
                }
                competetive_tests.push(competetive_test);
            }
            if (propertiesData[mappingData.SAT]) {
                const competetive_test = {
                    sat: propertiesData[mappingData.SAT].value ? propertiesData[mappingData.SAT].value :0
                }
                competetive_tests.push(competetive_test);
            }
            if (propertiesData[mappingData.ACT]) {
                const competetive_test = {
                    act: propertiesData[mappingData.ACT].value
                }
                competetive_tests.push(competetive_test);
            }
            let lEducation_Gap = 0;
            if(propertiesData[mappingData.Education_Gap]) {
                if (!isNaN(propertiesData[mappingData.Education_Gap].value)) {
                    lEducation_Gap = propertiesData[mappingData.Education_Gap].value;
                } else if(
                    propertiesData[mappingData.Education_Gap].value == "YES" ||
                    propertiesData[mappingData.Education_Gap].value == "yes" ||
                    propertiesData[mappingData.Education_Gap].value == "Yes" ||
                    propertiesData[mappingData.Education_Gap].value == true
                ) {
                    lEducation_Gap = 1;
                }
            }
            const mappedProperties = {
                v_id: prop.vid,
                academic: academics,
                experience: experiences,
                letter_of_recommendation: lors,
                key_highlights : key_highlights,
                language_efficiency: language_efficiency,
                competetive_test: competetive_tests,
                gap: lEducation_Gap,
                first_name: propertiesData[mappingData.first_name] && propertiesData[mappingData.first_name].value ? propertiesData[mappingData.first_name].value :"",
                last_name: propertiesData[mappingData.last_name] && propertiesData[mappingData.last_name].value ? propertiesData[mappingData.last_name].value :"",
                visa_rejection: propertiesData[mappingData.Visa_Rejection] && propertiesData[mappingData.Visa_Rejection].value ? propertiesData[mappingData.Visa_Rejection].value: 0,
                double_master: propertiesData[mappingData.double_master] && propertiesData[mappingData.double_master].value ? propertiesData[mappingData.double_master].value : "",
                previous_breach: propertiesData[mappingData.previous_breach] && propertiesData[mappingData.previous_breach].value ?  propertiesData[mappingData.previous_breach].value :0,
                course_type: propertiesData[mappingData.course_type] && propertiesData[mappingData.course_type].value ? propertiesData[mappingData.course_type].value : "",
                applied_course: propertiesData[mappingData.course_name] && propertiesData[mappingData.course_name].value ?  propertiesData[mappingData.course_name].value : "",
                user_id: user.id,
                organization_id:organization.organization_id
            }
            studentData.push(mappedProperties)
        })
        this.props.actions.createStudent(studentData, this.handleCreateSuccess, this.handleCreateFailure)
    }

    handleContactFailure = (data) => {

    }

    handleSuccess = (body) => {
        const { hubs } = this.props;
        const { mapping: { mapping } } = this.props

        let mappingData = JSON.parse(mapping)
        let propertiesData = [];

        for (let [key, value] of Object.entries(mappingData)) {
            propertiesData.push(`${value}`);
        }

        this.props.actions.getHubSpotContacts(this.handleContactSuccess, this.handleContactFailure, hubs, propertiesData);
    };

    handleFailure = (response, body) => { }

    componentDidMount() {
        const { hubs } = this.props;
        const handleCreateSuccess = (response, body) => {}
        const handleCreateFailure = (response, body) => { }
        this.props.actions.getIntegrationMapping(this.handleSuccess, this.handleFailure)
        const zoomscore = {
            "name": "zoomscore",
            "label": "Zoom Score",
            "description": "Show zoom score of a student from zilter",
            "groupName": "contactinformation",
            "type": "string",
            "fieldType": "text",
            "formField": false,
        }
	    this.props.actions.createHubSpotContactsProperties(handleCreateSuccess, handleCreateFailure, hubs, zoomscore);
        const eligible = {
            "name": "eligible",
            "label": "Eligible",
            "description": "Show eligible of a student from zilter",
            "groupName": "contactinformation",
            "type": "string",
            "fieldType": "text",
            "formField": false,
        }
	    this.props.actions.createHubSpotContactsProperties(handleCreateSuccess, handleCreateFailure, hubs, eligible);
    }
    render() {
        return (
            <MappedConnectMarkup />
        )
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.Auth.user,
        hubs: state.Integration.hubspotToken,
        hubspotProperties: state.Integration.hubspotProperties,
        mapping: state.Integration.mapping,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {

        authActions: bindActionCreators(authActions, dispatch),
        actions: bindActionCreators(actions, dispatch),
        wizardactions: bindActionCreators(wizardactions, dispatch),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MappedConnect));