
import React, { Component } from 'react';
import { Col, Row, Container, Form, Button } from 'react-bootstrap'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import './InterviewFinish.scss'
import actions from '../modules/actions';

class InterviewFinish extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstNameVal: "",
            emailVal: "",
            messageVal: "",
            lastNameVal: "",
            error: {},
            invalid: false

        }
    }

    onChangetext = (e) => {
        this.setState({
            [`${e.target.name}`]: e.target.value
        });
    }
    isValid = () => {
        const {
            emailVal: email,
            firstNameVal: first_name,

        } = this.state;
        let error = {};
        if (email === "" || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            error.email = true;
            this.setState({ error });
            return false;
        }

        this.setState({ error });
        return true;
    }

    onContactUs = () => {
        const {
            firstNameVal: first_name,
            lastNameVal: last_name,
            emailVal: email,
            messageVal: message,
          

        } = this.state;
        if (this.isValid()) {
            const data = {
                email,
                first_name,
                message,
                last_name
            }

            const handleFailure = (data) => {
                alert("ContactUs Unsuccess")
            };

            const handleSuccess = (data) => {
                alert("ContactUs Successfully")
            };
            this.props.actions.contactUs(handleSuccess,handleFailure, data);
        }
    }
    render() {
        const { emailVal, messageVal,error } = this.state
        return (
            <div className="contact-container">
                
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 230">
                    <path fill="#a2d9ff" fill-opacity="1" d="M0,160L48,144C96,128,192,96,288,96C384,96,480,128,576,154.7C672,181,768,203,864,181.3C960,160,1056,96,1152,69.3C1248,43,1344,53,1392,58.7L1440,64L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
                </svg>
                <Row className="contents text-center">
                    <Col md="8" lg="8">
                        <h1>You have already given the Interview.</h1>
                        <p>If You Have Any Questions or Concerns, Please Contact…</p>
                        <form action="https://www.zoomabroad.com">
                            <Button className="za" type="submit" variant="danger">Go To ZoomAbroad</Button>
                        </form>
                    </Col>
                    <Col md="4" lg="4">
                        <Form.Group>
                            <Form.Control
                                size="lg" name="firstNameVal" onChange={this.onChangetext} type="text" placeholder="First Name" /><br />
                            <Form.Control
                                size="lg" name="lastNameVal" onChange={this.onChangetext} type="text" placeholder="Last Name" /><br />
                            <Form.Control
                                size="lg" name="emailVal" value={emailVal} onChange={this.onChangetext} type="email" placeholder="Email" />
                            {error.email ? <span style={{ color: "red" }}>Please Enter Registered Email ID</span> : ''}
                            <br />
                            <Form.Control
                                size="lg" 
                                as="textarea"
                                name={"messageVal"}
                                rows="3"
                                placeholder="Enter Message"
                                value={messageVal}
                                onChange={this.onChangetext} /><br />
                            <Button onClick={this.onContactUs}> Contact Us</Button>

                        </Form.Group>
                        <br />
                      

                    </Col>

                </Row>


            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
       
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(InterviewFinish));


