import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Row, Col, Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import Model from "../../../../shared/model/model";
import './AllocateTask.scss';

class AllocateTask extends Component {
    constructor(props) {
        super(props)
        this.state = {
            VisaRefusedVal: '',
            id: '',
            errors: {},
            showModal:false,
            index: 100,

        }
    }
    componentDidMount() {

    }
    static getDerivedStateFromProps(props, state) {

    }



    onSubmit = (index, taskName) => {
        this.props.onCloseTaskAllocateModal()
        this.props.onTaskIndex(index, taskName)
    }
    onChangeInput = (name, value) => {
        this.setState({
            [name]: value, errors: {}
        })
    }
    onCloseModal = () =>{
        this.setState({
            showModal:false
        })
    }
    render() {
        const { showModal, index } = this.state;
        const { } = this.props
        return (
            <div className="conditional">
                <Col>
                    <Button className="save-offer" disabled={true} onClick={() => this.onSubmit(0, 'Needs & qualifications')}>Needs & qualifications</Button>
                </Col>
                <Col>
                    <Button className="save-offer" onClick={() => this.onSubmit(1, 'Scheduling meeting')}>Scheduling meeting</Button>
                </Col>
                <Col>
                    <Button className="save-offer" onClick={() => this.onSubmit(2, 'Attending meeting')}>Attending meeting</Button>
                </Col>
                <Col>
                    <Button className="save-offer" onClick={() => this.onSubmit(3, 'Required documents')}>Required documents</Button>
                </Col>
                <Col>
                    <Button className="save-offer" onClick={() => this.onSubmit(4, 'Confirming interests')}>Confirming interests</Button>
                </Col>
                {/* <Col>
                    <Button className="save-offer" onClick={() => this.onSubmit(4, 'Confirming interests')}>Confirming interests</Button>
                </Col> */}
                <Col>
                    <Button className="save-offer" onClick={() => this.onSubmit(5, 'Making final decision')}>Making final decision</Button>
                </Col>
                <Col>
                    <Button className="save-offer" onClick={() => this.onSubmit(6, 'Visa appointment')}>Visa appointment</Button>
                </Col>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,

    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        // action: bindActionCreators(action, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AllocateTask));
