import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./InterviewButtonFormatter.scss";
import { OverlayTrigger , Popover} from 'react-bootstrap';

const InterviewButtonFormatter = (cell, row, index, {userRoleAccess, onView, onEdit, onClickDelete } ) => {
   
    const access = userRoleAccess[2].operations
    
    return (
        <div className="button-formatter">
            {/*<FontAwesomeIcon className="icon-btn" onClick={ () => onView(cell) } icon="file-alt" size="6x" />*/}
            {access[0]==1?<OverlayTrigger
                placement={'top'}
                delay={{ hide: 200 }}
                overlay={<Popover className="tool-tip" id="tooltip-disabled">View</Popover>}>
                <FontAwesomeIcon className="icon-btn" onClick={ () => onView(cell) } icon="eye" size="2x" />
            </OverlayTrigger>:''}
            {/* <FontAwesomeIcon className="icon-btn" onClick={ () => onEdit(cell) } icon="edit" size="2x" /> */}
           {access[2]==1? <OverlayTrigger
                placement={'top'}
                delay={{ hide: 200 }}
                overlay={<Popover className="tool-tip" id="tooltip-disabled">Edit</Popover>}>
                <FontAwesomeIcon className="icon-btn edit" onClick={ () => onEdit(cell) } icon="edit" size="2x" />
            </OverlayTrigger>:''}
            {/* <OverlayTrigger
                placement={'top'}
                delay={{ hide: 200 }}
                overlay={<Popover className="tool-tip" id="tooltip-disabled">Copy</Popover>}>
                <FontAwesomeIcon className="icon-btn" icon="clone" />
            </OverlayTrigger> */}
           {access[3]==1? <OverlayTrigger
                placement={'top'}
                delay={{ hide: 200 }}
                overlay={<Popover className="tool-tip" id="tooltip-disabled">Delete</Popover>}>
                <FontAwesomeIcon onClick={ () => onClickDelete(cell, index) } className="icon-btn delete" icon="trash-alt" /> 
            </OverlayTrigger>:'' }                   
        </div>
    );
}

export default InterviewButtonFormatter
