import React from 'react';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "./AuthenticateMarkup.scss"

const AuthenticateMarkup = (props) => {
    const {} =props
    return (
        <div className="container">
            <div className="inner-container-forgot-password">
                    <div className="input-container">
                        <p className="loading">Authenticating, please wait</p>
                    </div>
            </div>
        </div>
    )
}

export default AuthenticateMarkup;