export default [		
		{
			name: "Student First Name",
			id: "first_name"
		},
		{
			name: "Student Last Name",
			id: "last_name"
		},
		{
			name: "Student No",
			id: "Student_No"
		},
		{
			name: "SSC %",
			id: "SSC"
		},
		{
			name: "SSC Certificate",
			id: "SSC_certificate"
		},
		{
			name: "HSC %",
			id: "HSC"
		},
		{
			name: "HSC Certificate",
			id: "HSC_certificate"
		},
		{
			name: "Diploma",
			id: "Diploma"
		},
		{
			name: "Diploma Certificate",
			id: "Diploma_certificate"
		},
		
		{
			name: "One-year degree",
			id: "one_year_degree"
		},
		{
			name: "One-year degree Certificate",
			id: "one_year_degree_certificate"
		},
		{
			name: "Two-year degree",
			id: "two_year_degree"
		},
		{
			name: "Two-year degree Certificate",
			id: "two_year_degree_certificate"
		},
		
		{
			name: "UG %",
			id: "UG"
		},
		{
			name: "UG Certificate",
			id: "UG_certificate"
		},
		{
			name: "Two or more certificates",
			id: "Two or more certificates"
		},
		
		{
			name: "PG %",
			id: "PG"
		},
		{
			name: "PG Certificate",
			id: "PG_certificate"
		},
		{
			name: "IELTS",
			id:"IELTS"
		},
		{
			name: "GRE",
			id: "GRE"
		},
		{
			name: "SAT",
			id: "SAT"
		},
		{
			name: "ACT",
			id:  "ACT"
		},
		{
			name: "GMAT",
			id: "GMAT"
		},
		{
			name: "Experiance",
			id: "Experiance"
		},
		{
			name: "Experiance Certificate",
			id: "Experiance_certificate"
		},
		{ 
			name :"Letter of Recommendation Certificate",
			id :"letter_of_recommendation_certificate"

		},
		{
			name: "Industry",
			id: "Industry"
		},
		{
			name: "Education Gap",
			id: "Education_Gap"
		},
		{
			name: "Visa Rejection",
			id: "Visa_Rejection"
		},
		{
			name: "School Co curricular acchivements",
			id: "school_co_curricular"
		},
		{
			name: "School awards or achivements",
			id: "school_awards_achivements"
		},
		{
			name: "Local / Open awards or achivements (charity work recognition)",
			id: "local_achivements"
		},
		{
			name: "Regional awards or achivements or certification",
			id: "regional_achivements"
		},
		{
			name: "National award or achivement or certifications",
			id: "national_achivements"
		},
		{
			name: "Double Master",
			id: "double_master"
		},
		{
			name: "Previous Breach",
			id: "previous_breach"
		},
		{
			name: "Applied Course Type",
			id: "course_type"
		},
		{
			name: "Applied Course Name",
			id: "course_name"
		},
	]	