import React, { Component } from "react";
import { Container, Row, Col, Form , Button, OverlayTrigger, Popover} from 'react-bootstrap';
import { FormGroup, InputGroup  } from "@blueprintjs/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { ZLoader } from '../../../shared/loader/ZLoader'
import Modal from '../../../shared/model/model'
import "./SourceMarkup.scss"

const SourceMarkup = (props) => {
    const { sourceList, openSourceEdit, showCreate, onCloseModal, createShow, onChangeInput, apaName, emailName, stateName, cityName, sourceCreate, openSourceDelete, isLoading, shortName, errors} = props
    return (
        <div className="source-container">
        {isLoading ? <ZLoader /> : null}
            <Modal
                modalTitle={"Source"}
                isModalOpen={createShow}
                onCloseModal={onCloseModal}

            >
                <div>
                    <Form>
                        <Form.Group>
                            <Form.Label>APA Name *</Form.Label>
                            <Form.Control
                                name="apaName"
                                value={apaName}
                                className="form-input-field"
                                placeholder="Enter APA Name"
                                onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                            />
                            {errors.apaName ? <span className="errors">Please Enter valid APA Name</span> : ''}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>APA Short Name *</Form.Label>
                            <Form.Control
                                name="shortName"
                                value={shortName}
                                className="form-input-field"
                                placeholder="Enter APA Short Name"
                                onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                            />
                            {errors.shortName ? <span className="errors">Please Enter valid Short APA Name</span> : ''}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email *</Form.Label>
                            <Form.Control
                                name="emailName"
                                value={emailName}
                                className="form-input-field"
                                placeholder="Enter Email"
                                onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                            />
                            {errors.emailName ? <span className="errors">Please Enter valid Email</span> : ''}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>State *</Form.Label>
                            <Form.Control
                                name="stateName"
                                value={stateName}
                                className="form-input-field"
                                placeholder="Enter State"
                                onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                            />
                            {errors.stateName ? <span className="errors">Please Enter valid State Name</span> : ''}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>City *</Form.Label>
                            <Form.Control
                                name="cityName"
                                value={cityName}
                                className="form-input-field"
                                placeholder="Enter City"
                                onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                            />
                            {errors.cityName ? <span className="errors">Please Enter valid City Name</span> : ''}
                        </Form.Group>
                        <Button disabled={!(apaName && shortName && emailName && stateName && cityName)}
                            className="pull-right source-button"
                            onClick={sourceCreate}
                        >Submit
                            </Button>
                    </Form>
                </div>
            </Modal>
            <Row className="source-top-row">
                <Col md="5" className="source-create">
                    Source-APA
                </Col>
                <Col className="source-create-button">
                    <Button
                        className="pull-right source-button"
                        onClick={showCreate}
                    >Create
                            </Button>
                </Col>
            </Row>
            <div>
                <Row>
                    <Col md='4'>
                        <p className="text-center">APA Name</p>
                    </Col>
                    <Col  md='3'>
                        <p className="text-center">Email</p>
                    </Col>
                    <Col md='2'>
                        <p className="text-left">State</p>
                    </Col>
                    <Col>
                        <p className="text-left">City</p>
                    </Col>
                    <Col>
                        <p className="text-left">Short</p>
                    </Col>
                    <Col>
                        <p className="text-left">Actions</p>
                    </Col>
                </Row>
            </div>
            {sourceList.map(source => {
                return (
                    <Accordion>
                        <Card className="source">
                            <Row className="source-row">
                                <Col md='4'>
                                    {source.apa_name}
                                </Col>
                                <Col md='3'>
                                    {source.email}
                                </Col>
                                <Col md='2'>
                                    {source.state}
                                </Col>
                                <Col className='city-name' md='1'>
                                    {source.city}
                                </Col>
                                <Col md='1'>
                                    {source.source_short_name}
                                </Col>
                                <Col md='1' className="source-action">
                                    <OverlayTrigger
                                        placement={'top'}
                                        delay={{ hide: 200 }}
                                        overlay={<Popover className="tool-tip" id="tooltip-disabled">Edit</Popover>}>
                                        <FontAwesomeIcon onClick={() => openSourceEdit(source.id, source)} className="cross-icon edit" icon={['fas', 'edit']} />
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        placement={'top'}
                                        delay={{ hide: 200 }}
                                        overlay={<Popover className="tool-tip" id="tooltip-disabled">Delete</Popover>}>
                                        <FontAwesomeIcon onClick={() => openSourceDelete(source.id)} className="cross-icon trash" icon={['fas', 'trash']} />
                                    </OverlayTrigger>
                                
                                </Col>
                            </Row>
                        </Card>

                    </Accordion>
                )
            })}
        </div>
    )
}
export default SourceMarkup;