import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import RegisterMarkup from '../components/RegisterMarkupNew';
import actions from '../modules/actions';
import ReactGA from 'react-ga';
import { Toast } from '../../../shared';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstNameVal: "",
            lastNameVal: "",
            emailVal: "",
            phoneVal: "",
            organizationNameVal: "",
            passwordVal: "passwordVal#123",
            confirmPasswordVal: "passwordVal#123",
            errors: {},
            invalid: false
        }
    }
    componentDidMount() {
        ReactGA.pageview('/register');
        const url = new URL(window.location.href);
        const referral_code = url.searchParams.get('utm_source');
        ReactGA.event({
            category: 'register',
            action: `Visited Website`,
            label: referral_code,
            value: 1
        });
    }
    onInputChange = (e) => {
        this.setState({
            [`${e.target.name}Val`]: e.target.value
        });
    }
    isValid = () => {
        const {
            firstNameVal: first_name,
            lastNameVal: last_name,
            emailVal: email,
            phoneVal: phone,
            organizationNameVal: organizationName,
            passwordVal: password,
            confirmPasswordVal: confpass,
        } = this.state;
        let errors = {};

        if (first_name === "" || first_name.length > 35 || !/[A-Za-z]/.test(first_name)) {
            errors.first_name = true;
            this.setState({ errors });
            return false;
        }
        if (last_name === "" || last_name.length > 35 || !/[A-Za-z]/.test(last_name)) {
            errors.last_name = true;
            this.setState({ errors });
            return false;
        }
        if (email === "" || !/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,9}$/.test(email )){
            errors.email = true;
            this.setState({ errors });
            return false;
        }
        if (phone === "" || !/[0-9]/.test(phone)) {
            errors.phone = true;
            this.setState({ errors });
            return false;
        }
        if (organizationName === "" || organizationName.length > 50 || !/[A-Za-z]/.test(organizationName)) {
            errors.organizationName = true;
            this.setState({ errors });
            return false;
        }
        if (password === ""||password.length<6) {
            errors.password = true;
            this.setState({ errors });
            return false;
        }
        if (confpass === "") {
            errors.confpass = true;
            this.setState({ errors });
            return false;
        }

        if (confpass !== password) {
            errors.confpass = true;
        } this.setState({ errors });

        if (errors.confpass) {
            return false;
        }

        return true;
    }
    onSignUp() {
        const url = new URL(window.location.href);
        const referral_code = url.searchParams.get('utm_source');
        const {
            firstNameVal: first_name,
            lastNameVal: last_name,
            emailVal: email,
            phoneVal: phone_number,
            organizationNameVal: organization_name,
            passwordVal: password,
            confirmPasswordVal: confpass

        } = this.state;

        if (this.isValid()) {
            const data = {
                email,
                password,
                first_name,
                last_name,
                phone_number,
                profile:{ 
                    organization_name
                },
                referral_code: referral_code
            }
            const handleSuccess = (data) => {
                Toast.show({
                    message: "We have received your request, will get back to you soon.",
                    timeout: 4000,
                    intent: 'success'
                });

                ReactGA.event({
                    category: 'register',
                    action: `Created Account`,
                    label: referral_code,
                    value: 2
                });
                this.props.history.push('/login');
            };
            const handleFailure = (data,body) => {
                console.log(body,"******************");
                
                Toast.show({
                    message: body.email,
                    timeout: 4000,
                    intent: 'danger'
                });
            };
            this.props.actions.register(handleSuccess, handleFailure, data);
        }
    }
    onKeyPress = (e) => {
        if(e.which === 13) {
            this.onSignUp();
        }
      }
    focus = () => {
        this.refs.firstName.focus();
    }
    render() {
        const { firstNameVal, lastNameVal, emailVal, phoneVal, passwordVal, confirmPasswordVal, errors } = this.state;
        return (
            <RegisterMarkup
                isLoading={this.props.isLoading}
                error={this.props.error}
                firstNameVal={firstNameVal}
                lastNameVal={lastNameVal}
                emailVal={emailVal}
                phoneVal={phoneVal}
                passwordVal={passwordVal}
                confirmPasswordVal={confirmPasswordVal}
                errors={errors}
                onInputChange={this.onInputChange}
                onSignUp={() => this.onSignUp()}
                onKeyPress = {this.onKeyPress }
                history={this.props.history}
            />
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.Auth.isLoading,
        error: state.Auth.error
    }
}
const mapDispatchToProps = (dispatch) => {
	return {
	    actions: bindActionCreators(actions, dispatch),
	};
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register));