import { URL, HUBSPOT, HUBSPOT_OAUTH_REQUEST } from '../env'

const config = {
    hubspot: {
        url: `${HUBSPOT}/oauth/authorize`,
        params: {
            client_id: HUBSPOT_OAUTH_REQUEST.client_id,
            scope: 'contacts+oauth',
            redirect_uri: `${URL}/integration/connect?app=hubspot`
        }
    }
};

export default config;
