import Constants from './constants';
import { APISAAS_DOMAIN, API_DOMAIN } from '../../../env';

const saveCourse = (handleSuccess, handleFailure, data) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token,user, organization } } } = getState();
        dispatch({
            type: Constants.SAVE_LOADING
        });
        fetch(`${APISAAS_DOMAIN}api/course/update/${organization.organization_id}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.SAVE_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.SAVE_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}


const getOrganizationCourses = (page) => {

    return (dispatch, getState) => {
        const { Auth: { user: { token, user, organization } } } = getState();
        dispatch({
            type: Constants.GET_ALL_COURSES_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/course/usercourse/${organization.organization_id}/${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_ALL_COURSES_FAIL,
                        payload: body.errors
                    });
                } else {
                    dispatch({
                        type: Constants.GET_ALL_COURSES_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}

const getOrganizationStudentsCourses = (org_id) => {
    let page =1 
    return (dispatch, getState) => {
        const { Auth: { user: { token, user, organization } } } = getState();
        dispatch({
            type: Constants.GET_ALL_COURSES_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/course/userstudentscourse/${org_id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_ALL_COURSES_FAIL,
                        payload: body.errors
                    });
                } else {
                    dispatch({
                        type: Constants.GET_ALL_COURSES_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}

const upload = (handleSuccess,handleFailure, formData) => {
    return function (dispatch) {
        dispatch({
            type: Constants.SAAS_UPLOAD_REQUEST
		});
            return fetch(`${APISAAS_DOMAIN}api/course/creates/csv/`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json()
            .then(body => ({ response, body })))
            .then(({ response, body }) => {
				if (!response.ok) {
                    dispatch({
                        type: Constants.SAAS_UPLOAD_REQUEST_FAIL,
                        payload: body.error
                    });
                    handleFailure();
                } else {
                    dispatch({
                        type: Constants.SAAS_UPLOAD_REQUEST_SUCCESS,
						payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

const addCourse = (handleSuccess, handleFailure, data) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token,user, organization } } } = getState();
        dispatch({
            type: Constants.SAVE_LOADING
        });
        fetch(`${APISAAS_DOMAIN}api/course/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.SAVE_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.SAVE_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

const updateCourse = (handleSuccess, handleFailure, data) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token,user, organization } } } = getState();
        dispatch({
            type: Constants.SAVE_LOADING
        });
        fetch(`${APISAAS_DOMAIN}api/course/courseupdate/${data.id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.SAVE_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.SAVE_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

const deleteCourse = (id, handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token,user, organization } } } = getState();
        dispatch({
            type: Constants.DELETE_COURSE_LOADING
        });
        fetch(`${APISAAS_DOMAIN}api/course/delete/id/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.DELETE_COURSE_ERROR,
                        payload: body.errors
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.DELETE_COURSE_SUCCESS,
                        payload: body
                    });

                    handleSuccess(body);
                }
            });
    }
}
const applyNowZoomabroad = (handleSuccess, handleFailure, data) => function (dispatch) {
    dispatch({
      type: Constants.POST_APPLY_NOW_REQUEST,
    });
    return fetch(`${API_DOMAIN}/user/apply`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: Constants.POST_APPLY_NOW_FAIL,
            payload: body.error,
          });
          handleFailure(body.error);
        } else {
          dispatch({
            type: Constants.POST_APPLY_NOW_SUCCESS,
            payload: body,
          });
          handleSuccess(body);
        }
      });
  };

  const universityCreateZoomabroad = (handleSuccess, handleFailure, data) => function (dispatch) {
    dispatch({
      type: Constants.POST_CREATE_UNIVERSITY_REQUEST,
    });
    return fetch(`${API_DOMAIN}/api/university/create/`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: Constants.POST_CREATE_UNIVERSITY_FAIL,
            payload: body.error,
          });
          handleFailure(body.error);
        } else {
          dispatch({
            type: Constants.POST_CREATE_UNIVERSITY_SUCCESS,
            payload: body,
          });
          handleSuccess(body);
        }
      });
  };

  const courseCreateZoomabroad = (handleSuccess, handleFailure, data) => function (dispatch) {
    dispatch({
      type: Constants.POST_CREATE_UNIVERSITY_COURSE_REQUEST,
    });
    return fetch(`${API_DOMAIN}/api/course/create/`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: Constants.POST_CREATE_UNIVERSITY_COURSE_FAIL,
            payload: body.error,
          });
          handleFailure(body.error);
        } else {
          dispatch({
            type: Constants.POST_CREATE_UNIVERSITY_COURSE_SUCCESS,
            payload: body,
          });
          handleSuccess(body);
        }
      });
  };
  const coursesCreateZoomabroad = (handleSuccess, handleFailure, data) => function (dispatch) {
    dispatch({
      type: Constants.POST_CREATE_UNIVERSITY_REQUEST,
    });
    return fetch(`${API_DOMAIN}/courses/create`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: Constants.POST_CREATE_UNIVERSITY_FAIL,
            payload: body.error,
          });
          handleFailure(body.error);
        } else {
          dispatch({
            type: Constants.POST_CREATE_UNIVERSITY_SUCCESS,
            payload: body,
          });
          handleSuccess(body);
        }
      });
  };
  const uniCourseCreateZoomabroad = (handleSuccess, handleFailure, data) => function (dispatch) {
    dispatch({
      type: Constants.POST_CREATE_UNIVERSITY_REQUEST,
    });
    return fetch(`${API_DOMAIN}/university/course`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          dispatch({
            type: Constants.POST_CREATE_UNIVERSITY_FAIL,
            payload: body.error,
          });
          handleFailure(body.error);
        } else {
          dispatch({
            type: Constants.POST_CREATE_UNIVERSITY_SUCCESS,
            payload: body,
          });
          handleSuccess(body);
        }
      });
  };
export default 
{ 
    saveCourse,
    getOrganizationCourses,
    getOrganizationStudentsCourses,
    upload,
    addCourse,
    updateCourse,
    deleteCourse,
    applyNowZoomabroad,
    universityCreateZoomabroad,
    courseCreateZoomabroad,
    coursesCreateZoomabroad,
    uniCourseCreateZoomabroad
}