
import Constants from './constants';

const INITIAL_STATE = {
    isLoading: false,
    channelList: [],
    error: undefined,
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Constants.GET_CHANNEL_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_CHANNEL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                channelList: action.payload
            };
        case Constants.GET_CHANNEL_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case Constants.DELETE_CHANNEL_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.DELETE_CHANNEL_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.DELETE_CHANNEL_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case Constants.UPDATE_CHANNEL_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.UPDATE_CHANNEL_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.UPDATE_CHANNEL_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case Constants.POST_CHANNEL_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.POST_CHANNEL_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.POST_CHANNEL_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};
