import React, { Component } from "react";
import { Card } from "@blueprintjs/core";
import { Container, Row, Col, Button } from 'react-bootstrap';
import moment from 'moment'
class Summary extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        const { cards, submit } = this.props
        return (
            <Container>
                <h3>Summary</h3>
                {cards.map(card => {
                    return (<Card>
                        <h5><a href="#">{card.title}</a></h5>
                        <Row>
                            {card.input.map(inputs => {
                                switch (inputs.type) {
                                    case "select":
                                        return (
                                            <Col md="6" lg="6" xs="6" sm="6">
                                                <p>{inputs.label} : <strong>{inputs.value.label?inputs.value.label:'N/A'}</strong></p>
                                            </Col>
                                        )
                                    case "date":
                                        return (
                                            <Col md="6" lg="6" xs="6" sm="6">
                                                <p>{inputs.label} : <strong>{inputs.value?moment(inputs.value).format('DD-MM-YYYY'):'N/A'}</strong></p>
                                            </Col>
                                        )
                                    case "textarea":
                                        return (
                                            <Col md="6" lg="6" xs="6" sm="6">
                                                <p>{inputs.label} : <strong>{inputs.value?inputs.value:'N/A'}</strong></p>
                                            </Col>
                                        )
                                    case "radio":
                                        return (
                                            <Col md="6" lg="6" xs="6" sm="6">
                                                <p>{inputs.label} : <strong>{inputs.value?inputs.value:'N/A'}</strong></p>
                                            </Col>
                                        )
                                    case "file":
                                        return (
                                            <Col md="6" lg="6" xs="6" sm="6">
                                                <p>{inputs.label} : <strong>{inputs.path?inputs.path:'N/A'}</strong></p>
                                            </Col>
                                        )
                                    default:
                                        return (
                                            <Col md="6" lg="6" xs="6" sm="6">
                                                <p>{inputs.label} : <strong>{inputs.value?inputs.value:'N/A'}</strong></p>
                                            </Col>
                                        )
                                }
                            })}
                        </Row>
                    </Card>)
                })}
            </Container>

        )


    }
}

export default Summary