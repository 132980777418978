import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Image, Form } from 'react-bootstrap';
import { H5, MenuItem, Switch } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import countryList from '../../../Integration/modules/countryList'

// import "normalize.css";
// import "@blueprintjs/core/lib/css/blueprint.css";
// import "@blueprintjs/select/lib/css/blueprint-select.css";
import './ApplicationCreate.scss';

export const renderItem = (item, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    // if (item.label == "" || item.label == "demo" || item.label == "xyz" || item.label == "test" || item.label == "test22" || item.label == "Qualifi" || item.label == "qualifi") {
    //     return null;
    // }
    return (
        <MenuItem
            // className="form-generator-menu-item"
            active={modifiers.active}
            disabled={modifiers.disabled}
            text={item.label ? item.label.toString() : null}
            key={item.label}
            onClick={handleClick}
        />
    );
};

const options = [
    {
        "value": "2028",
        "label": "2028"
      },
      {
        "value": "2027",
        "label": "2027"
      },
      {
        "value": "2026",
        "label": "2026"
      },
      {
        "value": "2025",
        "label": "2025"
      },
      {
        "value": "2024",
        "label": "2024"
      },
      {
        "value": "2023",
        "label": "2023"
      },
      {
        "value": "2022",
        "label": "2022"
      },
      {
        "value": "2021",
        "label": "2021"
      },
      {
       "value": "2020",
       "label": "2020"
     },
      {
        "value": "2019",
        "label": "2019"
      },
      {
        "value": "2018",
        "label": "2018"
      },
      {
        "value": "2017",
        "label": "2017"
      },
      {
        "value": "2016",
        "label": "2016"
      }
]
const months = [
    {
        "value": "January",
        "label": "January"
      },
      {
        "value": "February",
        "label": "February"
      },
      {
        "value": "March",
        "label": "March"
      },
      {
        "value": "April",
        "label": "April"
      },
      {
        "value": "May",
        "label": "May"
      },
      {
        "value": "June",
        "label": "June"
      },
      {
        "value": "July",
        "label": "July"
      },
      {
        "value": "August",
        "label": "August"
      },
      {
       "value": "September",
       "label": "September"
     },
      {
        "value": "October",
        "label": "October"
      },
      {
        "value": "November",
        "label": "November"
      },
      {
        "value": "December",
        "label": "December"
      }
]
const courseTypeOptions = [
    { "label": "Bachelor", "value": "Bachelors" },
    { "label": "Master", "value": "Masters" },
    { "label": "Foundation", "value": "Foundation" },
    { "label": "Phd", "value": 'phd_doctoral'},
    { "label": "Diploma", "value": "Diploma" },
    { "label": "English Language", "value": "english_language" },

]
export function createFilm(value, options) {
    return {
        value: value,
        label: value,
    };
}
const filterItems = (query, options) => {
    return options.label ? options.label.toLowerCase().indexOf(query.toLowerCase()) !== -1 : [];
};

export const renderCreateFilmOption = (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>,
) => (
        <MenuItem
            icon="add"
            text={`Create "${query}"`}
            active={active}
            onClick={handleClick}
            shouldDismissPopover={false}
        />
    );

const maybeCreateNewItemFromQuery = true ? createFilm : undefined;
const maybeCreateNewItemRenderer = true ? renderCreateFilmOption : null;
const ApplicationCreate = (props) => {
    const { appicationCourseCreate, onChangeInput, onClearAppModal, onApplyCreateCourse, zaCoursesData, zaUniversityData, channelData,applicationCourseType ,
        applicationCourseUniversity, applicationChannelName, applicationIntakeYear, applicationIntakeMonth, applicationCountry
        
    } = props;
    const renderInputValue = (options) => options.label;
    return (
        <div className="application-create-page">
            <Row>
                <Col md='3'>
            <Form.Label>Course Type *</Form.Label> 
            </Col>
                <Col className="application-form" md='9'>
                    <Suggest
                        className="select-class"
                        createNewItemFromQuery={maybeCreateNewItemFromQuery}
                        createNewItemRenderer={maybeCreateNewItemRenderer}
                        items={courseTypeOptions}
                        itemRenderer={renderItem}
                        noResults={<MenuItem disabled={true} text="No results." />}
                        filterable={true}
                        minimal={true}
                        itemPredicate={filterItems}
                        selectedItem={applicationCourseType ? applicationCourseType :'select'}
                        inputValueRenderer={renderInputValue}
                        inputProps={{ placeholder: 'Search' }}
                        onItemSelect={(e) => {
                            const event = {
                                target: {
                                    value: e,
                                }
                            }
                            onChangeInput('applicationCourseType', event.target.value)
                        }}
                    >
                        <Button text={courseTypeOptions.value ? courseTypeOptions.value.label : "select"} rightIcon="double-caret-vertical" />
                    </Suggest>

                </Col>
            </Row>
            <Row>
             <Col md='3'>
            <Form.Label>University Name *</Form.Label>
            </Col> 
                <Col className="application-form" md='9'>
                    <Suggest
                        className="select-class"
                        createNewItemFromQuery={maybeCreateNewItemFromQuery}
                        createNewItemRenderer={maybeCreateNewItemRenderer}
                        items={zaUniversityData ? zaUniversityData : []}
                        itemRenderer={renderItem}
                        noResults={<MenuItem disabled={true} text="No results." />}
                        filterable={true}
                        minimal={true}
                        disabled={applicationCourseType?false:true}
                        itemPredicate={filterItems}
                        selectedItem={applicationCourseUniversity ? applicationCourseUniversity :'select'}
                        inputValueRenderer={renderInputValue}
                        inputProps={{ placeholder: 'Search' }}
                        onItemSelect={(e) => {
                            const event = {
                                target: {
                                    value: e,
                                }
                            }
                            onChangeInput('applicationCourseUniversity', event.target.value)
                        }}
                    >
                        {zaUniversityData ? 
                        <Button text={zaUniversityData.value ? zaUniversityData.value.label : "select"} rightIcon="double-caret-vertical" />
                        : ''}
                    </Suggest>

                </Col>
            </Row>
            <Row>
                <Col md='3'>
            <Form.Label>Course Name *</Form.Label> 
            </Col>
                <Col className="application-form" md='9'> 
                    <Suggest
                        className="select-class"
                        createNewItemFromQuery={maybeCreateNewItemFromQuery}
                        createNewItemRenderer={maybeCreateNewItemRenderer}
                        items={zaCoursesData ? zaCoursesData : []}
                        itemRenderer={renderItem}
                        noResults={<MenuItem disabled={true} text="No results." />}
                        filterable={true}
                        minimal={true}
                        disabled={applicationCourseUniversity?false:true}

                        itemPredicate={filterItems}
                        selectedItem={appicationCourseCreate ? appicationCourseCreate :'select'}
                        inputValueRenderer={renderInputValue}
                        inputProps={{ placeholder: 'Search' }}
                        onItemSelect={(e) => {
                            const event = {
                                target: {
                                    value: e,
                                }
                            }
                            onChangeInput('appicationCourseCreate', event.target.value)
                        }}
                    >
                        {zaCoursesData ? 

                        <Button text={zaCoursesData.value ? zaCoursesData.value.label : "select"} rightIcon="double-caret-vertical" />
                        : ''}
                    </Suggest>
                </Col>
            </Row>
            <Row>
             <Col md='3'>
            <Form.Label>Intake Month *</Form.Label>
            </Col> 
                <Col className="application-form" md='9'>
                    <Suggest
                        className="select-class"
                        createNewItemFromQuery={maybeCreateNewItemFromQuery}
                        createNewItemRenderer={maybeCreateNewItemRenderer}
                        items={months}
                        itemRenderer={renderItem}
                        noResults={<MenuItem disabled={true} text="No results." />}
                        filterable={true}
                        minimal={true}
                        disabled={appicationCourseCreate?false:true}

                        itemPredicate={filterItems}
                        selectedItem={applicationIntakeMonth ? applicationIntakeMonth :'select'}
                        inputValueRenderer={renderInputValue}
                        inputProps={{ placeholder: 'Search' }}
                        onItemSelect={(e) => {
                            const event = {
                                target: {
                                    value: e,
                                }
                            }
                            onChangeInput('applicationIntakeMonth', event.target.value)
                        }}
                    >
                        <Button text={months.value ? months.value.label : "select"} rightIcon="double-caret-vertical" />
                    </Suggest>

                </Col>
            </Row>
            <Row>
             <Col md='3'>
            <Form.Label>Intake Year *</Form.Label>
            </Col> 
                <Col className="application-form" md='9'>
                    <Suggest
                        className="select-class"
                        createNewItemFromQuery={maybeCreateNewItemFromQuery}
                        createNewItemRenderer={maybeCreateNewItemRenderer}
                        items={options ? options : []}
                        itemRenderer={renderItem}
                        noResults={<MenuItem disabled={true} text="No results." />}
                        filterable={true}
                        minimal={true}
                        disabled={appicationCourseCreate?false:true}
                        placeholder={'Search'}
                        itemPredicate={filterItems}
                        selectedItem={applicationIntakeYear}
                        selectedItem={applicationIntakeYear ? applicationIntakeYear :'select'}
                        inputValueRenderer={renderInputValue}
                        inputProps={{ placeholder: 'Search' }}
                        onItemSelect={(e) => {
                            const event = {
                                target: {
                                    value: e,
                                }
                            }
                            onChangeInput('applicationIntakeYear', event.target.value)
                        }}
                    >
                        <Button  text={options.value ? options.value.label : "select"} rightIcon="double-caret-vertical" />
                    </Suggest>

                </Col>
            </Row>
            <Row>
             <Col md='3'>
            <Form.Label>Country *</Form.Label>
            </Col> 
                <Col className="application-form" md='9'>
                    <Suggest
                        className="select-class"
                        createNewItemFromQuery={maybeCreateNewItemFromQuery}
                        createNewItemRenderer={maybeCreateNewItemRenderer}
                        items={countryList ? countryList : []}
                        itemRenderer={renderItem}
                        noResults={<MenuItem disabled={true} text="No results." />}
                        filterable={true}
                        minimal={true}
                        disabled={appicationCourseCreate?false:true}
                        placeholder={'Search'}
                        itemPredicate={filterItems}
                        selectedItem={applicationCountry}
                        selectedItem={applicationCountry ? applicationCountry :'select'}
                        inputValueRenderer={renderInputValue}
                        inputProps={{ placeholder: 'Search' }}
                        onItemSelect={(e) => {
                            const event = {
                                target: {
                                    value: e,
                                }
                            }
                            onChangeInput('applicationCountry', event.target.value)
                        }}
                    >
                        <Button  text={options.value ? options.value.label : "select"} rightIcon="double-caret-vertical" />
                    </Suggest>

                </Col>
            </Row>
            <Row>
                <Col md='3'>
            <Form.Label>Channel *</Form.Label> 
            </Col>
                <Col className="application-form" md='9'>
                    <Suggest
                        className="select-class"
                        // createNewItemFromQuery={maybeCreateNewItemFromQuery}
                        // createNewItemRenderer={maybeCreateNewItemRenderer}
                        items={channelData ? channelData : []}
                        itemRenderer={renderItem}
                        noResults={<MenuItem disabled={true} text="No results." />}
                        filterable={true}
                        minimal={true}
                        disabled={appicationCourseCreate?false:true}
                        itemPredicate={filterItems}
                        selectedItem={applicationChannelName ? applicationChannelName :'select'}
                        inputValueRenderer={renderInputValue}
                        inputProps={{ placeholder: 'Search' }}
                        onItemSelect={(e) => {
                            const event = {
                                target: {
                                    value: e,
                                }
                            }
                            onChangeInput('applicationChannelName', event.target.value)
                        }}
                    >
                        {/* <Button text={channelData.value ? channelData.value.label : "select"} rightIcon="double-caret-vertical" /> */}
                    </Suggest>

                </Col>
            </Row>            
            <Col className="application-form">
                <Button className="app-clear-button" onClick={onClearAppModal}>Clear</Button>
                <Button className="app-apply-button" onClick={ () => onApplyCreateCourse()} disabled={!(applicationChannelName && applicationIntakeYear && applicationIntakeMonth && applicationCourseUniversity && applicationCourseType && applicationCountry)}>Apply</Button>
            
            </Col>
        </div>
    )
}
export default ApplicationCreate;
