
import Constants from './Constants';
import { APISAAS_DOMAIN } from '../../../env';

const userAccess = (handleSuccess,handleFailure,formData) => {
    return function (dispatch, getState) {
        const { Auth: { user: { organization} } } = getState();
        const { Auth } = getState();

        const org_id = organization.organization_id
        formData.org_id = org_id
        dispatch({
            type: Constants.USER_ACCESS_REQUEST
		});
            return fetch(`${APISAAS_DOMAIN}api/organization/createuser/`, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json()
            .then(body => ({ response, body })))
            .then(({ response, body }) => {
				if (!response.ok) {
                    dispatch({
                        type: Constants.USER_ACCESS_FAIL,
                        payload: body.error
                    });
                    handleFailure(body)
                } else {
                    dispatch({
                        type: Constants.USER_ACCESS_SUCCESS,
						payload: body
                    });
                    handleSuccess(body)
                }
            });
    }
}

const getUser = (id) => {
   
    return (dispatch, getState) => {
        const { Auth: { user: { token, user } } } = getState();
        dispatch({
            type: Constants.GET_USER_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/organizationusers/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_USER_FAIL,
                        payload: body.errors
                    });
                } else {
                    dispatch({
                        type: Constants.GET_USER_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}
const userDataReset = (payload) => function (dispatch) {
    dispatch({
      type: Constants.GET_USER_DATA_RESET,
      payload,
    });
  };
export default {
    userAccess,
    getUser,
    userDataReset
}