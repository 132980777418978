import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Row, Col, Form, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ZLoader } from '../../../shared/loader/ZLoader'
import { DateRangeInput } from "@blueprintjs/datetime";
import moment from 'moment';
import './CustomerReportMarkup.scss';

const ReportMarkup = (props) => {
    const { isLoading, handleRangeChange, start_date, end_date, getreport, allMembers, onChangeInput, memberValue,onDeselect,SelectedLeadStatus, leadStatusValue, callingReport } = props

    let members = []
    if (allMembers && allMembers) {
        members = allMembers.Users
        if (members) {
            let select = { 'first_name': "Select Owner", 'user_id': '' }
            let index = members.findIndex(x => x.first_name == "Select Owner");
            index === -1 ? members.splice(0, 0, select) : console.log("object already exists")
        }   
    }
    return (
        <div className="report-container">
            <Container>
                {isLoading ? <ZLoader /> : null}
                <Row style={{ marginBottom: 10 }}>  
                    <Col lg='2' sm='2' md='2'>
                        <Form.Group >
                            <Form.Control 
                                className="select-options"
                                name={"leadStatusValue"}
                                as="select"
                                placeholder="Status"
                                value={leadStatusValue ? leadStatusValue : null}
                                onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                            >
                                <option value="">Select...</option>
                                    <option value="international">International</option>
                                    <option value="subscriber">Subscriber</option>
                                    <option value="ringing">Ringing</option>
                                    <option value="others">Others</option>
                                    <option value="lead">Lead</option>
                                    <option value="hot_lead">Hot Lead</option>
                                    <option value="beyond_intake">Beyond Intake</option>

                            </Form.Control>                            
                        </Form.Group>
                    </Col>            
                    <Col lg='2' sm='2' md='2'>
                    <Form.Group >
                                {/* <Form.Label>Lead Status : </Form.Label> */}
                            <Form.Control
                                className="select-options"
                                name={"memberValue"}
                                as="select"
                                placeholder="Status"
                                value={memberValue ? memberValue : null}
                                onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                            >
                                {
                                    members && members.length ? members.map((members) => {
                                        if(members.first_name){
                                return (
                                    <option value={members.user_id}>{members.first_name} {members.last_name}</option>
                                )}
                                })
                                : ''}

                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col lg='6' sm='6' md='6'>
                        <DateRangeInput
                            formatDate={date => moment(date).format('DD/MM/YYYY')}
                            onChange={(event) => handleRangeChange(event)}
                            parseDate={str => new Date(str)}
                            allowSingleDayRange={true}
                            // defaultValue={[moment().add(-1, "weeks").toDate(),moment().add(0, "days").toDate()]}
                            value={[start_date ? start_date : null, end_date ? end_date : null]}
                        // timePickerProps={{precision:'minute'}}

                        />
                    </Col>

                    <Col>
                        <Button onClick={getreport}>
                            Get Report
                              </Button>
                    </Col>
                </Row>
                 <Row className="customer-selected-status">
                    {SelectedLeadStatus && SelectedLeadStatus.length ? <div className='selected-status'><strong>Selected Status :</strong></div> : null}
                    {SelectedLeadStatus && SelectedLeadStatus.length ? 
                        SelectedLeadStatus.map((item) => {
                        return <div className='selected-status'><span>{`${item.replaceAll("'", '')}`}&nbsp;&nbsp;<i onClick={() => onDeselect(item)} className="fa fa-times-circle delete-icon" aria-hidden="true"></i></span></div>;
                        }) : null}
                        
                </Row>
                <Row lg='8' sm='8' md='8' className='customer-report'>
                    {callingReport && callingReport ?
                        <>
                            <Table striped bordered hover responsive="sm">
                                <tbody>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Subject</th>
                                        <th>Note</th>
                                        <th>Last Active</th>
                                        <th>Type</th>
                                        <th>Lead Status</th>
                                        <th>Advisor Fname</th>
                                        <th>Advisor Lname</th>
                                        <th>Zilter link</th>
                                    </tr>
                                    {
                                        callingReport.map((numList, i) => (
                                            <tr key={i}>
                                                {
                                                    numList.map((num, j) =>
                                                        <td key={j}>{num}</td>
                                                    )
                                                }
                                            </tr>

                                        ))
                                    }
                                </tbody>
                            </Table>
                        </>
                        : ''}
                </Row>
            </Container>
        </div>
    )
}

export default ReportMarkup;
