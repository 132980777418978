import React from "react";
import { Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { ColumnToggle } from 'react-bootstrap-table2-toolkit';
import MultiSelectOptions from './MultiSelectOptions'
import "./Table.scss";
const Table = (props) => {
    const { data, columns,filterOptions, myColumnToggle,toggle,scrollview } = props;   
    return (
        <><Row className={scrollview ? "table-container widthset" : "table-container"}>
            <Col md="12" lg="12" sm="12" xs="12" className="table-section">           
                <ToolkitProvider
                keyField="id"
                data={ data }
                columns={ columns } 
                filterOptions={filterOptions}
                selected ={true}
                values = {columns}
                columnToggle
                >
                {
                    props => (
                        <div>
                            {toggle ?
                            <MultiSelectOptions
                            filterOptions={filterOptions}
                            selected ={true}
                            values = {columns}
                            myColumnToggle={ myColumnToggle }
                            { ...props.columnToggleProps }

                            />:'' 
                                
                            }
                        
                            <BootstrapTable
                                    { ...props.baseProps }
                                        keyField='id'
                                        data={ data && data.length ? data : [] }
                                        columns={columns}
                                        cellEdit={
                                            cellEditFactory({
                                                mode: 'click',
                                                blurToSave: true
                                            })
                                        }
                                        bordered={false}
                                        rowClasses={'table-row'}
                                        classes={'table-class'}
                                        headerClasses={scrollview ? "thclass" : ""}
                                    />
                        </div>
                    )
                }
                </ToolkitProvider>
            </Col>
        </Row>
        </>
    )
}
export default Table


