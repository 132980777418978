import Constants from './constants';
import { APISAAS_DOMAIN } from '../../../env';


const getOrgChannel = (org_id) => {    
    return (dispatch) => {
        dispatch({
            type: Constants.GET_CHANNEL_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/channel/organizationchannel/${org_id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.GET_CHANNEL_FAIL,
                        payload: body.errors
                    });
                } else {
                    dispatch({
                        type: Constants.GET_CHANNEL_SUCCESS,
                        payload: body
                    });
                }
            });
    }
}

const createChannel = (data, handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.POST_CHANNEL_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/channel/create/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.POST_CHANNEL_FAIL,
                        payload: body.errorsupdatechannel
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.POST_CHANNEL_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

const updateChannel = (data, id, handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.UPDATE_CHANNEL_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/channel/update/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: Constants.UPDATE_CHANNEL_FAIL,
                        payload: body.errorsupdatechannel
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: Constants.UPDATE_CHANNEL_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

const deleteChannel = (id, handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: Constants.DELETE_CHANNEL_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/channel/delete/${id}`, {
            method: 'DELETE',
            // body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }) 
            .then(({ response, body }) => {
                if (!response) {
                    dispatch({
                        type: Constants.DELETE_CHANNEL_SUCCESS,
                        // payload: body.errorsDELETEchannel
                    });
                    handleSuccess(response);
                } else {
                    dispatch({
                        type: Constants.DELETE_CHANNEL_FAIL,
                        // payload: body
                    });
                    handleSuccess();
                }
            });
    }
}
export default {
    getOrgChannel,
    createChannel,
    updateChannel,
    deleteChannel
};
