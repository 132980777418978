
import Constants from './constants';

const INITIAL_STATE = {
    isLoading: false,
    sourceList: [],
    error: undefined,
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Constants.GET_SOURCE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.GET_SOURCE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                sourceList: action.payload
            };
        case Constants.GET_SOURCE_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case Constants.DELETE_SOURCE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.DELETE_SOURCE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.DELETE_SOURCE_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case Constants.UPDATE_SOURCE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.UPDATE_SOURCE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.UPDATE_SOURCE_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case Constants.POST_SOURCE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Constants.POST_SOURCE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.POST_SOURCE_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};
