import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TickComponent from '../components/TickTable'
import profileActions from '../../Profile/module/actions'
import actions from '../modules/actions'
import { bindActionCreators } from 'redux';
import { Toast } from '../../../shared/toast/Toast';
class TickTableContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            emailVal: '',
            isCounsellor: false,
            mappingView: false,
            mappingCreate: false,
            mappingEdit: false,
            mappingDelete: false,
            questionView: false,
            questionCreate: false,
            questionEdit: false,
            questionDelete: false,
            interviewView: false,
            interviewCreate: false,
            interviewEdit: false,
            interviewDelete: false,
            courseView: false,
            courseCreate: false,
            courseEdit: false,
            courseDelete: false,
            reportView: false,
            reportCreate: false,
            reportEdit: false,
            reportDelete: false,
            studentsView: false,
            studentsCreate: false,
            studentsEdit: false,
            studentsDelete: false,
            dashboardView: false,
            dashboardCreate: false,
            dashboardEdit: false,
            dashboardDelete: false,
            counselorReportView: false,
            counselorReportCreate: false,
            counselorReportEdit: false,
            counselorReportDelete: false,
            mode: '',
            id: '',
            finalData: [],
            count: 0,
            edit: false,
            stateID: ''
        }

    }

    componentDidMount() {
        const { location: { pathname } } = this.props;
        const urlParts = pathname.split("/");
        const mode = urlParts[2]
        const id = urlParts[3]
        if (urlParts[2] && urlParts[3]) {
            this.props.actions.getUser(urlParts[3]);
            this.setState({ edit: true, id: id })
        }
    }
    static getDerivedStateFromProps(props, state) {
        // if (!state.stateID) {
        if (state.edit && !state.finalData.length) {
            const { userData } = props;
            console.log("userDatauserData",userData, userData.is_counselor)
            if (userData && userData.access) {
                const userDataAccess = userData.access
                const somedata = userDataAccess.replace(/'/g, '"')
                let finalData = JSON.parse(somedata)

                const mapping = finalData[0] && finalData[0].operations.length ? finalData[0].operations : ''
                const question = finalData[1] && finalData.length ? finalData[1].operations : ''
                const interview = finalData[2] && finalData.length ? finalData[2].operations : ''
                const course = finalData[3] && finalData.length ? finalData[3].operations : ''
                const report = finalData[4] && finalData.length ? finalData[4].operations : ''
                const student = finalData[5] && finalData.length ? finalData[5].operations : ''
                const dashboard = finalData[6] && finalData.length ? finalData[6].operations : ''
                const counselorReport = finalData[7] && finalData.length ? finalData[7].operations : ''

                
                let questionView = question[0] == 1 ? true : false
                let questionCreate = question[1] == 1 ? true : false
                let questionEdit = question[2] == 1 ? true : false
                let questionDelete = question[3] == 1 ? true : false

                let courseView = course[0] == 1 ? true : false
                let courseCreate = course[1] == 1 ? true : false
                let courseEdit = course[2] == 1 ? true : false
                let courseDelete = course[3] == 1 ? true : false

                let interviewView = interview[0] == 1 ? true : false
                let interviewCreate = interview[1] == 1 ? true : false
                let interviewEdit = interview[2] == 1 ? true : false
                let interviewDelete = interview[3] == 1 ? true : false

                let mappingView = mapping[0] == 1 ? true : false
                let mappingCreate = mapping[1] == 1 ? true : false
                let mappingEdit = mapping[2] == 1 ? true : false
                let mappingDelete = mapping[3] == 1 ? true : false

                let reportView = report[0] == 1 ? true : false
                let reportCreate = report[1] == 1 ? true : false
                let reportEdit = report[2] == 1 ? true : false
                let reportDelete = report[3] == 1 ? true : false

                let studentsView = student[0] == 1 ? true : false
                let studentsCreate = student[1] == 1 ? true : false
                let studentsEdit = student[2] == 1 ? true : false
                let studentsDelete = student[3] == 1 ? true : false

                let dashboardView = dashboard[0] == 1 ? true : false
                let dashboardCreate = dashboard[1] == 1 ? true : false
                let dashboardEdit = dashboard[2] == 1 ? true : false
                let dashboardDelete = dashboard[3] == 1 ? true : false

                let counselorReportView = counselorReport[0] == 1 ? true : false
                let counselorReportCreate = counselorReport[1] == 1 ? true : false
                let counselorReportEdit = counselorReport[2] == 1 ? true : false
                let counselorReportDelete = counselorReport[3] == 1 ? true : false

                let emailVal = userData.email;
                let stateID = props.userData.user_id
                let isCounsellor = props.userData.is_counselor
                return {
                    finalData,
                    emailVal,
                    stateID,
                    mappingView,
                    mappingCreate,
                    mappingEdit,
                    mappingDelete,
                    questionView,
                    questionCreate,
                    questionEdit,
                    questionDelete,
                    interviewView,
                    interviewCreate,
                    interviewEdit,
                    interviewDelete,
                    courseView,
                    courseCreate,
                    courseEdit,
                    courseDelete,
                    reportView,
                    reportCreate,
                    reportEdit,
                    reportDelete,
                    studentsView,
                    studentsCreate,
                    studentsEdit,
                    studentsDelete,
                    dashboardView,
                    dashboardCreate,
                    dashboardEdit,
                    dashboardDelete,
                    counselorReportView,
                    counselorReportCreate,
                    counselorReportEdit,
                    counselorReportDelete,
                    isCounsellor
                }
            }
        }
    }
    onCheckChange = (event) => {
        console.log("event>>>>>>>>>>>>>>>>>>>>>>>>>>>>", event.target.name)
        let nameVal = event.target.name
        this.setState({
            [nameVal]: event.target.checked
        })
    }
    changeText = (event) => {
        this.setState({
            emailVal: event.target.value
        })
    }

    onDisableCheck = () => {
        this.setState({
            mappingView: false
        })
    }



    onUpdate = () => {
        const {
            id,
            mappingView,
            mappingCreate,
            mappingEdit,
            mappingDelete,
            questionView,
            questionCreate,
            questionEdit,
            questionDelete,
            interviewView,
            interviewCreate,
            interviewEdit,
            interviewDelete,
            courseView,
            courseCreate,
            courseEdit,
            courseDelete,
            reportView,
            reportCreate,
            reportEdit,
            reportDelete,
            studentsView,
            studentsCreate,
            studentsEdit,
            studentsDelete,
            dashboardView,
            dashboardCreate,
            dashboardEdit,
            dashboardDelete,
            counselorReportView,
            counselorReportCreate,
            counselorReportEdit,
            counselorReportDelete,
            isCounsellor
        } = this.state

        let profileData = {
            is_counselor: isCounsellor ? 1 : 0,
        }
        let dataUpdate = {
            access: [
                {
                    "name": "MAPPING",
                    "operations": [
                        mappingView ? 1 : 0,
                        mappingCreate ? 1 : 0,
                        mappingEdit ? 1 : 0,
                        mappingDelete ? 1 : 0,

                    ]
                },
                {
                    "name": "QUESION",
                    "operations": [
                        questionView ? 1 : 0,
                        questionCreate ? 1 : 0,
                        questionEdit ? 1 : 0,
                        questionDelete ? 1 : 0
                    ]
                },
                {
                    "name": "INTERVIEW",
                    "operations": [
                        interviewView ? 1 : 0,
                        interviewCreate ? 1 : 0,
                        interviewEdit ? 1 : 0,
                        interviewDelete ? 1 : 0
                    ]
                },
                {
                    "name": "COURSE",
                    "operations": [
                        courseView ? 1 : 0,
                        courseCreate ? 1 : 0,
                        courseEdit ? 1 : 0,
                        courseDelete ? 1 : 0
                    ]
                },
                {
                    "name": "REPORT",
                    "operations": [
                        reportView ? 1 : 0,
                        reportCreate ? 1 : 0,
                        reportEdit ? 1 : 0,
                        reportDelete ? 1 : 0
                    ]
                },
                {
                    "name": "STUDENTS",
                    "operations": [
                        studentsView ? 1 : 0,
                        studentsCreate ? 1 : 0,
                        studentsEdit ? 1 : 0,
                        studentsDelete ? 1 : 0
                    ]
                },
                {
                    "name": "DASHBOARD",
                    "operations": [
                        dashboardView ? 1 : 0,
                        dashboardCreate ? 1 : 0,
                        dashboardEdit ? 1 : 0,
                        dashboardDelete ? 1 : 0
                    ]
                },
                {
                    "name": "COUNSELOR-REPORT",
                    "operations": [
                        counselorReportView ? 1 : 0,
                        counselorReportCreate ? 1 : 0,
                        counselorReportEdit ? 1 : 0,
                        counselorReportDelete ? 1 : 0
                    ]
                }

            ]

        }
        console.log("dataUpdatedataUpdate", dataUpdate, id)
        const handleSuccess = () => {
            Toast.show({
                message: "User update successfully..!",
                timeout: 4000,
                intent: 'success'
            })
            this.props.actions.getUser(id);

        }
        const handleFailure = () => {
            Toast.show({
                message: "User update failed",
                timeout: 10000,
                intent: 'danger'

            })
        }
        this.props.profileActions.updateOrgUser(dataUpdate, id, handleSuccess, handleFailure)
        this.props.profileActions.updateUser(profileData, id, handleSuccess, handleFailure)
    }

    
    onSend = () => {
        const {
            emailVal,
            mappingView,
            mappingCreate,
            mappingEdit,
            mappingDelete,
            questionView,
            questionCreate,
            questionEdit,
            questionDelete,
            interviewView,
            interviewCreate,
            interviewEdit,
            interviewDelete,
            courseView,
            courseCreate,
            courseEdit,
            courseDelete,
            reportView,
            reportCreate,
            reportEdit,
            reportDelete,
            studentsView,
            studentsCreate,
            studentsEdit,
            studentsDelete,
            dashboardView,
            dashboardCreate,
            dashboardEdit,
            dashboardDelete,
            counselorReportView,
            counselorReportCreate,
            counselorReportEdit,
            counselorReportDelete,
            isCounsellor
        } = this.state
        let data = {
            email: emailVal,
            is_counselor: isCounsellor ? 1 : 0,
            access: [
                {
                    "name": "MAPPING",
                    "operations": [
                        mappingView ? 1 : 0,
                        mappingCreate ? 1 : 0,
                        mappingEdit ? 1 : 0,
                        mappingDelete ? 1 : 0,

                    ]
                },
                {
                    "name": "QUESION",
                    "operations": [
                        questionView ? 1 : 0,
                        questionCreate ? 1 : 0,
                        questionEdit ? 1 : 0,
                        questionDelete ? 1 : 0
                    ]
                },
                {
                    "name": "INTERVIEW",
                    "operations": [
                        interviewView ? 1 : 0,
                        interviewCreate ? 1 : 0,
                        interviewEdit ? 1 : 0,
                        interviewDelete ? 1 : 0
                    ]
                },
                {
                    "name": "COURSE",
                    "operations": [
                        courseView ? 1 : 0,
                        courseCreate ? 1 : 0,
                        courseEdit ? 1 : 0,
                        courseDelete ? 1 : 0
                    ]
                },
                {
                    "name": "REPORT",
                    "operations": [
                        reportView ? 1 : 0,
                        reportCreate ? 1 : 0,
                        reportEdit ? 1 : 0,
                        reportDelete ? 1 : 0
                    ]
                },
                {
                    "name": "STUDENTS",
                    "operations": [
                        studentsView ? 1 : 0,
                        studentsCreate ? 1 : 0,
                        studentsEdit ? 1 : 0,
                        studentsDelete ? 1 : 0
                    ]
                },
                {
                    "name": "DASHBOARD",
                    "operations": [
                        dashboardView ? 1 : 0,
                        dashboardCreate ? 1 : 0,
                        dashboardEdit ? 1 : 0,
                        dashboardDelete ? 1 : 0
                    ]
                },
                {
                    "name": "COUNSELOR-REPORT",
                    "operations": [
                        counselorReportView ? 1 : 0,
                        counselorReportCreate ? 1 : 0,
                        counselorReportEdit ? 1 : 0,
                        counselorReportDelete ? 1 : 0
                    ]
                }

            ]
        }



        const handleSuccess = () => {
            Toast.show({
                message: "Invitation sent successfully..!",
                timeout: 4000,
                intent: 'success'
            })
            this.props.history.push('/members')
        }
        const handleFailure = () => {
            Toast.show({
                message: "User already exists",
                timeout: 10000,
                intent: 'danger'

            })
        }
        console.log(data)
        this.props.actions.userAccess(handleSuccess, handleFailure, data)
    }
    onback = () => {
        this.setState({
            stateID: ''
        })
        this.props.actions.userDataReset()
        this.props.history.goBack();
    }
    render() {
        const { accessData: { user: { user } }, userData } = this.props
        console.log("user", user, "this.props.accessData", this.props.accessData)

        const {
            mappingView,
            mappingCreate,
            mappingEdit,
            mappingDelete,
            questionView,
            questionCreate,
            questionEdit,
            questionDelete,
            interviewView,
            interviewCreate,
            interviewEdit,
            interviewDelete,
            courseView,
            courseCreate,
            courseEdit,
            courseDelete,
            reportView,
            reportCreate,
            reportEdit,
            reportDelete,
            studentsView,
            studentsCreate,
            studentsEdit,
            studentsDelete, emailVal, edit, finalData,
            dashboardView,
            dashboardCreate,
            dashboardEdit,
            dashboardDelete,
            counselorReportView,
            counselorReportCreate,
            counselorReportEdit,
            counselorReportDelete,
            isCounsellor } = this.state;
        console.log("finalDatafinalData", finalData)
        return (
            <TickComponent
                isSuperuser={user.is_superuser}
                mappingView={mappingView}
                mappingCreate={mappingCreate}
                mappingEdit={mappingEdit}
                mappingDelete={mappingDelete}
                questionView={questionView}
                questionCreate={questionCreate}
                questionEdit={questionEdit}
                questionDelete={questionDelete}
                interviewView={interviewView}
                interviewCreate={interviewCreate}
                interviewEdit={interviewEdit}
                interviewDelete={interviewDelete}
                courseView={courseView}
                courseCreate={courseCreate}
                courseEdit={courseEdit}
                courseDelete={courseDelete}
                reportView={reportView}
                reportCreate={reportCreate}
                reportEdit={reportEdit}
                reportDelete={reportDelete}
                studentsView={studentsView}
                studentsCreate={studentsCreate}
                studentsEdit={studentsEdit}
                studentsDelete={studentsDelete}
                emailVal={emailVal}
                onCheckChange={this.onCheckChange}
                onDisableCheck={this.onDisableCheck}
                changeText={this.changeText}
                onSend={this.onSend}
                isLoading={this.props.isLoading}
                edit={edit}
                finalData={finalData}
                userData={userData}
                onback={this.onback}
                isCounsellor={isCounsellor}
                onUpdate={this.onUpdate}
                dashboardView={dashboardView}
                dashboardCreate={dashboardCreate}
                dashboardEdit={dashboardEdit}
                dashboardDelete={dashboardDelete}
                counselorReportView={counselorReportView}
                counselorReportCreate={counselorReportCreate}
                counselorReportEdit={counselorReportEdit}
                counselorReportDelete={counselorReportDelete}
            />
        )
    }
}

const mapStateToProps = (state) => {
    console.log(state, "state.............................")
    return {
        isLoading: state.userRole.isLoading,
        accessData: state.Auth,
        userData: state.userRole.userData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
        profileActions: bindActionCreators(profileActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TickTableContainer));
