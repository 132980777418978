import React from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { DateInput } from "@blueprintjs/datetime";
import { RadioGroup, Radio } from "@blueprintjs/core";
import moment from 'moment';
import {
    Label
} from "@blueprintjs/core";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import action from '../../../modules/actions'
import './NoDetails.scss';

const Invoice = (props) => {
    const { onChangeInput, currencyinvoiceType, invoiceDate, amountInvoice } = props;
    return (
        <div className="noDetails">
           <p>No details available for the given status</p>
        </div>
    )
}
export default Invoice;
