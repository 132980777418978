import React from "react";
import { Button } from "@blueprintjs/core";
import { Row, Col, Badge } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import { getRole } from '../../../utils';
import "./MembersMarkup.scss"


const MembersMarkup = (props) => {
    const { item, onEdit } = props;
    console.log("item", item)
    let organizations = null;
    if (item && item.UserOrganizations && item.UserOrganizations.length) {
        organizations = item.UserOrganizations.map(organization => <h6><Badge variant="warning">{organization.Organization.name}</Badge></h6>)
    }
    return (
        <Card className="members-markup container-fluid bg-white">
            <Row>
                <Col md="8" xs="8" className="text-left">
                    <h6>{item.first_name ? item.first_name.charAt(0).toUpperCase() + item.first_name.slice(1) : ''}{" "}
                        {item.last_name ? item.last_name.charAt(0).toUpperCase() + item.last_name.slice(1) : ''}{" "}
                        <a href={`mailto:${item.email}`} target="_top">({item.email})</a>
                    </h6>
                    <p><strong>Advisor Code</strong> {item.advisor_code ? item.advisor_code : ''}</p>

                    <p><strong>Created:</strong> {item.created_at ? moment(item.created_at).format('LLLL') : ''}</p>
                </Col>
                <Col md="2" xs="2" className="editbtn text-center">
                    <OverlayTrigger
                        placement={'top'}
                        delay={{ hide: 200 }}
                        overlay={<Popover className="tool-tip" id="tooltip-disabled">Edit</Popover>}>
                        <FontAwesomeIcon className="icon-btn" onClick={() => onEdit(item.user_id)} icon="edit" />
                    </OverlayTrigger>
                </Col>
                {/* <Col md="2" xs="2" className="text-center">{getRole(item.status)}</Col> */}
            </Row>
        </Card>
    )
}

export default MembersMarkup

