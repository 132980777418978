export let filterMonths = [
  {
  "value": "january",
  "dataField": "January"
  },
  {
  "value": "february",
  "dataField": "February"
  },
  {
  "value": "march",
  "dataField": "March"
  },
  {
  "value": "april",
  "dataField": "April"
  },
  {
      "value": "may",
      "dataField": "May"
  },
  {
      "value": "june",
      "dataField": "June"
  },
  {
      "value": "july",
      "dataField": "July"
  },
  {
      "value": "august",
      "dataField": "August"
  },
  {
      "value": "september",
      "dataField": "September"
  },
  {
      "value": "october",
      "dataField": "October"
  },
  ,
  {
      "value": "november",
      "dataField": "November"
  },
  {
      "value": "december",
      "dataField": "December"
  },
]


export let monthsArr= [    
 {
   "value": "01",
   "label": "Jan"
 },
 {
   "value": "02",
   "label": "Feb"
 },
 {
   "value": "03",
   "label": "Mar"
 },
 {
   "value": "04",
   "label": "Apr"
 },
 {
   "value": "05",
   "label": "May"
 },
 {
   "value": "06",
   "label": "Jun"
 },
 {
   "value": "07",
   "label": "Jul"
 },
 {
   "value": "08",
   "label": "Aug"
 },
 {
  "value": "09",
  "label": "Sep"
},
 {
   "value": "10",
   "label": "Oct"
 },
 {
   "value": "11",
   "label": "Nov"
 },
 {
   "value": "12",
   "label": "Dec"
 }
]
